import { useEffect, useState } from 'react'
import ICON_GS from "../../imgComponents/icons/google_sheets.png";
import "./exportgs.css";
import MODAL_TABLE from '../modal/modal_Table';


export const Exportgs = (props) => {
  const [tokenClient, setTokenClient] = useState(null);
  const [access_token, setAccessToken] = useState(null);
  const [tokenReady, setTokenReady] = useState(false);
  const [bandera, setbandera] = useState(false);

  const [modalT, setmodalT] = useState(false);


  useEffect(() => {
    initTokenClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Creación de Google Sheets
  var CLIENT_ID = '301722987834-pec5a9vlojn6redpcneblrgd2ckqdj05.apps.googleusercontent.com';
  var API_KEY = 'AIzaSyBTswKLvFOwWbnlFqwQMqpUgUEL3LQu7ls';
  var DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];

  function getToken() {
    console.log("gettoken()");
    tokenClient.requestAccessToken();
  }

  async function initTokenClient() {
    const Google = await window.google;
    setTokenClient(
      Google?.accounts?.oauth2?.initTokenClient({
        client_id: "301722987834-pec5a9vlojn6redpcneblrgd2ckqdj05.apps.googleusercontent.com",
        scope: 'https://www.googleapis.com/auth/spreadsheets',
        discoveryDocs: DISCOVERY_DOCS,
        prompt: 'consent',
        callback: (tokenResponse) => {
          setAccessToken(tokenResponse.access_token);
          setTokenReady(true); // establece tokenReady en verdadero después de obtener el token
        },
      })
    );
  }

  // Inicio de sesión anterior
  function handleClientLoad() {

    console.log("metodo handleClientLoad ");
    window.gapi.load('client:auth2', initClient);
  }

  function appendPre(message) {
    console.log("metodo appenPre");
    var pre = document.getElementById('content');
    var textContent = document.createTextNode(message + '\n');
    pre.appendChild(textContent);
  }

  function initClient() {
    console.log("metodo initCLiente");
    window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
    }).then(function () {
      getToken();
    }, function (error) {
      console.log("error grave")
      appendPre(JSON.stringify(error, null, 2));
    });
  }
  async function datasheet() {
    setbandera(true);
    await props.handleshet();
  }

  function handledibujarsheet() {
    console.log(props.gs);
    if (bandera) {
      setmodalT(true);
      createSpreadsheetAndPopulateWithCsv(props.gs);
    }
  }

  useEffect(() => {
    handledibujarsheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gs])

  useEffect(() => {
    if (tokenReady) { // Verifica si se ha obtenido el token antes de ejecutar la función handledibujarsheet
      datasheet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenReady]);

  useEffect(() => {
    if (!bandera && props.gs) {
      setbandera(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gs]);



  function createSpreadsheetAndPopulateWithCsv(csv) {
    const spreadsheet = {
      properties: {
        title: props.nametable
      },
      sheets: [
        {
          properties: {
            title: 'My Sheet'
          }
        }
      ]
    };
    window.gapi.client.sheets.spreadsheets.create({
      resource: spreadsheet
    }).then(response => {
      const spreadsheetId = response.result.spreadsheetId;

      // const data = csv.split('\n').map(row => row.split(','));

      const data = csv.split('\n').map(row => {
        const rowWithSpecialChars = row.replace(/"([^"]*)"/g, (match, p1) => {
          const replaced = p1.replace(/,/g, '|');
          return `"${replaced}"`;
        });
        const rowArray = rowWithSpecialChars.split(',').map(value => {
          const withoutQuotes = value.replace(/^"|"$/g, ''); // remove double quotes at the beginning and end of the value
          return withoutQuotes.replace(/\|/g, ','); // replace '|' with commas
        });
        return rowArray;
      });


      const range = 'My Sheet!A1';
      window.gapi.client.sheets.spreadsheets.values.update({
        spreadsheetId,
        range,
        valueInputOption: 'USER_ENTERED',
        resource: {
          values: data
        },
      }).then(() => {
        setbandera(false);
        setmodalT(false);
        const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetId}`;
        window.open(spreadsheetUrl, '_blank');

      });
    });
  }

  return (
    <>
      <img
        src={ICON_GS}
        className='icon-export-pdf'
        onClick={() => {
          tokenReady ? datasheet() : handleClientLoad();
        }
        }
        title="Exportar GSheets"
        alt=''
      ></img>
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
    </>

  )
}