function SEARCH_FILTERS(props) {
  const visibleFilters = props?.search_filters?.filter((filter) => filter?.display !== 'none');
  const gridTemplateColumns = visibleFilters?.map((filter) => (filter?.fr === null ? '1fr' : `${filter?.fr}fr`)).join(' ');

  return (
    <div className="grid-container-search_filters" style={{ gridTemplateColumns }}>
      {visibleFilters?.map((filter, index) => (
        <div key={index} style={{ padding: filter?.padding ? `${filter?.padding}px` : '0' }}>
          {filter?.slot}
        </div>
      ))}
    </div>
  );
}

export default SEARCH_FILTERS;
