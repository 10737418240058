import React, { useEffect, useState, useMemo } from "react";
import TableContainer from "../../main_components/Table/TableContainer";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/table.css";
import "../../components_cfdi/modal.css";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import { StickyTable, Row, Cell } from "react-sticky-table";

import SubTable from "../../components_cfdi/invoice/consult_invoice/detail/detail_invoce";
import {
  get_supplier_invoices,
  delete_supplier_invoices,
  donwload_invoices,
} from "../../services/expenses/expenses";
import {
  base64toBlobxml,
  base64toBlob,
} from "../../main_components/donwloadfuncion/download";
import tache from "../../imgComponents/borrar.png";
import PDF from "../../imgComponents/pdf.png";
import XML from "../../imgComponents/xml.png";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

const ConsultarFacturasProvedores = () => {
  const [data, setData] = useState([]);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    try {
      var dta = await get_supplier_invoices(fi, ff);
      setData(dta.data);
      setOpen(false);
    } catch (err) {
      console.log(err);
      setOpen(false);
      setData([]);
    }
  };

  const handle_delete_invoice = async (id) => {
    setOpen(true);
    try {
      await delete_supplier_invoices(id);
      setOpen(false);
    } catch (err) {
      setOpen(false);
    }
  };

  const handle_download_invoice = async (id, file_name, document_type) => {
    setOpen(true);

    try {
      var data = await donwload_invoices(id, file_name, document_type);
      setOpen(false);
      var dt = data.data.file;
      var blob;
      if (document_type === "xml") {
        blob = base64toBlobxml(dt);
        console.log("entra aqui");
      } else {
        blob = base64toBlob(dt);
      }
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      var arr = file_name?.split("/");
      downloadLink.download = arr[4] + `.${document_type}`;
      downloadLink.click();
      setOpen(false);
    } catch (err) {
      setOpen(false);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Ac.",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          return (
            <div>
              <div className="img-cancel">
                <img
                  alt=""
                  src={tache}
                  onClick={async () => {
                    const result = await Confirm(
                      "¿Esta usted seguro de eliminar esta factura?",
                      "Eliminar factura de proveedor",
                      "Si",
                      "No"
                    );

                    if (result) {
                      handle_delete_invoice(row.original.id);
                    } else {
                    }
                  }}
                ></img>
                <img
                  src={XML}
                  alt=""
                  onClick={() =>
                    handle_download_invoice(
                      row.original.id,
                      row.original.filename,
                      "xml"
                    )
                  }
                ></img>
                <img
                  src={PDF}
                  alt=""
                  onClick={() =>
                    handle_download_invoice(
                      row.original.id,
                      row.original.filename,
                      "pdf"
                    )
                  }
                ></img>
              </div>
            </div>
          );
        },
        whiteSpace:"normal",
        maxWidth: 50,
        minWidth: 50,
        width: 50,
        className: "justify_data"
      },

      {
     
        Header: "Fecha",
        accessor: "invoice_date",
        whiteSpace:"normal",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Serie",
        accessor: "serie",
        id: "seriee",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "cancel",
        Header: "Folio",
        accessor: "folio",
      },
      {
        Header: "Cliente",
        accessor: "name_business_partner",
        whiteSpace:"normal",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "justify_data"
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "RFC",
        accessor: "rfc_business_partner",
      },

      {
        Header: "Subtotal",
        accessor: (d) => {
          return d.subtotal
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.subtotal)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.subtotal);
        },
        whiteSpace:"normal",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "right_data"
      },
      {
        Header: "Descuento",
        accessor: (d) => {
          return d.discount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount);
        },
        whiteSpace:"normal",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "right_data"
      },
      {
        Header: "Impuesto",
        accessor: (d) => {
          return d.tax
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.tax)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.tax);
        },
        whiteSpace:"normal",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "right_data"
      },
      {
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
        whiteSpace:"normal",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "right_data"
      },

      {
       
        Header: "Método de pago",
        whiteSpace:"normal",
        accessor: "payment_method",
        id: "payment",
        Filter: SelectColumnFilter,
        filter: "equals",
        maxWidth: 90,
        minWidth: 70,
        width: 80,
      },
      {
        maxWidth: 110,
        minWidth: 90,
        width: 100,
        whiteSpace:"normal",
        Header: "Tipo de comprobante",
        accessor: "tipodecomprobante",
        Filter: SelectColumnFilter,
        filter: "equals",
        className: "center_data"
      },

      {
        Filter: SelectColumnFilter,
        filter: "equals",
        Header: "Estado",
        accessor: (d) => {
          return d.cancel === true ? "CANCELADO" : "VIGENTE";
        },
      },

      {
        Header: "Usuario",
        accessor: "username",
        maxWidth: 110,
        minWidth: 90,
        width: 100,
        whiteSpace:"normal",
        className: "justify_data"

      },
    ],
    []
  );

  return (
    <div>
      <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>
      <BREADCRUMBS
        niveles={[
          {
            label: "FACTURAS DE PROVEEDORES",
            path: null,
          },
        ]}
        new_version={true}
        modul="CONSULTAR FACTURAS PROVEEDORES V2"
        path="/consultaFacturasProvedores_v2" 
      ></BREADCRUMBS>
      <div className="container-proveedor">
        <div className="div1c">
          <input
            type="text"
            className="fi04"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>

         
        </div>
        <div className="div2c">
        <input
            type="text"
            className="fi04"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="div3c">
          <button
            className="button"
            onClick={() => handleSubmit()}
            type="button"
          >
            Buscar
          </button>
        </div>
      </div>

      <div className="TableProveedor">
        <StickyTable>
          <TableContainer
            columns={columns}
            exportar={true}
            nametable={"Facturas Proveedores"}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable
                    data={data}
                    columns={columns}
                    defaultPageSize={3}
                    showPagination={false}
                    renderRowSubComponent={(row) => {
                      return (
                        <div style={{ padding: "20px" }}>algo más....</div>
                      );
                    }}
                  />
                </div>
              );
            }}
          />
        </StickyTable>
      </div>
    </div>
  );
};

export default ConsultarFacturasProvedores;
