//PAQUETERIAS
import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { MaterialReactTable, useMRT_DisplayColumns } from 'material-react-table';

import { MRT_Localization_ES } from 'material-react-table/locales/es';
import "../tableclientes.css"
import NotificationSystem from "react-notification-system";

//COMPONENTES
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { formattedCurrentDate, formattedPreviousDate } from '../../main_components/date/day';
import { BREADCRUMBS } from '../../main_components/pagination/breadcrumbs';
import SEARCH_FILTERS from './tools/shearch_filter';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import { pagination } from "../../main_components/pagination/pagination";
import AlertResponse from '../../main_components/alerts/alertResponse';
import data_metodo_pago from "../../dataComponets/metodo_pago.json";
import data_regimen_fiscal from "../../dataComponets/regimen.json";
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { update_bp } from '../../services/businesspartners/businesspartners';
import SPECIAL_ACTIONS from "./tools/special_actions"
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { update_null_identifiers } from '../../main_components/methods_v2/auxiliar_funtion';
config.autoAddCss = false;

//CSS

const BUSINESS_PARTNERT_TABLE = () => {

  const [grouping_dta, setgrouping_dta] = useState([]);
  const [columnfilters_dta, setcolumnfilters_dta] = useState([]);

  const [data, setData] = useState([]);
  const [total_rows, settotal_rows] = useState("");
  const [object_original_data, setobject_original_data] = useState([]);
  const [object_original_data_deep, setobject_original_data_deep] = useState([]);
  const [success_data, setsuccess_data] = useState([]);
  const [error_data, seterror_data] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const [status, setstatus] = useState(true);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const notificationSystemRef = useRef();
  const [validationErrors, setValidationErrors] = useState({});
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowId_save, seteditingRowId_save] = useState(null);
  const select_method_paid = data_metodo_pago.map(d => d.value).filter(d => d !== "");
  const select_reg_fisc = data_regimen_fiscal.map(d => d.value).filter(d => d !== "");
  const [saveQueue, setSaveQueue] = useState([]);
  const [def_table, setdef_table] = useState();
  const [bp_dta, setbp_dta] = useState();
  const [query_filters_dt, setquery_filters_data] = useState();



  var regex = new RegExp('^[A-ZÑ&]{3,4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z\\d]{3}$');
  var emailRegex = new RegExp('^[\\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}$');

  const update_identifiers =
    [{ code: 'string' },
    { business_partner: 'string' },
    { rfc: 'string' },
    { person_type: 'string' },
    { paymethod: "string" },
    { cfdi_use: "string" },
    { zip_code: "string" },
    { tax_regime: "string" },
    { email: "string" },
    { num_reg_id_trib: "string" },
    { tax_residence: "string" }];



  const handle_consult_data = async (msjAler) => {
    setmodalT(true);
    setIsLoading(true);
    setData([]);
    try {
      var dt_partner = await pagination(3, "bp", null);
      var dta = update_null_identifiers(dt_partner, update_identifiers)
      setmodalGeneral(false);
      setmodalT(false);
      setData(dta === null ? [] : dta);
      setobject_original_data(dta === null ? [] : JSON.parse(JSON.stringify(dta)));
      setobject_original_data_deep(dta === null ? [] : JSON.parse(JSON.stringify(dta)));
      setIsLoading(false);

    } catch (err) {
      alert("entra en el error");
      var errroM = err?.response?.data?.errorMessage;
      setData([]);
      setmodalT(false);
    }
  };




  const handle_success_filter = (a, b) => {
    handle_consult_data();
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }


  const handleEditClick = (row, table) => {
    setEditingRowId(row.id);
    table.setEditingRow(row);
  };

  const handleSave = (row) => {
    setSaveQueue(prevQueue => [...prevQueue, row]);
  };

  const processSaveQueue = async () => {
    if (saveQueue.length > 0) {
      const rowToSave = saveQueue[0];
      await saveRow(rowToSave);
      setSaveQueue(prevQueue => prevQueue.slice(1));
    }
  };

  const handleSuccess = (id) => {
    setsuccess_data((prevSuccessData) => {
      if (!prevSuccessData.includes(id)) {
        const newSuccessData = [...prevSuccessData, id];
        setTimeout(() => {
          removeSuccessData(id);
        }, 30000);

        return newSuccessData;
      }
      return prevSuccessData;
    });
  };

  const removeSuccessData = (id) => {
    setsuccess_data((prevSuccessData) => {
      return prevSuccessData.filter(item => item !== id);
    });
  };

  const handleError = (id) => {
    seterror_data((prevErrorData) => {
      if (!prevErrorData.includes(id)) {
        return [...prevErrorData, id];
      }
      return prevErrorData;
    });
  };

  const saveRow = async (row) => {
    if (!row._valuesCache.business_partner || row._valuesCache.business_partner.trim() === "") {
      setValidationErrors({
        ...validationErrors,
        business_partner: 'Campo obligatorio, favor de llenar'
      });
      return;
    }
    if (!regex.test(row._valuesCache.rfc.trim())) {
      setValidationErrors({
        ...validationErrors,
        rfc: 'Campo obligatorio, favor de proporcionar un RFC valido'
      });
      return;
    }
    if (row._valuesCache.zip_code?.trim()?.length !== 5) {
      setValidationErrors({
        ...validationErrors,
        zip_code: 'Campo obligatorio, favor de proporcionar un Código postal valido valido'
      });
      return;
    }

    seteditingRowId_save(row.id);
    var replace_data = row._valuesCache;
    var row_original_data = row.original;
    Object.keys(replace_data).forEach(key => { row_original_data[key] = replace_data[key]; });

    var dtt = row.original.id;
    var object_original = data;
    const indice = object_original.findIndex(objeto => objeto.id === dtt);

    try {
      var save_obj;

      save_obj = {
        business_partner: {
          business_partner: replace_data.business_partner,
          rfc: replace_data.rfc,
          tax_regime: replace_data.tax_regime,
          person_type: replace_data.rfc.length === 12 ? "MORAL" : "FÍSICA",
          cfdi_use: replace_data.cfdi_use,
          tax_residence: replace_data.tax_residence,
          num_reg_id_trib: replace_data.num_reg_id_trib,
          metodo_pago: replace_data.metodo_pago,
          paymethod: replace_data.paymethod,
          capital_regime: replace_data.capital_regime,
          email: replace_data.email,
          enable: true,
        },
        address: {
          zip_code: replace_data.zip_code === "" ? null : replace_data.zip_code,
        }

      }
      setData(object_original);

      update_bp(save_obj, row.original.id)
        .then(() => {
          if (indice !== -1) {
            object_original[indice] = row_original_data;
          }
          setData(object_original);
          setobject_original_data(JSON.parse(JSON.stringify(object_original)));
          handleSuccess(row.original.id);
          seteditingRowId_save(null);
          setmodalT(false);

        })
        .catch(error => {
          setobject_original_data(JSON.parse(JSON.stringify(object_original_data)));
          handleError(row.original.id);
          setData(object_original_data);
          seteditingRowId_save(null);
        });

    } catch (error) { }

    def_table.setEditingRow(false)
    setEditingRowId(null);
  };

  const handleCancelEditRow = ({ table }) => {
    def_table.setEditingRow(false)
    setEditingRowId(null);
  };


  const getRowStyles = (row, position) => {
    if (error_data.includes(row.original.id)) {
      return {
        backgroundColor: 'rgb(216 115 115)',
        color: 'white',
        textAlign: position + " !important"
      };
    } else if (success_data.includes(row.original.id)) {
      return {
        backgroundColor: 'rgb(140 198 140)',
        color: 'white',
        textAlign: position + " !important"
      };
    }
    return {
      textAlign: "center !important"
    };
  };


  const isAnyRowPinned = (rows) => {
    return rows.some(row => row.getIsPinned());
  };


  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };



  const columns = useMemo(
    () => [
      {
        id: "edit",
        header: "Editar",
        size: 75,
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
        Cell: ({ row, table }) => {
          setdef_table(table);
          var isEditing = row.id === editingRowId ? true : false;
          var isSave = row.id === editingRowId_save ? true : false;

          if (isSave === true) {
            return (
              <div>
                <CircularProgress color="secondary" />
              </div>
            );
          } else {
            return (
              <div>
                {isEditing
                  ?
                  <SaveIcon style={{ cursor: "pointer" }} onClick={() => handleSave(row, table)}></SaveIcon>
                  : <EditIcon style={{ cursor: "pointer" }} onClick={() => handleEditClick(row, table)}></EditIcon>}
              </div>
            );
          }

        },
      },
      {
        header: "Código",
        id: "code",
        accessorKey: "code",
        enableEditing: false,
        size: 145,
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
        muiTableBodyRowProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
      },
      {
        header: "Socio de Negocios",
        id: "business_partner",
        accessorKey: "business_partner",
        size: 180,
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.business_partner,
          helperText: validationErrors?.business_partner,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              business_partner: undefined,
            }),
        },
      },
      {
        header: "RFC",
        accessorKey: "rfc",
        id: "rfc",
        size: 145,
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.rfc,
          helperText: validationErrors?.rfc,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              rfc: undefined,
            }),
        },
      },
      {
        header: "Tipo de persona",
        id: "person_type",
        accessorKey: "person_type",
        size: 145,
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
        editVariant: 'select',
        editSelectOptions: ["MORAL", "FÍSICA"],
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.cfdi_use,
          helperText: validationErrors?.cfdi_use,
        },
        filterVariant: 'select',
        enableColumnFilterModes: false
      },

      {
        header: "Método de pago",
        id: "paymethod",
        accessorKey: "paymethod",
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
        editSelectOptions: select_method_paid,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.paymethod,
          helperText: validationErrors?.paymethod,
        },
      },
      {
        header: "Uso de CFDI",
        id: "cfdi_use",
        accessorKey: "cfdi_use",
        accessorFn: (d) => {
          return d.cfdi_use
        },
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
        editVariant: 'select',
        editSelectOptions: ["S01", "G01", "G03"],
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.cfdi_use,
          helperText: validationErrors?.cfdi_use,
        },
        filterVariant: 'select',
        enableColumnFilterModes: false

      },
      {
        header: "Código Postal",
        accessorKey: "zip_code",
        id: "zip_code",
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.zip_code,
          helperText: validationErrors?.zip_code,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              zip_code: undefined,
            }),
        },

      },
      {
        header: "Régimen Fiscal",
        accessorKey: "tax_regime",
        id: "tax_regime",
        accessorFn: (d) => {
          return d.tax_regime
        },
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
        editSelectOptions: select_reg_fisc,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.tax_regime,
          helperText: validationErrors?.tax_regime,
        },
      },
      {
        header: "Correo Electrónico",
        accessorKey: "email",
        id: "email",
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
      },
      {
        header: "Número de Registro de Identificación Tributaria",
        size: 300,
        accessorKey: "num_reg_id_trib",
        id: "num_reg_id_trib",
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),

        }),
      },
      {
        header: "Residencia Fiscal",
        accessorKey: "tax_residence",
        id: "tax_residence",
        muiTableBodyCellProps: ({ row }) => ({
          style: getRowStyles(row, "center"),
        }),
      },


    ],
    [editingRowId, validationErrors, data, object_original_data, editingRowId_save, error_data, success_data],
  );




  useEffect(() => {
    if (saveQueue.length > 0) {
      processSaveQueue();
    }
  }, [saveQueue]);


  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (saveQueue.length > 0) {
        alert("no se puede cerrar")
        e.preventDefault();
        e.returnValue = 'Es posible que algunos de tus datos no se guarden completamente, ya que aún esta en proceso de actualizar cierta información';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [saveQueue]);


  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (error_data.length > 0) {
        alert("no se puede cerrar")
        e.preventDefault();
        e.returnValue = 'Es posible que algunos de tus datos no se guarden completamente, ya que aún esta en proceso de actualizar cierta información';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [error_data]);


  useEffect(() => {
    var ndata = data;
    const indice = data?.findIndex(objeto => objeto.id === bp_dta?.id);
    ndata[indice] = bp_dta;
    if (indice > 0) {
      setData([])
      setTimeout(() => {
        setData(ndata)
        setTimeout(() => {
          handleSuccess(indice);
        }, 100);
      }, 100);
    }
  }, [bp_dta])


  useEffect(() => {
    handle_consult_data();
  }, []);

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])


  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR SOCIOS DE NEGOCIOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        rowSelection={rowSelection}
      ></BREADCRUMBS>

      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>

      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>

      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        createDisplayMode={'row'}
        editDisplayMode={'row'}
        enableEditing={true}
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        onStateChange={handleStateChange}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        enableColumnDragging={false}
        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        onEditingRowCancel={handleCancelEditRow}
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyRowProps={({ row, table }) => {
          const { density } = table.getState();
          const heightStyle = {
            height: row.getIsPinned()
              ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69}px`
              : undefined,
          };

          const colorStyle = error_data.includes(row.original.id)
            ? { backgroundColor: 'red', color: 'white' }
            : success_data.includes(row.original.id)
              ? { backgroundColor: 'green', color: 'white' }
              : {};

          return {
            sx: {
              ...heightStyle,
              ...colorStyle,
            },
          };
        }}

        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <SPECIAL_ACTIONS
                  handle_business_partner={handle_consult_data}
                ></SPECIAL_ACTIONS>
              </div>
            ),
            enableHiding: true,
            muiTableBodyCellProps: ({ row }) => ({
              style: getRowStyles(row),
            }),
          },
          'mrt-row-actions': {
            enableHiding: true,
            muiTableBodyCellProps: ({ row }) => ({
              style: getRowStyles(row),

            }),
          },
          'mrt-row-actions': {
            enableHiding: true,
            muiTableBodyCellProps: ({ row, table }) => {
              settotal_rows(table)
              const disableActions = isAnyRowPinned(table.getPrePaginationRowModel().rows);
              return {
                style: {
                  ...getRowStyles(row),
                  pointerEvents: disableActions ? 'none' : 'auto',
                  color: disableActions ? 'grey' : 'inherit',
                },
              };
            },
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
            muiTableBodyCellProps: ({ row }) => ({
              style: getRowStyles(row),

            }),
          },
        }}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        renderRowActionMenuItems={({ row, closeMenu, index }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS
            closeMenu={closeMenu}
            setmodalT={setmodalT}
            setmenssage={setmenssage}
            setmodalGeneral={setmodalGeneral}
            row={row}
            isPin={row.getIsPinned()}
            setbp_dta={setbp_dta}
            setIsLoading={setIsLoading}
            handleSubmit={handle_consult_data}
          />
        ]}

        // renderTopToolbarCustomActions={({ }) => (
        //   <SEARCH_FILTERS
        //     status={status}
        //     setstatus={setstatus}
        //     handle_business_partner={() => handle_consult_data()}
        //   ></SEARCH_FILTERS>
        // )}

        renderToolbarInternalActions={({ table }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            share_filter={true}
            query_filters={{ di: "" }}
            settotal_rows={settotal_rows}
            handle_success_filter={handle_success_filter}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"REPORTE MODULO SOCIO DE NEGOCIOS DEL " + fi?.format('YYYY-MM-DD') + " AL " + ff?.format('YYYY-MM-DD')}
            update_identifiers={update_identifiers}
            query_filters_dt={query_filters_dt}
            handle_get_consult={handle_consult_data}
          />
        )
        }
      />

    </div >
  );
};

export default BUSINESS_PARTNERT_TABLE;
