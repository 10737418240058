import { useEffect, useState } from 'react';
import "./chat.css";
import "./chatmini.css";
import { Auth } from 'aws-amplify';
import { CONVERSATION } from './conversation';
import { CHATMINI } from './chatmini/chatmini';

export const CHAT = (props) => {

  const [tipo, settipo] = useState("");
  const [currentUser, setcurrentuser] = useState("");



  const handle_init = async () => {
    try {

      localStorage.setItem("id_chat_smit", "none");
      localStorage.setItem("band_chats", "false");
      localStorage.setItem("name_chat_destino_smit", "");
      localStorage.setItem("imgfondo", "default");

      const currentUsernew = await Auth.currentAuthenticatedUser();
      
      setcurrentuser(currentUsernew);
      
      var permisosUsuario = currentUsernew.attributes['custom:roles'];
      var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "")
      var permisos = permisoUsuarioReplace?.split(",");
      var permisoSupport = permisos?.filter((x) => x === "ROLE_SUPPORT");
      settipo(permisoSupport.length > 0 ? "soporte" : "usuario");
    } catch (error) {
      console.log('Error al obtener los datos del usuario:', error);
    }
  }
  useEffect(() => {
    handle_init();
  }, [])

  return (
    <>
      {

        tipo === "" ? <></> :
          <CHATMINI
            origen={tipo === "soporte" ? "soporte@herramientasinformaticas.com" : currentUser}
            tipo={tipo === "soporte" ? "soporte" : "usuario"}
          ></CHATMINI>
      }
      {
        props?.mini ?
          <></> :
          <div className='chat-container'>
            {
              tipo === "" ? <></> :
                tipo === "soporte" ?
                  <CONVERSATION
                    origen={"soporte@herramientasinformaticas.com"}
                    tipo={"soporte"}
                  /> :
                  <CONVERSATION
                    origen={currentUser}
                    tipo={"usuario"}
                  />
            }
          </div>
      }
    </>

  )
}