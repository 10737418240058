//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
//COMPONENTES
import { SelectColumnFilter } from "../../filters";
import Set_tax from "./Set_Taxes";
import SubTable from "./Get_Configure_Tax";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import "./TableTax.css";
import "../../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../../imgComponents/save.png";
import Spinner from "../../../imgComponents/S.gif";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_taxes_configs, put_taxes_configs } from "../../../services/invoices/invoice";
import TableContainer from "../../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import ACTIONS_SPECIALS from "./tools/actions_specials";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import NotificationSystem from 'react-notification-system';


const TableConfigureTaxes_v2 = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [menssage, setMenssage] = useState(true);
  const [modalT, setmodalT] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");
  const notificationSystemRef = useRef();

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    setIsLoading(true);
    setMenssage("Consultando configuraciones");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_taxes_configs();
            const data = d === null ? [] : d.data;
            setData(data);
            setmodalT(false);
            setIsLoading(false);
            setMenssage("");
          } catch (err) {
            console.log("error", err);
            setData([]);
            setmodalT(false);
            setIsLoading(false);
            setMenssage("")
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);


  const columns = useMemo(
    () => [
      {
        id: "default",
        header: "Default",
        accessorKey: "default",
        accessorFn: (d) => {
          return d.default === true ? "Si" : "NO"

        },

      },
      {
        id: "rate",
        header: "Tarifa",
        accessorKey: "rate",
      },
      {
        id: "type",
        header: "Tipo",
        accessorKey: "type",
      },
      {
        id: "c_tipo_factor",
        header: "Tipo Factor",
        accessorKey: "c_tipo_factor",
      },

      {
        id: "c_impuesto",
        header: "Código",
        accessorKey: "c_impuesto",
      },
      {
        id: "tax_name",
        header: "Impuesto",
        accessorKey: "tax_name",
      },

    ],
    []
  );


  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const update_identifiers =
    [
      { default: 'string' },
      { rate: 'string' },
      { type: 'String' },
      { c_tipo_factor: 'string' },
      { c_impuesto: 'string' },
      { tax_name: 'string' }
    ];

  return (
    <div className="new_table_v2">
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR IMPUESTOS", path: "consult_taxes" },
          { label: "CONFIGURACIONES DE IMPUESTOS", path: null }
        ]
      }
        old_version={true}
        modul="CONFIGURACIONES DE IMPUESTOS V1"
        path="/consult_configure_taxes"
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>

      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        onStateChange={handleStateChange}
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        enableColumnDragging={false}
        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <ACTIONS_SPECIALS
            closeMenu={closeMenu}
            setmodalT={setmodalT}
            row={row}
            handleSubmit={handleSubmit}
            alertas={alertas}
          />
        ]}
        renderToolbarInternalActions={({ table }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table}
            file_name={"COnfiguracion de impuestos"}
            update_identifiers={update_identifiers}
          />
        )
        }
      />

    </div >
  );
};

export default TableConfigureTaxes_v2;
