import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import delet from "../../../../imgComponents/borrar.png";
import edit from "../../../../imgComponents/save.png";
import { delete_concepts_taxes } from "../../../../services/invoices/invoice";
import Put_Tax_Concept from "../Put_Tax_Concept";

const RENDER_ROW_ACTION_MENU_ITEMS_SUBTABLE = (props) => {

    const DeleteSector =async () =>{

        props?.setmodalT(true);
        try{
            var rf_token = await refresh_token();
            if(rf_token===true){
            setTimeout(async() => {
                try{
                const d = await delete_concepts_taxes(props?.id, props?.row.original.id);
                console.log(d);
                props?.alertas("Concepto eliminado con éxito", true);
                props?.setmodalT(false);
                props.handleSubmit();
            }catch(err){
                console.log("error", err);
                props?.setmodalT(false);
                props?.alertas("Error.,"+ err.response.data.errorMessage, false); 
                }
            }, 1000);
            }else{}
        }catch(err){
            console.log(err);
        }
    }
   

    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async() =>{
                    props.closeMenu();
                    await CustomDialog(
                        <Put_Tax_Concept
                            base={props?.row.original.base}
                            tasa_o_cuotan={props?.row.original.tasa_o_cuota}
                            tipo_factorn={props?.row.original.tipo_factor}
                            impueston={props?.row.original.impuesto}
                            person_typen={props?.row.original.person_type}
                            tipon={props?.row.original.tipo}
                            id_Impueston={props?.row.original.id}
                            id_tax_config_fk={props?.row.original.id_tax_config_fk}
                            handleSubmit2={props?.handleSubmit}
                            setmodalT = {props?.setmodalT}
                            alertas = {props?.alertas}
                            id = {props?.id}
                        ></Put_Tax_Concept>,
                      {
                        className: "modalTItle",
                        title: "Editar impuesto del concepto",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={edit}
                        title="Editar impuesto del concepto"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar impuesto del concepto
            </MenuItem>
            <MenuItem
                key={3}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar este Impuesto del Concepto?','Eliminar Impuesto del Concepto','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={delet}
                        title="Eliminar Impuesto del Concepto"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Impuesto del Concepto
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS_SUBTABLE;
