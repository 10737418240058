import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import {detele_sector } from "../../../services/businesspartners/businesspartners";
import OPERATIONS_SECTORS from "./op_sector";

import Details from "../../../imgComponents/lupa.png";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try {
            var rf_token = await refresh_token();
            if(rf_token===true){
                setTimeout(async() => {
                try{
                   const dt = await detele_sector(props?.row.original.id);
                   console.log(dt);
                   props.alertas("Sector Eliminado", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handleSubmit();
                }catch(err){
                   props.setmodalT(false);
                   props.setreconsult(false);
                  if(err.response.status===409){
                    props?.alertas("Aún hay puntos de venta relacionados al Sector", false);
                  }else{
                    props?.alertas("Error, no se pudo eliminar el sector", false);    
                  }
                }
                }, 1000);
            }else{}    
        } catch (err) {
        console.log(err);
        }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el Sector?','Eliminar Sector','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Sector"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Sector
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATIONS_SECTORS 
                             operation={"EDIT"}  
                             row={props?.row} 
                             handleSubmit={props?.handleSubmit} 
                             alertas={props?.alertas} 
                             setmodalT={props?.setmodalT}
                         />,
                    {
                        className: "custom-modal",
                        title: "EDITAR SECTOR",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Sector"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Sector
            </MenuItem>
            <MenuItem
                key={3}
                onClick={ async () =>{
                    props.closeMenu();
                    sessionStorage.setItem("id_sector", props?.row.original.id);
                    sessionStorage.setItem("sectorname", props?.row.original.name_sector);
                    sessionStorage.setItem("id_ruta", props?.row.original.id_route_fk);
                    window.location = "/consult_point_of_sale_sector_v2";
                  } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Details}
                        title="Puntos de Venta del Sector"
                        className=""
                    ></img>
                </ListItemIcon>
                Puntos de Venta del Sector
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
