import React from 'react';
import TextField from "@mui/material/TextField";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';


export const FILTER_MOV = (props) => {

    console.log(props);

    const handleOnSearch = () => {
        props?.setpointsale("");
      };
      const handleOnHover = () => {};
      const handleOnSelect5 = (item) => {
          props?.setpointsale(item.idPointSale);
      };
      const handleOnFocus5 = () => {
        console.log("Focused");
      };
      const handleOnClear = () => {
        console.log("Cleared");
      };
    

    return (
    <div className="search-mov-frz-war">
        <div className="smfw1">
        <TextField
                select
                label="Tipo Movimiento"
                value={props?.mov}
                defaultValue={""}
                InputLabelProps={{shrink: true}}
                SelectProps={{native: true}}
                sx={{ m: 1.5, width: "100%"}}
                onChange={(event) => 
                {
                    props?.setmov(event.target.value)
                }
                }
            >
                <option key={1} value={"1"}>
                    {"Almacén"}
                </option>
                <option key={2} value={"2"}>
                    {"Punto de Venta"}
                </option>
                <option key={3} value={"3"}>
                    {"Ventas"}
                </option>
            </TextField>
        </div>
        <div className="smfw2">
        {
            props?.mov==="1"?
            <TextField
                select
                label="Almacén"
                value={props?.id_warehouse}
                defaultValue={""}
                InputLabelProps={{shrink: true}}
                SelectProps={{native: true}}
                sx={{ m: 1.5, width: "100%"}}
                onChange={(event) => props?.setid_warehouse(event.target.value)}  
            >
                <option key={0} value={"vacio"}>
                    {"Selecciona Almacén"}
                </option>
                
                {
                props?.datawarehouse?.map(item=>(
                    <option key={item.id} value={item.id}>
                    {item.warehouse_name}
                    </option>
                ))
                }
        </TextField>:
        props?.mov==="2"?
        <div className="autocomplete-pos">
            <ReactSearchAutocomplete
            items={props?.pointsales}
            fuseOptions={{ keys: ["code","name"]}}
            resultStringKeyName="codeAndName"
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect5}
            onFocus={handleOnFocus5}
            onClear={handleOnClear}
            styling={{
                height: "35px",
                border: "1px solid gray",
                borderRadius: "4px",
                backgroundColor: "white",
                color: "black",
                iconColor: "black",
                lineColor: "black",
                placeholderColor: "black",
                zIndex: 10
            }}
            showIcon={true}
            placeholder="Punto de Venta"      
            />
        </div>:
        props?.mov==="3"?
        <div className="input-num-serie">
            <TextField
            label="Número Económico"
            value={props?.scan}
            defaultValue={""}
            InputLabelProps={{shrink: true}}
            sx={{ m: 1.5, width: "100%", height: "50px"}}
            onChange={(event) => props?.setscan(event.target.value)}  
            ></TextField>
        </div>
        
        :<></>
        }
    </div>
    <div className="smfw3">
    <input
        type="text"
        className="fi"
        value={props?.fi}
        onFocus={(e) => {
        e.currentTarget.type = "date";
        e.currentTarget.focus();
        }}
        placeholder="Fecha Inicial"
        onChange={(event) => props?.setfi(event.target.value)}
  
    ></input>
    </div>
    <div className="smfw4">
        <input
        type="text"
        className="ff"
        value={props?.ff}
        onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
        }}
        placeholder="Fecha Final"
        onChange={(event) => props?.setff(event.target.value)}
    ></input>
    </div>
    <div className="smfw5">
            <button className="button" onClick={props?.handleSubmit}>Buscar</button>
    </div>
    </div>
  )
}
