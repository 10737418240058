import { CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import vis from "../../../imgComponents/lupa.png";
import SubTable2 from "../Get_datail_Sales_v2";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <SubTable2 
                            verData={true}
                            row={props?.row}
                            datei={props?.datei}
                            datef={props?.datef}
                            route={props?.route} 
                            nosale={props?.nosale}                      
                        />,
                    {
                        className: "custom-modal-3",
                        title: "Detalles de clasificación",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={vis}
                        title="Detalles de clasificación"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles de clasificación
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
