//yarn add react-google-slides
import CountdownClock from "./countdownclock";
import "./dashboard.css";
import { Auth } from 'aws-amplify';
import GoogleSlides from 'react-google-slides';
import AddCardIcon from '@mui/icons-material/AddCard';
import c1 from "../../imgComponents/iconos/icono-1.png";
import c2 from "../../imgComponents/iconos/icono-2.png";
import c3 from "../../imgComponents/iconos/icono-3.png";
import proximg from "../../imgComponents/iconos/apps.png";
import imgpublicidad from "../../imgComponents/iconos/AWS.png";
import { arrStorage } from "../../main_components/menu/data";
import { get_config_user, post_config_user } from "../../services/dashboard/dashboard";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { useEffect, useRef, useState } from "react";
import { CHAT } from "../../chat_support/chat";

export const DASHBOARD = () => {
    const tokenAcces = sessionStorage.getItem("getAccesTokenRefresh");

    // Verificar si tokenAcces no es nulo antes de acceder a sus propiedades
    if (tokenAcces) {
        // Intenta decodificar el tokenAcces solo si es una cadena válida

        try {
            const tokenParts = tokenAcces.split('.');
            if (tokenParts.length === 3) {
                const payload = JSON.parse(atob(tokenParts[1]));
                const expirationDate = new Date(payload.exp * 1000); // Multiplicamos por 1000 para convertir a milisegundos
                // Resto del código utilizando expirationDate
                var expirationDateAcces = new Date(0);
                expirationDateAcces.setUTCSeconds(JSON.parse(atob(tokenAcces.split('.')[1])).exp);
            } else {
                console.error("El token de acceso no tiene el formato esperado.");
            }
        } catch (error) {
            console.error("Error al decodificar el token de acceso:", error);
            expirationDateAcces = null

        }
    } else {
        // Manejo de error o acción cuando tokenAcces es nulo
        console.error("El token de acceso es nulo.");
    }

    const modulos = [
        {
            id: 1,
            modulo: "Gestión de Rutas",
            des: "En este módulo el usuario podrá asignar a las rutas un supervisor responsable",   
            icono: c2,
        },
        {
            id: 2,
            modulo: "Cuentas de Pago",
            des: "En este módulo el usuario podrá vincular cuentas de pago a usuarios para la carga de gastos",
            icono: c1
        },
        
    ]

    var r = localStorage.getItem("rolesfav");
    var u = localStorage.getItem("userIdfav");
    const [datafav, setdatafav] = useState([]);

    const analisis = () => {

        const d = arrStorage(r, u);
        const nuevoJSON = d?.map((valor) => {
            if (valor.forStorage === null || valor.forStorage === undefined || valor.restriccion === "none" || valor.restriccion === "DENEGADO"
            ) {
                return null; // O puedes devolver un objeto vacío {} si prefieres mantener el elemento en el arreglo pero sin datos.
            } else {
                return {
                    ruta: valor.ruta,
                    name: valor.name,
                };
            }
        }).filter(Boolean); // Filtramos para eliminar los elementos que fueron mapeados a null (o vacíos) debido a las condiciones.

        return nuevoJSON;
    }

    const handlepost = async (tour) => {

        var nuevoJSON = analisis();

        var data = {
            tour: tour,
            favorites: nuevoJSON,
        }
        try {
            const d = await post_config_user(data);
            if (tour === "true") {
                window.location.reload();
            } else {
                setTimeout(() => {
                    handleconf();
                }, 1500);
            }
        } catch (err) {
            console.log(err);
        }

    }
    function handlehi() {
        window.open("https://www.herramientasinformaticas.com", "_blank");
    }

    useEffect(() => {
        handleconf();
    }, [])

    const handleconf = async () => {
        const arrLocalStorage = arrStorage(r, u);
        try {
            const d = await get_config_user();
            const modulosSeleccionados = arrLocalStorage.filter((item) =>
                d.data.favorites.some((itemf) => itemf.name === item.name)
            );
            setdatafav(modulosSeleccionados);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='container-dashboard'>
            {/* <CHAT mini={true}></CHAT> */}
            <div className='welcome-container'>
                <div className='title-dashboard'>
                    BIENVENIDO A OD2
                </div>
                <div className='button-tour'>
                   
                </div>
            </div>
            <div className='grid-dashboard'>
                <div className='seccion1' id="seccion1">
                    <div className="seccion-noticias">
                        <div className="title-noticias">
                            Nuevas Actualizaciones
                        </div>
                        <div className="presentacion-noticias">
                            <GoogleSlides
                                width="100%"
                                height="310px"
                                slidesLink="https://docs.google.com/presentation/d/1PpLq219koLCBInu5dQTLhxasFij4hMVLg6ZGAgyxjEA"
                                slideDuration={5}
                                position={1}
                                showControls
                            />
                        </div>
                    </div>
                </div>
                <div className='seccion2' id="seccion2">
                    <div className="hi-seccion">
                        <div className="title-hi">
                            Herramientas Informáticas
                        </div>
                        <div className="publicidad-hi">
                            <img src={imgpublicidad} alt="" onClick={() => handlehi()} />
                        </div>

                    </div>
                </div>
                <div className='seccion3'>
                    <div className='token-container' id="token-container">
                        <div className='token-title'>
                            Tiempo de Sesión
                        </div>
                        <div className='token-time'>
                            Tiempo restante:<CountdownClock expirationDateAcces={expirationDateAcces} />
                        </div>
                    </div>
                    <div className="moduls-act" id="moduls-act">
                        <div className="title-moduls">
                            Nuevos Módulos
                        </div>
                        <div className="moduls-list">
                            {
                                modulos?.map(item => (
                                    <div key={item?.id} className="cart-modulo">
                                        <div className="cart-modulo-icon">
                                            <img src={item.icono} alt="" />
                                        </div>
                                        <div className="cart-modulo-title">
                                            <p className="name-modu">{item.modulo}</p>
                                            <p className="description-modu">{item.des}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="seccion4" id="seccion4">
                    <div className="title-seccion4">
                        Módulos Favoritos Guardados
                    </div>
                    <div className="modulos-fav-star">
                        {
                            datafav?.map((valor, index) => {
                                return (
                                    <div className={`$carta${index}`}>
                                        <a key={datafav?.name} href={valor.ruta} className="cartas">
                                            <div className="iconofav">
                                                {valor.icono}
                                            </div>
                                            <div className="desfav">
                                                {valor.name}
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="seccion5" id="seccion5">
                    <div className="title-moduls">
                        Proximos Módulos
                    </div>
                    <div className="moduls-list-prox">
                        <div className="img-prox">
                            <img src={proximg} alt="" />
                        </div>
                        <div className="title-prox">
                            Sin Información
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
