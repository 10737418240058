import axios from "axios";
import { numberData } from "./numberData";
import config from "../../config";

export async function pagination_v2(
  divnum,
  origen,
  yy,
  weeks,
  fi,
  ff,
  id_bp,
  id_pdv,
  debts,
  params
) {
  var promises = [];
  const urlbp = `${config?.baseURLBP}`;
  const urlinvoice = `${config?.baseURLIVC}`;


  const urlreport = `${config?.baseURLREPORT}`;

  var url = "";
  var urlnumber = "";

  switch (origen) {
    case "bp":
      url = `${urlbp}/development/businesspartners?role=FACTURA&`;
      urlnumber = `${urlbp}/development/businesspartners/number`;
      break;
    case "sumary":
      url = `${urlreport}/prod/annual-summary-by-pos?`;
      urlnumber = `${urlreport}/prod/annual-summary-by-pos/number`;
      break;
    case "sumaryWeek":
      url = `${urlreport}/prod/weektly-summary-by-pos?`;
      urlnumber = `${urlreport}/prod/weektly-summary-by-pos/number`;
      break;
    case "pointofsales":
      url = `${urlbp}/development/pointsofsale?`;
      urlnumber = `${urlbp}/development/pointsofsale/number`;
      break;
    case "debts":
      url = `https://b7z3hke4a8.execute-api.us-east-1.amazonaws.com/od/debts?di=${fi}&df=${ff}&`;
      urlnumber = `https://b7z3hke4a8.execute-api.us-east-1.amazonaws.com/od/debts`;
      break;
    case "ot":
      url = `${urlbp}`;
      break;
    case "cabinets":
      url = `https://ua5rm92fs2.execute-api.us-east-1.amazonaws.com/development/cabinets?`;
      urlnumber = `https://ua5rm92fs2.execute-api.us-east-1.amazonaws.com/development/cabinets/number`;
      break;
    case "aux1":
      url = `${urlbp}/development/businesspartners/auxiliary-consultation?`;
      urlnumber = `${urlbp}/development/businesspartners/auxiliary-consultation`;
      break;
    case "aux2":
      url = `${urlbp}/development/pointsofsale/auxiliary-consultation?`;
      urlnumber = `${urlbp}/development/pointsofsale/auxiliary-consultation`;
      break;

    case "invoices":
      url = `${urlinvoice}/od/invoices?di=2024-04-23&df=2024-05-24&type=null&`;
      urlnumber = `${urlinvoice}/od/invoices?count=true&di=2024-04-23&df=2024-05-24&type=null`;
      break;

    default:
      url = urlbp;
      break;
  }

  var totalData = numberData(
    urlnumber,
    yy,
    weeks,
    origen,
    fi,
    ff,
    id_bp,
    id_pdv,
    debts,
    params
  );

  try {
    const resultado = await totalData;
    const miVariable = resultado;
    if (miVariable === 0) {
      return [];
    }

    if (origen === "debts") {
      if (miVariable >= 0 && miVariable <= 500) {
        promises.push(
          axios.get(`${url}offset=${0}&limit=${miVariable}`, {
            params: {
              id_partner: id_bp,
              id_pos: id_pdv,
              paid: debts === "" ? null : debts
            },
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          })
        );

        const results = await Promise.all(promises);
        const ndata = results.flatMap((result) => result?.data || []);
        return ndata;
      }
    }


    if (origen === "invoices") {
      if (miVariable >= 0 && miVariable <= 500) {
        promises.push(
          axios.get(`${url}offset=${0}&limit=${miVariable}`, {
            params: params,
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          })
        );

        const results = await Promise.all(promises);
        const ndata = results.flatMap((result) => result?.data || []);
        return ndata;
      }
    }

    const offsetInt = Math.floor(miVariable === null ? 0 : miVariable / divnum);
    const lim = offsetInt * divnum;
    const limitF = null ? 0 : miVariable - lim;
    const limitFinal = limitF < 0 ? lim : limitF;

    var cont = divnum - 1;

    var parameters = {};
    if (origen === "sumaryWeek") {
      parameters = {
        weeks: weeks,
        date: yy,
      };
    } else {
      parameters = { year: yy };
    }

    if (origen === "debts") {
      parameters = {
        id_partner: id_bp,
        id_pos: id_pdv,
        paid: debts === "" ? null : debts

      };
    }
    if (origen === "invoices") {
      parameters = params;
      console.log(parameters)
    }

    for (let i = 0; i <= cont; i++) {
      promises.push(
        axios.get(
          `${url}offset=${i === 0 ? 0 : offsetInt * i}&limit=${i === cont ? offsetInt * (i + 1) + limitFinal : offsetInt * (i + 1)
          }`,
          {
            params: parameters,
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
      );
    }

    const results = await Promise.all(promises);
    const ndata = results?.flatMap((result) => result?.data || []);
    return ndata;
  } catch (error) {
    return [];
  }
}
