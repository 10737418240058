//PAQUETERIAS
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NotificationSystem from "react-notification-system";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";

//COMPONENTES
import { formattedCurrentDate, formattedPreviousDate } from "../../main_components/date/day";
import SEARCH_FILTERS from './tools/shearch_filter';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import AlertResponse from '../../main_components/alerts/alertResponse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SHEARCH_IMG from "../../imgComponents/lupa.png"
import DETAIL_SALE from './tools/detail_sale';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import SliderComponent from './tools/sliderComponent';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#81980f'
    },
    secondary: {
      main: '#00bcd4'
    }
  }
});
const DOMY_DATA = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedCurrentDate));
  const notificationSystemRef = useRef();

  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});


  const [g_total, setg_total] = useState(0);


  const update_identifiers = [
    { usuario: "string" },
    { date_censo: "string" },
    { folio: "string" },
    { agency: "string" },
    { business_partner_name: "string" },
    { business_partner_rfc: "string" },
    { business_partner_address: "string" },
    { business_partner_neighborhood: "string" },
    { business_partner_code: "string" },
    { business_partner_town: "string" },
    { business_partner_state: "string" },
    { business_partner_sales: "string" },
    { business_partner_phone: "string" },
    { business_partner_phone2: "string" },
    { business_partner_reference: "string" },
    { point_of_sale_name: "string" },
    { point_of_sale_code_sap: "string" },
    { point_of_sale_address: "string" },
    { point_of_sale_neighborhood: "string" },
    { point_of_sale_zip_code: "string" },
    { point_of_sale_town: "string" },
    { point_of_sale_state: "string" },
    { point_of_salechannel: "string" },
    { point_of_sale_route: "string" },
    { point_of_sale_phone: "string" },
    { point_of_sale_phone2: "string" },
    { point_of_sale_reference: "string" },
    { point_of_sale_nearest_corner: "string" },
    { point_of_sale_visit_day: "string" },
    { point_of_sale_frequency: "string" },
    { payment_method: "string" },
    { credit_days: "string" },
    { credit_limit: "string" },
    { discount_type: "string" },
    { discount_percentage: "string" },
    { cabinet_type_cabinet: "string" },
    { cabinet_economic_number: "string" },
    { cabinet_serie: "string" },
    { cabinet_brand: "string" },
    { cabinet_model: "string" }
  ];



  const [data2, setData2] = useState([]);
  const [data_original, setdata_original] = useState([]);

  const [sales_data, set_sales_data] = useState([]);
  const [sales_returns, set_sales_returns] = useState([]);

  const [sales_data_original, set_sales_data_original] = useState([]);
  const [sales_returns_original, set_sales_returns_original] = useState([]);

  const [open, setopen] = useState(false);
  const [ruta, setruta] = useState("vacio");
  const [routedata, setroutedata] = useState([]);
  const [ffi, setffi] = useState(true);
  const [routes, setroutes] = useState([]);
  const [select_route, setselect_route] = useState("1");
  const [select_vendor, setselect_vendor] = useState("");

  const [vendor, setvendor] = useState([]);
  const [selectVendro, setselectVendro] = useState("Selecciona un usuario");
  const [msj_data, setmsjdata] = useState("");
  const [checked_data, setchecked_data] = useState(true);
  const [filtersColumsView, setfiltersColumsView] = useState(true);

  const [code_product, set_code_product] = useState("");

  const [query_filters_dt, setquery_filters_data] = useState("");





  const consult_censo = async (query_filters_flag, query_filters_data) => {
    setIsLoading(true)

    var config = {
      method: "get",
      url: "https://oe6i9zr4ok.execute-api.us-east-1.amazonaws.com/prod/censos",
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        var sortedFilteredData;
        if (query_filters_flag) {
          setquery_filters({ fi: query_filters_data.fi, ff: query_filters_data.ff })
          setff(dayjs(query_filters_data.ff));
          setffi(dayjs(query_filters_data.fi));
          sortedFilteredData = response.data.filter(item => item.date_censo >= query_filters_data.fi && item.date_censo <= query_filters_data.ff).sort((a, b) => new Date(b.date_censo) - new Date(a.date_censo));
        } else {
          setquery_filters({ fi: fi.format('YYYY-MM-DD'), ff: ff.format('YYYY-MM-DD') })
          sortedFilteredData = response.data.filter(item => item.date_censo >= fi.format('YYYY-MM-DD') && item.date_censo <= ff.format('YYYY-MM-DD')).sort((a, b) => new Date(b.date_censo) - new Date(a.date_censo));
        }

        setData(sortedFilteredData)
        setIsLoading(false)

      })
      .catch(function (error) {
        setData([])
        setIsLoading(false)

      });
  }


  const handle_filter_product_sales = () => {
    if (JSON.stringify(data) === JSON.stringify(data_original.filter((d) => d.sales_products !== undefined))) {
      setData(data_original);
    } else {
      setData(data_original.filter((d) => d.sales_products !== undefined));
    }

  }


  const filter_Sales = (c) => {
    const ventasConDetalleFiltrado = [];
    const returnConDetalleFiltrado = [];

    for (const venta of sales_data_original) {
      const detallesVenta = venta.details;
      const detalleFiltrado = detallesVenta.find(
        (detalle) => detalle.code === c
      );

      if (detalleFiltrado) {
        ventasConDetalleFiltrado.push(venta);
      }
    }

    if (ventasConDetalleFiltrado.length > 0) {
      const ventasConDetalleAgrupado = {
        productoCodigo: c,
        ventas: [],
      };

      for (const ventaFiltrada of ventasConDetalleFiltrado) {
        ventasConDetalleAgrupado.ventas.push(ventaFiltrada);
      }

      console.log("Ventas con el producto de código", c);
      console.log(ventasConDetalleAgrupado);

      set_sales_data(ventasConDetalleAgrupado?.ventas);
      set_code_product(ventasConDetalleAgrupado?.productoCodigo);
    } else {
      console.log(`No se encontraron ventas con el producto de código ${c}`);
      set_sales_returns([]);

    }

    for (const venta of sales_returns_original) {
      const detallesVenta = venta.details;
      const detalleFiltrado = detallesVenta.find(
        (detalle) => detalle.code === c
      );

      if (detalleFiltrado) {
        returnConDetalleFiltrado.push(venta);
      }
    }

    if (returnConDetalleFiltrado.length > 0) {
      const retunsConDetalleAgrupado = {
        productoCodigo: c,
        return: [],
      };

      for (const ventaFiltrada of returnConDetalleFiltrado) {
        retunsConDetalleAgrupado.return.push(ventaFiltrada);
      }

      console.log("returns con el producto de código", c);
      console.log(retunsConDetalleAgrupado);

      set_sales_returns(retunsConDetalleAgrupado?.return);

    } else {
      console.log(
        `No se encontraron los retuns con el producto de código ${c}`
      );
      set_sales_returns([]);
    }
  };


  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handle_success_filter = (a, b, c) => {
    consult_censo(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={c} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  };

  useEffect(() => {
    if (total_rows !== "") {
      var countVisibleColumns = total_rows?.getState()?.columnVisibility;

      // Check if countVisibleColumns is not an empty object
      if (countVisibleColumns && Object.keys(countVisibleColumns).length > 0) {
        console.log(countVisibleColumns?.length);
        console.log(countVisibleColumns);

        var elementosFalse = Object?.entries(countVisibleColumns)
          .filter(([clave, valor]) => valor === false);
        var cantidadFalse = elementosFalse.length;
        console.log(cantidadFalse)
        // Perform further operations using cantidadFalse or elementosFalse
      } else {
        // Handle the case when countVisibleColumns is an empty object
        console.log("countVisibleColumns is an empty object");
      }
    }
    console.log(total_rows)

  }, [total_rows]);

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])


  const columns = useMemo(
    () => [
      {
        header: "Acciones",
        size: 200,
        id: "ac",
        accessorFn: (d) => {
          return (
            <div>
              {/* <img src={SHEARCH_IMG} alt='Ver Detalles' title='Ver Detalles' style={{ cursor: "pointer" }}></img> */}
              <span title='Ver Imagenes del Congelador' style={{ cursor: "pointer" }}>
                <InsertPhotoIcon style={{ color: "#f39c12" }}
                  onClick={async () => {
                    console.log(d?.cabinet?.photos)

                    try {
                      await CustomDialog(

                        <SliderComponent
                          imageUrls={d?.cabinet?.photos}
                        ></SliderComponent>
                        ,
                        {
                          className: "custom-modal-6",
                          title: `Historial de censos`,
                          showCloseIcon: true,
                        }
                      );
                    } catch (err) {

                    }
                  }}
                ></InsertPhotoIcon>
              </span>
              <span title='Ver Historial' style={{ cursor: "pointer", display: d?.repeated_count ? "" : "none" }}>
                <KeyboardDoubleArrowDownIcon
                  onClick={async () => {

                    try {
                      await CustomDialog(
                        <DETAIL_SALE
                          update_identifiers={update_identifiers}
                          economic_number={d?.cabinet?.economic_number}
                          date_censo={d?.date_censo}
                        ></DETAIL_SALE>
                        ,
                        {
                          className: "custom-modal-2",
                          title: `Historial de censos`,
                          showCloseIcon: true,
                        }
                      );
                    } catch (err) {

                    }
                  }}
                ></KeyboardDoubleArrowDownIcon>
              </span>

            </div>
          )
        }
      },
      {
        header: "Responsable",
        size: 200,
        id: "usuario",
        accessorKey: "usuario"
      },
      {
        header: "Fecha",
        size: 150,
        id: "date_censo",
        accessorKey: "date_censo",
      },
      {
        header: "Folio",
        size: 150,
        id: "folio",
        accessorKey: "folio",
      },
      {
        header: "Agencia",
        size: 200,
        id: "agency",
        accessorKey: "agency",
      },

      {
        header: "Tipo de Movimiento",
        size: 250,
        id: "movement_type",
        accessorKey: "movement_type",
      },

      // DATOS DEL SOCIO DE NEGOCIOS
      {
        header: "Nombre del Propietario",
        size: 200,
        accessorKey: "business_partner_name",
        id: "business_partner_name",
        accessorFn: (d) => { return d?.business_partner?.name },
      },

      {
        header: "RFC",
        size: 200,
        id: "business_partner_rfc",
        accessorKey: "business_partner_rfc",
        accessorFn: (d) => { return d?.business_partner?.rfc },
      },
      {
        header: "Domicilio",
        size: 200,
        id: "business_partner_address",
        accessorKey: "business_partner_address",
        accessorFn: (d) => { return d?.business_partner?.address },
      },
      {
        header: "Colonia",
        size: 200,
        id: "business_partner_neighborhood",
        accessorKey: "business_partner_neighborhood",
        accessorFn: (d) => { return d?.business_partner?.neighborhood },
      },
      {
        header: "Código Postal",
        size: 200,
        id: "business_partner_code",
        accessorKey: "business_partner_code",
        accessorFn: (d) => { return d?.business_partner?.code },
      },

      {
        header: "Municipio",
        size: 200,
        id: "business_partner_town",
        accessorKey: "business_partner_town",
        accessorFn: (d) => { return d?.business_partner?.town },
      },
      {
        header: "Estado",
        size: 200,
        id: "business_partner_state",
        accessorKey: "business_partner_state",
        accessorFn: (d) => { return d?.business_partner?.state },
      },
      {
        header: "Venta Con",
        size: 200,
        id: "business_partner_sales",
        accessorKey: "business_partner_sales",
        accessorFn: (d) => { return d?.business_partner?.sales },
      },
      {
        header: "Teléfono 1 (CLIENTE)",
        size: 200,
        id: "business_partner_phone",
        accessorKey: "business_partner_phone",
        accessorFn: (d) => { return d?.business_partner?.phone },
      },
      {
        header: "Teléfono 2 (CLIENTE)",
        size: 200,
        id: "business_partner_phone2",
        accessorKey: "business_partner_phone2",
        accessorFn: (d) => { return d?.business_partner?.phone2 },
      },
      {
        header: "Referencia",
        size: 200,
        id: "business_partner_reference",
        accessorKey: "business_partner_reference",
        accessorFn: (d) => { return d?.business_partner?.reference },
      },

      // DATOS DEL PUNTO DE VENTA

      {
        header: "Nombre del Negocio",
        size: 250,
        id: "point_of_sale_name",
        accessorKey: "point_of_sale_name",
        accessorFn: (d) => { return d?.point_of_sale?.name },
      },

      {
        header: "Código SAP",
        size: 200,
        id: "point_of_sale_code_sap",
        accessorKey: "point_of_sale_code_sap",

        accessorFn: (d) => { return d?.point_of_sale?.code_sap },
      },
      {
        header: "Domicilio (NEGOCIO)",
        size: 200,
        id: "point_of_sale_address",
        accessorKey: "point_of_sale_address",
        accessorFn: (d) => { return d?.point_of_sale?.address },
      },
      {
        header: "Colonia (NEGOCIO)",
        size: 200,
        id: "point_of_sale_neighborhood",
        accessorKey: "point_of_sale_neighborhood",
        accessorFn: (d) => { return d?.point_of_sale?.neighborhood },
      },
      {
        header: "Código Postal (NEGOCIO)",
        size: 200,
        id: "point_of_sale_zip_code",
        accessorKey: "point_of_sale_zip_code",
        accessorFn: (d) => { return d?.point_of_sale?.zip_code },
      },

      {
        header: "Municipio (NEGOCIO)",
        size: 200,
        id: "point_of_sale_town",
        accessorKey: "point_of_sale_town",

        accessorFn: (d) => { return d?.point_of_sale?.town },
      },
      {
        header: "Estado (NEGOCIO)",
        size: 200,
        id: "point_of_sale_state",
        accessorKey: "point_of_sale_state",
        accessorFn: (d) => { return d?.point_of_sale?.state },
      },
      {
        header: "Canal de Venta (NEGOCIO)",
        size: 250,
        id: "point_of_salechannel",
        accessorKey: "point_of_salechannel",
        accessorFn: (d) => { return d?.point_of_sale?.channel },
      },
      {
        header: "Ruta (NEGOCIO)",
        size: 200,
        id: "point_of_sale_route",
        accessorKey: "point_of_sale_route",
        accessorFn: (d) => { return d?.point_of_sale?.route },
      },
      {
        header: "Teléfono 1 (Negocio)",
        size: 200,
        id: "point_of_sale_phone",
        accessorKey: "point_of_sale_phone",
        accessorFn: (d) => { return d?.point_of_sale?.phone },
      },
      {
        header: "Teléfono 2 (Negocio)",
        size: 200,
        id: "point_of_sale_phone2",
        accessorKey: "point_of_sale_phone2",
        accessorFn: (d) => { return d?.point_of_sale?.phone2 },
      },
      {
        header: "Referencia (NEGOCIO)",
        size: 200,
        id: "point_of_sale_reference",
        accessorKey: "point_of_sale_reference",
        accessorFn: (d) => { return d?.point_of_sale?.reference },
      },
      {
        header: "Esquina Más Cercana (NEGOCIO)",
        size: 250,
        id: "point_of_sale_nearest_corner",
        accessorKey: "point_of_sale_nearest_corner",
        accessorFn: (d) => { return d?.point_of_sale?.nearest_corner },
      },

      {
        header: "Días de Visita",
        size: 200,
        id: "point_of_sale_visit_day",
        accessorKey: "point_of_sale_visit_day",
        accessorFn: (d) => { return d?.point_of_sale?.visit_day },
      },
      {
        header: "Periodicidad",
        size: 200,
        id: "point_of_sale_frequency",
        accessorKey: "point_of_sale_frequency",

        accessorFn: (d) => { return d?.point_of_sale?.frequency },
      },

      {
        header: "Forma de Pago",
        size: 250,
        id: "payment_method",
        accessorKey: "payment_method"
      },
      {
        header: "Días de Crédito",
        size: 250,
        id: "credit_days",
        accessorKey: "credit_days"
      },
      {
        header: "Límite de Crédito",
        size: 250,
        id: "credit_limit",
        accessorKey: "credit_limit"
      },
      {
        header: "Tipo de Descuento",
        size: 250,
        id: "discount_type",
        accessorKey: "discount_type"
      },
      {
        header: "Descuento (%)",
        size: 250,
        id: "discount_percentage",
        accessorKey: "discount_percentage"
      },

      // DATOS DEL CONGELADOR

      {
        header: "Tipo",
        size: 200,
        id: "cabinet_type_cabinet",
        accessorKey: "cabinet_type_cabinet",
        accessorFn: (d) => { return d?.cabinet?.type_cabinet },
      },
      {
        header: "Número Económico",
        size: 250,
        id: "cabinet_economic_number",
        accessorKey: "cabinet_economic_number",

        accessorFn: (d) => { return d?.cabinet?.economic_number },
      },
      {
        header: "Serie",
        size: 200,
        id: "cabinet_serie",
        accessorKey: "cabinet_serie",

        accessorFn: (d) => { return d?.cabinet?.serie },
      },
      {
        header: "Marca",
        size: 200,
        id: "cabinet_brand",
        accessorKey: "cabinet_brand",

        accessorFn: (d) => { return d?.cabinet?.brand },
      },
      {
        header: "Modelo",
        size: 200,
        id: "cabinet_model",
        accessorKey: "cabinet_model",

        accessorFn: (d) => { return d?.cabinet?.model },
      },
      {
        header: "Latitud",
        id: "lat",
        accessorKey: "lat",
        accessorFn: (d)=>{
          return d?.lat ? d?.lat : ""
        }
      },
      {
        header: "Longitud",
        id: "lng",
        accessorKey: "lng",
        accessorFn: (d)=>{
          return d.lng  ? d.lng : ""
        }
      }


    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]

  );


  // useEffect(() => {
  //   consult_censo();
  // }, []);



  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>
      <BREADCRUMBS
        niveles={
          [
            { label: "CENSOS", path: null },
          ]
        }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        cards_secundary={true}

      ></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      {/* <ThemeProvider theme={theme}> */}
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          onStateChange={handleStateChange}
          enableExpandAll={false}
          initialState={{ showGlobalFilter: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important",  color: "white !important", } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }


        renderTopToolbarCustomActions={({ }) => (
          <SEARCH_FILTERS
            fi={fi}
            setfi={setfi}
            ff={ff}
            setff={setff}
            select_route={select_route}
            setselect_route={setselect_route}
            setselect_vendor={setselect_vendor}
            select_vendor={select_vendor}
            handleSubmit={consult_censo}
            filter_Sales={filter_Sales}
            handle_filter_product_sales={handle_filter_product_sales}
            total_rows={total_rows !== "" ? total_rows : 0}
            data={data}
          ></SEARCH_FILTERS>
        )}
        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            share_filter={true}
            query_filters={query_filters}
            settotal_rows={settotal_rows}
            handle_success_filter={handle_success_filter}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"CENSO DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
            update_identifiers={update_identifiers}
            handle_get_consult={consult_censo}
            query_filters_dt={query_filters_dt}

            />
        )
        }
      />
    </div >
  );
};

export default DOMY_DATA;