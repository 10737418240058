//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { StickyTable} from "react-sticky-table";
import { CustomDialog, useDialog } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "../SubTableContainer";
import ViewDetail_Detail_Bonus from "./ViewDetail_Detail_Bonus";
import {SelectColumnFilter,DefaultColumnFilter} from "../../components_cfdi/filters";
import { get_detail_bonus } from "../../services/reports/reports";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";
import "../sales/view_detail.css";
//IMAGENES
import Spinner from "../../imgComponents/S.gif";
import VER from "../../imgComponents/visualizar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_dashboard1_bonus2 } from "../../services/reports/dashboard";
import { ceil } from "lodash";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ViewDetail_Bonus_v2({ datei,datef,route,id_detail,filtro,note,dev,bonus}) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [Reconsulta, setReconsulta] = useState(false);
  const [ReconsultaDta, setReconsultaDta] = useState(false);
  const [cantidad, setcantidad] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const dialog = useDialog();

const handle_detail_bonus = async() =>{
    var promises = [];
    setReconsulta(true);

    console.log(ceil(dev+bonus)/100)
    var page= Math.ceil((dev+bonus)/100);
    console.log(page);

    try {
      for (let i = 1; i <= page; i++) {
        promises.push(
          await get_detail_bonus(datei,datef,route,filtro,note,i,id_detail)
        );
        console.log(i+ "==="+page)
        if(i===page){
          setReconsulta(false);
        }
      }
   
    const results = await Promise.all(promises);
    console.log(results);
    const ndata = results.flatMap((result) => result?.data || []);
    var ndt=ndata?.map((d)=>{
      var json={
        id: d.id,
        code: d.code,
        name: d.name,
        address: d.address,
        frequency: d.frequency,
        visit_days: d.visit_days,
        date: d.date,
        customer_returns: d.customer_returns,
        customer_bonus: d.customer_bonus,
        route: d.route,
        detail: d?.details?.map(detail => `${detail?.ticket} / ${detail?.note}`).join(", "),
        total:d?.details?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => {return previous + current;}, 0)
      }
      return json;
    })
    console.log(ndt);
    setData(ndt);

    } catch (error) {
      
    }
  } 

  useEffect(() => {
    handle_detail_bonus();
  }, []);

  const columns = useMemo(
    () => [

      // {
      //   header: "Acciones",
      //   id: "Acciones",

      //   Cell: ({ valor, row }) => {

      //     return (
      //       <div>

      //         <img
      //           alt=""
      //           title="Ver detalle"
      //           src={VER}
      //           className="centerText"
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <ViewDetail_Detail_Bonus
      //                 id_detail={id_detail}
      //                 id_id_detail={row.original.id}
      //                 datei={datei}
      //                 datef={datef}
      //                 route={route}
      //                 filtro={filtro}
      //                 note={note}
      //                 address={row.original.address}
      //                 pointSale={row.original.name}
      //               ></ViewDetail_Detail_Bonus>,
      //               {
      //                 title: " ",
      //                 className: "saleDetailTikect",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //           }}
      //         ></img>
      //       </div>
      //     );
      //   },
      // },
      {
        header: "Ruta",
        accessorKey: "route",
        // Filter:SelectColumnFilter
      },
      {
        header: "Código",
        accessorKey: "code",
      },
      {
        header: "Fecha",
        accessorKey: "date",
      },
      {
        header: "Punto de Venta",
        accessorKey: "name",
      },
      {
        header: "Devolución",
        accessorKey: "customer_returns",
      },
      {
        header: "Bonificación",
        accessorKey: "customer_bonus",
      },
      {
        header: "Frecuencia",
        accessorKey: "frequency",
      },
      {
        header: "Día de Visita",
        accessorKey: "visit_days",
      },
      {
        header: "Domicilio",
        accessorKey: "address",
      },   
      {
        header: "Detalles",
        accessorKey:"detail"
      },
      {
        header: "Total",
        accessorKey: "total"
      },
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  
                  variant="h6"
                  component="h2"
                >
                  <img  alt="" src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

        <div className="">
          
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowSelection
          enableRowActions
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          initialState={{ showGlobalFilter: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
                  muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              row={row}
              id_detail={id_detail}
              datei={datei}
              datef={datef}
              route={route}
              filtro={filtro}
              note={note}
            />
          ]}
      />
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default ViewDetail_Bonus_v2;
