import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const MAP_WITH_MARKER = (props) => {
  const [lng] = useState(props.lng);
  const [lat] = useState(props.lat);

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: Number(lat), lng: Number(lng) }}
      >
        <Marker
          position={{ lat: Number(lat), lng: Number(lng) }}
          icon={{
            fillOpacity: 1,
            rotation: 0,
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 2,
            animation: window.google.maps.Animation.DROP,
            draggable: true,
          }}
        />
      </GoogleMap>
    ))
  );

  return (
    <div>
      <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
          <div style={{ height: `300px`, width: "100%" }} />
        }
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default MAP_WITH_MARKER;
