import { useEffect, useState, useRef } from 'react';

import GroupIcon from '@mui/icons-material/Group';
import RoomIcon from '@mui/icons-material/Room';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { INVOICE_COMPRIMED_V2 } from '../Table/export/invoice_comprimed _v2';
import { jsPDF } from 'jspdf';


import {
  Box,
  MenuItem,
  IconButton,
} from '@mui/material';
import { CustomDialog } from "react-st-modal";
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
} from 'material-react-table';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import GoogleIcon from '@mui/icons-material/Google';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ReplyIcon from '@mui/icons-material/Reply';
import { EXPORT_GS_V2 } from '../Table/export_gs_v2';
import ReenvioCorreo from '../../components_cfdi/invoice/consult_invoice/report_email/send_invoice';

import INTERFAZ_CLIENTS from '../../component_unilever/Interface/clients';
import INTERFAZ_ROUTES from '../../component_unilever/Interface/routes';
import INTERFAZ_CABINETS from '../../component_unilever/Interface/cabinets';
import INTERFAZ_SALES from '../../component_unilever/Interface/sales';
import INTERFAZ_TICKETS from '../../component_unilever/Interface/tickets';
import PDF_CONFIG from './pdf-config';
import { SWITCH_OP } from './switch_op';
import { UPLOAD_FILE_CSV } from '../Table/upload/upload';
import SHARE_FILTER from './share_filter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import AlertResponse from '../../main_components/alerts/alertResponse';
import NotificationSystem from "react-notification-system";



const RENDER_TOOLBAR_INTERNAL_ACTIONS = (props) => {


  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  var a;
  const notificationSystemRef = useRef();
  props?.settotal_rows ? props?.settotal_rows(props?.table) : a = "";

  const fechaRegex = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun),\s\d{2}\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}\s\d{2}:\d{2}:\d{2}\sGMT$/;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const enable_colums = props.table.getFlatHeaders()?.map(d => d.column).map(d => d.columnDef).map(d => { return { accessorKey: d.accessorKey, header: d.header } }).filter(d => d.accessorKey !== undefined);
  const get_pre_pagination_row_model = props.table.getPrePaginationRowModel().rows.map((row) => {
    const columnIds = props.table.getAllColumns().map(column => column?.id);
    return columnIds.reduce((acc, columnId) => {
      acc[columnId] = row.getValue(columnId);
      return acc;
    }, {});
  });
  const order_colums = props.table.getState().columnOrder;

  const get_selected_row_model = props.table.getSelectedRowModel().rows?.map((row) => {
    const columnIds = props.table.getAllColumns().map(column => column?.id);
    return columnIds.reduce((acc, columnId) => {
      acc[columnId] = row.getValue(columnId);
      return acc;
    }, {});
  });

  const get_pre_pagination_row_model_zip = props.table.getPrePaginationRowModel().rows?.map(d => d.original);
  const get_selected_row_model_zip = props.table.getSelectedRowModel().rows?.map(d => d.original);

  const data_row = get_selected_row_model?.length === 0 ? get_pre_pagination_row_model : get_selected_row_model;
  const data_row_zip = get_pre_pagination_row_model_zip?.length === 0 ? get_selected_row_model_zip : get_pre_pagination_row_model_zip;




  const orderObject = data_row.map(objeto => orderObjectAccordingToArray(objeto, order_colums));

  const transform_object = orderObject.map(dataObject =>
    Object.entries(dataObject).reduce((filteredItem, [key, value]) => {
      if (enable_colums.some(o => o.accessorKey === key)) {
        filteredItem[key] = value;
      }
      return filteredItem;
    }, {})
  );

  const csvConfig = mkConfig({
    filename: props.file_name,
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });


  function orderObjectAccordingToArray(object, orderArray) {
    var orderedObject = {};
    orderArray.forEach(key => {
      if (object.hasOwnProperty(key)) {
        orderedObject[key] = object[key];
      }
    });
    return orderedObject;
  }


  const handle_click = (event) => { setAnchorEl(event.currentTarget) };

  const handle_close = () => { setAnchorEl(null) };

  const format_export = (dataArray, enableColumns) => {
    return dataArray.map(dataObject => {
      const modifiedItem = {};
      Object.keys(dataObject).forEach(key => {
        const header = enableColumns.find(o => o.accessorKey === key)?.header;
        modifiedItem[header || key] = dataObject[key];
      });
      return modifiedItem;
    });
  }

  const handle_calculate_totals = (data, schema) => {
    const result = [...data];
    const totals = {};

    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        const schemaItem = schema.find((s) => Object.keys(s)[0] === key);
        const type = schemaItem ? Object.values(schemaItem)[0] : '';

        if (type === 'number') {
          if (!totals[key]) {
            totals[key] = 0;
          }
          const numericValue = Number(item[key]) || 0;
          totals[key] += numericValue;
        } else {
          totals[key] = '';
        }
      });
    });

    Object.keys(totals).forEach((key) => {
      if (totals[key] !== '') {
        totals[key] = totals[key].toFixed(2);
      }
    });

    result.push(totals);
    return result;
  };


  const handleExportRows = (rows) => {
    const processedRows = rows.map(row => {
      return Object.fromEntries(
        Object.entries(row).map(([key, value]) => {
          return [key, value === null ? '' : value];
        })
      );
    });

    const csv = generateCsv(csvConfig)(processedRows);
    download(csvConfig)(csv);
  };



  const handle_share_filter = () => {
    const column_visibility = props.total_rows?.getState()?.columnVisibility;
    var column_visibility_filter;
    if (column_visibility && Object.keys(column_visibility).length > 0) {
      column_visibility_filter = Object.keys(column_visibility).reduce((obj, key) => {
        if (column_visibility[key] === false) {
          obj[key] = false;
        }
        return obj;
      }, {});
    } else {
      column_visibility_filter = {};
    }

    var json_share_filter = {
      query_filters: props?.query_filters === undefined ? null : props.query_filters,
      filters: props.total_rows?.getState().columnFilters === undefined ? [] : props.total_rows?.getState().columnFilters,
      grouping: props.total_rows?.getState().grouping === undefined ? [] : props.total_rows?.getState().grouping,
      column_visibility: column_visibility_filter === undefined ? {} : column_visibility_filter,
      column_order: props.total_rows?.getState()?.columnOrder
    };
    console.log(json_share_filter)
    const queryStringGenerada = objetoAQueryString(json_share_filter);
    console.log("http://localhost:3000/invoice_v2?" + queryStringGenerada)

    // navigator.clipboard.writeText("https://od2.fricongelados.com" + window?.location?.pathname + "?" + queryStringGenerada)
    navigator.clipboard.writeText("http://localhost:3000" + window?.location?.pathname + "?" + queryStringGenerada)

      .then(() => {
        console.log('Cadena de consulta copiada al portapapeles');
      })
      .catch(err => {
        console.error('Error al copiar al portapapeles:', err);
      });

  };

  const handle_copy_filter = (a, b) => {
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Copiado en el Porta Papeles"} view={true}></AlertResponse>,
      level: "success",
      position: "tc",
      autoDismiss: 10,
      dismissible: 'both',

    });
  }

  useEffect(() => {
    if (props?.query_filters_dt === "") {
      props.handle_get_consult();
    } else {
      handle_set_filter(props.query_filters_dt);
    }

  }, [props.query_filters_dt])

  const handle_set_filter = (filterdta) => {

    try {
      const objetoDestransformado = queryStringAObjeto(filterdta);
      console.log(objetoDestransformado?.query_filters);
      console.log(props?.total_rows?.getState());
      console.log(props?.total_rows);
      console.log(objetoDestransformado);

      props?.total_rows.setColumnFilters(objetoDestransformado?.filters);
      props?.total_rows.setGrouping(objetoDestransformado?.grouping);
      props?.total_rows.setColumnVisibility(objetoDestransformado?.column_visibility);
      props?.total_rows.setColumnOrder(objetoDestransformado?.column_order);
      props?.handle_success_filter(true, objetoDestransformado?.query_filters, "Filtro Aplicado");

    } catch (err) {
      console.log(err)
    }
  }

  function objetoAQueryString(objeto) {
    const { query_filters, filters, grouping, column_visibility, column_order } = objeto;

    const queryFiltersQueryString = Object.entries(query_filters)
      .map(([key, value]) => `query_filters[${key}]=${encodeURIComponent(value ?? '')}`)
      .join('&');

    const filtersQueryString = filters.map((filter, index) => {
      const valuePart = Array.isArray(filter.value)
        ? filter.value.map(v => encodeURIComponent(v)).join(',')
        : encodeURIComponent(filter.value); // Maneja correctamente las fechas en formato ISO
      return `filters[${index}][id]=${encodeURIComponent(filter.id)}&filters[${index}][value]=${valuePart}`;
    }).join('&');

    const groupingQueryString = grouping.map(key => `grouping=${encodeURIComponent(key)}`).join('&');

    const columnVisibilityQueryString = Object.entries(column_visibility)
      .map(([key, value]) => `column_visibility[${key}]=${encodeURIComponent(value)}`)
      .join('&');

    const columnOrderQueryString = column_order.map(key => `column_order=${encodeURIComponent(key)}`).join('&');

    return [queryFiltersQueryString, filtersQueryString, groupingQueryString, columnVisibilityQueryString, columnOrderQueryString]
      .filter(part => part)
      .join('&');
  }


  function queryStringAObjeto(queryString) {
    const obj = { query_filters: {}, filters: [], grouping: [], column_visibility: {}, column_order: [] };

    queryString.split('&').forEach(pair => {
      let [key, value] = pair.split('=');
      value = decodeURIComponent(value);

      if (value === 'null') {
        value = null;
      }

      if (key.startsWith('query_filters[')) {
        const innerKey = key.match(/\[(.*?)\]/)[1];
        obj.query_filters[innerKey] = value === 'true' ? true : value === 'false' ? false : value;
      } else if (key.startsWith('filters[')) {
        const index = key.match(/\[([0-9]+)\]/)[1];
        const innerKey = key.match(/\]\[([a-z]+)\]/)[1];
        obj.filters[index] = obj.filters[index] || { id: '', value: null };

        if (innerKey === 'id') {
          obj.filters[index].id = value;
        } else if (innerKey === 'value') {
          // Verifica si el valor coincide con el patrón de fecha
          if (fechaRegex.test(value)) {
            // Convierte la fecha al formato ISO o al deseado
            const date = new Date(value);
            obj.filters[index].value = date.toISOString();
          } else if (value.includes(',')) {
            // Si el valor es un array
            obj.filters[index].value = value.split(',').map(v => decodeURIComponent(v));
          } else if (obj.filters[index].id.includes('-list')) {
            // Si el id contiene "-list", trata el valor como una lista
            obj.filters[index].value = [decodeURIComponent(value)];
          } else {
            // Para valores individuales
            obj.filters[index].value = decodeURIComponent(value);
          }
        }
      } else if (key === 'grouping') {
        obj.grouping.push(value);
      } else if (key.startsWith('column_visibility[')) {
        const innerKey = key.match(/\[(.*?)\]/)[1];
        obj.column_visibility[innerKey] = value === 'true';
        console.log(obj);
      } else if (key === 'column_order') {
        obj.column_order.push(value);
      }
    });

    // Filtra los filtros incompletos
    obj.filters = obj.filters.filter(filter => filter.id && filter.value != null);

    return obj;
  }




  const handleExportRowsPDF_ProducLoad = (rows, header, orientation, format_sheet, size, title_pdf) => {
    console.log(rows)
    console.log(header)

    const body = rows.map(row =>
      header.map(fieldName => {
        var value = row[fieldName];
        if (value === null || value === undefined) {
          return '';
        }

        return value.toString();

      })
    );
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'letter',
    });

    doc.setFontSize(13);
    doc.setFont('helvetica', 'bold');

    console.log(props?.surplus_products)

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const titleDimensions = doc.getTextDimensions("fecha taltala");
    const tableWidth = pageWidth * 70 / 100;
    const columnWidth = tableWidth / header.length;
    var columnStyles = {};

    header.forEach((_, index) => {
      columnStyles[index] = { cellWidth: columnWidth };
    });

    doc.text("FECHA: " + props?.date, 60, 25);
    doc.text("VENDEDOR: " + props?.name_vendor, 60, 35);
    doc.text("RUTA: " + props?.name_route, 60, 45);

    doc.setFont('helvetica');
    doc.setTextColor(100);
    doc.autoTable({
      head: [header],
      body: body,
      theme: 'striped',
      styles: {
        font: 'helvetica',
        lineColor: [44, 62, 80],
        lineWidth: 1,
        fontSize: (Number(12) / 1.333)
      },
      headStyles: {
        fillColor: [0, 51, 102],
        textColor: 255,
        fontStyle: 'bold',
      },
      footStyles: {
        fillColor: [0, 51, 102],
        textColor: 255,
      },
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 160 },
        2: { cellWidth: 50 },
        3: { cellWidth: 50 },
        4: { cellWidth: 40 },
        5: { cellWidth: 40 },



      },
      startY: 50,
      margin: { left: props?.surplus_products?.length>0?35:50 },
      didDrawPage: function (data) {
        // Aquí agregamos la nota en el pie de página
        const pageCount = doc.internal.getNumberOfPages();
        const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        doc.setFontSize(10);
        doc.setTextColor(150);
        doc.text(`Fecha de Generación ${time.toLocaleDateString() + " " + time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds()}`, data.settings.margin.left, pageHeight - 10);
      },
    });

    doc.save(props.file_name + '.pdf');
    // const pdfBlob = doc.output('blob');
    // const pdfUrl = URL.createObjectURL(pdfBlob);



  };


  return (
    <Box sx={{ display: 'flex', gap: '0.1rem', alignItems: 'flex-end' }}>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>

      <MRT_GlobalFilterTextField table={props.table} sx={{ height: 30 }} />
      <span title='Activar Filtros'>
        <MRT_ToggleFiltersButton table={props.table} />
      </span>
      <span title='Interlineado'>
        <MRT_ToggleDensePaddingButton table={props.table} />
      </span>
      <span title='Pantalla Completa'>
        <MRT_ToggleFullScreenButton table={props.table} />
      </span>
      <span title='Herramientas de Columnas'>
        <MRT_ShowHideColumnsButton table={props.table} />
      </span>
      <IconButton
        aria-label="Acciones"
        size="medium"
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handle_click}
      >
        <AddCircleIcon fontSize="inherit" />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handle_close}
        TransitionComponent={Fade}
      >
        {/*Inico Funcionalidades genericas*/}
        <MenuItem
          key={"report_registrar"}
          style={{ display: props.create_modul ? "inline-block" : "none" }}
          onClick={async () => {
            handle_close();
            await CustomDialog(
              <SWITCH_OP
                create_modul={props?.create_modul}
                alertas={props?.alertas}
                handleSubmit={props?.handleSubmit}
                setmodalT={props?.setmodalT}
                isOpen={props?.opencreate}
                onClose={props?.onClose}
                dataextra={props}
              />,
              {
                className: (
                  props?.create_modul === "promotions" || props?.create_modul === "employees" || props?.create_modul === "commissions" ||
                    props?.create_modul === "routes" || props?.create_modul === "points_of_sale" || props?.create_modul === "drivers" ||
                    props?.create_modul === "freezers" || props?.create_modul === "daily_staff" || props?.create_modul === "create_expense" ?
                    "custom-modal-2" : "custom-modal"),
                title: 'Registrar', showCloseIcon: true,
              });
          }}
        ><LibraryAddIcon></LibraryAddIcon>REGISTRAR</MenuItem>
        <MenuItem
          key={"share_filter"}
          style={{ display: props.share_filter === true ? "inline-block" : "none" }}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => { handle_share_filter(); }}
        ><ContentCopyIcon></ContentCopyIcon>COPIAR FILTRO</MenuItem>
        <MenuItem
          key={"csv/xls"}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => {
            const get_total_row = handle_calculate_totals(transform_object, props?.update_identifiers)
            console.log(get_total_row)
            handleExportRows(format_export(get_total_row, enable_colums))
            handle_close();
          }}><FileDownloadIcon></FileDownloadIcon>CSV/XLS</MenuItem>

        <MenuItem
          style={{ display: props?.camarista === true ? "" : "none" }}
          key={"doct_pdf_camarista"}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            const get_total_row = handle_calculate_totals(transform_object, props?.update_identifiers)
            console.log(get_total_row);

            var format_export_dt = format_export(get_total_row, enable_colums)

            var enable_colums_export_pdf = [
              "Código",
              "Producto",
              "Venta Neta",
              "Inv. Final",
              "Entrega",
            ];

            var enable_colums_export_pdf_exednte = [
              "Código",
              "Producto",
              "Venta Neta",
              "Inv. Final",
              "Entrega",
              "Exedente"
            ];
            handleExportRowsPDF_ProducLoad(format_export_dt, props?.surplus_products?.length>0?enable_colums_export_pdf_exednte:enable_colums_export_pdf)
            handle_close();
          }}
        ><PictureAsPdfIcon></PictureAsPdfIcon>CAMARISTA</MenuItem>


        <MenuItem
          key={"doct_pdf"}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            const get_total_row = handle_calculate_totals(transform_object, props?.update_identifiers)
            console.log(get_total_row)
            var format_export_dt = format_export(get_total_row, enable_colums)
            if (props?.old_balance) {
              var old_balance_variable = format_export_dt[format_export_dt.length - 1]
              const sum_total_old_balance = Number(old_balance_variable["Al corriente"]) + Number(old_balance_variable["1-30"]) + Number(old_balance_variable["31-60"]) + Number(old_balance_variable["61-90"]) + Number(old_balance_variable["91 o Más"]);
              format_export_dt[format_export_dt.length - 1].Factura = sum_total_old_balance;
              console.log(format_export_dt)
            }


            handle_close(); await CustomDialog(
              <PDF_CONFIG product_load={props?.product_load} old_balance_date={props?.old_balance_date} id_shearch_dt={props?.id_shearch_dt} shearch_old_balance_data={props?.shearch_old_balance_data} old_balance={props?.old_balance} format_export={format_export_dt} enable_colums={enable_colums.map(d => d.header)}></PDF_CONFIG>,
              { className: "custom-modal", title: 'PDF', showCloseIcon: true, });
          }}
        ><PictureAsPdfIcon></PictureAsPdfIcon>PDF</MenuItem>
        <MenuItem
          key={"doct_zip"}
          style={{ display: props.zip === true ? "inline-block" : "none" }}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            handle_close();
            await CustomDialog(<INVOICE_COMPRIMED_V2 page={data_row_zip}></INVOICE_COMPRIMED_V2>, { className: "modalmini", title: "Comprimir facturas", showCloseIcon: true, isCanClose: false, });
          }}
        ><FolderZipIcon></FolderZipIcon>ZIP</MenuItem>
        <MenuItem
          key={"doct_sheet"}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            const get_total_row = handle_calculate_totals(transform_object, props?.update_identifiers)
            handle_close();
            await CustomDialog(<EXPORT_GS_V2 row={format_export(get_total_row, enable_colums)} nametable={props.file_name}></EXPORT_GS_V2>, { className: "google_sheats_modal", title: '', showCloseIcon: false, });
          }}
        ><GoogleIcon></GoogleIcon>Google Sheets</MenuItem>
        {/*Fin Funcionalidades genericas*/}


        {/*Reporte especial para facturación*/}
        <MenuItem
          key={"report_public"}
          style={{ display: props.general_public_report === true ? "inline-block" : "none" }}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            handle_close();
            await CustomDialog(<ReenvioCorreo report={true}></ReenvioCorreo>,
              { className: "custom-modal", title: 'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO', showCloseIcon: true, });
          }}
        ><FileOpenIcon></FileOpenIcon>Reporte Oportuno</MenuItem>
        {/*Fin Reporte especial para facturación*/}

        {/* Reporte de daily staff */}
        <MenuItem
          key={"report_commissions"}
          style={{ display: props.report_daily === true ? "inline-block" : "none" }}
          onClick={async () => {
            handle_close();
            await CustomDialog(
              <ReenvioCorreo report={"commissions"}></ReenvioCorreo>,
              {
                className: "custom-modal",
                title:
                  'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO',
                showCloseIcon: true,
              });
          }}>
          <FileOpenIcon></FileOpenIcon>Generar Reporte</MenuItem>
        {/* Fin Reporte de daily staff */}


        {/*Inicio Funciones especiales para DMS*/}
        <MenuItem
          key={"report_clients"}
          style={{ display: props.dms === true ? "inline-block" : "none" }}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            handle_close();
            await CustomDialog(<INTERFAZ_CLIENTS report={true}></INTERFAZ_CLIENTS>,
              { className: "custom-modal", title: 'Nota: INTERFA DE CLIENTES', showCloseIcon: true, });
          }}
        ><GroupIcon></GroupIcon>CLIENTES</MenuItem>
        <MenuItem
          key={"report_routes"}
          style={{ display: props.dms === true ? "inline-block" : "none" }}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            handle_close();
            await CustomDialog(<INTERFAZ_ROUTES report={true}></INTERFAZ_ROUTES>,
              { className: "custom-modal", title: 'INTERFAZ DE RUTAS', showCloseIcon: true, });
          }}
        ><RoomIcon></RoomIcon>RUTAS</MenuItem>
        <MenuItem
          key={"report_cabinets"}
          style={{ display: props.dms === true ? "inline-block" : "none" }}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            handle_close();
            await CustomDialog(<INTERFAZ_CABINETS report={true}></INTERFAZ_CABINETS>,
              { className: "custom-modal", title: 'INTERFAZ DE CONGELADORES', showCloseIcon: true, });
          }}
        ><AcUnitIcon></AcUnitIcon>CONGELADORES</MenuItem>
        <MenuItem
          key={"report_sales"}
          style={{ display: props.dms === true ? "inline-block" : "none" }}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            handle_close();
            await CustomDialog(<INTERFAZ_SALES report={true}></INTERFAZ_SALES>,
              { className: "custom-modal", title: 'INTERFAZ DE VENTAS', showCloseIcon: true, });
          }}
        ><PointOfSaleIcon></PointOfSaleIcon>VENTAS</MenuItem>

        <MenuItem
          key={"report_tickets"}
          style={{ display: props.dms === true ? "inline-block" : "none" }}
          disabled={props.table.getPrePaginationRowModel().rows.length === 0}
          onClick={async () => {
            handle_close();
            await CustomDialog(<INTERFAZ_TICKETS report={true}></INTERFAZ_TICKETS>,
              { className: "custom-modal", title: 'INTERFAZ DE TICKETS', showCloseIcon: true, });
          }}
        ><ReceiptIcon></ReceiptIcon>TICKETS</MenuItem>
        <MenuItem
          key={"upload_csv"}
          style={{ display: props.uploadcsv ? "inline-block" : "none" }}
          onClick={async () => {
            handle_close();
            await CustomDialog(
              <UPLOAD_FILE_CSV
                modulo="sectores"
                id={sessionStorage.getItem("id_sector")}
                handleSubmit={props?.handleSubmit}
                alertas={props?.alertas}
                setmodalT={props?.setmodalT}
              />,
              { className: "custom-modal", title: 'IMPORTAR CSV', showCloseIcon: true, });
          }}
        ><UploadFileIcon></UploadFileIcon>IMPORTAR CSV</MenuItem>

        {/*Fin Reporte especial para DMS*/}

      </Menu>


    </Box>

  );
}
export default RENDER_TOOLBAR_INTERNAL_ACTIONS;
