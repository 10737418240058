//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import { CustomDialog, Confirm } from "react-st-modal";
import { MRT_Localization_ES } from 'material-react-table/locales/es';

//COMPONENTES

//ESTILOS

import { get_detail_sale_cashclosing } from "../../../services/cashclosing/cashclosing";
import { get_detail_sale_return_cashclosing } from "../../../services/cashclosing/cashclosing";

function DETAIL_SALE(props) {
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const handle_detail_sale = async () => {
    try {
      const dt = await get_detail_sale_cashclosing(props.id);
      setData(dt.data)
      setisLoading(false)
    } catch (err) {
      setData([])
    }
  };

  const handle_detail_return_sale = async () => {
    try {
      const dt = await get_detail_sale_return_cashclosing(props.id);
      setData(dt.data)
      setisLoading(false)

    } catch (err) {
      setData([])
    }
  };

  useEffect(() => {
    if (props.typeSale === "SALE") {
      handle_detail_sale();
    } else {
      handle_detail_return_sale();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const columns = useMemo(
    () => [
      {
        header: "Linea",
        accessorKey: "row",
        size: 80
      },
      {
        header: "Código",
        accessorKey: "code",
        size: 120

      },
      {
        header: "Nombre del producto",
        accessorKey: "product_name",
        size: 80

      },
      {
        header: "Precio",
        accessorKey: "price",
        size: 80

      },

      {
        header: "Cantidad",
        accessorKey: "quantity",
        size: 80

      },
      {
        header: "Sub Total",
        size: 80,
        accessorKey: "subtotal",
      },
      {
        header: "Descuento",
        accessorKey: "discount",
        size: 80
      },
      {
        header: "Impuesto",
        accessorKey: "tax",
        size: 80
      },
      {
        header: "Total",
        accessorKey: "total",
        size: 80
      },
      {
        header: "Fecha de registro",
        accessorKey: "date_register",
        size: 80
      }
    ],

    []
  );

  return (
    <div className="new_table_v2">
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters
        enablePagination
        localization={MRT_Localization_ES}
        enableSorting
        enableBottomToolbar={true}
        enableTopToolbar={true}
        state={{ isLoading: isLoading }}
        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
        muiCircularProgressPropsave_datas={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        paginationDisplayMode='pages'
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        enableGrouping
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        getRowId={(row) => row?.id}
        enableTooltips={false}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableFullScreenToggle={false}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        enableColumnDragging={false}
        enableExpandAll={false}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        rowPinningDisplayMode='sticky'
        layoutMode="semantic"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={({ row, table }) => {
          const { density } = table.getState();
          const heightStyle = {
            height: row.getIsPinned()
              ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69}px`
              : undefined,
          };

          return {
            sx: {
              ...heightStyle,
            },
          };
        }}
      />

    </div>

  );
}

export default DETAIL_SALE;
