//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
//ESTILOS
import "../../components_cfdi/estilo.css";
import "./estilostableedit.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { delete_bonification_packages_sales, get_bonification_packages_sales, put_bonification_packages_sales } from "../../services/bonifications/bonifications";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./render_row_action_menuItems";
import SEARCH_FILTERS from "./shearch_filter";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const TableEditAddSales_V2 = () => {
  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [total_rows, settotal_rows] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  var entrada= 0;   
  const [editedUsers, setEditedUsers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(()=>{
    handleSubmit();
  },[]);

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  function alertas(msj,status){
    if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br', 
        autoDismiss: 60, 
      });
    }  
  }

//proceso de consultar ventas del paquete de bonificaciones
const handleSubmit = async () => {

  if(entrada===0){
    setmodalGeneral(true);
    entrada = entrada + 1;
    setmenssage("Buscando ventas del paquete");
  }
    setmodalT(true); 
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_bonification_packages_sales(sessionStorage.getItem("id_pack_edit"));
            var ndt = update_null_identifiers(d?.data?.sales, update_identifiers);
            setData(ndt === null ? [] : ndt);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            console.log("error", err);
            alert("el paquete aún no tiene ventas asignadas");
            window.location="/consult_report_bonuses_v2";
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
};

const update_identifiers =
  [
    { date: 'string' },
    { name: 'string' },
    { name_business_partner: 'String' },
    { outer_code: 'number' },
    { number_oxxo_store: 'string' },
    { ticket: 'string' },
    { special_folio_number: 'string' },
    { total: 'string' },
    { paper: 'string' }
  ];

const[cont, setcont]=useState(0);

useEffect(()=>{
  setcont(selectedRows.length);
}, [selectedRows])


//console.log(difT);
const actualizarpaper = async (arreglo) => {
  
  var data = {
    sales: arreglo
  }

  setmodalT(true);

  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const d = await put_bonification_packages_sales(sessionStorage.getItem("id_pack_edit"), data);
          console.log(d);    
          alertas("Ventas actualizadas con exito", true);
          setTimeout(() => {
            window.location ="/consult_report_bonuses_v2";
            setmodalT(false);
          }, 2000);
        }catch(err){
          console.log("error", err);
          alertas("Error al actualizar ventas", false);
          catchErrorModul("Bonificaciones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          setmodalT(false);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
}


const columns = useMemo(
  () => [
    {
      id: "date",
      header: "Fecha",
      accessorKey: "date",
      enableEditing: false,
    },
    {
      id: "name",
      header: "Punto de Venta",
      accessorKey: "name",
      enableEditing: false,
    },
    {
      id:"name_business_partner",
      header: "Socio de negocios",
      accessorKey: "name_business_partner",
      enableEditing: false,
    },
    {
      id: "outer_code",
      header: "Código Externo",
      accessorKey: "outer_code",
      enableEditing: false,
    },
    {
      id: "number_oxxo_store",
      header: "Número Tienda",
      accessorKey: "number_oxxo_store",
      enableEditing: false,
    },
    {
      id: "ticket",
      header: "Ticket",
      accessorKey: "ticket",
      enableEditing: false,
    },
    {
      id: "special_folio_number",
      header: "Folio Especial",
      accessorKey: "special_folio_number",
      enableEditing: false,
    },
    { 
      id: "total",
      header: "Total",
      accessorKey: "total",
      enableEditing: false,
      accessorFn: (d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
      },   
    },
    {
      header: "Comentarios",
      accessorKey: "paper",
      id: "paper",
      enableFilters: false,//deshabilita el filtrado
      enableRowOrdering: false,//deshabilita el orden de la columna
      enableGrouping: false,//deshabilita el grupo de la columna
      enableColumnDragging: false,//deshabilita el movimiento de la columna
      enableColumnActions: false,//deshabilita el las acciones
      muiEditTextFieldProps: ({ cell, row }) => ({ //permite la edición a nivel de input
        type: 'text',
        required: true,
        error: !!validationErrors?.[cell.id],
        helperText: validationErrors?.[cell.id],
        onChange: (event) => {
          const updatedValue = event.target.value;
          setEditedUsers(prev => ({
            ...prev,
            [row.id]: {
              ...row.original,
              "paper": updatedValue,
            }
          }));
        }
      }),
    }
    
    ],
    [selectedRows]
);

const analisisedit = () =>{
  const join_object = data?.map((originalItem) => {
    const editedItem = Object?.values(editedUsers)?.find((editedObj) => editedObj?.id === originalItem?.id);
    
    if (editedItem) {
      return { ...originalItem, ...editedItem };
    } else {
      return originalItem;
    }
  });
  
  const arregloFiltrado = join_object?.filter(elemento => rowSelection[elemento.id]);
  const vacios = arregloFiltrado?.map(item => {
    if (item?.paper === "" || item?.paper === null || item?.paper.trim() === "") {
      return item;
    } else {
      return null; 
    }
  }).filter(item => item !== null);

  const arreglo = arregloFiltrado.map(item=>{
    var arr = { id: item?.id, paper: item?.paper}
    return arr
  })

  if(arreglo.length>0){
    actualizarpaper(arreglo);
  }else{
    alertas("Ninguna venta seleccionada", false);
  }
}


  return (
    <>
        <BREADCRUMBS niveles={
        [
          {label: "CONSULTAR REPORTES DE BONIFICACIONES", path: "/consult_report_bonuses_v2"},
          {label: "EDITAR PAQUETE", path: null}
        ]
        }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
       <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
        <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
        ></MODAL_TABLE> 

    <div className="">
      
          <div className="package_data_newt"> 
            <div className="packdata">
              <p>Nombre Paquete: <span>{sessionStorage.getItem("name_pack")}</span></p>
              <p>Número paquete: <span>{sessionStorage.getItem("num_pack")}</span></p>
            </div>
          </div>
          
          <div className="new_table_v2">
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableRowActions
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              editDisplayMode={'table'} //habilita la edicion en cualquier campo de la tabla
              enableEditing //habilta la edición en tabla
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: modalT }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={{
                showGlobalFilter: true,
                 columnOrder: [
                   'mrt-row-actions',
                   'date',
                   'name',
                   'name_business_partner',
                   'outer_code',
                   'number_oxxo_store',
                   'ticket',
                   'special_folio_number',
                   'total',
                   'paper',
                   'mrt-row-select',
                 ],
              }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
                      muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 70,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,

                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
          renderTopToolbarCustomActions={({ }) => (
            <SEARCH_FILTERS
              analisisedit={analisisedit}
            ></SEARCH_FILTERS>
          )}
           renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              row={row}
              setmodalT={setmodalT}
              alertas={alertas}
              handleSubmit={handleSubmit}
            />
          ]}
          renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
               table={table}
                file_name={"Ventas"}
                // update_identifiers={update_identifiers}
                settotal_rows={settotal_rows}
              />
            )
            }
        /> 
        </div>
            
            
         
        </div>
     
    </>
  );
};

export default TableEditAddSales_V2;
