
import CreateFreez from '../../componentAssets/componentFreezer/utilities/CreateFreez'
import OPERATION_BRANDS from '../../componentAssets/componentMarcaF/utilities/op_brands'
import Set_vehicle from '../../componentAssets/componentVehicle/set_vehicle'
import OPERATIONES_ROUTES from '../../componentConfiguration/componentRutas/tools/op_routes'
import OPERATIONS_WAREHOUSE from '../../componentConfiguration/componentWarehouse/tools/op_warehouse'
import CreateEmployees from '../../componentHumanResources/componentEmployees/utilities/CreateEmployees'
import Set_driver from '../../componentHumanResources/drivers/set_driver'
import OPERATION_DISCOUNT from '../../componentSociosNegocios/componentDescuentos/tools/op_discount'
import CreatePointOfSale from '../../componentSociosNegocios/componentOfSale/CreatePointOfSale'
import ADD_POINT_SALE_SECTOR from '../../componentSociosNegocios/componentSectors/details/tools/add_point_sale'
import OPERATIONS_SECTORS from '../../componentSociosNegocios/componentSectors/tools/op_sector'
import CreatePromotions from '../../componentSociosNegocios/componentpromotions/utilities/CreatePromotions'
import OPERATION_ACCOUNTING_ACCOUNT from '../../componentaccount/tools/op_accounting_account'
import { OPERATIONS_COMMISSIONS } from '../../componentsNewDashboard/daily_staff/commissions/tools/op_commss';
import CREATE_UPDATE from '../../componentsNewDashboard/daily_staff/tools/create_update'
import SupportCaseModal from '../../componentsSupports/createCase/CreateCase'
import OPERATIONS_ASSIGNED_CONCEPTS from '../../components_Expenses/ComponentExpenseConcept/detail/tools/op_assigned_concepts'
import OPERATIONS_EXPENSE_CONCEPT from '../../components_Expenses/ComponentExpenseConcept/tools/op_expense_concept'
import OPERATIONS_RULES_SE from '../../components_Expenses/componentRulesSendingExpenses/tools/op_rules_se'
import CREATE_UPDATE_DETAIL from '../../components_Expenses/expenses/detail_expense/tools/create_update_detail'
import CREATE_UPDATE_EXPENSE from '../../components_Expenses/expenses/tools/create_expense'
import CREATE_DETAIL_POLICY from '../../components_Expenses/policy/detail/create_detail_policy'
import CREATE_POLICY from '../../components_Expenses/policy/tools/create_policy'

export const SWITCH_OP = (props) => {
    
    console.log("---------switch--------------");
    console.log(props);


    const renderComponent = (props) => {
            switch (props?.create_modul){
                case "routes":
                    return (
                    <OPERATIONES_ROUTES
                        operation={"CREATE"}
                        handleSubmit={props?.handleSubmit} 
                        setmodalT={props?.setmodalT} 
                        alertas={props?.alertas}
                    />);
                case "warehouse":
                    return (
                    <OPERATIONS_WAREHOUSE
                        operation={"CREATE"}
                        handleSubmit={props?.handleSubmit} 
                        setmodalT={props?.setmodalT} 
                        alertas={props?.alertas} 
                    />); 
                case "help_center":
                    return(
                        <SupportCaseModal
                            isOpen={props?.opencreate}
                            onClose={props?.onClose}
                            alertas={props?.alertas}
                        />
                    );
                case "create_expense":
                    return(
                      <CREATE_UPDATE_EXPENSE
                        codCocept={""}
                        codDescripcion={""}
                        codDate={""}
                        codImporte={0}
                        codAccount={"9090909090909"}
                        codIdPayment={""}
                        id_expense={""}
                        codidConcept={""}
                        method={"CREATE"}
                        foreing={props?.dataextra?.foreing}
                        origin={props?.dataextra?.origin}
                        handleSubmit={props?.handleSubmit}
                      />
                    );
                case "create_expense_details":
                    return(
                        <CREATE_UPDATE_DETAIL
                            method={"CREATE"}
                            row={props?.dataextra?.dataprincipal?.original}
                            foreign={props?.dataextra?.foreign}
                            codeffect={"CARGO"}
                            codamount={0}
                            handleSubmit={props?.handleSubmit}
                        />
                    );
                case "policy_details":
                    return(
                        <CREATE_DETAIL_POLICY
                            id_policy={props?.dataextra?.id}
                            handleSubmit={props?.handleSubmit}
                            setmodalT={props?.setmodalT}
                            alertas={props?.alertas}
                        ></CREATE_DETAIL_POLICY>
                    );
                
                default:
                    return (<></>);
            }
    }


  return (
    <div className='' style={{"textAlign": "center"}}>
         {renderComponent(props)}
    </div>
    )
}
