import TextField from "@mui/material/TextField";
import { useState } from "react";
import axios from "axios";
import "./operations.css";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formattedCurrentDate } from "../../main_components/date/day";
import dayjs from "dayjs";
import { useDialog } from "react-st-modal";

export const OPERATIONS = (props) => {

  const [code, setcode] = useState(props?.operation==="EDIT" ? props?.row?.original?.code : "");
  const [version, sertversion] = useState(props?.operation==="EDIT" ? props?.row?.original?.version : "");
  const [date, setdate] = useState(dayjs(props?.operation==="EDIT" ? props?.row?.original?.date :formattedCurrentDate));
  const [title, settitle] = useState(props?.operation==="EDIT" ? props?.row?.original?.title : "");
  const [intro, setintro] = useState(props?.operation==="EDIT" ? props?.row?.original?.introduction : "");
  const [linkimg, setlinkimg] = useState(props?.operation==="EDIT" ? props?.row?.original?.img : "");
  const [linkpdf, setlinkpdf] = useState(props?.operation==="EDIT" ? props?.row?.original?.pdf :"");
  const [linkform, setlinkform] = useState(props?.operation==="EDIT" ? props?.row?.original?.form : "");

  const dialog = useDialog();


  const update = async() =>{
  
    const url = "https://6grw6zjp4e.execute-api.us-east-1.amazonaws.com/prod/avisos_od2/read"
    var data = {
      'id': props?.row?.original?.id,
      'code': Number(code),
      'version': version,
      'title': title,
      'date': date.format('YYYY-MM-DD'),
      'introduction': intro,
      'links3img': linkimg,
      'links3pdf': linkpdf,
      'linkform': linkform=="" || linkform==undefined || linkform == null ? null : linkform
    }
    props?.setmodalt(true);
    props?.setIsLoading(true);

    console.log(data);

    try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        });
        console.log(response);
        props?.setmodalt(false);
        props?.alertas("Aviso actualizado con éxito", true);
        props?.setIsLoading(false);
        dialog.close();
        props.get_avisos();
      } catch (error) {
        console.log(error);
        props?.alertas("Error al actualizar el aviso", false);
        props?.setmodalt(false);
        props?.setIsLoading(false);
      }
  }


  const upload = async() =>{

    const url = "https://6grw6zjp4e.execute-api.us-east-1.amazonaws.com/prod/avisos_od2"
    var data = {
      'code': Number(code),
      'version': version,
      'title': title,
      'date': date.format('YYYY-MM-DD'),
      'introduction': intro,
      'links3img': linkimg,
      'links3pdf': linkpdf,
      'linkform': linkform=="" || linkform==undefined || linkform == null ? null : linkform
    
    }
    props?.setmodalt(true);
    props?.setIsLoading(true);

    try {
        const response = await axios.post(url, data, {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        });
        props?.setmodalt(false);
        props?.alertas("Aviso registrado con éxito", true);
        props?.setIsLoading(false);
        dialog.close();
        props.get_avisos();
      } catch (error) {
        console.log(error);
        props?.alertas("Error al registrar el aviso", false);
        props?.setmodalt(false);
        props?.setIsLoading(false);
      }

    };

  return (
    <div className='form-content'>
        <div className='form'>
          <div className="formgrid">
            <div className="">
              <TextField
                  label="Código"
                  value={code}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setcode(event.target.value.replace(/\D/g, ''))}  
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              ></TextField>
            </div>
            <div className="">
              <TextField
                  label="version"
                  value={version}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => sertversion(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
            <div className="">
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
                <DatePicker
                  sx={{  m: 1.5, width: "100%" }}
                  label="Fecha"
                  value={date}
                  onChange={(newValue) => setdate(newValue)}
                  locale="es"
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="formgrid2">
            <div className="">
              <TextField
                label="Titulo"
                value={title}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => settitle(event.target.value)}  
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
          </div>
          <div className="formgrid2">
            <div className="">
              <textarea
                label="Introducción"
                placeholder="Introducción"
                value={intro}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setintro(event.target.value)}  
                InputLabelProps={{ shrink: true }}
              ></textarea>
            </div>
          </div>
          <div className="formgrid2">
            <div className="">
              <TextField
                label="Link de Imagen"
                value={linkimg}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setlinkimg(event.target.value)}  
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
          </div>
          <div className="formgrid2">
            <div className="">
              <TextField
                label="Link de archivo PDF"
                value={linkpdf}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setlinkpdf(event.target.value)}  
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
          </div>
          <div className="formgrid2">
            <div className="">
              <TextField
                label="Link de formulario"
                value={linkform}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setlinkform(event.target.value)}  
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
          </div>

          <div className="gridfiles">
            <div className="uploadimg">
              <label className="upload-register" onClick={
                ()=>{props?.operation==="EDIT"? update():upload()}
                }
              >
                {
                  props?.operation==="EDIT" ? "Actualizar":"Registrar"
                }  
                </label>
            </div>
            <div className="uploadpdf">
              <label className="upload-cancel" onClick={()=>{dialog.close();}}>Cancelar</label>
            </div>
          </div>
        </div>
    </div>
  )
}
