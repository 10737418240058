import EXPENSES_V2 from "../consult_expenses_v2";
const EXPENSES_FOREING_V2 = () => {
  return (
    <>
    <EXPENSES_V2 foreing={1} expense={"EXTRANJERO"} origin={2} />
    </>
  );
};

export default EXPENSES_FOREING_V2;
