import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { download_expense } from "../../../../services/expenses/expenses";

import PDF from "../../../../imgComponents/pdf.png";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import { base64toBlob } from "../../../../main_components/donwloadfuncion/download";
import UPDATE_EXPENSE from "../../tools/update_expense";
import SAVE from "../../../../imgComponents/save.png";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
  
    const handle_download = async(id, file_name) =>{
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          props?.setmodalT(true);
          try {
            var dta = await download_expense(id);
            const data = dta.data;
            var blob = base64toBlob(data.file);
            const blobUrl = URL.createObjectURL(blob);
            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;
            var arr = file_name.split("/");
            downloadLink.download = arr[4] + ".pdf";
            downloadLink.click();
            props?.setmodalT(false);
          } catch (err) {
            props?.setmodalT(true);
          }
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    }



    
   

    return (
        <div>
            
            <MenuItem
                key={1}
                onClick={() =>{
                    props.closeMenu();
                    handle_download(props?.row.original.id, props?.row.original.file_name) 
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={PDF}
                        title="Descargar PDF"
                        className=""
                    ></img>
                </ListItemIcon>
                Descargar PDF
            </MenuItem>
            <MenuItem
                key={2}
                onClick={async() =>{
                  props.closeMenu();
                  if (props?.row.original.modifiable === true) {
                    const result = await CustomDialog(
                      <UPDATE_EXPENSE
                        codCocept={props?.row.original.concept}
                        codDescripcion={props?.row.original.description}
                        codDate={props?.row.original.date}
                        codImporte={props?.row.original.ammount}
                        codAccount={props?.row.original.account}
                        codIdPayment={props?.row.original.paiement_account}
                        id_expense={props?.row.original.id}
                        codidConcept={props?.row.original.id_account}
                        foreign={props?.row.original.origin === "EXTRANJERO" ? 1 : 0}
                        origin={props?.row.original.origin}
                        date_data={props?.row.original.payment_date}
                        concept={props?.row.original.concept}
                      ></UPDATE_EXPENSE>,
                      {
                        className: "modalTItle",
                        title: "Editar Gasto",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                    if (result === true) {
                      props?.handleSubmit();
                    } else {
                      console.log("no se reconsulto");
                    }
                  } else {
                    alert("Este gasto ya no se puede modificar.");
                  }
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={SAVE}
                        title="Editar Gasto"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Gasto
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
