import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { delete_supplier_invoices, donwload_invoices } from "../../../services/expenses/expenses";
import tache from "../../../imgComponents/borrar.png";
import PDF from "../../../imgComponents/pdf.png";
import XML from "../../../imgComponents/xml.png";
import { base64toBlob, base64toBlobxml } from "../../../main_components/donwloadfuncion/download";


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    
    const handle_delete_invoice = async (id) => {
        props.setmodalT(true);
        try {
          await delete_supplier_invoices(id);
          props.setmodalT(false);
        } catch (err) {
          props.setmodalT(false);
        }
      };

      
  const handle_download_invoice = async (id, file_name, document_type) => {
    props?.setmodalT(true);

    try {
      var data = await donwload_invoices(id, file_name, document_type);
      props?.setmodalT(false);
      var dt = data.data.file;
      var blob;
      if (document_type === "xml") {
        blob = base64toBlobxml(dt);
        console.log("entra aqui");
      } else {
        blob = base64toBlob(dt);
      }
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      var arr = file_name?.split("/");
      downloadLink.download = arr[4] + `.${document_type}`;
      downloadLink.click();
      props?.setmodalT(false);
    } catch (err) {
      props?.setmodalT(false);
    }
  };


       return (
        <div>
            <MenuItem
                key={1}
                onClick={ async () => {
                    props.closeMenu();
                    const result = await Confirm(
                      "¿Esta usted seguro de eliminar esta factura?",
                      "Eliminar factura de proveedor",
                      "Si",
                      "No"
                    );

                    if (result) {
                      handle_delete_invoice(props?.row?.original?.id);
                    } else {
                    }
                  }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={tache}
                        title="Eliminar Factura"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Factura
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ () =>{
                    props.closeMenu();
                    handle_download_invoice(
                      props?.row.original.id,
                      props?.row.original.filename,
                      "xml"
                    )}}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={XML}
                        title="Descargar XML"
                        className=""
                    ></img>
                </ListItemIcon>
                Descargar XML
            </MenuItem>
            <MenuItem
                key={3}
                onClick={ () =>{
                    props.closeMenu();
                    handle_download_invoice(
                        props?.row.original.id,
                        props?.row.original.filename,
                        "pdf"
                    )}}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={PDF}
                        title="Descargar PDF"
                        className=""
                    ></img>
                </ListItemIcon>
                Descargar PDF
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
