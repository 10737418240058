import { CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import view from "../../../imgComponents/visualizar.png";
import ViewDetail_Detail_Bonus_v2 from "../ViewDetail_Detail_Bonus_v2";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <ViewDetail_Detail_Bonus_v2
                            id_detail={props?.id_detail}
                            id_id_detail={props?.row.original.id}
                            datei={props?.datei}
                            datef={props?.datef}
                            route={props?.route}
                            filtro={props?.filtro}
                            note={props?.note}
                            address={props?.row.original.address}
                            pointSale={props?.row.original.name}
                        />,
                    {
                        className: "custom-modal-2",
                        title: "VER DETALLES",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={view}
                        title="Ver Detalles"
                        className=""
                    ></img>
                </ListItemIcon>
                Ver Detalles
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
