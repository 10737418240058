import { useExportData } from "react-table-plugins";
import React, { useEffect, useState, Fragment } from "react";
import Papa from "papaparse";
import XLSX from "xlsx";
import exportE from "../../imgComponents/export.png";
import "../../componentsNewDashboard/dashboarModal.css";
import axios from "axios";
import { CustomDialog } from "react-st-modal";

import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, CustomInput } from "reactstrap";
import { Filter, DefaultColumnFilter } from "../../components_cfdi/filters";
import { useSticky } from "react-table-sticky";
import SET_REPORT from "./cashclosing/report/set_option_consult";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import reportSales from "../../imgComponents/reportSales.png";
const multiSelectFilterFn=(rows, index, filterValue)=> {

  if (filterValue && Array.isArray(filterValue) && filterValue.length > 0) {
    console.log(rows)
    
   
    return rows.filter(row => {
      const rowValue = row.values[index];
      return filterValue.some(filter => filter.value === rowValue);
    });
  } else {
    return rows;
  }
}
const TableContainer = ({
  columns,
  data,
  renderRowSubComponent,
  exportEx,
  visible,
  Gtotal,
  campSUbtable,
  GtotalBonus,
  GtotalP,
  GtotalNsales,
  Gmodal,
  Gmodal2,
  pgd,
  imp,
  desc,
  totalv,
  ad,
  st,
  rp,
  reportsale,
  inventario,
  totalCutBox,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
    exportData,
  } = useTable(
    {
      columns,
      data,
      TableStickyHeader: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        // Agrega tu función de filtrado personalizada aquí
        includes: multiSelectFilterFn,
      },
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.id),
        pageIndex: 0,
        pageSize: 10,
      },
      getExportFileBlob,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useExportData,
    useRowSelect,
    useSticky
  );
  
  const [cut_box, setcut_box] = useState([]);
  const [cut_box2, setcut_box2] = useState([]);
  const [cut_box3, setcut_box3] = useState([]);
  const [totalsubtotal, settotalsubtotal] = useState(0);
  const [totaldiscount, settotaldiscount] = useState(0);
  const [totaltax, settotaltax] = useState(0);
  const [totaltotal, settotaltotal] = useState(0);
  const [totaldebt, settotaldebt] = useState(0);
  const [totalpayed, settotalpayed] = useState(0);
  const [modalT, setmodalT] = useState(false);

  const [totalinvoiced, settotalinvoiced] = useState(0);
  const [totalnot_payed, settotalnot_payed] = useState(0);
  const [totalgifts, settotalgifts] = useState(0);
  const [totalcredit, settotalcredit] = useState(0);
  const [totalsecundary_sales, settotalsecundary_sales] = useState(0);
  const [totalcash_secondary_sales, settotalcash_secondary_sales] = useState(0);
  const [totalsubtotal1, settotalsubtotal1] = useState(0);
  const [totaltotal_sales, settotaltotal_sales] = useState(0);

  useEffect(() => {
    const map1 = page.map((x) => x.original);
    const map2 = page.map((x) => x.values);

    // sessionStorage?.setItem("filter_data",JSON?.stringify(map2));
        //sum cutbox_detail
    const sumsubtotal = map1.filter((cliente) => !isNaN(parseFloat(cliente.subtotal))).map((cliente) => parseFloat(cliente.subtotal)).reduce((previous, current) => previous + current, 0);
    const sumdiscount = map1.filter((cliente) => cliente?.discount !== undefined).filter((cliente) => !isNaN(parseFloat(cliente.discount))).map((cliente) => parseFloat(cliente.discount)).reduce((previous, current) => previous + current, 0);
    const sumtax = map1.filter((cliente) => !isNaN(parseFloat(cliente.tax))).map((cliente) => parseFloat(cliente.tax)).reduce((previous, current) => previous + current, 0);
    const sumtotal = map1.filter((cliente) => !isNaN(parseFloat(cliente.total))).map((cliente) => parseFloat(cliente.total)).reduce((previous, current) => previous + current, 0);
    const sumdebt = map1.filter((cliente) => !isNaN(parseFloat(cliente.debt))).map((cliente) => parseFloat(cliente.debt)).reduce((previous, current) => previous + current, 0);
    const sumpayed = map1.filter((cliente) => !isNaN(parseFloat(cliente.payed))).map((cliente) => parseFloat(cliente.payed)).reduce((previous, current) => previous + current, 0);

    settotaltotal(sumtotal);
    settotaldebt(sumdebt);
    settotalpayed(sumpayed);
    settotaltax(sumtax);
    settotalsubtotal(sumsubtotal);
    settotaldiscount(sumdiscount);

    //sum cutbox
    settotalinvoiced(map1.filter((cliente) => !isNaN(parseFloat(cliente.invoiced))).map((cliente) => parseFloat(cliente.invoiced)).reduce((previous, current) => previous + current, 0));
    settotalnot_payed(map1.filter((cliente) => !isNaN(parseFloat(cliente.not_payed))).map((cliente) => parseFloat(cliente.not_payed)).reduce((previous, current) => previous + current, 0));
    settotalgifts(map1.filter((cliente) => !isNaN(parseFloat(cliente.gifts))).map((cliente) => parseFloat(cliente.gifts)).reduce((previous, current) => previous + current, 0));
    settotalcredit(map1.filter((cliente) => !isNaN(parseFloat(cliente.credit))).map((cliente) => parseFloat(cliente.credit)).reduce((previous, current) => previous + current, 0));
    settotalsecundary_sales(map1.filter((cliente) => !isNaN(parseFloat(cliente.secundary_sales))).map((cliente) => parseFloat(cliente.secundary_sales)).reduce((previous, current) => previous + current, 0));
    settotalcash_secondary_sales(map1.filter((cliente) => !isNaN(parseFloat(cliente.cash_secondary_sales))).map((cliente) => parseFloat(cliente.cash_secondary_sales)).reduce((previous, current) => previous + current, 0));
    settotalsubtotal1(map1.filter((cliente) => !isNaN(parseFloat(cliente.subtotal1))).map((cliente) => parseFloat(cliente.subtotal1)).reduce((previous, current) => previous + current, 0));
    settotaltotal_sales(map1.filter((cliente) => !isNaN(parseFloat(cliente.total_sales))).map((cliente) => parseFloat(cliente.total_sales)).reduce((previous, current) => previous + current, 0));
  }, [page]);


console.log(page)

  const handelConsultReport = () => {
    var ncomparacion = data.map((d) => {
      var dataRegister = d.date_register.split(" ");
      var dtR = dataRegister[0];
      var json = {
        idate: d.idate,
        fdate: d.fdate,
        route: d.route,
        date_register: dtR,
      };
      return json;
    });

    const mapND = ncomparacion.filter((d) => sessionStorage.getItem("FilterData") === d.date_register);
    const mapN = mapND.map((x) => {
      setmodalT(true);
      const narradata = axios
        .get(
          `https://b7z3hke4a8.execute-api.us-east-1.amazonaws.com/od/cashclosing/sales?`,
          {
            params: {
              di: x?.idate,
              df: x?.fdate,
              route: x?.route,
            },
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          var data = response.data;
          setcut_box(cut_box2.concat(data));
          setTimeout(() => {
            window.location = "/cut_box_report";
          }, 1 * 40 * 1000);
          return data;
        })
        .catch((err) => {});
      return narradata;
    });

    setcut_box3(mapN);
  };

  useEffect(() => {
    setcut_box(cut_box2);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cut_box]);
  useEffect(() => {
    setcut_box2(cut_box);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handelReport = (result) => {
    if (result === true) {
      setcut_box2([]);
      setcut_box([]);
      handelConsultReport();
    }
  };

  useEffect(() => {
    setcut_box3(cut_box3.concat(cut_box));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cut_box]);

  useEffect(() => {
    if (rp === true) {
      sessionStorage.setItem("cutBoxReport", JSON.stringify(cut_box3));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cut_box3]);

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    } else if (fileType === "xlsx") {
      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });
      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      return false;
    }

    return false;
  }
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };



 

  return (
    <div key={1}>
      <MODAL_TABLE modalGeneral={modalT} modalT={modalT} message="Generando reporte..."></MODAL_TABLE>

      <div
        style={{ display: exportEx === true ? "" : "none" }}
        className="containercashclosing"
      >
        <div className="expen-66">
          <img
            alt=""
            src={exportE}
            
            id=""
            title="Exportar"
            onClick={() => {
              exportData("csv", false);
            }}
          ></img>
         
        </div>

        <div className="expen-6" style={{ display: reportsale === true ? "" : "none" }}>
          
          <img
            alt=""
            src={reportSales}
            id=""
            title="Generar Reporte"
            onClick={async () => {
              const result = await CustomDialog(
                <SET_REPORT></SET_REPORT>,
                {
                  className: "cutBoxModal",
                  title: "Consultar ventas",
                  showCloseIcon: true,
                  isCanClose: false,
                }
              );
              // eslint-disable-next-line no-lone-blocks
              {
                handelReport(result);
              }
            }}
          ></img>
        </div>
      </div>

      <Fragment>
        <div className={inventario===true?"tableFixHead2": data?.length<=1?"":"tableFixHead"}>
          <Table key={data.id} bordered hover {...getTableProps()}>
            <thead className="fijoHeader">
              {headerGroups.map((headerGroup, index) => (
                <tr key={headerGroup?.id || index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div {...column.getSortByToggleProps()} title="Ascendente/Descendente">
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                      <Filter column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()} className="fijoBodyTable">
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr key={row?.id}>
                      {row.cells.map((cell) => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>

                    {row.isExpanded && (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent(row)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
            {/* <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render("Footer")}</td>
            ))}
          </tr>
        ))}
      </tfoot> */}

            <tbody
              {...getTableBodyProps()}
              style={{ display: Gmodal2 === false ? "" : "none" }}
            >
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ display: st === true ? "" : "none" }}>
                  {totalsubtotal.toFixed(2)}
                </td>
                <td style={{ display: desc === true ? "" : "none" }}>
                  {totaldiscount.toFixed(2)}
                </td>
                <td style={{ display: imp === true ? "" : "none" }}>
                  {totaltax.toFixed(2)}
                </td>
                <td style={{ display: totalv === true ? "" : "none" }}>
                  {totaltotal.toFixed(2)}
                </td>
                <td style={{ display: ad === true ? "" : "none" }}>
                  {totaldebt.toFixed(2)}
                </td>
                <td style={{ display: pgd === true ? "" : "none" }}>
                  {totalpayed.toFixed(2)}
                </td>
              </tr>
            </tbody>


            <tbody
              style={{ display: totalCutBox === true ? "" : "none" }}
            >
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td >
                  {totalinvoiced.toFixed(2)}
                </td>
                <td >
                  {totalpayed.toFixed(2)}
                </td>
                <td >
                  {totalnot_payed.toFixed(2)}
                </td>
                <td >
                  {totalgifts.toFixed(2)}
                </td>
                <td >
                  {totalcredit.toFixed(2)}
                </td>
                <td >
                  {totalsecundary_sales.toFixed(2)}
                </td>
                <td >
                  {totalcash_secondary_sales.toFixed(2)}
                </td>
                <td >
                  {totalsubtotal1.toFixed(2)}
                </td>
                <td >
                  {totaltotal_sales.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div
          className="sk-fading-circle"
          style={{ display: Gmodal === true ? "" : "none" }}
        >
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>

        <div style={{ display: visible === true ? "none" : "" }}>
          <Row
            style={{ maxWidth: 1300, margin: "0 auto", textAlign: "center" }}
          >
            <Col md={3}>
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </Col>
            <Col md={2} style={{ marginTop: 7 }}>
              Pagina{" "}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>
            </Col>

            <Col md={4}>
              <CustomInput
                id="select"
                type="select"
                key={data?.id}
                onChange={onChangeInSelect}
              >
                {[
                  10,
                  Math.round(data.length / 4),
                  Math.round(data.length / 2),
                  data.length,
                ].map((pageSize, index) => (
                  <option key={index} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </CustomInput>
            </Col>
            <Col md={3}>
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </Col>
          </Row>
       
          <h3 className='facturasConsultasTotales'>Consultados {page.length} de {data.length}</h3>

        </div>
      </Fragment>
    </div>
  );
};

export default TableContainer;
