import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomDialog } from "react-st-modal";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import BarChartIcon from '@mui/icons-material/BarChart';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { pagination_v2 } from '../../../main_components/pagination/pagination_v2';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import GRAPH from "./pie_chart_with_customized_label"
import { get_routes } from '../../../services/cashclosing/cashclosing';
import { get_types_of_sales } from '../../../services/configuration/configuration';
import { ValidationTextField } from '../../../main_components/lable/labe';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Person2Icon from '@mui/icons-material/Person2';
import StoreIcon from '@mui/icons-material/Store';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CachedIcon from '@mui/icons-material/Cached';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { GRAPHIC } from './graphic';
function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
const SEARCH_FILTERS = (props) => {
  const [businesspartners_data, setbusinesspartners_data] = useState([]);
  const [pointsofsale_data, setpointsofsale_data] = useState([]);
  const [routes, setroutes] = useState([]);
  const [routes_data, setroutes_data] = useState([]);
  const [type_sales_data, settype_sales_data] = useState([]);
  const [options, setOptions] = useState([]);
  const [restriction_data, setrestriction] = useState(props?.restriction_dt);
  const [route_select, setroute_select] = useState(props?.select_route);
  const [typer_sale_select, settyper_sale_select] = useState(props?.select_vendor);
  const [id_shearch, setid_shearch] = useState(props?.id_shearch_dt);
  const [returns, setreturns] = useState(props?.sale_returns);
  const [open, setOpen] = useState(false);

  const loading = open && options?.length === 0;

  console.log(
    {
      fi: props.fi,
      ff: props.ff,
      route: route_select,
      user: typer_sale_select,

    }
  )

  useEffect(() => {
    setid_shearch(props.id_shearch_dt)
    setrestriction(props.restriction_dt);
    setreturns(props.sale_returns)
  }, [props.id_shearch_dt, props.restriction_dt, props.sale_returns])






  const handle_set_client = (filterdta) => {

    try {
      // const objetoDestransformado = queryStringAObjeto(filterdta);
      console.log(props.total_rows?.getState());
      console.log(props.total_rows);
      var column_visibility_dt = {
        "mrt-row-pin": true,
        "mrt-row-select": true,
        "usuario": true,
        "date_censo": true,
        "folio": true,
        "agency": true,
        "movement_type": true,
        "business_partner_name": true,
        "business_partner_rfc": true,
        "business_partner_address": true,
        "business_partner_neighborhood": true,
        "business_partner_code": true,
        "business_partner_town": true,
        "business_partner_state": true,
        "business_partner_sales": true,
        "business_partner_phone": true,
        "business_partner_phone2": true,
        "business_partner_reference": true,
        "point_of_sale_name": false,
        "point_of_sale_code_sap": false,
        "point_of_sale_address": false,
        "point_of_sale_neighborhood": false,
        "point_of_sale_zip_code": false,
        "point_of_sale_town": false,
        "point_of_sale_state": false,
        "point_of_salechannel": false,
        "point_of_sale_route": false,
        "point_of_sale_phone": false,
        "point_of_sale_phone2": false,
        "point_of_sale_reference": false,
        "point_of_sale_nearest_corner": false,
        "point_of_sale_visit_day": false,
        "point_of_sale_frequency": false,
        "payment_method": false,
        "credit_days": false,
        "credit_limit": false,
        "discount_type": false,
        "discount_percentage": false,
        "cabinet_type_cabinet": false,
        "cabinet_economic_number": false,
        "cabinet_serie": false,
        "cabinet_brand": false,
        "cabinet_model": false
      }

      props?.total_rows.setColumnVisibility(column_visibility_dt);

    } catch (err) {
      console.log(err)
    }
  }

  const handle_set_point_of_sale = (filterdta) => {

    try {
      // const objetoDestransformado = queryStringAObjeto(filterdta);
      console.log(props.total_rows?.getState());
      console.log(props.total_rows);
      var column_visibility_dt = {
        "mrt-row-pin": true,
        "mrt-row-select": true,
        "usuario": true,
        "date_censo": true,
        "folio": true,
        "agency": true,
        "movement_type": true,
        "business_partner_name": false,
        "business_partner_rfc": false,
        "business_partner_address": false,
        "business_partner_neighborhood": false,
        "business_partner_code": false,
        "business_partner_town": false,
        "business_partner_state": false,
        "business_partner_sales": false,
        "business_partner_phone": false,
        "business_partner_phone2": false,
        "business_partner_reference": false,
        "point_of_sale_name": true,
        "point_of_sale_code_sap": true,
        "point_of_sale_address": true,
        "point_of_sale_neighborhood": true,
        "point_of_sale_zip_code": true,
        "point_of_sale_town": true,
        "point_of_sale_state": true,
        "point_of_salechannel": true,
        "point_of_sale_route": true,
        "point_of_sale_phone": true,
        "point_of_sale_phone2": true,
        "point_of_sale_reference": true,
        "point_of_sale_nearest_corner": true,
        "point_of_sale_visit_day": true,
        "point_of_sale_frequency": true,
        "payment_method": false,
        "credit_days": false,
        "credit_limit": false,
        "discount_type": false,
        "discount_percentage": false,
        "cabinet_type_cabinet": false,
        "cabinet_economic_number": false,
        "cabinet_serie": false,
        "cabinet_brand": false,
        "cabinet_model": false
      }

      props?.total_rows.setColumnVisibility(column_visibility_dt);

    } catch (err) {
      console.log(err)
    }
  }


  const handle_set_comercial = (filterdta) => {

    try {
      // const objetoDestransformado = queryStringAObjeto(filterdta);
      console.log(props.total_rows?.getState());
      console.log(props.total_rows);
      var column_visibility_dt = {
        "mrt-row-pin": true,
        "mrt-row-select": true,
        "usuario": true,
        "date_censo": true,
        "folio": true,
        "agency": true,
        "movement_type": true,
        "business_partner_name": false,
        "business_partner_rfc": false,
        "business_partner_address": false,
        "business_partner_neighborhood": false,
        "business_partner_code": false,
        "business_partner_town": false,
        "business_partner_state": false,
        "business_partner_sales": false,
        "business_partner_phone": false,
        "business_partner_phone2": false,
        "business_partner_reference": false,
        "point_of_sale_name": false,
        "point_of_sale_code_sap": false,
        "point_of_sale_address": false,
        "point_of_sale_neighborhood": false,
        "point_of_sale_zip_code": false,
        "point_of_sale_town": false,
        "point_of_sale_state": false,
        "point_of_salechannel": false,
        "point_of_sale_route": false,
        "point_of_sale_phone": false,
        "point_of_sale_phone2": false,
        "point_of_sale_reference": false,
        "point_of_sale_nearest_corner": false,
        "point_of_sale_visit_day": false,
        "point_of_sale_frequency": false,
        "payment_method": true,
        "credit_days": true,
        "credit_limit": true,
        "discount_type": true,
        "discount_percentage": true,
        "cabinet_type_cabinet": false,
        "cabinet_economic_number": false,
        "cabinet_serie": false,
        "cabinet_brand": false,
        "cabinet_model": false
      }

      props?.total_rows.setColumnVisibility(column_visibility_dt);

    } catch (err) {
      console.log(err)
    }
  }

  const handle_set_cabinet = (filterdta) => {

    try {
      // const objetoDestransformado = queryStringAObjeto(filterdta);
      console.log(props.total_rows?.getState());
      console.log(props.total_rows);
      var column_visibility_dt = {
        "mrt-row-pin": true,
        "mrt-row-select": true,
        "usuario": true,
        "date_censo": true,
        "folio": true,
        "agency": true,
        "movement_type": true,
        "business_partner_name": false,
        "business_partner_rfc": false,
        "business_partner_address": false,
        "business_partner_neighborhood": false,
        "business_partner_code": false,
        "business_partner_town": false,
        "business_partner_state": false,
        "business_partner_sales": false,
        "business_partner_phone": false,
        "business_partner_phone2": false,
        "business_partner_reference": false,
        "point_of_sale_name": false,
        "point_of_sale_code_sap": false,
        "point_of_sale_address": false,
        "point_of_sale_neighborhood": false,
        "point_of_sale_zip_code": false,
        "point_of_sale_town": false,
        "point_of_sale_state": false,
        "point_of_salechannel": false,
        "point_of_sale_route": false,
        "point_of_sale_phone": false,
        "point_of_sale_phone2": false,
        "point_of_sale_reference": false,
        "point_of_sale_nearest_corner": false,
        "point_of_sale_visit_day": false,
        "point_of_sale_frequency": false,
        "payment_method": false,
        "credit_days": false,
        "credit_limit": false,
        "discount_type": false,
        "discount_percentage": false,
        "cabinet_type_cabinet": true,
        "cabinet_economic_number": true,
        "cabinet_serie": true,
        "cabinet_brand": true,
        "cabinet_model": true
      }

      props?.total_rows.setColumnVisibility(column_visibility_dt);

    } catch (err) {
      console.log(err)
    }
  }

  const handle_set_replay = (filterdta) => {

    try {
      // const objetoDestransformado = queryStringAObjeto(filterdta);
      console.log(props.total_rows?.getState());
      console.log(props.total_rows);
      var column_visibility_dt = {
        "mrt-row-pin": true,
        "mrt-row-select": true,
        "usuario": true,
        "date_censo": true,
        "folio": true,
        "agency": true,
        "movement_type": true,
        "business_partner_name": true,
        "business_partner_rfc": true,
        "business_partner_address": true,
        "business_partner_neighborhood": true,
        "business_partner_code": true,
        "business_partner_town": true,
        "business_partner_state": true,
        "business_partner_sales": true,
        "business_partner_phone": true,
        "business_partner_phone2": true,
        "business_partner_reference": true,
        "point_of_sale_name": true,
        "point_of_sale_code_sap": true,
        "point_of_sale_address": true,
        "point_of_sale_neighborhood": true,
        "point_of_sale_zip_code": true,
        "point_of_sale_town": true,
        "point_of_sale_state": true,
        "point_of_salechannel": true,
        "point_of_sale_route": true,
        "point_of_sale_phone": true,
        "point_of_sale_phone2": true,
        "point_of_sale_reference": true,
        "point_of_sale_nearest_corner": true,
        "point_of_sale_visit_day": true,
        "point_of_sale_frequency": true,
        "payment_method": true,
        "credit_days": true,
        "credit_limit": true,
        "discount_type": true,
        "discount_percentage": true,
        "cabinet_type_cabinet": true,
        "cabinet_economic_number": true,
        "cabinet_serie": true,
        "cabinet_brand": true,
        "cabinet_model": true
      }

      props?.total_rows.setColumnVisibility(column_visibility_dt);
      props.handleSubmit();

    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    console.log(routes_data)
    console.log(routes_data.filter(d => d.route == route_select)[0]?.users)
    settype_sales_data(routes_data.filter(d => d.route == route_select)[0]?.users)
    settyper_sale_select(routes_data.filter(d => d.route == route_select)[0]?.users[0]?.id);
    props.setselect_vendor(routes_data.filter(d => d.route == route_select)[0]?.users[0]?.id);

  }, [route_select])

  useEffect(() => {
    // handle_get_types_of_sales();
    // handle_businesspartners();
    // handle_pointsofsale();
  }, []);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
        setOptions([...restriction_data === "SC" ? [] : restriction_data === "SN" ? businesspartners_data : pointsofsale_data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '10px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 135 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 135 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>

      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', width: "100%" }}
            onClick={(e) => {
              props.handleSubmit()
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 60}}>

      </FormControl>
      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='Datos del Cliente'
            sx={{ width: "100%", backgroundColor: '#00a65a', fontSize: "11.5px", }}
            onClick={(e) => {
              handle_set_client()
            }}
          >
            <Person2Icon></Person2Icon>
          </Button>
        </Box>
      </FormControl>

      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='Datos del Negocio'
            sx={{ width: "100%", backgroundColor: '#d9534f', fontSize: "11.5px", }}
            onClick={(e) => {
              handle_set_point_of_sale()
            }}
          >
            <StoreIcon></StoreIcon>
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='Condiciones Comerciales'
            sx={{ width: "100%", backgroundColor: '#f39c12', fontSize: "11.5px", }}
            onClick={(e) => {
              handle_set_comercial()
            }}
          >
            <BusinessCenterIcon></BusinessCenterIcon>
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='Datos del Congelador'
            sx={{ width: "100%", backgroundColor: '#00c0ef', fontSize: "11.5px", }}
            onClick={(e) => {
              handle_set_cabinet()
            }}
          >
            <AcUnitIcon></AcUnitIcon>
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='Gráficas'
            sx={{ width: "100%", backgroundColor: '#6C2BB9', fontSize: "11.5px", }}
            onClick={(e) => {
              CustomDialog(               
                <div> 
                  <GRAPHIC data={props?.data}></GRAPHIC>
                </div>,
              {
                className: "custom-modal-2",
                title:"Graficas",
                showCloseIcon: true,
                isCanClose:false,
              })
            }}
          >
            <SignalCellularAltIcon></SignalCellularAltIcon>
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='Reconsultar'
            sx={{ width: "100%", backgroundColor: '#afbdca', fontSize: "11.5px", }}
            onClick={(e) => {
              handle_set_replay()
            }}
          >
            <CachedIcon></CachedIcon>
          </Button>
        </Box>
      </FormControl>

    </Box>
  );
};

export default SEARCH_FILTERS;
