//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
import { SelectColumnFilter } from "../../components_cfdi/filters";
import TextField from "@mui/material/TextField";
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./freez.css"; 
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_cabinets_warehouse, get_movement_cabinets_sales, get_movement_pos_cabinets, get_movement_warehouse_cabinets } from "../../services/assets/assets";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { get_pointsofsale_list } from "../../services/businesspartners/businesspartners";
import WARNING from "../../main_components/alerts/warning";

const MovementWarehouseCabinets= () => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);

  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [pointsale, setpointsale] = useState(null);
  const [pointsales, setpointsales] = useState([]);
  const [mov, setmov] = useState("1");
 
  const [id_warehouse, setid_warehouse]=useState(null);
  const [datawarehouse, setdatawarehouse]=useState([]);
  const [fi, setfi]=useState("");
  const [ff, setff]=useState("");
  const [scan, setscan]=useState("");

  const [show, setshow]=useState(true);

useEffect(()=>{
  handlealmacenes();
  handlepointsale();
},[]);


//console.log(sessionStorage.getItem("roles_user"));

var permisosUsuario = sessionStorage.getItem("roles_user");
var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
var permisos = permisoUsuarioReplace?.split(",");
var permisoeditfrezz = permisos?.filter((x) => x === "ROLE_ASSETS_CONFIGURATION");

function alertas(msj,status){
  if(status==="warning"){
    notificationSystemRef.current.addNotification({
      message: <WARNING msj={msj} view={true}></WARNING>,
      level: "success",
      position: "br",
      autoDismiss: 30,
    });
  }else if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}

const handlealmacenes = async () =>{  
    try {  
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          var d = await get_cabinets_warehouse();
          setdatawarehouse(d === null ? [] : d?.data?.warehouses);
        }, 1000);
      }else{}
    } catch (error) {
      console.error(error);
      setdatawarehouse([]);
    }
}
const handlepointsale = async ()=>{
  try {  
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_pointsofsale_list();
          setpointsales(d === null ? [] : d?.data?.parametersList);     
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log("error", error);
  } 
}

const handleSubmit = async () => {

  if(mov==="1"){
    setshow(true);

    if(id_warehouse===null||id_warehouse==="vacio"){
      alertas("No ha seleccionado almacén", "warning");
      return null;
    }
  
    try {
     
      setData([]);
      setopen(true);
      setmenssage("Buscando");
      setmodalT(true);

      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_movement_warehouse_cabinets(id_warehouse, fi, ff);
            console.log(d);
            setData(d === null ? [] : d.data.movements);
            if(d.data.movements.length===0){
              alertas("No se encontro información de movimientos en el rango de fechas", false);
            }
            setopen(false);
            setmodalT(false);
          }catch(err){
            catchErrorModul("Congeladores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            console.log(err);
            setopen(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
  }else if(mov==="2"){

    if(pointsale===null || pointsale===""){
      alertas("No ha seleccionado punto de venta", "warning");
      return null;
    }

    setshow(false);
    try {
      setmodalT(true);
      setopen(true);
      setmenssage("Buscando");
      setData([]);
      
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_movement_pos_cabinets(pointsale, fi, ff);
            console.log(d);
            setData(d === null ? [] : d?.data?.movements);
            if(d.data.movements.length===0){
             alertas("No se encontro información de movimientos en el rango de fechas", "warning");
            }
            setopen(false);
            setmodalT(false);
          }catch(err){
            // catchErrorModul("Congeladores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            console.log(err);
            setopen(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
  }else if(mov==="3"){

    try {
      setmodalT(true);
      setopen(true);
      setmenssage("Buscando");
      setData([]);
      
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_movement_cabinets_sales(scan?.trim()===""?null:scan, fi, ff);
            console.log(d);
            setData(d === null ? [] : d?.data?.sales);
            if(d?.data?.sales?.length===0){
               alertas("No se encontro información de movimientos en el rango de fechas", "warning");
            }
            setopen(false);
            setmodalT(false);
          }catch(err){
            // catchErrorModul("Congeladores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            console.log(err);
            if(err?.code==="ERR_NETWORK"){
              alertas("Error al obtener la información de ventas", false);
              alertas("Vuelve e Intentar con un rango de fechas reducido", "warning");
            }else{
              alertas("Error al obtener la información de ventas", false);
            }
            setopen(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
  }
};

const columns = useMemo(
  () => [
    {
      Header:"Número Económico",
      accessor:"economic_number",
    },
    {
      Filter: SelectColumnFilter,
      Header:"Marca",
      accessor:"name"
    },
    {
      Header:"Modelo",
      accessor:"name_1"
    },
    {
      Header:"Movimiento",
      accessor:"movement_type"
    },
    {
      Header:"Fecha Movimiento",
      accessor:"date_movement"
    },
    {
      Header:"Almacén",
      accessor:"warehouse_name",
      show: show,
      id: "warehouse_name"
    },
    {
      Header:"Nota",
      accessor:"note"
    }
    ],
    [show]
);

const columns2 = useMemo(
  () => [
    {
      Header:"Código Punto de Venta",
      accessor:"code_pos",
    },
    {
      Header: "Nombre Punto de Venta",
      accessor: "name_pos"
    },
    {
      Header: "Ticket",
      accessor: "ticket"
    },
    {
      Header: "Fecha",
      accessor: "date"
    },
    {
      Header: "Congelador Escaneado",
      accessor: "scan"
    },
    {
      Header: "Marca Congelador",
      accessor: (d)=>{
        return d?.cabinet ? d?.cabinet?.name : ""
      }
    },
    {
      Header: "Modelo Congelador",
      accessor: (d)=>{
        return d?.cabinet ? d?.cabinet?.name_1 : ""
      }
    }
    ],
    []
);




const handleOnSearch = () => {
  setpointsale("");
};
const handleOnHover = () => {};
const handleOnSelect5 = (item) => {
    setpointsale(item.idPointSale);
};
const handleOnFocus5 = () => {
  console.log("Focused");
};
const handleOnClear = () => {
  console.log("Cleared");
};

console.log(mov)

  return (
    <>
     <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR CONGELADORES", path: "/consult_frezzers"},
                  {label: "MOVIMIENTOS DE CONGELADORES", path: null}, 
                ]
              }
            new_version={true}
            modul="CONSULTAR MOVIMIENTOS DE CONGELADORES V2"
            path="/consult_warehouse_movement_cabinets_v2"
      ></BREADCRUMBS>
      
<div className="TableCongeladores-warehouse">
  <div >         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
          <>
          <div className="search-mov-frz-war">
          <div className="smfw1">
            <TextField
                  select
                  label="Tipo Movimiento"
                  value={mov}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setmov(event.target.value)}
                 >
                  <option key={1} value={"1"}>
                      {"Almacén"}
                  </option>
                  <option key={2} value={"2"}>
                      {"Punto de Venta"}
                  </option>
                  <option key={3} value={"3"}>
                      {"Ventas"}
                  </option>
                </TextField>
          </div>
          <div className="smfw2">
          {
              mov==="1"?
              <TextField
                  select
                  label="Almacén"
                  value={id_warehouse}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_warehouse(event.target.value)}  
                 >
                  <option key={0} value={"vacio"}>
                      {"Selecciona Almacén"}
                  </option>
                  
                  {
                    datawarehouse?.map(item=>(
                      <option key={item.id} value={item.id}>
                        {item.warehouse_name}
                      </option>
                    ))
                  }
             </TextField>:
             mov==="2"?
             <div className="autocomplete-pos">
              <ReactSearchAutocomplete
                items={pointsales}
                fuseOptions={{ keys: ["code","name"]}}
                resultStringKeyName="codeAndName"
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect5}
                onFocus={handleOnFocus5}
                onClear={handleOnClear}
                styling={{
                  height: "35px",
                  border: "1px solid gray",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  zIndex: 10
                }}
                showIcon={true}
                placeholder="Punto de Venta"      
              />
             </div>:
             mov==="3"?
            <div className="input-num-serie">
              <TextField
                label="Número de Serie del Congelador"
                value={scan}
                defaultValue={""}
                InputLabelProps={{shrink: true}}
                sx={{ m: 1.5, width: "100%", height: "50px"}}
                onChange={(event) => setscan(event.target.value)}  
              ></TextField>
            </div>
             
            :<></>
          }
        </div>
        <div className="smfw3">
        <input
          type="text"
          className="fi"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha inicial"
        onChange={(event) => setfi(event.target.value)}
        ></input>
        </div>
      <div className="smfw4">
        <input
          type="text"
          className="ff"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Final"
          onChange={(event) => setff(event.target.value)}
        ></input>
      </div>
        <div className="smfw5">
              <button className="button" onClick={handleSubmit}>Buscar</button>
        </div>
      </div>
      <div className="waremovfrezz">
        <StickyTable>
            <TableContainer
              paginado={"Congeladores"}
              consultas={"Congeladores Consultados"}
              exportar={true}
              columns={mov==="3"?columns2:columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
      </div>
          
          </>   
          </div>
        </div>
    </>
  );
}

export default MovementWarehouseCabinets;
