//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

//COMPONENTES
import TableContainer from "../../../Table_Container_Tax";
import UPDATE_POLICY from "./tools/update_policy";
import SubTable from "../../detail_expense/datail";
import TableDetailGrupExpensePolicyDetail from "./detail_group_policy_conceptl";
import {
  get_expenses_groups_detail,
  post_expenses_groups_detail,
  delete_expenses_groups_detail,
} from "../../../../services/expenses/expenses";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../../../expense.css";
//IMAGENES/ICONOS
import SAVE from "../../../../imgComponents/save.png";
import DELETE from "../../../../imgComponents/borrar.png";
import Spinner from "../../../../imgComponents/S.gif";
import CREATE from "../../../../imgComponents/create.png";
import ADD from "../../../../imgComponents/add.png";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TableDetailGrupExpensePolicy = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [estadoAc, setestadoAc] = useState("");
  const [valorP, setvalorP] = useState(sessionStorage.getItem("totalPoliza"));
  const [idPolicy, setidPolicy] = useState("");
  const [codModificacble, setcodModificacble] = useState("");
  const [DetaiPlolicy, setDetaiPlolicy] = useState("none");

  const handleSubmit = async (event) => {
    event?.preventDefault();

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        setMenssage("Consultando grupo de gastos...");

        try {
          var dta = await get_expenses_groups_detail(
            sessionStorage.getItem("IdGrupExpense")
          );
          console.log(dta);
          setData(dta?.data);
          setidPolicy(dta.data[0].id);
          sessionStorage.setItem("idPolicy", dta?.data[0].id);
          setcodModificacble(dta?.data[0]?.modificable);
          console.log(dta?.data[0].id);
          setDetaiPlolicy("");
          setOpen(false);
          setMenssage("");
          setestadoAc("ocupado");
        } catch (err) {
          console.log("error", err);
          setData([]);
          setOpen(false);
          setDetaiPlolicy("none");
          setMenssage("");
          setestadoAc("vacio");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  useEffect(() => {
    handleSubmit();
  }, [valorP]);

  const handle_create_policy = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          setMenssage("Eliminando Poliza...");
          setOpen(true);
          await post_expenses_groups_detail(
            {},
            sessionStorage.getItem("IdGrupExpense")
          );
          setOpen(false);
          window.location = "/expensesGrupDetail";
        } catch (err) {
          setOpen(false);
          var error = err?.response?.data?.errorMessage;
          alert("Error.," + error);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_delete_expenses_groups_detail = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          await delete_expenses_groups_detail(
            sessionStorage.getItem("IdGrupExpense"),
            id
          );
          setOpen(false);
          setMenssage("");
          handleSubmit();
        } catch (err) {
          setOpen(false);
          setMenssage("");
          alert("Error.," + err?.response?.data?.errorMessage);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "CrearPoliza",
        Header: ({ valor, row }) => {
          return (
            <img
              title="Crear Poliza"
              src={CREATE}
              alt=""
              onClick={() => handle_create_policy()}
            ></img>
          );
        },
        Cell: ({ valor, row }) => {
          return (
            <div>
              <img
                title="Inyectar"
                src={ADD}
                alt=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <UPDATE_POLICY
                      codCocept={row.original.concept}
                      codType={row.original.type}
                      codId={row.original.id}
                      codAction={"INJECTION"}
                    ></UPDATE_POLICY>,
                    {
                      className: "modalTItle",
                      title: "Inyectar poliza",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(result);
                  }
                }}
              ></img>
              <img
                title="Editar"
                alt=""
                src={SAVE}
                onClick={async () => {
                  const result = await CustomDialog(
                    <UPDATE_POLICY
                      codCocept={row.original.concept}
                      codType={row.original.type}
                      codId={row.original.id}
                      date={row.original.export_date}
                      codAction={"UPDATE"}
                    ></UPDATE_POLICY>,
                    {
                      className: "modalTItle",
                      title: "Editar poliza",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(result);
                  }
                }}
              ></img>

              <img
                title="Eliminar Poliza"
                alt=""
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta poliza?",
                    "Eliminar Poliza",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_expenses_groups_detail(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "No.",
        accessor: "id",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Fecha",
        accessor: "export_date",
      },
      {
        Header: "Estado",
        accessor: "status",
      },
      {
        Header: "Modificable",
        accessor: (d) => {
          return d.modificable === true ? "✅" : "❌";
        },
      },
    ],
    []
  );

  return (
    <div >
      <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>

      <div>
        <h3>CABECERA DE LA POLIZA</h3>
      </div>

      <br></br>
      <div className="TableReport ExpenseNoDeducible ExpenseCabeceraP">
        <div style={{ overflowX: "autidPolicyo" }}>
          <TableContainer
            visible={true}
            exportEx={true}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable verData={true} />
                </div>
              );
            }}
          />
        </div>
      </div>
      <div style={{ display: DetaiPlolicy }}>
        <TableDetailGrupExpensePolicyDetail
          idGrupExpense={idPolicy}
          codModificable={codModificacble}
        ></TableDetailGrupExpensePolicyDetail>
      </div>
    </div>
  );
};

export default TableDetailGrupExpensePolicy;
