import "./bandeja.css";
export const BANDEJA = (props) => {

  const date = props?.item?.conversacion?.fecha
  const fechaActual = new Date();
  const year = fechaActual.getFullYear();
  const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
  const day = String(fechaActual.getDate()).padStart(2, '0');
  const fechaFormateada = `${year}-${month}-${day}`; 

  const d = props?.item?.conversacion?.name_destino;
  const primeraLetra = d.charAt(0);


  return (
    <div className='bandeja-container'>
      <div className='circle'>
        <p>{primeraLetra}</p>
      </div>
      <div className='container-history'>
        <div className='info-contact-list'>
          <div className='name-contact'>
            <p className=''>{props?.item?.name_destino}</p>
          </div>
          <div className='tiempo-contact-last-mss'>
            <p className=''>{date===fechaFormateada?props?.item?.conversacion?.hora : props?.item?.conversacion?.fecha}</p>
          </div>
        </div>
        <div className='info-last-mss'>
          <p className=''>{
              props?.item?.conversacion?.tipo==="text"? props?.item?.conversacion?.mensaje: "imagen"
            }</p>
        </div>
      </div>

    </div>
  )
}
