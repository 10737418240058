import { CustomDialog } from "react-st-modal";
import GENERATE_EXPENSE_GROUP from "./generate_expense_group";
import { ListItemIcon, MenuItem } from '@mui/material';
import Agrupar from "../../../../imgComponents/agrupar.png";
import { create_expense_group } from "../../../../services/expenses/expenses";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";

const SPECIAL_ACTIONS = (props) => {

  const agrupar_gastos = async () => {
    const id = Object.keys(props.rowSelection).filter((clave) => props.rowSelection[clave]);





    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        props.setIsLoading(true);
        var data = { expense: props.total_rows.getSelectedRowModel().rows.map(d => { return { id: d.original.id } }) };
        try {
          await create_expense_group(data);
          window.location="/expensesReport_v2";

        } catch (error) {
          console.log(error);
          props?.alertas(error?.response?.data?.errorMessage, false);

        }finally{
          props.setIsLoading(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }

  }

  return (
    <div>
      <MenuItem
        key={2}
        onClick={async () => {
          // props.closeMenu();
          agrupar_gastos()
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <img
            alt=""
            src={Agrupar}
            title="Agrupar Gastos"
            className=""
          ></img>
        </ListItemIcon>
        Descargar PDF
      </MenuItem>

    </div>

  );
}
export default SPECIAL_ACTIONS;