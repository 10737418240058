
import axios from "axios";

export async function number_data(url, origen, di, df, route, saletype, scan, pos, bp, sale, returns) {
  console.log(saletype)
  if (sale === null ? "" : sale!=="") {
    return 1
  };

  var parameters = {};
  parameters = {
    di: di,
    df: df,
    route: route,
    saletype: saletype,
    scan: scan,
    count: true,
    pos: pos,
    bp: bp,
    sale: sale,
    returns: returns

  }
  console.log(parameters)
  try {
    const response = await axios.get(`${url}`, {
      params: parameters,
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    const data = response.data.count;
    console.log(data)
    return data;
  } catch (error) {
    console.error(error);
    return 0;
  }
}







