import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HailIcon from '@mui/icons-material/Hail';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BuildIcon from '@mui/icons-material/Build';
import Groups2Icon from '@mui/icons-material/Groups2';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AddCardIcon from '@mui/icons-material/AddCard';
//subcatalogo
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import PercentIcon from '@mui/icons-material/Percent';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import StoreIcon from '@mui/icons-material/Store';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PublicIcon from '@mui/icons-material/Public';
import TrafficIcon from '@mui/icons-material/Traffic';
import SummarizeIcon from '@mui/icons-material/Summarize';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import GavelIcon from '@mui/icons-material/Gavel';
import BallotIcon from '@mui/icons-material/Ballot';
import FlightIcon from '@mui/icons-material/Flight';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import RequestPageIcon from '@mui/icons-material/RequestPage';
//imagenes
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import FeedIcon from '@mui/icons-material/Feed';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import InventoryIcon from '@mui/icons-material/Inventory';
import IcecreamIcon from '@mui/icons-material/Icecream';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BadgeIcon from '@mui/icons-material/Badge';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const data = [
    {
        id: 101,
        name: 'ACTIVOS',
        icon: <AttachMoneyIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
            {
              id: 101101,
              name: 'MARCAS Y MODELOS',
              icon: <BrandingWatermarkIcon className='icon' style={{color: "white"}}/>,
              permision: [' '],
              videos: [
                    {
                      code: 10101,
                      titlevideo: "CREAR MARCA", 
                      description: 'En este video se mostrará paso a paso el como se puede crear una marca', 
                      url: '', 
                      img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
                    },
              ],
            },  
            {
              id: 101102,
              name: 'VEHÍCULOS',
              permision: [' '],
              icon: <CarCrashIcon className='icon' style={{color: "white"}}/>,
              videos: [
                    {
                      code: 10105,
                      titlevideo: "VIDEO", 
                      description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                      url: '', 
                      img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
                    },
              ],
            }
        ]
    },
    {
        id: 102,
        name: 'BANCOS',
        icon: <AccountBalanceIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 102101,
            name: 'CORTE DE CAJA',
            icon: <AccountBalanceWalletIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10201,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 103101,
            name: 'DEUDAS',
            icon: <AddCardIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10201,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          }
        ]
    },
    {
        id: 103,
        name: 'COLABORADORES',
        icon: <HailIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 103101,
            name: 'EMPLEADOS',
            icon: <EngineeringIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },  
          {
            id: 103102,
            name: 'CONDUCTORES',
            permision: [' '],
            icon: <AirlineSeatReclineNormalIcon className='icon' style={{color: "white"}}/>,
            videos: [
              {
                code: 10302,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 103103,
            name: 'ASIGNACIÓN DE COMISIONES',
            icon: <BadgeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10303,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          }, 
        ]
    },
    {
        id: 104,
        name: 'FINANZAS',
        icon: <PriceChangeIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 104101,
            name: 'CUENTAS CONTABLES',
            icon: <PointOfSaleIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
                {
                  code: 1041011,
                  titlevideo: "CONSULTAR CUENTAS CONTABLES", 
                  description: 'Video tutorial cuentas contables', 
                  url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Finanzas/Consultar+Cuentas+Contables.mp4', 
                  img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Finanzas/consultar_cuenta_contable.png',
                },
                {
                  code: 1041012,
                  titlevideo: "REGISTRAR CUENTA CONTABLE", 
                  description: 'Video tutorial cuentas contables', 
                  url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Finanzas/Registrar+Cuentas+Contables.mp4', 
                  img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Finanzas/registrar_cuenta_contable.png',
                },
                {
                  code: 1041013,
                  titlevideo: "EDITAR CUENTAS CONTABLES", 
                  description: 'Video tutorial cuentas contables', 
                  url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Finanzas/Editar+Cuentas+Contables.mp4', 
                  img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Finanzas/editar_cuenta_contable.png',
                },
                {
                  code: 1041014,
                  titlevideo: "ELIMINAR CUENTAS CONTABLES", 
                  description: 'Video tutorial cuentas contables', 
                  url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Finanzas/Eliminar+Cuentas+Contables.mp4', 
                  img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Finanzas/eliminar_cuenta_contable.png',
                }
            ]
          },
        ]
    },
    {
        id: 105,
        name: 'REPORTES',
        icon: <AssessmentIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 105101,
            name: 'DETALLES DE VENTAS',
            icon: <ReceiptLongIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 105102,
            name: 'MAPA DE RUTAS',
            icon: <PublicIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 1051021,
                titlevideo: "CONSULTAR MAPA DE RUTAS", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Reportes/Mapa+de+Rutas/Consultar_Mapa_de_Rutas.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Reportes/Mapa+de+Rutas/Consultar_Mapa_de_Rutas.png',
              },
              {
                code: 1051022,
                titlevideo: "DIBUJAR POLÍGONO", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Reportes/Mapa+de+Rutas/Dibujar_Poligono.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Reportes/Mapa+de+Rutas/Dibujar_Poligono.png',
              }
            ]
          },
          {
            id: 105103,
            name: 'REPORTE DE VENTAS POR RUTA',
            icon: <TrendingDownIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 105104,
            name: 'REPORTE DE VENTAS EN TIEMPO REAL',
            icon: <PendingActionsIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 105105,
            name: 'REPORTE SUMARIA ANUAL',
            icon: <SummarizeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 105105,
            name: 'REPORTE SUMARIA SEMANAL',
            icon: <SummarizeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10501,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 105107,
            name: 'SEMÁFORO',
            icon: <TrafficIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
        ]
    },
   
    {
        id: 106,
        name: 'COMPRAS',
        icon: <ShoppingCartIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 106101,
            name: 'CONSULTAR DOCUMENTOS ENVÍADOS',
            icon: <ContentPasteSearchIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106102,
            name: 'ENVÍO DE DOCUMENTOS',
            icon: <UploadFileIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106108,
            name: 'CATALOGO DE GASTOS',
            icon: <BorderAllIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106109,
            name: 'REGLA DE GASTOS',
            icon: <GavelIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106103,
            name: 'GASTOS NO DEDUCIBLES',
            icon: <BallotIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106104,
            name: 'GASTOS DEL EXTRANJERO',
            icon: <FlightIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106105,
            name: 'REPORTE DE GASTOS',
            icon: <ReceiptLongIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106106,
            name: 'REPORTE DE GASTOS AGRUPADOS',
            icon: <RecentActorsIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106107,
            name: 'POLIZAS',
            icon: <RequestPageIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106110,
            name: 'CONSULTAR PROVEEDORES',
            icon: <ContactMailIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10601,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
        ]
    },
    {
        id: 107,
        name: 'FACTURAS',
        icon: <LibraryBooksIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 107102,
            name: "CONSULTAR CONCEPTOS",
            icon: <CreditScoreIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107103,
            name: "CONSULTAR FACTURAS",
            icon: <RequestQuoteIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107104,
            name: "CONSULTAR FACTURAS PPD",
            icon: <RequestQuoteIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107105,
            name: "CONSULTAR IMPUESTOS",
            icon: <MoneyOffIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107101,
            name: "REALIZAR CARTA PORTE",
            icon: <ContentPasteGoIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107108,
            name: "REALIZAR COMPLEMENTO DE PAGO",
            icon: <CurrencyExchangeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107107,
            name: "REALIZAR FACTURA",
            icon: <FeedIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
        ]
    },
    {
        id: 108,
        name: 'SOCIOS DE NEGOCIOS',
        icon: <Groups2Icon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 108101,
            name: 'DESCUENTOS',
            icon: <PercentIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 555522,
                titlevideo: "CONSULTAR DESCUENTOS", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Descuentos/Consultar_Descuentos.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Descuentos/Consultar_Descuentos.png',
              },
              {
                code: 555523,
                titlevideo: "REGISTRAR DESCUENTO", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Descuentos/Agregar_Descuento.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Descuentos/Agregar_Descuento.png',
              },
              {
                code: 555524,
                titlevideo: "EDITAR DESCUENTO", 
                description: 'En este video ', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Descuentos/editar_Descuento.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Descuentos/Editar_Descuento.png',
              },
              {
                code: 555525,
                titlevideo: "ELIMINAR DESCUENTO", 
                description: 'En este video', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Descuentos/eliminar_Descuento.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Descuentos/Eliminar_Descuento.png',
              },
            ]
          },
          {
            id: 108102,
            name: 'PROMOCIONES',
            icon: <LoyaltyIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 108103,
            name: 'PUNTOS DE VENTA',
            icon: <StoreIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 108104,
            name: 'SECTORES',
            icon: <HomeWorkIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 1081041,
                titlevideo: "CONSULTAR SECTORES", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo Sector.', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Consultar_Sectores.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Consultar_Sectores.png',
              },
              {
                code: 1081042,
                titlevideo: "CREAR SECTOR", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Crear_Sector.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Crear_Sector.png',
              },
              {
                code: 1081043,
                titlevideo: "EDITAR SECTOR", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Editar_Sector.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Editar_Sector.png',
              },
              {
                code: 1081044,
                titlevideo: "ELIMINAR SECTOR", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Eliminar_Sector.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Eliminar_Sector.png',
              },
              {
                code: 1081045,
                titlevideo: "CONSULTAR PUNTOS DE VENTA RELACIONADOS AL SECTOR", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Consultar_Puntos_de_Venta_Relacionados_al_Sector.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Consultar_Puntos_de_Venta_Relacionados_al_Sector.png',
              },
              {
                code: 1081046,
                titlevideo: "AGREGAR PUNTO DE VENTA AL SECTOR", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Agregar_Punto_de_Venta_al_Sector.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Agregar_Punto_de_Venta_al_Sector.png',
              },
              {
                code: 1081047,
                titlevideo: "ELIMINAR PUNTO DE VENTA RELACIONADO AL SECTOR", 
                description: '', 
                url: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Eliminar_Punto_de_Venta_Relacionado_al_Sector.mp4', 
                img: 'https://videos-capacitacion-od2.s3.amazonaws.com/Socios-de-Negocios/Sectores/Eliminar_Punto_de_Venta_Relacionado_al_Sector.png',
              }
            ]
          },
          {
            id: 108105,
            name: 'SOCIO DE NEGOCIOS',
            icon: <HailIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 108106,
            name: 'RECUPERAR SOCIO DE NEGOCIOS',
            icon: <ReduceCapacityIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 108107,
            name: 'REGISTRO GENERAL',
            icon: <SwitchAccountIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 108108,
            name: 'PUNTOS DE VENTA SIN SECTOR',
            icon: <AddBusinessIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          }
        ]
    },
    {
        id: 109,
        name: 'OPERACIONES CON UNILEVER',
        icon: <AssignmentIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 109101,
            name: "REPORTE DE BONIFICACIONES",
            icon: <FactCheckIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
        ]
    },
    {
        id: 110,
        name: 'CONFIGURACIÓN',
        icon: <BuildIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 110101,
            name: "ALMACÉN",
            icon: <WarehouseIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 110102,
            name: "EMPRESA",
            icon: <ApartmentIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 110103,
            name: "ROLES",
            icon: <AccountTreeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 110104,
            name: "RUTAS",
            icon: <ForkLeftIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 110105,
            name: "USUARIOS",
            icon: <PersonSearchIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
        ]
    },
    {
      id: 112,
      name: 'INVENTARIOS',
      icon: <InventoryIcon className='icon' style={{color: "white"}}/>,
      restriccion:"",
      sections: [
        {
          id: 112101,
          name: "PRODUCTOS",
          icon: <IcecreamIcon className='icon' style={{color: "white"}}/>,
          permision: [' '],
          videos: [
            {
              code: 11201,
              titlevideo: "VIDEO", 
              description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
              url: '', 
              img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
            }
          ]
        },
        {
          id: 112102,
          name: "LINEA DE PRODUCTOS",
          icon: <FormatLineSpacingIcon className='icon' style={{color: "white"}}/>,
          permision: [' '],
          videos: [
            {
              code: 10301,
              titlevideo: "VIDEO", 
              description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
              url: '', 
              img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
            }
          ]
        },
        {
          id: 112103,
          name: "ALMACEN DE PRODUCTOS",
          icon: <WarehouseIcon className='icon' style={{color: "white"}}/>,
          permision: [' '],
          videos: [
            {
              code: 10301,
              titlevideo: "VIDEO", 
              description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
              url: '', 
              img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
            }
          ]
        },
        {
          id: 112104,
          name: "MOVIMIENTO DE PRODUCTOS",
          icon: <ExitToAppIcon className='icon' style={{color: "white"}}/>,
          permision: [' '],
          videos: [
            {
              code: 10301,
              titlevideo: "VIDEO", 
              description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
              url: '', 
              img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
            }
          ]
        },
        {
          id: 112105,
          name: "LISTA DE PRECIOS",
          icon: <ListAltIcon className='icon' style={{color: "white"}}/>,
          permision: [' '],
          videos: [
            {
              code: 112101,
              titlevideo: "VIDEO", 
              description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
              url: '', 
              img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
            }
          ]
        },
        
      ]
  }
]