import { useEffect, useMemo, useRef, useState } from 'react';
import { CustomDialog } from "react-st-modal";
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import CREATE_UPDATE from './create_update';
import SAVE from "../../../imgComponents/pago.png";


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {


    return (
        <div>
            <MenuItem
                key={props.row.original.id}
                style={{ display: props.row.original.debt > 0 ? "" : "none" }}
                onClick={async () => {
                    props.closeMenu();
                    const result = await CustomDialog(
                        <CREATE_UPDATE
                            subtabla={props.subtabla}
                            id={props.row.original.id}
                            uuid={props.row.original.uuid}
                            ticket={props.row.original.ticket}
                            totalsale={props.row.original.total_debt}
                            pointofsale={props.row.original.name_pos}
                            bp={props.row.original.name_business_partner}
                            max_payment={props.row.original.debt}
                            method_payment={props.row.original.ticket}
                            payment={props.row.original.ticket}
                            note={props.row.original.ticket}
                            tarjet={props.row.original.ticket}
                            name_line={props.row.original.id_products_line_fk}
                            description={props.row.original.description}
                            enable={props.row.original.enable}
                            method={"UPDATE"}
                            name_supplier={props.row.original.name_supplier}
                            id_supp={props.row.original.id_supp}
                            product_name={props.row.original.product_name}
                            barcode={props.row.original.barcode}
                            type={props.row.original.type}
                            product_line_data={props.row.original.product_line_data}
                            only_promo={props.row.original.only_promo}
                            cost={props.row.original.cost}
                            pieces={props.row.original.pieces}
                            clave_prod_serv={props.row.original.clave_prod_serv}
                            clave_unidad={props.row.original.clave_unidad}
                            clave_unidad_2={props.row.original.clave_unidad}
                            weight={props.row.original.weight}
                            iva0={props.row.original.iva0}
                            iva16={props.row.original.iva16}
                            ieps8={props.row.original.ieps8}
                            minimum_sales_unit={props.row.original.minimum_sales_unit}
                            minimum_sales_unit2={props.row.original.minimum_sales_unit_2}
                            unit={props.row.original.unit}
                            unit_dms={props.row.original.unit_dms}
                            unit_conversion={props.row.original.unit_conversion}
                            brand={props.row.original.brand}
                            paidout={props.row.original.paidout}
                            handleSubmit={props.handleSubmit}
                        ></CREATE_UPDATE>,
                        {
                            className: "modalTItle",
                            title: "Actualizar deuda",
                            showCloseIcon: true,
                            isCanClose: false,
                        }
                    );
                    console.log(result)

                    console.log(result?.validate)
                    if(result?.validate){
                        props.handle_success_filter(false, [], "Pago realizado con exito");
                    }

                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        title="Realizar pago"
                        src={SAVE}
                    ></img>
                </ListItemIcon>
                Realizar Pago
            </MenuItem>

        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
