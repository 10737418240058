import React, { useEffect, useState, useMemo, useRef} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {  useDialog } from "react-st-modal";
import { Checkbox } from "semantic-ui-react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_products, post_promotion, post_promotion_details } from "../../../services/businesspartners/businesspartners";
import TableContainer from "../../../main_components/Table/TableContainer";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";

function CreatePromotions_v2(props) {

  const dialog = useDialog();
  const notificationSystemRef = useRef();
  
  const[namepromotion, setnamepromotion] = useState(null);
  const[client,setclient] = useState(null);
  const[clientdisc, setclientedisc] = useState(null);
  const[cant, setcant] = useState(null);
  const[enable, setenable] = useState(false);
  const [fi, setfi] = useState(null);
  const [ff, setff] = useState(null);
  const [difT, setDifT] = useState([{ id: "eliminar" }]);
  const [products, setproducts] = useState([]);
  const [bandera, setbandera]=useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");
  
  const [modalT, setmodalT] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  
  const [editedUsers, setEditedUsers] = useState([]);
  const [editedUsers2, setEditedUsers2] = useState([]);

  useEffect(()=>{
    handleproducts();
  },[]);

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

const handleChange = () => {
  if(enable===true){
    setenable(false);
  }else{
    setenable(true);
  }
};
const handleproducts = async ()=>{
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_products();
          setproducts(d === null ? [] : d.data.productList);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}

const addpromotion =async ()=>{

  if(namepromotion===null || fi===null || ff===null || client===null || clientdisc===null || cant===null){
    props.alertas("Datos faltantes de promoción",false);
  }
  else{

    if(rowSelection?.length === 0){
      props?.alertas("No ha seleccionado ningún producto", false);
      return null;
    }else{

      const updatedUsers = { ...editedUsers };

      for (const key in editedUsers2) {
        if (editedUsers2.hasOwnProperty(key)) {
          if (updatedUsers.hasOwnProperty(key)) {
            updatedUsers[key] = {
              ...updatedUsers[key],
              quantity: editedUsers2[key].quantity
            };
          }
        }
      }  
      const join_object = products?.map((originalItem) => {
        const editedItem = Object?.values(updatedUsers)?.find((editedObj) => editedObj?.id === originalItem?.id);
        if (editedItem) {
          return { ...originalItem, ...editedItem };
        } else {
          return originalItem;
        }
      });
      
      const arregloFiltrado = join_object?.filter(elemento => rowSelection[elemento.id]);
      const vacios = arregloFiltrado?.map(item => {
        if(item?.quantity && item?.cost){
          if (item?.quantity === "" || item?.quantity === null || item?.quantity.trim() === "" || item?.quantity <= 0 ||
          item?.cost <= 0 || item?.cost === undefined || item?.cost === "" ) {
            return item;
          } else {
            return null; 
          }
        }else{
          return item;
        }
      }).filter(item => item !== null);
      
      if(vacios.length > 0 ){
        props?.alertas("Falta asignar valores en algunos productos", false);
        return null;
      }else{
       props.setmodalT(true);
       setbandera(false);
       var data = {
         promoName: namepromotion,
         enable: enable,
         initialDatePromo: fi,
         finalDatePromo: ff,
         typeBusinessPartner: client,
         customerWithDiscount:  clientdisc,
         amountRequired:  cant
       }      
        try {
           var rf_token = await refresh_token();
           if(rf_token===true){
             setTimeout(async() => {
               try{
                 const dt = await post_promotion(data);
                 console.log(dt);
                 filtrar(dt, arregloFiltrado);  
              }catch(err){
                console.log("error post", err);
                 catchErrorModul("Promociones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
                 props.setmodalT(false);
                 props.alertas("No se pudo crear la promoción, vuelva a intertarlo",false);
                 setbandera(true);
               }       
             }, 1000);
           }else{}
         } catch (err) {}
     } 
    }
   }   
}


const filtrar = async (response, arreglo) => {
 
   var arregloactualizado = arreglo.map(item=>{
    const d = {
      promoPrice: Number(item?.cost),
      productName: item.product_name,
      productCode: item.code,
      quantity: Number(item?.quantity)
    }
    return d;
   })
   var data = {
     details: arregloactualizado
   } 
    try {
     var rf_token = await refresh_token();
     if(rf_token===true){
       setTimeout(async() => {
         try{
           const dt = await post_promotion_details(response?.data?.idPromotion, data);
           console.log(dt);
           props?.alertas("Promoción creada con éxito", true);
           props?.setmodalT(false);
           dialog?.close();     
           props?.handleSubmit();
         }catch(err){
           console.log("error post", err);
           catchErrorModul("Promociones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
           props.alertas("Error al agregar detalles a una promoción", false);
           setbandera(true);
           props.setmodalT(false);
         }    
       }, 1000);
     }else{}
     } catch (err) {}
}

const columns = useMemo(
  () => [  
     {
        header: "Código",
        accessorKey: "code",
        enableEditing: false,
      },
      {
       header: "Nombre",
       accessorKey: "product_name",
       enableEditing: false,
     },
     {
      header: "Precio",
      accessorKey: "cost",
      id: "cost",
      enableFilters: false,//deshabilita el filtrado
      enableRowOrdering: false,//deshabilita el orden de la columna
      enableGrouping: false,//deshabilita el grupo de la columna
      enableColumnDragging: false,//deshabilita el movimiento de la columna
      enableColumnActions: false,//deshabilita el las acciones
      muiEditTextFieldProps: ({ cell, row }) => ({ //permite la edición a nivel de input
        type: 'text',
        required: true,
        error: !!validationErrors?.[cell.id],
        helperText: validationErrors?.[cell.id],
        onChange: (event) => {
          const updatedValue = event.target.value;
          setEditedUsers(prev => ({
            ...prev,
            [row.id]: {
              ...row.original,
              "cost": updatedValue,
            }
          }));
        }
      }),
    },
    {
      header: "Cantidad",
      accessorKey: "quantity",
      id: "quantity",
      enableFilters: false,//deshabilita el filtrado
      enableRowOrdering: false,//deshabilita el orden de la columna
      enableGrouping: false,//deshabilita el grupo de la columna
      enableColumnDragging: false,//deshabilita el movimiento de la columna
      enableColumnActions: false,//deshabilita el las acciones
      muiEditTextFieldProps: ({ cell, row }) => ({ //permite la edición a nivel de input
        type: 'text',
        required: true,
        error: !!validationErrors?.[cell.id],
        helperText: validationErrors?.[cell.id],
        onChange: (event) => {
          const updatedValue = event.target.value;
          setEditedUsers2(prev => ({
            ...prev,
            [row.id]: {
              ...row.original,
              "quantity": updatedValue,
            }
          }));
        }
      }),
    }
  ],[]
)

  return (
    <div style={{textAlign:"center"}}>
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
      <div className="">
       <div className="form-create-promotion">
       <div className="grid-form1">
          <div className="form-prom-campo1">
          <TextField
              label="Nombre de la Promoción"
              required
              disabled={!bandera}
              value={namepromotion}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setnamepromotion(event.target.value.toUpperCase())}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
          <div className="form-prom-campo2">
          <TextField
              select
              required
              label="Tipo de Cliente"
              defaultValue={""}
              disabled={!bandera}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setclient(event.target.value)}  
            >
              <option key={0} value={0} selected>{"Selecciona uno"}</option>
              <option key={1} value={"PROPIOS"}>{"Cliente Propio"}</option>
              <option key={2} value={"EXTERNOS"}>{"Cliente Unilever"}</option>
              <option key={3} value={"TODOS"}>{"Todos"}</option>
          </TextField>
          </div>
        </div>
        <div className="grid-form2">
          <div className="form-prom-campo3">
          <TextField
              select
              required
              label="Cliente con Descuento"
              disabled={!bandera}
              defaultValue={""}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setclientedisc(event.target.value)}  
            >
              <option key={0} value={0} selected>{"Selecciona uno"}</option>
              <option key={1} value={"SI"}>{"Si"}</option>
              <option key={2} value={"NO"}>{"No"}</option>
              <option key={3} value={"TODOS"}>{"Todos"}</option>
          </TextField>
          </div>
          <div className="form-prom-campo4">
          <TextField
              label="Cantidad Requerida"
              disabled={!bandera}
              type={"number"}
              required
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setcant(event.target.value)}
              InputLabelProps={{ shrink: true }}
            ></TextField> 
          </div>
          <div className="form-prom-campo5">
          <label className="disponiblepromo"
            >Disponible: </label>
            <Checkbox
            checked={enable}
            disabled={!bandera}
            onChange={handleChange}
            inputProps={{"aria-label": "primary checkbox"}}
            />      
          </div>
        </div>
       <div className="grid-form3">
        
          <div className="form-prom-campo6">
          <label className="Fechainicial">Fecha Inicial</label>
         
          <input
                  type="text"
                  className="fi" 
                  required
                  disabled={!bandera}
                  style={{"width":"100%"}}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                placeholder="Fecha inicial"
                onChange={(event) => setfi(event.target.value)}
            ></input>
          </div>
          <div className="form-prom-campo7">
          <label className="Fechainicial">Fecha Final</label>
         
          <input
                  type="text"
                  required
                  style={{"width":"100%"}}
                  disabled={!bandera}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
            ></input>
          </div>
        </div>
       </div>
      <br></br>
      <div style={bandera===true?{"displat":"block"}:{"display":"none"}}>     
        <div className="new_table_v2" style={{"width":"92%", margin: "0 auto"}}>      
        <MaterialReactTable
              columns={columns}
              data={products}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              // onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              editDisplayMode={'table'}
              enableEditing
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: modalT }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={{
                showGlobalFilter: true,
                 columnOrder: [
                   'code',
                   'product_name',
                   'cost',
                   'quantity',
                   'mrt-row-select'
                 ],
              }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
                      muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,

                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    products.splice(
                      hoveredRow.index,
                      0,
                      products.splice(draggingRow.index, 1)[0],
                    );
                    setproducts([...products]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              // renderTopToolbarCustomActions={({ }) => (
              //   <SEARCH_FILTERS
              //     fi={fi}
              //     setfi={setfi}
              //     ff={ff}
              //     setff={setff}
              //     contpackage={contpackage}
              //     handleSubmit={handleSubmit}
              //     handle_send_data={handle_send_data}
              //     Terminar={Terminar}
              //   ></SEARCH_FILTERS>
              // )}
          
          //  renderToolbarInternalActions={({ table }) => (
          //    <RENDER_TOOLBAR_INTERNAL_ACTIONS
          //     table={table}
          //      file_name={"Ventas"}
          //      // update_identifiers={update_identifiers}
          //      settotal_rows={settotal_rows}
          //    />
          //  )
          //  }
        />
        </div>
      </div>        
    </div>
      <br/>
         <Box>
            {
              bandera===true?
              <Button
              style={{"backgroundColor":"green"}}
              variant="contained"
              sx={{ m: 1.5, width: "30ch" }}
              onClick={addpromotion}
            >
              Agregar Promoción
            </Button>:
            <Button
            style={{"backgroundColor":"green", "height":"35px"}}
            variant="contained"
            sx={{ m: 1.5, width: "30ch" }}
            onClick={addpromotion}
          ><Spinner/></Button>
          }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
              cancelar
            </Button>
          </Box>
      </div>
  );
}

export default CreatePromotions_v2;
