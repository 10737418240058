import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { create_policy } from "../../../services/expenses/expenses";
import "../../expense.css";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValueType = [
  { value: "INGRESO", label: "INGRESO" },
  { value: "EGRESO", label: "EGRESO" },
];

const CREATE_POLICY = (props) => {
  const dialog = useDialog();
  const [open, setOpen] = useState(false);
  const [codTypePolicy, setcodTypePolicy] = useState("INGRESO");
  const [codConceptPolicy, setcodConcepPolicy] = useState("");
  const [ff, setff] = useState(true);

  const handelConcept = (event) => {
    setcodConcepPolicy(event?.target?.value);
  };
  const handelType = (event) => {
    setcodTypePolicy(event?.target?.value);
  };

  var data = {
    policy: {
      concept: codConceptPolicy,
      type: codTypePolicy,
      export_date: ff,
    },
  };

  console.log(data);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    props?.setmodatT ? props?.setmodalT(true): console.log("");

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        var data = {
          policy: {
            concept: codConceptPolicy,
            type: codTypePolicy,
            export_date: ff,
          },
        };
        console.log(data);
        setOpen(true);
        try {
          await create_policy(data);
          setOpen(false);
          props?.setmodatT ? props?.setmodalT(true): console.log("");
          props?.alertas ? props?.alertas("Poliza creada correctamente", true):console.log("");
          dialog.close(true);
          props?.handleSubmit ? props?.handleSubmit() : console.log("");
        } catch (err) {
          setOpen(false);
          var error = err?.response?.data?.errorMessage;
          alert("Error.," + error);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{"textAlign":"center"}}>
      <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>

      <div>
        <form>
          <Box>
            <TextareaAutosize
              label="Concepto"
              variant="outlined"
              aria-label="empty textarea"
              value={codConceptPolicy}
              placeholder="Concepto"
              className="TexAreaAutosize"
              onChange={handelConcept}
            />
          </Box>

          <div className="generarExpense">
            <div className="expen-6">
              <input
                type="text"
                className="fExpense2"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha"
                onChange={(event) => setff(event.target.value)}
              ></input>
            </div>
            <div className="expen-7">
              <TextField
                select
                label="Tipo"
                value={codTypePolicy}
                sx={{ m: 1.5, width: "94%" }}
                onChange={handelType}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {ValueType.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    selected={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </div>

          <br></br>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              CREAR
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default CREATE_POLICY;
