import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../../imgComponents/save.png";
import Delete from "../../../../imgComponents/borrar.png";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import CREATE_UPDATE_DETAIL from "./create_update_detail";
import { delete_expense_detail } from "../../../../services/expenses/expenses";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const d = await await delete_expense_detail(props?.dataprincipal?.original.id, props?.row?.original?.id);
                   console.log(d);
                   props.alertas("Detalle de Gasto Eliminado", true);
                   props.setmodalT(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.alertas("Error, no se pudo eliminar el Detalle de Gasto", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar este Detalle de Gasto?','Eliminar Detalle de Gasto','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Detalle"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Detalle
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ 
                    async () => {
                    props.closeMenu();  
                    if (props?.row.original.modifiable === true) {
                      const result = await CustomDialog(
                        <CREATE_UPDATE_DETAIL
                            method={"UPDATE"}
                            id_detail={props?.row.original.id}
                            row={props?.dataprincipal?.original}
                            codaccounts={props?.row.original.account}
                            codconcept={props?.row.original.concept}
                            codamount={props?.row.original.ammount}
                            codeffect={props?.row.original.efect}
                            codidConcept={props?.row.original.id_account}
                            foreign={props.foreign}
                            handleSubmit={props?.handleSubmit}
                      />,
                    {
                        className: "custom-modal",
                        title: "EDITAR DETALLES",
                        showCloseIcon: true,
                    });
                    {
                        props.handleSubmit();
                    }
                  }else {
                    alert("Este detalle ya no se puede modificar");
                  }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Detalle"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Detalle
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
