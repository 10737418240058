import { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import { ValidationTextField } from '../../form_business_partner/get_busiines_p';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import {
    Box,

} from '@mui/material';
import { CustomDialog, useDialog } from "react-st-modal";

const sheet_format_data = [
    { value: "letter", label: "Carta" },
    { value: "legal", label: "Oficio" },
    { value: "tabloid", label: "Doble Carta" },
    { value: "a4", label: "A4" },
    { value: "a3", label: "A3" },
    { value: "a2", label: "A2" },
    { value: "a1", label: "A1" },
];
const blade_orientation_data = [
    { value: "p", label: "Vertical" },
    { value: "l", label: "Horizontal" },
];

const PDF_CONFIG = (props) => {
    const dialog = useDialog();
    const [pdfSrc, setpdfSrc] = useState("");
    const [letter_size, setletter_size] = useState(props?.old_balance ? 12 : props?.product_load?10:8);
    const [table_with_dt, settable_with_dt] = useState(props?.product_load?65:90);
    const [sheet_format, setsheet_format] = useState("letter");
    const [blade_orientation, setblade_orientation] = useState("p");
    const [title_pdf, settitle_pdf] = useState(props?.old_balance ? "Antigüedad de Saldos" : "");
    const [isLoading, setIsLoading] = useState(false);



    const handleExportRowsPDF = (rows, header, orientation, format_sheet, size, title_pdf) => {
        setIsLoading(true);

        const body = rows.map(row =>
            header.map(fieldName => {
                var value = row[fieldName];
                if (value === null || value === undefined) {
                    return '';
                }
                return value.toString();
            })
        );

        console.log(body)
        const doc = new jsPDF({
            orientation: orientation,
            unit: 'px',
            format: format_sheet,
        });

        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const titleDimensions = doc.getTextDimensions(title_pdf);
        const titleX = (pageWidth - titleDimensions.w) / 2;
        const tableWidth = pageWidth * table_with_dt / 100;
        const startX = (pageWidth - tableWidth) / 2;
        const columnWidth = tableWidth / header.length;
        var columnStyles = {};

        header.forEach((_, index) => {
            columnStyles[index] = { cellWidth: columnWidth };
        });

        doc.text(title_pdf, titleX, 25);

        doc.setFont('helvetica');
        doc.setTextColor(100);
        doc.autoTable({
            head: [header],
            body: body,
            theme: 'striped',
            styles: {
                font: 'helvetica',
                lineColor: [44, 62, 80],
                lineWidth: 1,
                fontSize: (Number(size) / 1.333)
            },
            headStyles: {
                fillColor: [0, 51, 102],
                textColor: 255,
                fontStyle: 'bold',
            },
            footStyles: {
                fillColor: [0, 51, 102],
                textColor: 255,
            },
            columnStyles: columnStyles,
            startY: 40,
            margin: { left: startX }
        });

        // doc.save(props.file_name + '.pdf');
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setpdfSrc(pdfUrl);
        setIsLoading(false);

    };

    const handleExportRowsPDF_debt = (rows, header, orientation, format_sheet, size, title_pdf) => {
        setIsLoading(true);

        const body = rows.map(row =>
            header.map(fieldName => {
                var value = row[fieldName];
                if (value === null || value === undefined) {
                    return '';
                }

                return value.toString();

            })
        );
        const doc = new jsPDF({
            orientation: orientation,
            unit: 'px',
            format: format_sheet,
        });

        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const titleDimensions = doc.getTextDimensions(title_pdf);
        const titleX = (pageWidth - titleDimensions.w) / 2;
        const tableWidth = pageWidth * table_with_dt / 100;
        const startX = (pageWidth - tableWidth) / 2;
        const columnWidth = tableWidth / header.length;
        var columnStyles = {};

        header.forEach((_, index) => {
            columnStyles[index] = { cellWidth: columnWidth };
        });

        doc.text(title_pdf, titleX, 24);
        doc.line(startX, 25, tableWidth + 25, 25);
        console.log(props.id_shearch_dt)
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.text(props.shearch_old_balance_data === 1 ? "Antigüedad de Facturas Pagadas" : "Antigüedad de Saldos", 25, 24);
        doc.text(props?.id_shearch_dt, 25, 34);
        doc.text(props.old_balance_date, 25, 44);
        doc.text("Moneda: Pesos", 25, 54);
        doc.text("Tipo cambio: 1.000000", 170, 54);




        doc.setFont('helvetica');
        doc.setTextColor(100);
        doc.autoTable({
            head: [header],
            body: body,
            theme: 'striped',
            styles: {
                font: 'helvetica',
                lineColor: [44, 62, 80],
                lineWidth: 1,
                fontSize: (Number(size) / 1.333)
            },
            headStyles: {
                fillColor: [0, 51, 102],
                textColor: 255,
                fontStyle: 'bold',
            },
            footStyles: {
                fillColor: [0, 51, 102],
                textColor: 255,
            },
            columnStyles: {
                0: { cellWidth: 100 },
                1: { cellWidth: "auto" },
                2: { cellWidth: "auto" },
                3: { cellWidth: "auto" },
                4: { cellWidth: "auto" },
                5: { cellWidth: "auto" },
                6: { cellWidth: "auto" },
                7: { cellWidth: "auto" },
                8: { cellWidth: "auto" },
                9: { cellWidth: "auto" },


            },
            startY: 60,
            margin: { left: startX }
        });

        // doc.save(props.file_name + '.pdf');
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setpdfSrc(pdfUrl);

    };




    const handleExportRowsPDF_ProducLoad = (rows, header, orientation, format_sheet, size, title_pdf) => {
        setIsLoading(true);

        const body = rows.map(row =>
            header.map(fieldName => {
                var value = row[fieldName];
                if (value === null || value === undefined) {
                    return '';
                }

                return value.toString();

            })
        );
        const doc = new jsPDF({
            orientation: orientation,
            unit: 'px',
            format: format_sheet,
        });

        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const titleDimensions = doc.getTextDimensions(title_pdf);
        const titleX = (pageWidth - titleDimensions.w) / 2;
        const tableWidth = pageWidth * table_with_dt / 100;
        const startX = (pageWidth - tableWidth) / 2;
        const columnWidth = tableWidth / header.length;
        var columnStyles = {};

        header.forEach((_, index) => {
            columnStyles[index] = { cellWidth: columnWidth };
        });

        doc.text(title_pdf, titleX, 25);

        doc.setFont('helvetica');
        doc.setTextColor(100);
        doc.autoTable({
            head: [header],
            body: body,
            theme: 'striped',
            styles: {
                font: 'helvetica',
                lineColor: [44, 62, 80],
                lineWidth: 1,
                fontSize: (Number(size) / 1.333)
            },
            headStyles: {
                fillColor: [0, 51, 102],
                textColor: 255,
                fontStyle: 'bold',
            },
            footStyles: {
                fillColor: [0, 51, 102],
                textColor: 255,
            },
            columnStyles: {
                0: { cellWidth: 40 },
                1: { cellWidth: 150 },
                2: { cellWidth: 40 },
                3: { cellWidth: 40 },
                4: { cellWidth: 40 },
                5: { cellWidth: 40 },



            },
            startY: 40,
            margin: { left: startX }
        });

        // doc.save(props.file_name + '.pdf');
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setpdfSrc(pdfUrl);
        setIsLoading(false);

    };








    useEffect(() => {
        if (pdfSrc) {
            CustomDialog(
                <div>
                    <iframe src={pdfSrc} width="100%" height="600px"></iframe>

                </div>,
                { className: "custom-modal-2", title: 'PDF', showCloseIcon: true, }
            );
            setIsLoading(false);

        }
    }, [pdfSrc]);



    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

                <ValidationTextField
                    label="Titulo del PDF"
                    placeholder="Titulo del PDF"
                    sx={{ m: 2, width: "50%" }}
                    variant="outlined"
                    id="validation-outlined-input"
                    onChange={(e) => settitle_pdf(e.target.value)}
                    value={title_pdf}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <ValidationTextField
                    label="Tamaño de letra"
                    placeholder="Tamaño de letra"
                    sx={{ m: 2, width: "24%" }}
                    variant="outlined"
                    id="validation-outlined-input"
                    type='number'
                    onChange={(e) => setletter_size(e.target.value)}
                    value={letter_size}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <ValidationTextField
                    label="Construcción %"
                    placeholder="Tamaño de letra"
                    sx={{ m: 2, width: "24%" }}
                    variant="outlined"
                    id="validation-outlined-input"
                    type='number'
                    onChange={(e) => settable_with_dt(e.target.value)}
                    value={table_with_dt}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

                <TextField
                    select
                    label="Orientación"
                    sx={{ m: 1.5, width: "100%" }}
                    size="small"
                    onChange={(e) => setblade_orientation(e.target.value)}
                    value={blade_orientation}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {blade_orientation_data?.map((option) => (
                        <option
                            key={option?.value}
                            value={option?.value}
                            selected={option?.value}
                        >
                            {option?.label}
                        </option>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Tipo de Hoja"
                    child
                    sx={{ m: 1.5, width: "100%" }}
                    size="small"
                    value={sheet_format}
                    onChange={(e) => setsheet_format(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {sheet_format_data?.map((option) => (
                        <option
                            key={option?.value}
                            value={option?.value}
                            selected={option?.value}
                        >
                            {option?.label}
                        </option>
                    ))}
                </TextField>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '98%', margin: "0 auto", marginBottom: "10px" }}>
                <Button
                    variant="contained"
                    sx={{ width: "70%", height: "34px", margin: "5px", background: "green", display: props.old_balance || props?.product_load ? "none" : "" }}
                    onClick={() => handleExportRowsPDF(props.format_export, props.enable_colums, blade_orientation, sheet_format, letter_size, title_pdf, table_with_dt)}
                >
                    Generar
                </Button>
                <Button
                    variant="contained"
                    disabled={isLoading}
                    sx={{ width: "70%", height: "34px", margin: "5px", background: "green", display: props.old_balance ? "" : "none" }}
                    onClick={() => handleExportRowsPDF_debt(props.format_export, props.enable_colums, blade_orientation, sheet_format, letter_size, title_pdf, table_with_dt)}
                >
                    Generar Antigüedad de Saldos
                </Button>
                <Button
                    variant="contained"
                    disabled={isLoading}
                    sx={{ width: "70%", height: "34px", margin: "5px", background: "green", display: props.product_load ? "" : "none" }}
                    onClick={() => handleExportRowsPDF_ProducLoad(props.format_export, props.enable_colums, blade_orientation, sheet_format, letter_size, title_pdf, table_with_dt)}
                >
                    Generar Reporte
                </Button>

                <Button
                    variant="contained"
                    sx={{ width: "30%", height: "34px", margin: "5px", background: "red" }}
                    onClick={() => dialog.close()}
                >
                    CERRAR
                </Button>
            </Box>
        </div>
    );
}
export default PDF_CONFIG;
