import axios from "axios";
import config from "../../config";

const urlspt = `${config?.baseURLSUPPORT}`;

//Consultar Soportes
export const get_supports = async () => {
    const url = `${urlspt}/production/supports`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

//Crear Soportes
export const createSupport = async (data) => {
  const url = `${urlspt}/production/supports`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Actualizar Soporte
export const updateSupport = async (id, data) => {
  const url = `${urlspt}/production/supports/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar comentarios de un ticket
export const getComments = async (id) => {
  const url = `${urlspt}/production/supports/${id}/comments`;
  try {
    const response = await axios.get(url, {
      params: {
        ticket: id,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Crear Comentario
export const createComment = async (id,data) => {
  const url = `${urlspt}/production/supports/${id}/comments`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Actualizar Soporte
export const updateComment = async (id, data) => {
  const url = `${urlspt}/production/supports/${id}/comments`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Actualizar Soporte
export const uploadimgticket = async (id, data) => {
  const url = `${urlspt}/production/supports/${id}/images`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadimgticketprueba = async (id, data) => {
  // const url = `${urlspt}/production/supports/${id}/images`;
  const url = `${urlspt}/od/supports/${id}/images`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Actualizar Soporte
export const getimgticket = async (id, name) => {
  // const url = `${urlspt}/production/supports/${id}/images`;
  const url = `${urlspt}/od/supports/${id}/images`;
  try {
    const response = await axios.get(url, {
      params: {
        image: name
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_telegram_secrets = async () => {
  const url = `https://kyxq3rrh02.execute-api.us-east-1.amazonaws.com/od/secret_telegram`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json", 
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const post_telegram_msj = async (data) => {
  const url = `https://kyxq3rrh02.execute-api.us-east-1.amazonaws.com/od/secret_telegram`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json", 
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};