//PAQUETERIAS
import { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Button from "@mui/material/Button";
import { CustomDialog } from "react-st-modal";
import NotificationSystem from "react-notification-system";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
//COMPONENTES
import metodo_pago from "../../../dataComponets/metodo_pago.json";
import forma_pago from "../../../dataComponets/forma_pago.json";
import uso_cfdi from "../../../dataComponets/usoCFDI.json";
import voucher_type from "../../../dataComponets/tipo_comprobante.json";
import CONCEPT_INVOICE from "./concept_invoice";
import JOIN_INVOICE from "../join_invoice/join_invoice";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { pagination } from "../../../main_components/pagination/pagination";
import { calculate_tax } from "../../../services/invoices/invoice";
import { get_series } from "../../../services/invoices/invoice";
import WARNING from "../../../main_components/alerts/warning";
import { get_concept } from "../../../services/invoices/invoice";
import { generate_invoice } from "../../../services/invoices/invoice";
import valid_tax_regimen from "../../../dataComponets/filterregimen.json";
import cfdi_regimen from "../../../dataComponets/filtercfdi.json";
//ESTILOS
import "./factura.css";

//IMAGENES / ICONOS
import Spinner from "../../../imgComponents/S.gif";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";

const rlI = [
  { value: "", label: "Selecciona un tipo de relacion" },
  { value: "04", label: "Sustitución de los CFDI previos" },
  { value: "06", label: "Factura generada por los traslados de previos" },
  { value: "07", label: "CFDI por aplicacion de anticipo" },
];
const rl0E = [
  { value: "", label: "Selecciona un tipo de relacion" },
  { value: "01", label: "Nota de crédito de los documentos relacionados" },
  { value: "02", label: "Nota de débito de los documentos relacionados" },
  {
    value: "03",
    label: "Devolucion de mercancías sobre facturas o trasladoas previos",
  },
  { value: "04", label: "Sustitución de los CFDI previos" },
  { value: "06", label: "Factura generada por los traslados de previos" },
];


const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
    height: 42,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const GENERATE_INVOICE = () => {
  const [partners, setpartners] = useState([]);
  const [ser, setSer] = useState([]);
  const [concep, setConcep] = useState([]);
  const [obj_concept, setobj_concept] = useState([]);
  const [obj_partners, setobj_partners] = useState([]);
  const [numSer, setNumSer] = useState();
  const [obj_serie_data, setobj_serie_data] = useState("");
  const [type, settype] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [pay_form, setpay_form] = useState("01");
  const [pay_method, setpay_method] = useState("PUE");
  const [save_concept, setsave_concept] = useState([]);
  const [obj_series, setobj_series] = useState("");
  const [amount, setamount] = useState(1);
  const [invoice, setinvoice] = useState([]);
  const [vSumTotalIm, setVSumTotalIm] = useState(0);
  const [vSumTotalImlocal, setVSumTotalImLocal] = useState(0);
  const [vSumTotal, setVSumTotal] = useState(0);
  const [show_component_calculate_taxes, setshow_component_calculate_taxes] = useState(false);
  const [show_component_credit_day, setshow_component_credit_day] = useState(false);
  const [vSumTotalSub, setVSumTotalSub] = useState(0);
  const [vSumDescuento, setVDescuento] = useState(0);
  const [sinIva, setsinIva] = useState();
  const [psinIva, setpsinIva] = useState();
  const [precioP, setprecioP] = useState("");
  const [unidadP, setUnidadP] = useState("");
  const [edit_description, setedit_description] = useState("");
  const [note, setnote] = useState("");
  const [condition, setcondition] = useState("");
  const [select_cfdi, set_select_cfdi] = useState("");
  const [cfdiselec, setcfdiselec] = useState("none");
  const [cfdiinput, setcfdiinput] = useState("");
  const [uuid_rel, setuuid_rel] = useState(false);
  const [n_forma_pago, setn_forma_pago] = useState(metodo_pago.filter((d) => d.value !== "99"));
  const [n_uso_CFDI, setn_uso_CFDI] = useState([]);
  const [n_metodo_pago, setn_metodo_pago] = useState(forma_pago);




  const notificationSystemRef = useRef();

  const cambiarCFDI = () => {
    setcfdiinput("none");
    setcfdiselec("");
  };

  const selectDiasCreditos = () => {
    if (pay_method === "PPD") {
      setshow_component_credit_day(true);
    } else {
      setshow_component_credit_day(false);
      setcondition("");
    }
  };

  const handle_select_condition = (event) => {
    setcondition(event.target.value);
  };
  const handle_description = (event) => {
    setedit_description(event.target.value);
  };
  const handle_select_cfdi = (event) => {
    set_select_cfdi(event.target.value);
    console.log(partners.tax_regime);
    console.log(partners.rfc);
    console.log(partners);
    valid_regimen(partners.tax_regime, partners.rfc, event.target.value)


  };
  const handle_note = (event) => {
    setnote(event.target.value);
  };
  const handleChangeecalcular = (event) => {
    setsinIva(event.target.value);
  };
  const handleOnClickCalcular = (event) => {
    setprecioP("");
    var pSinIva = sinIva / 1.16;
    setpsinIva(pSinIva.toFixed(2));
    setshow_component_calculate_taxes(false);
  };

  const handleChangeeCantidad = (event) => {
    setamount(event.target.value);
  };
  const handleChangeePrecionP = (event) => {
    setprecioP(event.target.value);
  };
  const handleRemoveItem = (id) => {
    setinvoice(invoice.filter((item) => item.id !== id));
    setsave_concept(save_concept.filter((item) => item.id !== id));
  };

  const handleChangee0 = (event) => {
    setNumSer(event.target.value);
  };

  function numType() {
    var indice2 = ser.findIndex((filio) => filio.serie === numSer);

    if (indice2 === -1) {
      try {
        setobj_serie_data(ser[0].folio);
        setobj_series(ser[0].serie);
      } catch (err) {
        console.log(err);
      }
    } else {
      setobj_series(ser[indice2]?.serie);
      setobj_serie_data(ser[indice2].folio);
    }
  }
  const handleChangee4 = (event) => {
    setpay_form(event.target.value);
  };
  const handleChangee5 = (event) => {
    setpay_method(event.target.value);
    if (event.target.value === "PPD") {
      setn_forma_pago(metodo_pago.filter((d) => d.value === "99"))
      setpay_form("99")
    } else {
      setn_forma_pago(metodo_pago.filter((d) => d.value !== "99"))
      setpay_form("01")
    }
  };
  const handleOnSearch = (string, results) => { };
  const handleOnSearch2 = (string, results) => {
    setobj_concept("");
    setUnidadP("");
    setprecioP("");
    setpsinIva("");
    setedit_description("");
  };

  const handleOnHover = (result) => { };
  const handleOnHover2 = (result) => { };
  const handleOnSelect = (item) => {
    setpartners(item);
    numType();
    setsave_concept([]);
    setinvoice([]);
    handle_valid_data(item);
  };
  const handleOnSelect2 = (item) => {
    setobj_concept(item);
    setedit_description(item?.descripcion);
    setprecioP(item?.valor_unitario);
    numType();
  };
  const handleOnFocus = () => {
    console.log("Focused");
    setobj_concept("");
    setUnidadP("");
    setprecioP("");
    setpsinIva("");
    setedit_description("");
  };
  const handleOnFocus2 = () => {
    console.log("Focused");
  };
  const handleOnClear = () => {
    console.log("Cleared");
  };
  const handleOnClear2 = () => {
    console.log("Cleared");
  };

  function establecer(cont) {
    if (isExist(cont) === true) {
      cont = cont + 2;
    } else {
      cont = cont + 1;
      if (isExist(cont) === true) {
        cont = cont + 2;
      } else {
        cont = cont + 1;
        if (isExist(cont) === true) {
          establecer(cont);
        } else {
          cont = cont + 1;
        }
      }
    }
  }

  function isExist(id) {
    if (invoice.indexOf(id) > -1) {
      return true;
    } else {
      return false;
    }
  }

  const handle_series = async (type) => {
    setobj_serie_data("");
    console.log(type);
    setSer([]);
    if (type === "E") {
      set_select_cfdi("G02")
      setcfdiinput("none");
      setcfdiselec("");
      // setn_uso_CFDI(uso_cfdi.filter((d) => d.value === "G02"));
      setn_uso_CFDI(uso_cfdi);

      setn_metodo_pago(forma_pago.filter((d) => d.value === "PUE"));
      setn_forma_pago(metodo_pago.filter((d) => d.value !== "99"))
      setpay_method("PUE")
      setpay_form("15")
    } else {
      set_select_cfdi("")
      setcfdiinput("");
      setcfdiselec("none");
      setn_uso_CFDI(uso_cfdi);
      setn_metodo_pago(forma_pago);
      setpay_method("PUE");
      setn_forma_pago(metodo_pago.filter((d) => d.value !== "99"))
      setpay_form("01")



    }
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_series(type);
            setSer(dt.data);
          } catch (err) {
            console.log("error", err);
            setSer([]);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_get_businesspartners = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var bp = await pagination(3, "bp", null);
            setobj_partners(bp);
          } catch (err) {
            setobj_partners({});
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_get_concept = async () => {
    setConcep([]);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_concept();
            setConcep(dt.data);
          } catch (err) {
            setConcep([]);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_calculate_tax = async () => {
    var data = {
      person_type: partners.person_type,
      concepto: {
        concept: obj_concept.concept,
        descripcion: edit_description,
        clave_prod_serv: obj_concept.clave_prod_serv,
        clave_unidad: obj_concept.clave_unidad,
        unidad: obj_concept.unidad,
        no_identificacion: obj_concept.no_identificacion,
        valor_unitario: Number(precioP) || Number(psinIva),
        peso_en_kg: obj_concept.peso_en_kg,
        cantidad: Number(amount),
        descuento: obj_concept.descuento,
        importe: obj_concept.importe,
        impuestos: obj_concept.impuestos,
        implocales: obj_concept.implocales,
      },
    };

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const res = await calculate_tax(data);
            const concept_data = res.data;
            const transfer_tax = res.data.concepto.impuestos.traslados;
            const transfer_tax_impl = res.data.concepto.implocales.traslados;
            const concept_tax = res.data.concepto.impuestos;
            const concept_tax_impl = res.data.concepto.implocales;
            const sum_transfer_tax = transfer_tax
              .map((item) => item.importe)
              .reduce((prev, curr) => prev + curr, 0);
            const sum_transfer_tax_impl = (transfer_tax_impl ?? [])
              .map((item) => item.importe)
              .reduce((prev, curr) => prev + curr, 0);
            var cont = invoice.length;
            establecer(cont);

            var impuestolocal =
              obj_concept.implocales?.traslados !== null &&
                obj_concept.implocales.traslados.length > 0 &&
                obj_concept.implocales.traslados[0].implocaltrasladado === "ISH"
                ? "ISH(" + transfer_tax_impl[0].importe + ")"
                : "";
            var taxes = "";
            if (obj_concept.impuestos?.traslados != null) {
              for (var i = 0; i < obj_concept.impuestos.traslados.length; i++) {
                var impuesto = obj_concept.impuestos.traslados[i].impuesto;
                var importe =
                  transfer_tax[i]?.importe === 0
                    ? "0.0000"
                    : transfer_tax[i]?.importe;
                if (impuesto === "001") {
                  taxes += "ISR(" + importe + ")";
                } else if (impuesto === "002") {
                  taxes += "IVA(" + importe + ")";
                } else if (impuesto === "003") {
                  taxes += "IEPS(" + importe + ")";
                }
              }
            }

            setinvoice([
              ...invoice,
              {
                id: cont,
                subtotal: concept_data.concepto.importe,
                total: concept_data.total,
                timpuestos: sum_transfer_tax,
                timpuestosLocales: sum_transfer_tax_impl,
                precio: concept_data.concepto.valorunitario,
                concepto: obj_concept.concept,
                implocales: impuestolocal,
                impuestos: taxes,
                descripcion: concept_data.concepto.descripcion,
                claveprodserv: concept_data.concepto.claveprodserv,
                claveunidad: concept_data.concepto.claveunidad,
                unidad: concept_data.concepto.unidad,
                noidentificacion: concept_data.concepto.noidentificacion,
                valorunitario: concept_data.concepto.valorunitario,
                cantidad: concept_data.concepto.cantidad,
                descuento: concept_data.concepto.descuento,
                importe: concept_data.concepto.importe,
              },
            ]);

            const CONCEPTOOO = [
              ...save_concept,
              {
                id: cont,
                descripcion: concept_data.concepto.descripcion,
                claveprodserv: concept_data.concepto.claveprodserv,
                claveunidad: concept_data.concepto.claveunidad,
                unidad: concept_data.concepto.unidad,
                noidentificacion: concept_data.concepto.noidentificacion,
                valorunitario: concept_data.concepto.valorunitario,
                cantidad: concept_data.concepto.cantidad,
                descuento: concept_data.concepto.descuento,
                importe: concept_data.concepto.importe,
                impuestos: concept_tax,
                implocales: concept_tax_impl,
              },
            ];
            setsave_concept(CONCEPTOOO);
            setobj_concept(" ");
            setUnidadP(" ");
            setprecioP(" ");
            setedit_description("");
          } catch (err) {
            catchErrorModul(
              "Generar Factura => Calcular impuestos al concepto",
              JSON.stringify(data),
              JSON.stringify(err?.response)
            );
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  var dt = {
    comprobante: {
      serie: obj_series,
      formapago: pay_form,
      condicionesdepago: condition || pay_method,
      subtotal: Number(vSumTotalSub),
      descuento: vSumDescuento,
      moneda: "MXN",
      tipocambio: null,
      total: Number(vSumTotal),
      tipodecomprobante: type,
      exportacion: "01",
      metodopago: pay_method,
    },
    receptor: {
      id: partners.id,
      nombre: partners.business_partner,
      rfc: partners.rfc,
      usocfdi: select_cfdi || partners.cfdi_use,
      residenciafiscal: partners.tax_residence,
      numregidtrib: partners.num_reg_id_trib,
      regimenfiscalreceptor: partners.tax_regime,
      domiciliofiscalreceptor: partners.zip_code,
    },
    conceptos: save_concept,
    cfdirelacionados:
      uuid_rel === undefined
        ? null
        : uuid_rel === false
          ? null
          : [
            {
              tiporelacion: uuid_rel?.motivo,
              uuid: uuid_rel?.uuid_sat,
            },
          ],
    nota: note,
  };
  console.log(dt)

  const handleSubmit = async () => {
    var dt = {
      comprobante: {
        serie: obj_series,
        formapago: pay_form,
        condicionesdepago: condition || pay_method,
        subtotal: Number(vSumTotalSub),
        descuento: vSumDescuento,
        moneda: "MXN",
        tipocambio: null,
        total: Number(vSumTotal),
        tipodecomprobante: type,
        exportacion: "01",
        metodopago: pay_method,
      },
      receptor: {
        id: partners.id,
        nombre: partners.business_partner,
        rfc: partners.rfc,
        usocfdi: select_cfdi || partners.cfdi_use,
        residenciafiscal: partners.tax_residence,
        numregidtrib: partners.num_reg_id_trib,
        regimenfiscalreceptor: partners.tax_regime,
        domiciliofiscalreceptor: partners.zip_code,
      },
      conceptos: save_concept,
      cfdirelacionados:
        uuid_rel === undefined
          ? null
          : uuid_rel === false
            ? null
            : [
              {
                tiporelacion: uuid_rel?.motivo,
                uuid: uuid_rel?.uuid_sat,
              },
            ],
      nota: note,
    };
    setIsLoding(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            await generate_invoice(dt);
            setIsLoding(false);
            window.location = "/GenerarFactura";
            alert("Factura Generada.");
          } catch (err) {
            catchErrorModul(
              "Generar Factura",
              JSON.stringify(dt),
              JSON.stringify(err?.response)
            );
            alert(JSON.stringify(err.response));
            setIsLoding(false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
      setIsLoding(false);

    }
  };

  const handele_test = (msj) => {
    notificationSystemRef.current.addNotification({
      message: <WARNING msj={msj} view={true}></WARNING>,
      level: "success",
      position: "br",
      autoDismiss: 30,
    });
  };

  const handle_valid_data = (b) => {
    console.log(b);
    if (b.tax_regime === "" || b.tax_regime === null) {
      handele_test("El socio de negocios seleccionado no tiene configurado un régimen capital favor de actualizar el socio de negocios");
      return true
    }
    if (b.zip_code === "" || b.zip_code === null) {
      handele_test("El socio de negocios seleccionado no tiene configurado bien su dirección fiscal, favor de validar sus datos domiciliarios");
      return true
    }
    if (b.tax_residence === "") {
      handele_test("El socio de negocios tiene configurado un dato erróneo en el apartado de socio extranjero, favor de verificar el dato");
      return true
    }
    if (b.tax_regime !== "" || b.tax_regime !== null) {
      valid_regimen(b.tax_regime, b.rfc, b.cfdi_use)
    }
  };


  function valid_regimen(tax_regime, rfc, usoCfdi) {
    const tipoPersonaFiscal = valid_tax_regimen.find(
      (tipo) => tipo.value === Number(tax_regime)
    );
    console.log(tipoPersonaFiscal);

    if (tipoPersonaFiscal) {
      if (
        (tipoPersonaFiscal.person_type === 12 && rfc.length === 12) ||
        (tipoPersonaFiscal.person_type === 13 && rfc.length === 13) ||
        (tipoPersonaFiscal.person_type === 1 && rfc.length === 12)
      ) {
        verificarAplicabilidadRfcCfdi(tax_regime, usoCfdi)
      } else {
        handele_test(`El régimen fiscal "${tipoPersonaFiscal.label}"  no corresponde al tipo de persona "${rfc.length === 12 ? "Moral" : "Física"}" asignado al socio de negocios.`);
      }
    } else {
      return false;
    }
  }

  function verificarAplicabilidadRfcCfdi(regimenFiscal, usoCfdi) {
    console.log(regimenFiscal);
    console.log(usoCfdi);

    const cfdi = cfdi_regimen.find((c) => c.value === usoCfdi);
    console.log(cfdi);
    console.log(cfdi?.stf?.includes(Number(regimenFiscal)))

    if (cfdi) {
      if (cfdi.stf?.includes(Number(regimenFiscal))) {
        console?.log(cfdi?.stf?.includes(Number(regimenFiscal)))
        return false
      } else {
        const cfdisConRegimen = cfdi_regimen.filter((c) => c.stf?.includes(Number(regimenFiscal)));
        console.log(cfdisConRegimen)
        const cfdisAceptados = cfdisConRegimen.map((c) => c.value);
        console.log(cfdisAceptados)
        handele_test(`El uso de CFDI ${usoCfdi} seleccionado no es aplicable al régimen fiscal ${regimenFiscal} asignado al socio de negocios. Se recomienda cambiar a los siguientes CFDI: ${cfdisAceptados.join(", ")}.`);
        return true
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    setVSumTotalIm(
      invoice
        .map((saldo) => parseFloat(saldo.timpuestos))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    setVSumTotalImLocal(
      invoice
        .map((saldo) => parseFloat(saldo.timpuestosLocales))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    setVSumTotal(
      invoice
        .map((saldo) => parseFloat(saldo.total))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    setVSumTotalSub(
      invoice
        .map((saldo) => parseFloat(saldo.subtotal))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    const sumar = invoice.reduce(
      (previous, current) => previous + parseFloat(current.descuento),
      0
    );
    setVDescuento(sumar || null);
  }, [invoice]);

  useEffect(() => {
    handle_get_businesspartners();
    handle_get_concept();
  }, []);

  return (
    <div>

      <MODAL_TABLE modalGeneral={isLoding} modalT={isLoding} message={"Generando..."}></MODAL_TABLE>

      <div className="">
        <BREADCRUMBS
          niveles={[{ label: "REALIZAR FACTURA", path: null }]}
        ></BREADCRUMBS>

        <NotificationSystem ref={notificationSystemRef}></NotificationSystem>

        <div className="containerFactureResponse-0">
          <div className="fact-19 color-2">
            <Button
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              S
              onClick={async () => {
                const result = await CustomDialog(
                  <JOIN_INVOICE
                    type={type === "I" ? rlI : rl0E}
                    relacionar={true}
                    rfc={partners.rfc}
                  ></JOIN_INVOICE>,
                  {
                    className: "custom-modal-3",
                    title: "Relacionar Factura",
                    showCloseIcon: true,
                  }
                );
                setuuid_rel(result);
              }}
            >
              {uuid_rel === undefined
                ? "Relacionar"
                : uuid_rel === false
                  ? "Relacionar"
                  : "UUID_SAT:" +
                  uuid_rel?.uuid_sat +
                  " Motivo:" +
                  uuid_rel?.motivo}
            </Button>{" "}
            {/*dos */}
          </div>

          <div className="fact-20 color-2">
            <Button
              onClick={() => setshow_component_calculate_taxes(!show_component_calculate_taxes)}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
            >
              {show_component_calculate_taxes ? `Ocultar` : `Calcular Iva`}
            </Button>
            {/*tres */}
          </div>
        </div>

        <div>
          <div className={show_component_calculate_taxes ? "show-element" : null}>
            {show_component_calculate_taxes && (
              <Box>
                <ValidationTextField
                  label="Calcular Iva "
                  variant="outlined"
                  placeholder="Calcular Iva"
                  sx={{ m: 2, width: "20ch" }}
                  value={sinIva}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChangeecalcular}
                />
                <Button
                  onClick={handleOnClickCalcular}
                  sx={{ m: 2, width: "30ch" }}
                  variant="contained"
                >
                  Calcular Iva
                </Button>
              </Box>
            )}
          </div>
        </div>

        <div className="containerFactureResponse">
          <div className="fact-1 color-1">
            <TextField
              id="Tipo de comprobante"
              select
              label="Tipo de comprobante"
              value={type}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="CFDIUse"
              onChange={(event) => {
                handle_series(event.target.value)
                settype(event.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {voucher_type.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="fact-2 color-2">
            <TextField
              id="Serie"
              select
              label="Tipo de serie"
              value={numSer}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handleChangee0}
              size="small"
              name="CFDIUse"
              onClick={numType}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {ser.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.serie}
                </option>
              ))}
            </TextField>
          </div>

          <div className="fact-3 color-3">
            <ValidationTextField
              label="Folio"
              variant="outlined"
              placeholder="Folio"
              sx={{ m: 1.5, width: "100%" }}
              value={obj_serie_data}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="fact-4 color-4">
            <TextField
              id="condiciones de pago"
              select
              label="Método de pago"
              value={pay_method}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handleChangee5}
              size="small"
              onClick={selectDiasCreditos}
              name="metodoPago"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {n_metodo_pago.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="fact-5 color-5">
            <TextField
              id="payMethod"
              select
              label="Forma de pago"
              value={pay_form}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handleChangee4}
              size="small"
              name="payMethod"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {n_forma_pago.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
        </div>

        <div className={show_component_calculate_taxes ? "show-element" : null}>
          {show_component_credit_day && (
            <Box>
              <ValidationTextField
                label="Días de crédito"
                variant="outlined"
                placeholder="Días de crédito"
                sx={{ m: 2, width: "20%" }}
                value={condition}
                InputLabelProps={{ shrink: true }}
                onChange={handle_select_condition}
              />
            </Box>
          )}
        </div>

        <div className="containerFactureResponse-2">
          <div className="fact-6 color-1">
            <div className="fact6-reactSearchautocomplete">
              <ReactSearchAutocomplete
                items={obj_partners}
                fuseOptions={{ keys: ["rfc", "business_partner"] }}
                resultStringKeyName="business_partner"
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                onClear={handleOnClear}
                showIcon={true}
                placeholder="Buscar socio de negocios..."
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 10,
                }}
              />
            </div>
          </div>

          <div className="fact-7 color-2">
            <ValidationTextField
              label="RFC"
              variant="outlined"
              placeholder="RFC"
              sx={{ m: 2, width: "100%" }}
              value={partners.rfc}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="fact-8 color-3">
            <ValidationTextField
              label="USO DE CFDI"
              variant="outlined"
              style={{ display: cfdiinput }}
              onClick={cambiarCFDI}
              sx={{ m: 2, width: "100%" }}
              value={
                (partners.cfdi_use === "G01"
                  ? "Adquisición de mercancias (G01)"
                  : "") +
                (partners.cfdi_use === "G02"
                  ? "Devoluciones, descuentos o bonificaciones (G02)"
                  : "") +
                (partners.cfdi_use === "G03" ? "Gastos en general (G03)" : "") +
                (partners.cfdi_use === "I01" ? "Construcciones (I01)" : "") +
                (partners.cfdi_use === "I02"
                  ? "Mobilario y equipo de oficina por inversiones (I02)"
                  : "") +
                (partners.cfdi_use === "I03"
                  ? "Equipo de transporte (I03)"
                  : "") +
                (partners.cfdi_use === "I04"
                  ? "Equipo de computo y accesorios (I04)"
                  : "") +
                (partners.cfdi_use === "I05"
                  ? "Dados, troqueles, moldes, matrices y herramental (I05)"
                  : "") +
                (partners.cfdi_use === "I06"
                  ? "Comunicaciones telefónicas (I06)"
                  : "") +
                (partners.cfdi_use === "I07"
                  ? "Comunicaciones satelitales (I07)"
                  : "") +
                (partners.cfdi_use === "I08"
                  ? "Otra maquinaria y equipo (I08)"
                  : "") +
                (partners.cfdi_use === "D01"
                  ? "Honorarios médicos, dentales y gastos hospitalarios (D01)"
                  : "") +
                (partners.cfdi_use === "D02"
                  ? "Gastos médicos por incapacidad o discapacidad (D02)"
                  : "") +
                (partners.cfdi_use === "D03" ? "Gastos funerales (D03)" : "") +
                (partners.cfdi_use === "D04" ? "Donativos (D04)" : "") +
                (partners.cfdi_use === "D05"
                  ? "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación) (D05)."
                  : "") +
                (partners.cfdi_use === "D06"
                  ? "Aportaciones voluntarias al SAR (D06)"
                  : "") +
                (partners.cfdi_use === "D07"
                  ? "Primas por seguros de gastos médicos (D07)"
                  : "") +
                (partners.cfdi_use === "D08"
                  ? "Gastos de transportación escolar obligatoria (D08)"
                  : "") +
                (partners.cfdi_use === "D09"
                  ? "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones (D09)"
                  : "") +
                (partners.cfdi_use === "D10"
                  ? "Pagos por servicios educativos (D10)"
                  : "") +
                (partners.cfdi_use === "P01" ? "Por definir (P01)" : "") +
                (partners.cfdi_use === "S01"
                  ? "Sin efectos fiscales (S01)"
                  : "")
              }
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="CFDIUse"
              select
              label="USO DE CFDI"
              value={select_cfdi}
              style={{ display: cfdiselec }}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handle_select_cfdi}
              size="small"
              name="CFDIUse"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {n_uso_CFDI.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
        </div>

        <div className="containerFactureResponse-3">
          <div className="fact-9 color-1">
            <div className="fact6-reactSearchautocomplete">
              <ReactSearchAutocomplete
                items={concep}
                fuseOptions={{ keys: ["concept"] }}
                resultStringKeyName="concept"
                onSearch={handleOnSearch2}
                onHover={handleOnHover2}
                onSelect={handleOnSelect2}
                onFocus={handleOnFocus2}
                onClear={handleOnClear2}
                showIcon={true}
                placeholder="Buscar concepto..."
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 2,
                }}
              />
            </div>
          </div>

          <div className="fact-10 color-2">
            <TextareaAutosize
              label="Descripcion"
              variant="outlined"
              className="textareaContenidoDescription"
              aria-label="empty textarea"
              value={edit_description}
              placeholder="Descripción del producto"
              onChange={handle_description}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>

        <div className="containerFactureResponse-4">
          <div className="fact-11 color-1">
            <ValidationTextField
              label="Cantidad"
              variant="outlined"
              placeholder="Cantidad"
              sx={{ m: 2, width: "100%" }}
              onChange={handleChangeeCantidad}
              value={amount}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="fact-12 color-2">
            <ValidationTextField
              label="Precio"
              variant="outlined"
              placeholder="Precio del producto"
              sx={{ m: 2, width: "100%" }}
              onChange={handleChangeePrecionP}
              value={precioP || psinIva}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="fact-13 color-3">
            <ValidationTextField
              label="Unidad"
              variant="outlined"
              placeholder="Unidad"
              sx={{ m: 2, width: "100%" }}
              value={unidadP || obj_concept.unidad}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="fact-14 color-4">
            <ValidationTextField
              label="Impuesto calculado"
              variant="outlined"
              placeholder="Impuesto calculado"
              sx={{ m: 2, width: "100%" }}
              value={
                (obj_concept.impuestos?.traslados[0]?.impuesto === "001"
                  ? "ISR" +
                  "(" +
                  (obj_concept.impuestos?.traslados[0]?.tasa_o_cuota === null
                    ? "0.0000"
                    : obj_concept.impuestos?.traslados[0]?.tasa_o_cuota) +
                  ")"
                  : obj_concept.impuestos?.traslados[1]?.impuesto === "001"
                    ? "ISR" +
                    "(" +
                    (obj_concept.impuestos?.traslados[1]?.tasa_o_cuota === null
                      ? "0.0000"
                      : obj_concept.impuestos?.traslados[1]?.tasa_o_cuota) +
                    ")"
                    : obj_concept.impuestos?.traslados[2]?.impuesto === "001"
                      ? "ISR" +
                      "(" +
                      (obj_concept.impuestos?.traslados[2]?.tasa_o_cuota === null
                        ? "0.0000"
                        : obj_concept.impuestos?.traslados[2]?.tasa_o_cuota) +
                      ")"
                      : "") +
                (obj_concept.implocales?.traslados != null
                  ? "ISH(" + obj_concept.implocales?.traslados[0].importe + ")"
                  : "") +
                (obj_concept.impuestos?.traslados[0]?.impuesto === "002"
                  ? "IVA" +
                  "(" +
                  (obj_concept.impuestos?.traslados[0]?.tasa_o_cuota === null
                    ? "0.0000"
                    : obj_concept.impuestos?.traslados[0]?.tasa_o_cuota) +
                  ")"
                  : obj_concept.impuestos?.traslados[1]?.impuesto === "002"
                    ? "IVA" +
                    "(" +
                    (obj_concept.impuestos?.traslados[1]?.tasa_o_cuota === null
                      ? "0.0000"
                      : obj_concept.impuestos?.traslados[1]?.tasa_o_cuota) +
                    ")"
                    : obj_concept.impuestos?.traslados[2]?.impuesto === "002"
                      ? "IVA" +
                      "(" +
                      (obj_concept.impuestos?.traslados[2]?.tasa_o_cuota === null
                        ? "0.0000"
                        : obj_concept.impuestos?.traslados[2]?.tasa_o_cuota) +
                      ")"
                      : "") +
                (obj_concept.impuestos?.traslados[0]?.impuesto === "003"
                  ? "IEPS" +
                  "(" +
                  (obj_concept.impuestos?.traslados[0]?.tasa_o_cuota === null
                    ? "0.0000"
                    : obj_concept.impuestos?.traslados[0]?.tasa_o_cuota) +
                  ")"
                  : obj_concept.impuestos?.traslados[1]?.impuesto === "003"
                    ? "IEPS" +
                    "(" +
                    (obj_concept.impuestos?.traslados[1]?.tasa_o_cuota === null
                      ? "0.0000"
                      : obj_concept.impuestos?.traslados[1]?.tasa_o_cuota) +
                    ")"
                    : obj_concept.impuestos?.traslados[2]?.impuesto === "003"
                      ? "IEPS" +
                      "(" +
                      (obj_concept.impuestos?.traslados[2]?.tasa_o_cuota === null
                        ? "0.0000"
                        : obj_concept.impuestos?.traslados[2]?.tasa_o_cuota) +
                      ")"
                      : "")
              }
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="fact-15 color-5">
            <Button
              onClick={handle_calculate_tax}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
            >
              Agregar concepto
            </Button>
          </div>
        </div>

        <CONCEPT_INVOICE
          invoice={invoice}
          handleRemoveItem={handleRemoveItem}
          vSumTotalIm={vSumTotalIm}
          vSumTotalImlocal={vSumTotalImlocal}
          vSumTotalSub={vSumTotalSub}
          vSumTotal={vSumTotal}
        ></CONCEPT_INVOICE>

        <br></br>

        <div className="containerFactureResponse-5">
          <div className="fact-16 color-1">
            <TextareaAutosize
              label="Nota"
              variant="outlined"
              className="textareaContenidoDescription"
              aria-label="empty textarea"
              value={note}
              placeholder="Nota"
              onChange={handle_note}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="fact-17 color-2">
            <Button
              onClick={() => handleSubmit()}
              sx={{ m: 2, width: "40ch" }}
              variant="contained"
            >
              Generar Factura
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GENERATE_INVOICE;
