//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "../SubTableContainer";

//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";

//IMAGENES
import Spinner from "../../imgComponents/S.gif";
import reconsultaTable from "../../imgComponents/reconsultaTable.png";

import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { dashboard1_clasificacion_ticket } from "../../services/reports/dashboard";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ViewDetail_Detail({ datei,datef,route,id_detail,pointSale,address}) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [ReconsultaDta, setReconsultaDta] = useState(false);

  const dialog = useDialog();

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setReconsulta(true);
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await dashboard1_clasificacion_ticket(sessionStorage.getItem("clasificacion").replaceAll(' ', '_')?.replaceAll("/","-"), id_detail,sessionStorage.getItem("idTicket"), datei, datef, route);
            const data = d === null ? [] : d.data;
            setData(data);
            const cargos = data.filter((item) => item.efect === "CARGO");
            const abonos = data.filter((item) => item.efect === "ABONO");
    
            const sumCargos = cargos
              .map((cargoSum) => parseFloat(cargoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
    
            const sumAbonos = abonos
              .map((abonoSum) => parseFloat(abonoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            console.log("==>cargos<==");
            console.log(sumCargos);
            console.log("==>abonos<==");
            console.log(sumAbonos);
            if (sumCargos - sumAbonos === 0) {
              setEquality("Los datos cuadran");
              console.log("Los datos cuadran");
              setSuccess_msg("");
              setError_msg("none");
            } else {
              setEquality("Los datos no cudran");
              console.log("Los datos no cudran");
              setError_msg("");
              setSuccess_msg("none");
            }
            setReconsulta(false);
          }catch(err){
            setData([]);
            setReconsulta(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }

    /*

    axios
      .get(
        `https://zaqp4ihdn9.execute-api.us-east-1.amazonaws.com/prod/dashboard1/${sessionStorage.getItem("clasificacion").replaceAll(' ', '_')?.replaceAll("/","-")}/${id_detail}/${sessionStorage.getItem("idTicket")}`,
        {
          params: {
            datei: datei,
            datef: datef,
            route: route,
          },

          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setData(data);
        const cargos = data.filter((item) => item.efect === "CARGO");
        const abonos = data.filter((item) => item.efect === "ABONO");

        const sumCargos = cargos
          .map((cargoSum) => parseFloat(cargoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);

        const sumAbonos = abonos
          .map((abonoSum) => parseFloat(abonoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);
        console.log("==>cargos<==");
        console.log(sumCargos);
        console.log("==>abonos<==");
        console.log(sumAbonos);
        if (sumCargos - sumAbonos === 0) {
          setEquality("Los datos cuadran");
          console.log("Los datos cuadran");
          setSuccess_msg("");
          setError_msg("none");
        } else {
          setEquality("Los datos no cudran");
          console.log("Los datos no cudran");
          setError_msg("");
          setSuccess_msg("none");
        }

        setReconsulta(false);
      })
      .catch((err) => {
        setData([]);
        setReconsulta(false);
      });
      */
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };



  
  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [      
      
      {
        Header: "Linea",
        accessor: "line",
      },
      
      {
        Header: "Producto",
        accessor: "product",
      },
      
      {
        Header: "Cantidad",
        accessor: "quantity",
      },
      {
        Header: "Descuento",
        accessor: "discount",
      },
      {
        Header: "Impuesto",
        accessor: "tax",
      },
      {
        Header: "Sub Total",
        accessor: "subtotal",
      },
     
      {
        id:"total",
        Header: () => {
          return (
            <div>
              <br></br>
              Total
              <img
                title="Actualizar"
                src={reconsultaTable}
                onClick={handleSubmit}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
        accessor: "total",
      },

     
 
      
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  
                  variant="h6"
                  component="h2"
                >
                  <img src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

        <div className="TableConsultarTodo2 TableDetailTIketDetail">
         
          <StickyTable>
            <SubTableContainer
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
             
            />
          </StickyTable>
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default ViewDetail_Detail;
