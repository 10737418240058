import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { refresh_token  } from "../../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";
import { get_pointsofsale_list, post_pos_sector } from "../../../../services/businesspartners/businesspartners";


function ADD_POINT_SALE_SECTOR(props) {

const dialog = useDialog();
const [pointsales, setpointsales] = useState();
const [ordensector, setordensector] = useState("");
const [pointsale, setpointsale] = useState();
const [bandera, setbandera]=useState(true);

useEffect(()=>{
  handlepointsale();
},[]);

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}
  
const handlepointsale = async ()=>{
  try {  
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_pointsofsale_list();
          setpointsales(d === null ? [] : d.data.parametersList.filter(item=>item.idRoute==props.idRuta));     
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log("error", error);
  } 
}


//agregar un punto de venta al sector
const addnewpointsale = async () =>{

if(ordensector==="" || pointsale===undefined){
  props.alertas("Datos faltantes", false);
}else{

setbandera(false);
props.setmodalT(true);

var data = {
  "idPointSale":pointsale,
  "orderSector":ordensector
}
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const dt = await post_pos_sector(props.id, data);
          console.log(dt);
          props.alertas("Punto de venta agregado al sector", true);
          props.setmodalT(false);
          dialog.close();
          props.handleSubmit();   
        }catch(err){
          catchErrorModul("Puntos de venta por sector", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          if(err?.response?.status===500){
            props.alertas("Error, No se pudo agregar el punto de venta al sector vuelva a intentarlo", false);
            setbandera(true);
            props.setmodalT(false);
          }else{
            props.alertas(err?.response?.data?.errorMessage, false);
            setbandera(true);
            props.setmodalT(false);
          }
        }     
      }, 1000);
    }else{}
   } catch (err) {  
    console.log(err);
  }
}} 


const handleOnSearch = () => {
  setpointsale("");
};
const handleOnHover = () => {};
const handleOnSelect5 = (item) => {
    setpointsale(item.idPointSale);
};
const handleOnFocus5 = () => {
  console.log("Focused");
};
const handleOnClear = () => {
  console.log("Cleared");
};

  return (
    <div style={{"textAlign":"center"}}>
      <div className="addpointsalecss">
          <Box>
          <div className="contentposs">
           <div className="ordensec">
            <TextField
              label="Orden Sector"
              type={"number"}
              disabled={!bandera}
              required
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setordensector(event.target.value)}
              InputLabelProps={{ shrink: true }}

            ></TextField>
          </div>
            <div className="searchautop">
        
          <ReactSearchAutocomplete
                      items={pointsales}
                      fuseOptions={{ keys: ["code","name"]}}
                      resultStringKeyName="codeAndName"
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect5}
                      onFocus={handleOnFocus5}
                      onClear={handleOnClear}
                      styling={{
                        height: "2px",
                        border: "1px solid gray",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        zIndex: 10,
                      }}
                      showIcon={true}
                      placeholder="Buscar punto de venta"      
                    />
          </div>
          </div>
          </Box>
          <br></br>
          <br></br>
          <Box>
            {bandera===true?
          <Button
          style={{"backgroundColor":"green"}}
          variant="contained"
          sx={{ m: 1.5, width: "30ch" }}
          onClick={addnewpointsale}
        >
          Agregar Punto de Venta
        </Button>:  
        <Button
          style={{"backgroundColor":"green","height":"35px"}}
          variant="contained"
          disabled
          sx={{ m: 1.5, width: "30ch" }}
          onClick={addnewpointsale}
        ><Spinner/></Button>  
        }
        <Button
          style={{"backgroundColor":"red"}}
          variant="contained"
          disabled={!bandera}
          sx={{ m: 1.5, width: "20ch" }}
          onClick={() => {
              dialog.close();
            } 
          }
        >
              cancelar
            </Button>
          </Box>
      </div>
    </div>
  );
}

export default ADD_POINT_SALE_SECTOR;
