import React, { useRef, useState } from 'react';
import load from "../../../imgComponents/iconos/loading.gif";
import "./update.css";
import { CONFIRM_UPLOAD } from './confirm';
import { CustomDialog, useDialog } from 'react-st-modal';
import loadimg from "../../../imgComponents/iconos/loading.gif";
import { put_points_of_sale } from '../../../services/businesspartners/businesspartners';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export const UPDATEADDRESS = (props) => {
    
    const [highlight, setHighlight] = useState(false);
    const fileInputRef = useRef(null);
    const [file, setfile]=useState(null);
    const [band, setband]=useState(false);
    const [err, seterr]=useState(false);
    const [msj, setmsj]=useState("");
    const [progr, setProgr] = useState(0);
    const [datacsv, setdatacsv]=useState([]);
    const [process, setprocess]=useState(false);
    const [finish, setfinish]=useState(false);
    const [details, setdetails]=useState(false);
    const dialog = useDialog();

    const [errfile, seterrfile] = useState(false);
    const [errmsjf, seterrmsjf]=useState("");

    const[load, setload]=useState(false);
    var progress = 0;

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    
    const handleDrop = (e) => {
      e.preventDefault();
      setHighlight(false);
      const files = e.dataTransfer.files;
      setfile(files[0]);
      handleFiles(files);
    };

    const handleFileInputClick = () => {
      fileInputRef.current.click();
      setprocess(false);
    };  
    const handleFileInputChange = (e) => {
      const files = e.target.files;
      setprocess(false);
      setfile(files[0]);
      handleFiles(files);
    };
  
    const handleFiles = (files) => {
        seterrmsjf("");
        seterrfile(false);
      for (const file of files) {
        if (file.type === 'text/csv') {
          const reader = new FileReader();
          reader.onload = (e) => {
            const contents = e.target.result;
            analisis(contents);
          };
          reader.readAsText(file);
        } else {
          alert('Por favor, selecciona un archivo CSV.');
          setfile(null);
        }
      }
    };

    const analisis = (content)=>{
        setband(true);
        const lines = content.trim().split('\n');
        const headers = lines[0].split(',');
        const result = [];
        for (let i = 1; i < lines.length; i++) {
            const obj = {};
            const currentLine = lines[i].split(',');
    
            headers.forEach((header, index) => {
                obj[header.trim().replace(/"/g, '')] = currentLine[index].trim().replace(/"/g, '');
            });
            result.push(obj);
        }
        headers_check(result);
    }

    const headers_check=(result)=>{


        const requiredFields = [
            "Código", "Latitud", "Longitud", "Calle", "Número Exterior", 
            "Número Interior", "Colonia", "Código postal", "Ciudad", 
            "Municipio", "Estado", "País", "Referencia"
        ];
        const firstObject = result[0];
        const missingFields = requiredFields.filter(field => !firstObject.hasOwnProperty(field));
        if (missingFields.length > 0) {
            seterrmsjf("Faltan los siguientes campos: "+ missingFields.join(', '));
            seterrfile(true);
            setband(false);
        } else {
            update_null_data(result);
        }
    }

    const update_null_data = (result) =>{
        result.forEach(obj => {
            Object.keys(obj).forEach(key => {
                if (obj[key] === '') {
                    obj[key] = null;
                }
            });
        });
        const filteredResult = result.filter(obj => {
            return Object.values(obj).some(value => value !== null);
        });
        join_data(filteredResult);
    }

    const join_data = (result) =>{

        try{
            result.forEach(item => {
                const matchingData = props?.dataorigen?.find(data => data?.code === item?.Código);
                if (matchingData) {
                    item.id = matchingData.id;
                    item.name = matchingData.name;
                    item.status = "";
                }
            });
            
            setband(false);
            setdatacsv(result);
            setprocess(true);
        }catch(err){
            setband(false);
            setdatacsv([]);
            setprocess(false);
        }
        
    }

    const confirm = async () => {
        await CustomDialog(
            <CONFIRM_UPLOAD put_address={put_address}></CONFIRM_UPLOAD>,
            {
                className: "modalmini",
                title: "Confirmar Actualización",
                showCloseIcon: true,
                isCanClose: false,
            }
        )
    }   

    const put_address = async() =>{

        setload(true);

        for (const item of datacsv) {
            try{
                await put_point_sale(item);
            }catch(err){
                console.error(`Error processing item ${item?.Código}:`, err);
                item.status = "Error";
            }
        }
       
        const hasError = datacsv.some(item => item.status === "Error");
        if(hasError){
            setmsj("Algunos Puntos de venta no se procesaron con éxito");
            setfinish(true);
            progress = 0;
            seterr(true);
            props?.handleSubmit();
        }else{
            setmsj("Proceso de actualización terminado");
            setfinish(true);
            progress = 0;
            props?.handleSubmit();
        }
       
    }

    const put_point_sale = (item) =>{
    
        var data = {
            "latitude": item?.Latitud,
            "longitude": item?.Longitud,
            "calle": item?.Calle,
            "numExt": item["Número Exterior"],
            "numInt": item["Número Interior"],
            "colonia": item?.Colonia,
            "codigoPostal": item["Código postal"],
            "municipio":item?.Municipio, 
            "pais": item?.País,
            "referencia": item?.Referencia
        }

        return new Promise(async(resolve, reject) => {
            try{
                
                item.status = "Actualizando";
                progress = progress + 1 / datacsv.length * 100;
                setProgr(progress);
                const d = await put_points_of_sale(item?.id, data);
                console.log(d);
                item.status = "Terminado";
                setmsj("Actualizando Punto de venta: " + item?.Código + " - " + item?.name);
                resolve();     
            }catch(err){
                console.log(err);
                reject(err); 
            }
        });
    }

  return (
    <div>
        <div className='container-upload-csv'>
        {
            process === true ? <></>:
                band === false?
                <>
                    <div className={`drop-area ${highlight ? 'highlight' : ''}`}
                    onDrop={handleDrop}
                    onDragOver={preventDefaults}
                    onDragEnter={() => setHighlight(true)}
                    onDragLeave={() => setHighlight(false)}
                    onClick={handleFileInputClick}>
                    <p>Arrastra y suelta un archivo CSV aquí o haz clic para seleccionarlo.</p>
                    <p>El proceso es exclusivamente para actualizar la dirección de múltiples puntos de venta simultáneamente.</p>
                    <input
                        type="file"
                        accept=".csv"
                        ref={fileInputRef}
                        onChange={handleFileInputChange}
                        style={{ display: 'none' }}
                    />
                    </div>
                    {
                        errfile === true ? <p className='err-msj'>{errmsjf}</p>:<></>
                    }
                </>
                :
                    <div className='drop-load'>
                        <img src={load}></img>
                        <p>Analizando Archivo...</p>
                    </div>
            }
            
            {
                file===null?<></>:band===true?<></>:<p className='file-select'>Archivo Seleccionado: <span>{file?.name}</span></p>
            } 
            {
            
                load===true?
                    <div className='cargando-facturas'>
                      
                            <div className='loading-zip'>
                            {
                                finish=== true ?<></>: 
                                <img src={loadimg} alt=''></img>
                            }
                                <p className='msj-zip'>{msj}</p>                        
                            </div>
                        <div className='load-progress'>
                            {
                                err?<div className='progress-bar progres-error' style={{ width: `${progr}%` }}></div>:
                                    <div className='progress-bar' style={{ width: `${progr}%` }}></div>
                            }
                    
                        </div>
                        <p onClick={()=>setdetails(!details)}>{details===true?"Ocultar Detalles":"Ver Detalles..."}</p>
                        <div className='progress-points' style={details?{"display":""}:{"display":"none"}}>
                                {
                                    datacsv?.map(item=>(
                                        <>
                                            <div className='content-progrs'>
                                                <p>{item?.Código} - {item?.name}</p>
                                                <div 
                                                    className={item?.status === "" ? "progress-bar-ind" :
                                                            item?.status === "Actualizando" ? "progress-bar-pross":
                                                                item?.status === "Error" ? "progress-bar-error":
                                                                    item?.status === "Terminado" ? "progress-bar-finish":
                                                                        "progress-bar-ind" 
                                                    }
                                                >{item?.status===""?"Pendiente":item?.status}</div>
                                            </div>
                                         </>
                                    ))                                   
                                }
                        </div>
                        <br></br><br></br>
                        <Button
                            disabled={!finish}
                            variant="contained"
                            style={{ "backgroundColor": "#007bff" }}
                            sx={{ m: 1.5, width: "30ch" }}
                            onClick={() => {
                                dialog.close();
                            }}
                        > FINALIZAR
                        </Button> 
                    </div>:
                process===true?
                    <>
                    <div className='point-info'>
                        <h2>Puntos de venta a actualizar</h2>
                        {
                            datacsv?.map(item=>(
                                <p>{item?.Código} - {item?.name}</p>
                            ))
                        }
                    </div>
                    <div className=''>
                    <Box>
                        <Button
                            variant="contained"
                            style={{ "backgroundColor": "green" }}
                            sx={{ m: 1.5, width: "30ch" }}
                            onClick={()=>confirm()}
                        > ACTUALIZAR DIRECCIÓN
                        </Button> 
                        <Button
                            style={{ "backgroundColor": "#007bff" }}
                            variant="contained"
                            sx={{ m: 1.5, width: "30ch" }}
                            onClick={() => {
                                setprocess(false);
                            }}>CAMBIAR ARCHIVO
                        </Button>
                        <Button
                            style={{ "backgroundColor": "red" }}
                            variant="contained"
                            sx={{ m: 1.5, width: "20ch" }}
                            onClick={() => {
                                dialog.close();
                                }}>
                            cancelar
                        </Button>
                    </Box>
                    </div>
                </>
                :
                <></>
            }
        </div>
        
    </div>
  )
}
