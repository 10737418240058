//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "./SubTableContainer";

//ESTILOS
import "../../../components_cfdi/estilo.css";
import "../../../components_cfdi/modal.css";
import "../../../components_cfdi/table.css";

//IMAGENES
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { delete_taxes_sub_config, get_taxes_sub_config } from "../../../services/invoices/invoice";



const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};



function SubTable () {

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const handleSubmit = async(event) => {
    event?.preventDefault();
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_taxes_sub_config(sessionStorage.getItem("id_tax_subtable"));
            const data = d===null?[]:d.data;
            setData(data);
          }catch(err){
            setData([]);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }

    /*
    axios
      .get(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/taxes/${sessionStorage.getItem("id_tax_subtable")}/config`,{headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
      })
      .catch((err) => {
        setData([]);
      });
      */
  };
  

  useEffect(() => {
    handleSubmit();
  }, []);



  const columns = useMemo(
    () => [

      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function Redireccion() { 
          }
          const DeleteTax = async () => {
            setOpen(true);
            try{
              var rf_token = await refresh_token();
              if(rf_token===true){
                setTimeout(async() => {
                  try{
                    const d = await delete_taxes_sub_config(sessionStorage.getItem("id_tax_subtable"), row.original.id);
                    alert("Configuración eliminada correctamente")
                    setOpen(false);
                    window.location="/consult_taxes"
                  }catch(err){
                    console.log("error", err);
                    setOpen(false);
                  }
                }, 1000);
              }else{}
            }catch(err){
              console.log(err);
            }
            /*
            refresh_token ();
            axios
              .delete(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/taxes/${sessionStorage.getItem("id_tax_subtable")}/config/${row.original.id}?`, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                alert("Configuración eliminada correctamente")
                setOpen(false);
                window.location="/consult_taxes"
              })
              .catch((err) => {
                console.log("error", err);
                setOpen(false);
              });*/
          }

          return (
            <div>
              
              <img
                title="Eliminar configuración"
                src={DELETE}
                
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta configuracioń?",
                    "Eliminar Configuración",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteTax();
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Default",
        accessor: (d) => {
          return d.default==true?"Si":"No"
        },
      },
      {
        Header: "Tarifa",
        accessor: "rate",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Tipo factor",
        accessor: "c_tipo_factor",
      },

    ],
    []
  );



  return (

    <div>
      <div>
           <Modal
             open={open}
             onClose={open}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
             >
               <Box sx={style2} >
                 <div className="centermodaldescarga">
                   <Typography id="modal-modal-title"    variant="h6" component="h2">
                     <img src={Spinner}></img>
                   </Typography>
                   <Typography id="modal-modal-description"  sx={{ mt: 2 }}>  
                     {"Eliminando configuración..."}
                   </Typography>
                   </div>
                 </Box>
            </Modal>
       </div>

        <div className="TableConsultarTodo2">
          
                <StickyTable>
          

                  <SubTableContainer
                    columns={ columns}
                    data={data}
                    style={{ overflowx: 10 }}
                  />

                  
                </StickyTable>
        </div>
    </div>

  );
};

export default SubTable;
