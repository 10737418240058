import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import Set_configure from "../Set_Configure_Tax";
import add from "../../../../imgComponents/add.png";
import delet from "../../../../imgComponents/borrar.png";
import { delete_taxes, delete_taxes_sub_config } from "../../../../services/invoices/invoice";

const DELETE_CONF = (props) => {

    const DeleteSector =async () =>{

            props?.setmodalT(true);
            try{
              var rf_token = await refresh_token();
              if(rf_token===true){
                setTimeout(async() => {
                  try{
                    const d = await delete_taxes_sub_config(props?.id, props?.row.original.id);
                    console.log(d);
                    props?.alertas("Configuración eliminada correctamente", true);
                    props?.setmodalT(false);
                    props.handleSubmit();
                }catch(err){
                    console.log("error", err);
                    props?.setOpen(false);
                    props?.alertas("Error.,"+ err.response.data.errorMessage, false); 
                  }
                }, 1000);
              }else{}
            }catch(err){
              console.log(err);
            }
            
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar la configuración del impuesto?','Eliminar Configuración Impuesto','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={delet}
                        title="Eliminar Configuración"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Configuración
            </MenuItem>
            
        </div >
    );
};

export default DELETE_CONF;
