import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../../imgComponents/save.png";
import Delete from "../../../../imgComponents/borrar.png";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import { delete_business_partner } from "../../../../services/businesspartners/businesspartners";
import Set_bussines_p from "../../tools/set_bussines_p";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const handle_delete = async (id) => {
        props?.setmodalT(true);
      try {
        await delete_business_partner(id);
        props?.setmodalT(false);
        props?.handleReconsulta(true,"Proveedor eliminado correctamente");

      } catch (err) {
        props?.setmodalT(false);
      }
    };
    console.log(props?.row)
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el proveedor?','Eliminar Proveedores','Si','No');
                    if(result){
                        handle_delete(props?.row?.original?.id)
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Proveedor"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Proveedor
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                      <Set_bussines_p
                        method={"UPDATE"}
                        modul={"PROVEEDOR"}
                        actualiza={false} 
                        supplierdata={true}
                        code={props?.row.original.code}
                        business_partner={props?.row.original.business_partner}
                        tax_regime={props?.row.original.tax_regime}
                        name={props?.row.original.name}
                        rfc={props?.row.original.rfc}
                        first_last_name={props?.row.original.first_last_name}
                        second_last_name={props?.row.original.second_last_name}
                        balance={props?.row.original.balance}
                        credit_days={props?.row.original.credit_days}
                        business_partner_type={props?.row.original.business_partner_type}
                        capital_regime={props?.row.original.capital_regime}
                        partner_type={props?.row.original.partner_type}
                        person_type={props?.row.original.person_type}
                        cfdi_use={props?.row.original.cfdi_use}
                        phone={props?.row.original.phone}
                        email={props?.row.original.email}
                        metodo_pago={props?.row.original.metodo_pago}
                        paymethod={props?.row.original.paymethod}
                        allow_invoicing={props?.row.original.allow_invoicing}
                        street={props?.row.original.street}
                        outdoor_number={props?.row.original.outdoor_number}
                        inside_number={props?.row.original.neighborhood}
                        neighborhood={props?.row.original.neighborhood}
                        zip_code={props?.row.original.zip_code}
                        city={props?.row.original.city}
                        state={props?.row.original.state}
                        country={props?.row.original.country}
                        reference={props?.row.original.reference}
                        address_type={props?.row.original.address_type}
                        subsidiary={props?.row.original.subsidiary}
                        tax_residence={props?.row.original.tax_residence===null?"":props?.row.original.tax_residence}
                        num_reg_id_trib={props?.row.original.num_reg_id_trib}
                        town={props?.row.original.town}
                        partner_type_data={props?.row.original.partner_type}
                        uso_cfdi_data={props?.row.original.cfdi_use}
                        state_data={props?.row.original.state}
                        address_type_data={props?.row.original.address_type}
                        tax_regime_data={props?.row.original.tax_regime}
                        subsidiary_data={props?.row.original.subsidiary}
                        metodo_pago_data={props?.row.original.metodo_pago}
                        account_number={props?.row.original.account_number}
                        account_data={props?.row.original.name_accounting_account+"-"+ props?.row.original.code_accounting_account}
                        id_accounting_account_fk={props?.row.original.id_accounting_account_fk}
                        id={props?.row.original.id}
                      />,
                    {
                        className: "custom-modal-2",
                        title: "EDITAR PROVEEDOR",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Proveedor"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Proveedor
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
