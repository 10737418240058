import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import add from "../../../imgComponents/add.png";

import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_promotion } from "../../../services/businesspartners/businesspartners";
import EditPromotion from "./EditPromotion";
import AddproductPromotion from "./AddproductPromotion";
import { ADDPRODUCTPROMOTIONV2 } from "./addproductpromotionv2";


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try {
            var rf_token = await refresh_token();
            if(rf_token===true){
                setTimeout(async() => {
                try{
                   const dt = await delete_promotion(props?.row.original.id);
                   console.log(dt);
                   props.alertas("Promoción Eliminada", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handlesubmit();
                }catch(err){
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.alertas("Error, no se pudo eliminar la promocion", false);
                }
                }, 1000);
            }else{}    
        } catch (err) {
        console.log(err);
        }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar la promoción?','Eliminar Promoción','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Promoción"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Promoción
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <EditPromotion 
                            handlesubmit={props?.handlesubmit} 
                            row={props?.row} 
                            setmodalT={props?.setmodalT} 
                            alertas={props?.alertas}
                        />,
                    {
                        className: "custom-modal-3",
                        title: "EDITAR PROMOCIÓN",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Promoción"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Promoción
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <ADDPRODUCTPROMOTIONV2
                            handlesubmit={props?.handlesubmit} 
                            setmodalT={props?.setmodalT} 
                            alertas={props.alertas}
                            row={props?.row}
                        ></ADDPRODUCTPROMOTIONV2>,
                    {
                        className: "custom-modal-3",
                        title: "AGREGAR PRODUCTOS A PROMOCIÓN",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={add}
                        title="Agregar Productos a Promoción"
                        className=""
                    ></img>
                </ListItemIcon>
                Agregar Productos a Promoción
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
