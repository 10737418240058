import React, { useState, useEffect } from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import { useDialog } from "react-st-modal";

import MODAL_TABLE from '../modal/modal_Table';
const CLIENT_ID = '301722987834-pec5a9vlojn6redpcneblrgd2ckqdj05.apps.googleusercontent.com';
const API_KEY = 'AIzaSyBTswKLvFOwWbnlFqwQMqpUgUEL3LQu7ls';
const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';
export const EXPORT_GS_V2 = (props) => {

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [modalT, setmodalT] = useState(false);

  const dialog = useDialog();

  console.log(props.row)
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
      window.gapi.load('client:auth2', initClient);
    };
    document.body.appendChild(script);
  }, []);

  const initClient = () => {
    window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
      scope: SCOPES,
    }).then(() => {
      window.gapi.auth2.getAuthInstance().isSignedIn.listen(setIsSignedIn);
      setIsSignedIn(window.gapi.auth2.getAuthInstance().isSignedIn.get());
    });
  };

  const handleAuthClick = () => {
    window.gapi.auth2.getAuthInstance().signIn({ prompt: 'select_account' }).then(() => {
      handleCreateSheet();
    });
  };

  const handleCreateSheet = () => {
    setmodalT(true)
    window.gapi.client.sheets.spreadsheets.create({
      properties: {
        title: props.nametable
      }
    }).then((response) => {
      console.log('Hoja de Cálculo Creada', response.result);
      const newSheetName = "Sheet"; 
      renameFirstSheet(response.result.spreadsheetId, newSheetName,response.result.spreadsheetUrl);
    }).catch((error) => {
      setmodalT(true)
      alert("No se logro crear la hoja de calculo comuniquese con soporte")
      setmodalT(false)
      console.error('Error al crear la hoja de cálculo', error);
    });
  };

  const renameFirstSheet = (spreadsheetId, newSheetName,urlSheet) => {
    window.gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: spreadsheetId,
      requests: [{
        updateSheetProperties: {
          properties: {
            sheetId: 0,
            title: newSheetName
          },
          fields: 'title'
        }
      }]
    }).then((response) => {
      console.log('Nombre de la hoja cambiado', response.result);
      updateSheet(spreadsheetId, urlSheet);
    }).catch((error) => {
    });
  };



  const transformDataToJsonFormat = (jsonData) => {
    if (jsonData.length === 0) {
      return [];
    }

    const headers = Object.keys(jsonData[0]);

    const rows = jsonData.map(item => {
      return headers.map(header => {
        return item.hasOwnProperty(header) ? item[header] : "";
      });
    });

    return [headers, ...rows];
  };

  const updateSheet = (id, url) => {
    console.log(transformDataToJsonFormat(props.row))
    const values = transformDataToJsonFormat(props.row);
    const range = "Sheet";
    const spreadsheetId = id;

    window.gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId: spreadsheetId,
      range: range,
      valueInputOption: "USER_ENTERED",
      resource: {
        values: values
      }
    }).then((response) => {
      console.log('Datos actualizados', response.result);
      setmodalT(false)
      window.open(url, '_blank');
      window.gapi.auth2.getAuthInstance().signOut().then(() => {
        setIsSignedIn(false);
        console.log('Sesión cerrada con éxito');
      });
      dialog.close(true);
    }).catch((error) => {
      alert("No se logro actualizar la hoja de calculo comuniquese con soporte")
      setmodalT(false)
      console.error('Error al actualizar la hoja de cálculo', error);

    });
  };

  useEffect(() => {
    setTimeout(() => {
      handleAuthClick();
    }, 600);
  }, [])

  return (

    <div>
      <MODAL_TABLE
        open={true}
        message={"Exportando a google...."}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
    </div>
  );
};

