import React, { useEffect, useMemo, useState } from 'react'
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';

export const HISTORY_S3 = () => {
  
    
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData]=useState([]);
  
    
  useEffect(() => {
    history_get();
  }, [])
  

  const history_get = async() =>{
    
    const url = "https://d7vstz3f03.execute-api.us-east-1.amazonaws.com/prod/downloads3"
        try {
            setIsLoading(true);
            const response = await axios.get(url, {
              headers: {
                Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                "Content-Type": "application/json",
              },
            });
            console.log(response);
            const sortedData = response?.data?.sort((a, b) => new Date(b.StartTime) - new Date(a.StartTime));
            setData(sortedData);
            //setData(response?.data);        
            setIsLoading(false);
           
        } catch (error) {
            setIsLoading(false);           
            console.log(error);
        }
    
  }
  
  
const columns = useMemo(
    () => [
      {
        id: "Execution_ID",
        header:"Execution ID",
        accessorKey: "Execution_ID",
       },
       {
        id: "StartTime",
        header: "Hora de Inicio",
        accessorKey: "StartTime"
       },
       {
        id: "Status",
        header: "Estado",
        accessorKey: "Status"
       },
       {
        id: "EstimatedFilesToTransfer",
        header: "Archivos Estimados a Transferir",
        accessorKey: "EstimatedFilesToTransfer",
        size: 300
       },
       {
        id: "EstimatedBytesToTransfer",
        header: "Bytes Estimados a Transferir",
        accessorKey: "EstimatedBytesToTransfer",
        size: 300
       },
       {
        id: "FilesTransferred",
        accessorKey: "FilesTransferred",
        header: "Archivos Transferidos",
        size: 200
       },
       {
        id: "BytesWritten",
        accessorKey: "BytesWritten",
        header: "Bytes Escritos",
        size: 200
       },
       {
        id: "BytesTransferred",
        accessorKey: "BytesTransferred",
        header: "Bytes Transferidos"
       }
      ],
      []
  );

  
  const update_identifiers =
    [
      { Execution_ID: 'string' }
    ];



  return (
    <div className='new_table_v2' style={{width: "90%", margin: "0 auto"}}>

    <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        // onStateChange={handleStateChange}
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderToolbarInternalActions={({ table, index }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table} 
            file_name={"Historico S3"}
            update_identifiers={update_identifiers}
            
          />
        )
        }
      />

    </div>
  )
}
