import { useEffect, useMemo, useState } from 'react';
import { get_cabinets_movement } from '../../../services/assets/assets';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

export const HistoryFrezz_v2 = (props) => {

    const [data, setdata]=useState([]);
    const [open, setopen]=useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
  

useEffect(() => {
    handlemovement();
}, [])

const handlemovement = async () => {
  setopen(true);
  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{

        var d = await get_cabinets_movement(props.id);
        setdata(d === null ? [] : d.data.movements);
        setopen(false);


      }catch(error){
        console.log("error", error);
        setopen(false);
      }
      }, 1000);
    }else{}
  }catch(error){
    console.log("error", error);
    setopen(false);
  }
}


const columns = useMemo(
    () => [
      {
        id: "movement_type",
        header: "Tipo de Movimiento",
        accessorKey : "movement_type"
      },
      {
        id: "date_movement",
        header: "Fecha Movimiento",
        accessorKey: "date_movement"
      },
      {
        header: "Almacén",
        accessorKey: "warehouse_name",
      },
      {
        id: "name_pos",
        header: "Punto de Venta",
        accessorKey: "name_pos"
      },
      {
        id: "name_employee",
        header: "Empleado",
        accessorKey: "name_employee"  
      },
      {
        id: "note",
        header: "Nota",
        accessorKey: "note"
      }
    ]
)
  return (
    <div className="">
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping={false}
        enablePinning={false}
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning={false}
        enableColumnFilterModes
        enableRowActions={false}
        enableRowSelection={false}
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning={false}
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setdata([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
      >  
      </MaterialReactTable>
    </div>
    
  )
}
