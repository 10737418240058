//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, useDialog, Confirm, Alert } from "react-st-modal";
import { StickyTable, Row, Cell } from "react-sticky-table";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import { SelectColumnFilter } from "../../components_cfdi/filters";
import SubTable from "./subTable";
import Set_driver from "./set_driver";
import Put_driver from "./put_driver";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "./tableclientes.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import CODEQR from "../../imgComponents/codigoqr.png";
import SUCCESSFULLY from "../../imgComponents/successfully.png";
import { delete_drivers, get_drivers } from "../../services/humanResources/humanResources";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import TableContainer from "../../main_components/Table/TableContainer";

var style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: "10px 5px 2px 1px",
    },

    success: {
      // Applied only to the success notification item
      color: "red",
    },
  },
};

const Consult_drivers = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando conductores...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [menssageAler, setmenssageAler] = useState("");


  
  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  }

  const handleSubmit = async(msjAler) => {
    setmodalT(true);
    setData([]);
    setIsLoding(true);
    setTimeout(async() => {
      setmodalGeneral(false);
      try{
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const d = await get_drivers();
              const data = d === null?[]:d.data;
              setmodalT(false);
              setData(data);
              setIsLoding(false);
              if (msjAler?.length > 1) {
                notificationSystemRef.current.addNotification({
                  message: (
                    <AlertResponse msj={msjAler} view={true}></AlertResponse>
                  ),
                  level: "success",
                  position: "br",
                  autoDismiss: 10,
                });
              }
            }catch(err){
              var errroM = err?.response?.data?.errorMessage;
              setData([]);
              notificationSystemRef.current.addNotification({
                message: (
                  <AlertResponse
                    msj={
                      errroM === undefined
                        ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                        : errroM
                    }
                    view={false}
                  ></AlertResponse>
                ),
                level: "error",
                position: "br",
                autoDismiss: 60,
              });
              setmodalT(false);
            }
          }, 1000);
        }else{}
      }catch(err){
        console.log(err);
      }
    }, 2000);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };
  const columns = useMemo(
    () => [
    
      {
        Header:()=>{
          return (
            <div>
              <p style={{color:"white"}} >Acciones</p>
              <img
                alt=""
                title="Crear conductor"
                src={CREATE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <Set_driver
                      handleSubmit={handleSubmit}
                      alertas={alertas}
                      setmodalT={setmodalT}

                    ></Set_driver>,
                    {
                      className: "modalSetBussnes",
                      title: "Crear conductor",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
              ></img>
            </div>
          );
        },
        id: "Acciones",

        Cell: ({ valor, row }) => {

          const DeleteBusnes = async () => {
            setmenssage("Eliminando conductor");
            setmodalGeneral(true);
            setIsLoding(true);
            setmensajeDescarga("Eliminando");
            try{
              var rf_token = await refresh_token();
              if(rf_token === true){
                setTimeout(async() => {
                  try{
                    const d = await delete_drivers(row.original.id);
                    console.log(d);
                    handleReconsulta(true, "Conductor eliminado correctamente");
                  }catch(err){
                    var errroM = err?.response?.data?.errorMessage;
                    notificationSystemRef.current.addNotification({
                      message: (
                        <AlertResponse
                          msj={
                            errroM === undefined
                              ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                              : errroM
                          }
                          view={false}
                        ></AlertResponse>
                      ),
                      level: "error",
                      position: "br",
                      autoDismiss: 60,
                    });
                  }
                }, 1000);
              }else{}
            }catch(err){
              console.log(err);
            }
          }

          return (
            <div>
              <img
                alt=""
                title="Actualizar conductor"
                src={SAVE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <Put_driver
                      License_num={row.original.license_num}
                      Num_reg_id_trib={row.original.num_reg_id_trib}
                      Id={row.original.id}
                      Rfc={row.original.rfc}
                      Type={row.original.type}
                      Transport_parts={row.original.transport_parts}
                      Fiscal_residence={row.original.fiscal_residence}
                      Ruta={row.original.id_route_fk}
                      Driver_name={row.original.driver_name}
                      alertas={alertas}
                      handleSubmit={handleSubmit}
                      setmodalT={setmodalT}
                    ></Put_driver>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar conductor",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
              ></img>

              <img
                src={DELETE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este conductor?",
                    "Eliminar conductor",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteBusnes();
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Ruta",
        accessor: "route",
      },
      
      {
        Header: "Conductor",
        accessor: "driver_name",
      },
      
      {
        Header: "RFC",
        accessor: "rfc",
      },

      {
        Header: "Número de licencia",
        accessor: "license_num",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Piezas de transporte",
        accessor: "transport_parts",
      },
      {
        Header: "Num_Reg_Id_Trib",
        accessor: "num_reg_id_trib",
      },

      {
        Header: "Residencia Fiscal",
        accessor: "fiscal_residence",
      },
     
    ],
    []
  );

  return (
    <div>
       <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR CONDUCTORES", path: null},
                ]
              }
          new_version={true}
          modul="CONSULTAR CONDUCTORES V2"
          path="/consult_drivers_v2"  
      ></BREADCRUMBS>
      <br/><br/><br/>


      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <div >
          <div className="TableCOnsultaClientes">
            <TableContainer
              exportar={true}
              columns={columns}
              data={data}
              Gmodal={modalT}
              nametable={"Tabla Conductores"}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div>
      </div>
    </div>
  );
};

export default Consult_drivers;
