//PAQUETERIAS
import React, { useState } from "react";
import { useDialog, Confirm } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
//COMPONENTES
import JOIN_INVOICE_CANCEL from "./join_invoice_cancel";
import JOIN_INVOICE_CANCEL_2 from "./join_invoice_cancel _v2";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { cancel_invoice } from "../../../services/invoices/invoice";
//ESTILOS
import "../../estilo.css";
import "../../modal.css";
import "../../table.css";
//IMAGENES




const CANCEL_INVOICE = ({ idCancel, handle_get_invoice, serie, folio }) => {



  const m_cancel = [
    { value: "", label: "Seleccione un motivo de cancelación" },
    { value: "01", label: "Comprobante emitido con errores con relación" },
    { value: "02", label: "Comprobante emitido con errores sin relación" },
    { value: "03", label: "No se llevó a cabo la operación" },
    {
      value: "04",
      label: "Operación nominativa relacionada en una factura global",
    },
  ];

  const [selectMCancel, setselectMCancel] = useState("");
  const [frelation, setfrelation] = useState(false);
  const [relacionF, setrelacionF] = useState(false);
  const [btonCancelSinRetaion, setbtonCancelSinRetaion] = useState(false);
  const [modalT, setmodalT] = useState(false);

  const dialog = useDialog();

  const handleMCancel = (event) => {
    if (event?.target?.value === "01") {
      setrelacionF(true);
      setfrelation(true);
      setselectMCancel(event.target.value);
      setbtonCancelSinRetaion(false);
    } else if (event?.target?.value === "04") {
      setrelacionF(true);
      setfrelation(true);
      setselectMCancel(event.target.value);
      setbtonCancelSinRetaion(false);
    } else {
      setrelacionF(false);
      setfrelation(false);
      setselectMCancel(event.target.value);
      setbtonCancelSinRetaion(true);
    }
  };

  const handlehancelR = async () => {
    setmodalT(true);
    var data = {
      uuid: sessionStorage.getItem("rowUuid"),
      motivo: selectMCancel,
      cancellation_request_id: sessionStorage.getItem("faCancel"),
    };
    try {
      await cancel_invoice(idCancel, data);
      alert("Exito se cancelo la factura")
      setmodalT(false);
      handle_get_invoice();
      dialog.close(true);
    } catch (err) {
      if (err?.code === "ERR_NETWORK") {
        alert("El proceso no se pudo completar, vuelve a intentarlo por favor.")
        dialog.close(true);
      } else {
        alert(JSON.stringify(err.response.data));
        catchErrorModul("Cancelar Factura", JSON.stringify(data), JSON.stringify(err?.response));
        dialog.close(true);
      }

      setmodalT(false);
    }

  };
  return (
    <div className="">
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '98%', margin: "0 auto", marginBottom: "10px" }}>
        <TextField
          select
          label="Tipo de cancelación"
          value={selectMCancel}
          sx={{ m: 2, width: "100%" }}
          onChange={handleMCancel}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            native: true,
          }}
        >
          {m_cancel.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '98%', margin: "0 auto", marginBottom: "10px" }}>
        <p className="contentMCancel">Motivo de cancelación: {selectMCancel}</p>
      </Box>
      <div
        style={{ display: relacionF === false ? "none" : "" }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '95%', margin: "0 auto", marginBottom: "10px" }}>

          <JOIN_INVOICE_CANCEL_2
            handleSubmit={handle_get_invoice}
            open={frelation}
            id_cancel={idCancel}
            reason_cancel={selectMCancel}
          ></JOIN_INVOICE_CANCEL_2>
        </Box>
      </div>
      <div style={{ display: btonCancelSinRetaion === false ? "none" : "" }}>

        <Box sx={{ display: 'flex', justifyContent: 'center', width: '98%', margin: "0 auto", marginBottom: "10px" }}>
          <Button
            onClick={async () => {
              const result = await Confirm(
                "¿Esta usted seguro de cancelar esta factura?",
                `Cancelar Factura ${serie}${folio}`,
                "Si",
                "No"
              );
              if (result) {
                handlehancelR();
              }
            }}
            sx={{ m: 2, width: "100%" }}
            variant="contained"
            style={{ background: "green" }}
          >
            CANCELAR FACTURA
          </Button>
          <Button
            onClick={() => {
              dialog.close();
            }}
            sx={{ m: 2, width: "100%" }}
            variant="contained"
            style={{ background: "red" }}
          >
            SALIR
          </Button>

        </Box>
      </div>



    </div>
  );
};

export default CANCEL_INVOICE;
