import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

import { CustomDialog, Confirm } from "react-st-modal";

//Date Picker Imports

import FormControl from '@mui/material/FormControl';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import PUBLIC from "../../../../../imgComponents/publico_general.png";
import PDF from "../../../../../imgComponents/pdf.png";
import RECARGA from "../../../../../imgComponents/actualizar.png";
import INVOICE from "../../../../../imgComponents/invoice.png";
import CP from "../../../../../imgComponents/invoice_cp.png";
import REFACTURAR from "../../../../../imgComponents/re-facturar.png";
import REFACTURARPG from "../../../../../imgComponents/refacturar-pg.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MaterialUISwitch } from "../../../../../main_components/material_switch/switch";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SaveIcon from '@mui/icons-material/Save';
import DoorBackIcon from '@mui/icons-material/DoorBack';

const SEARCH_FILTERS = (props) => {

  const [count, setcount] = useState(2);

  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>

      <FormControl required sx={{ width: 120 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='VENTA PRIMARIA DE CONTADO'
            sx={{ width: "100%", backgroundColor: "green", fontSize: "10px" }}
            onClick={() => props?.handleFIlterSalePrimaryC()}
          >
            V.P.CONTADO
          </Button>
        </Box>
      </FormControl>

      <FormControl required sx={{ width: 120 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='VENTA PRIMARIA DE CRÉDITO'
            sx={{ width: "100%", backgroundColor: "green", fontSize: "10px" }}
            onClick={() => props?.handleFIlterSalePrimaryCredit()}
          >
            V.P.CRÉDITO
          </Button>
        </Box>
      </FormControl>

      <FormControl required sx={{ width: 120 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='VENTA SECUNDARIA DE CONTADO'
            sx={{ width: "100%", backgroundColor: "red", fontSize: "10px" }}
            onClick={() => props?.handleFIlterSaleSecundaryC()}
          >
            V.S.CONTADO
          </Button>
        </Box>
      </FormControl>

      <FormControl required sx={{ width: 120 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='VENTA SECUNDARIA DE CRÉDITO'
            sx={{ width: "100%", backgroundColor: "red", fontSize: "10px" }}
            onClick={() => props?.handleFIlterSaleSecundaryCredit()}
          >
            V.S.CRÉDITO
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 130 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='VENTA OBSEQUIO'
            sx={{ width: "100%", backgroundColor: "rgb(158, 90, 175)", fontSize: "10px" }}
            onClick={() => props?.handleFIlterDebit()}
          >
            VENTA OBSEQUIO
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 130 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='VENTA PRIMARIA'
            sx={{ width: "100%", backgroundColor: 'rgb(158, 90, 175)', fontSize: "10px" }}
            onClick={() => props?.handleFIlterSalePrimaryCredit()}
          >
            VENTA PRIMARIA
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};

export default SEARCH_FILTERS;
