import React, { useEffect, useRef } from 'react';
import gif1 from "../../../imgComponents/circle.gif";
import "../bot.css";
import { CustomDialog } from 'react-st-modal';
import { MANUAL_USER } from './manual';

export const HISTORY = (props) => {

const commentsEndRef = useRef(null);
const scrollToBottom = () => {
    setTimeout(() => {
        commentsEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
}

function handlemodal(url) {
    CustomDialog(
        <div>
          <MANUAL_USER
            url={url}
          />
        </div>,
        {
          className: "modalpdf",
          title: "Manual de Usuario",
          showCloseIcon: true,
          isCanClose: false,
        }
      );
}

window.handlemodal = handlemodal;

useEffect(() => {
    scrollToBottom();
}, [props?.data]);

const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+?\.pdf)/g;
    return text.split(urlRegex).map((part, index) => {
        if (urlRegex.test(part)) {
            return `<a className="a-link-bot" key=${index} onclick="handlemodal('${part}')">"Da clic para visualizar el Manual de Usuario"</a>`;
        }
        return part;
    }).join('');
};

  return (
    <div className='baninbbot'>
        <div className='mss-bot'>
            <div className='msj-bot'>
                <p>Bienvenido soy SMIT <br/>Puedo resolver cualquier duda sobre el módulo seleccionado<br/><br/>¿En que puedo ayudarte el día de hoy?</p>
            </div>
        </div>
        {
            props?.data?.data?.map((item, index)=>(   
                item?.role === "assistant"?
                <div className='mss-bot' key={index} ref={commentsEndRef}>
                    <div className='msj-bot'>
                        {
                             item?.content?.map((it, index) => (
                                 <p dangerouslySetInnerHTML={{ __html: urlify(it?.text?.value.replace(/\n/g, "<br>")) }} />
                             ))
                        }
                       
                    </div>
                </div>:
                <div className='mss-user' key={index} ref={commentsEndRef}>
                    <div className='msj-user'>
                        {
                            item?.content?.map((it)=>(
                                <p>{it?.text?.value}</p>
                               
                            ))
                        }
                    </div>
                </div>
            ))
        }
        <div className='loading-msj-receive' style={props?.load===true?{display:""}:{display:"none"}}>
            <img src={gif1}></img>
            <p>{props?.loadmsj}</p>
        </div>
     {/* <button className="bottom-css-msj" onClick={scrollToBottom} >abajo</button> */}
    </div>
  )
}
