import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
const SEARCH_FILTERS = (props) => {
  



  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 135 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 135 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>
      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', width: "100%" }}
            onClick={(e) => {
              props.handleSubmit()
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>

      <FormControl required sx={{ width: 170 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ width: "100%", backgroundColor: 'rgb(158, 90, 175)', fontSize: "11.5px",marginLeft: "5px" }}
            onClick={(e) => {
               props.handle_send_data()
            }}
          >
            <NoteAddIcon></NoteAddIcon>&nbsp;Agregar Ventas
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 170 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ width: "100%", backgroundColor: 'rgb(61,154,12)', fontSize: "11.5px",marginLeft: "5px" }}
            onClick={(e) => {
               props.Terminar()
            }}
          >
            <AssignmentTurnedInIcon></AssignmentTurnedInIcon>&nbsp;Guardar y Salir
          </Button>
        </Box>
      </FormControl>
      &nbsp;&nbsp;&nbsp;
      <FormControl required sx={{ width: 200 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <p style={{"fontSize":"14px", marginTop:"10px", marginBottom:"0px"}}>VENTAS EN PAQUETE:  {props?.contpackage}</p>  
        </Box>
      </FormControl>
    </Box>
  );
};

export default SEARCH_FILTERS;
