import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import add from "../../../imgComponents/save.png";
import del from "../../../imgComponents/borrar.png";
import lup from "../../../imgComponents/lupa.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_points_of_sale } from "../../../services/businesspartners/businesspartners";
import DetailsPointsOfSale from "../DetailsPointsOfSale";
import EditPointsOfSale from "../EditPointsOfSale";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {


    const delet = async ()=>{

        props?.setmodalT(true);

        try {
            var rf_token = await refresh_token();
            if(rf_token===true){
              setTimeout(async() => {
                try{
                  const dt = await delete_points_of_sale(props?.row?.original?.id);
                  props?.alertas("Punto de Venta Eliminado", true);
                  props?.setmodalT(false);
                  props?.handleSubmit();
                  
                }catch(err){
                  console.log("error", err);
                  props?.alertas("Error, vuelva a intentarlo", false);
                  props?.handleSubmit();
                  props?.setmodalT(false);
                }
              }, 1000);
            }else{}
          } catch (err) {
            console.log(err);
          }
    }



    return (
        <div>
           
            <MenuItem
                key={1}
                onClick={
                    async() =>{
                        props.closeMenu();
                        const result = await Confirm('¿Esta usted seguro de eliminar el Punto de Venta?','Eliminar Punto de Venta','Si','No');
                        if(result){
                            delet()
                            }
                        }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={del}
                        title="Eliminar Punto de Venta"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Punto de Venta
            </MenuItem>
            <MenuItem
                key={2}
                onClick={
                    
                    async () => {
                        props.closeMenu();
                        const result = await CustomDialog(
                          <div>
                            <DetailsPointsOfSale row={props?.row} />
                          </div>,
                          {
                            className: "custom-modal-3",
                            title: "Detalles de punto de venta",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                }}
                title="Detalles de punto de venta"
                alt=""
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={lup}
                        title="Detalles del Punto de Venta"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles del Punto de Venta
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                       <EditPointsOfSale
                        handleSubmit={props?.handleSubmit}
                        row={props?.row}
                        alertas={props?.alertas}
                        setmodalT={props?.setmodalT}
                      />,
                        {
                            className: "custom-modal-3",
                            title: "EDITAR PUNTO DE VENTA",
                            showCloseIcon: true,
                        }
                    );
                  } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={add}
                        title="Editar Punto de Venta"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Punto de Venta
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
