//PAQUETERIAS
import React, { useState, useMemo } from "react";
import {useDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";
//COMPONENTES
import TableContainer from "../../../main_components/Table/TableContainer";
import {SelectColumnFilter} from "../../filters";
import { get_invoices } from "../../../services/invoices/invoice";
import TextField from "@mui/material/TextField";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
//ESTILOS CSS
import "../../modal.css";

//IMAGENES
import Motivo from "../../../imgComponents/motivo.png";
import Spinner from "../../../imgComponents/S.gif";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";

const JOIN_INVOICE = (props) => {
  const [data, setData] = useState([]);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [mj, setmj] = useState("");  
  const [isLoding, setIsLoding] = useState(false);
  const [viewR, setviewR] = useState(false);
  const [uuidRelation, setuuidRelation] = useState("");
  const dialog = useDialog();
  const [select_m, setselect_m] = useState("");
  const [cfdi, setcfdi] = useState(false);
  const [cfdi_isvalid, setcfdi_isvalid] = useState(false);



  const handle_select_m = (event) => {  
    setselect_m(event.target.value);
    if(event.target.value!==""){
      setcfdi(true);
    }else{
      setcfdi(false);
    }
  }
  const handleChage = () => {
    setcfdi(true);
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setIsLoding(true);
    setData([]);
    setmj("Buscando...")
    try {
      var dt = await get_invoices(fi, ff, props.rfc, null, null, false);
      setData(dt.data.slice().sort((a, b) => a.invoice_date < b.invoice_date));
      setIsLoding(false); 
    } catch (err) {
      setData([]);
      setIsLoding(false);
    }   
  };



  const handle_rel = () => {
    const env= {
      uuid_sat:uuidRelation,
      motivo:select_m,
    }
    dialog.close(env);
  };


  const columns = useMemo(
    () => [
      {
        Header: "Relacionar",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function setRelacion() {
            setuuidRelation(row.original.uuid_sat);
            setviewR(true);
            setcfdi_isvalid(true);
            setcfdi(false);
          }
          return (
            <div className="">
              <img
                alt=""
                style={{width:"30px",cursor:"pointer"}}
                src={Motivo}
                onClick={async () => {
                  const result = await Confirm(
                    "Factura relacionada: " + row.original.uuid_sat,
                    "ESTA USTED SEGURO DE RELACIONAR ESTA FACTURA, DE NO SER ASI DE AL BOTON DE CANCELAR PARA ESCOGER OTRA"
                  );
                  if (result) {
                    setRelacion(result);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
        width: 80,

      },

      {
       
        width: 130,
        Header: "Serie",
        accessor: "serie",
        Filter: SelectColumnFilter,
        filter: "equals",
        id: "seriee",
      },

      {
       
        width: 130,
        Header: "Folio",
        accessor: "folio",
      },

      {
  
        width: 130,
        Header: "Uuid",
        accessor: "uuid_sat",
      }, 
      {
 
        width: 130,
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },
    ],
    []
  );

  return (
    <div >
    <div className="selectMR">
        <TextField
          select
          label="Tipo de cancelación"
          value={select_m}
          sx={{ m: 2, width: "100%" }}
          onChange={handle_select_m}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            native: true,
          }}
        >
          {props.type.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
      <div className="bp-2">
        <p className="contentMCancel">Motivo de relación: {select_m}</p>
      </div>

      <p
        style={{display:cfdi_isvalid===true?"":"none"}}
        className="contentMCancel"
        title="Seleeccionar otra factura"
        onClick={handleChage}
      >
        Factura relacionada:{uuidRelation}
      </p>

      <div style={{ display: cfdi === true ? "" : "none" }}>
        <div className="containerCosultCancel">
          <div className="bp-bus1">
            <input
              type="text"
              className="fi"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha inicial"
              onChange={(event) => setfi(event.target.value)}
            ></input>
          </div>
          <div className="bp-bus2">
            <input
              type="text"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha Final"
              onChange={(event) => setff(event.target.value)}
            ></input>
          </div>
          <div className="bp-bus">
            <button className="button" onClick={handleSubmit} type="button">
              Buscar
            </button>
          </div>
        </div>

    
          <MODAL_TABLE modalGeneral={isLoding} modalT={isLoding} message={mj}></MODAL_TABLE>

          <div>
            <TableContainer
              id="uno"
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              consultas={"Facturas"}
              exportar={true} 
            />
          </div>

      </div>

      <div
          className="contanerBtnRelacionar"
          style={{ display: uuidRelation!=="" ? "" : "none" }}
        >
          <div className="bp-4" style={{display:props.relacionar===true?"none":""}}>
            <Button
             onClick={async() =>{
              const result = await Confirm('¿Esta usted seguro de cancelar esta factura?','Cancelar Factura','Si','No');
              if(result){
                handle_rel();
                }
              }            
            }
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              Relacionar
            </Button>
            
          </div>
          <div className="bp-4" style={{display:props.relacionar===true?"":"none"}}>
            <Button
             onClick={async() =>{
              const result = await Confirm('¿Esta usted seguro de relacionar esta factura?','Relacionar Factura','Si','No');
              if(result){
                handle_rel();
                }
              }            
            }
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              Relacionar
            </Button>
            
          </div>
          <div className="bp-5">
            <Button
              onClick={() => {
                dialog.close(false);
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              SALIR
            </Button>
          </div>
        </div>
    </div>
  );
};

export default JOIN_INVOICE;
