import React, { useEffect, useState} from "react";

import "../../../components_cfdi/estilo.css";
import "../../../components_cfdi/modal.css";
import "../../../components_cfdi/table.css";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_socioHabilitado } from "../../../services/businesspartners/businesspartners";


function SubTable () {

  const [data, setData] = useState([]);

  
const handleSubmit = async(event) => {
  event?.preventDefault();
  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const d = await get_socioHabilitado(sessionStorage.getItem("socioHabilitado"));
          const data = d === null ? [] : d.data;
          console.log(d.data);
          setData(data);
        }catch(err){
          console.log("error", err);
          setData([]);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }

/*
  const url = `https://m0fsi6ifd9.execute-api.us-east-1.amazonaws.com/development/businesspartners/${sessionStorage.getItem("socioHabilitado")}`;
  axios
    .get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      const data = response.data;
      console.log(response.data);

      setData(data);
    })
    .catch((err) => {
      console.log("error", err);
      setData([]);
    });*/
};


useEffect(() => {
  handleSubmit();
}, []);


  return (
    <div className="TableConsultarTodo2">
      <table>
        <thead>
          <tr>
            <th>Número de régimen tributario</th>
            <th>Residencia fiscal</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.num_reg_id_trib}</td>
            <td>{data.tax_residence}</td>
            <td>{data.email}</td>
          </tr>
          
        </tbody>
        
      </table>
    </div>
  );
};

export default SubTable;
