import React, { useEffect } from 'react';
export const SO_CHECK = (setOs) => {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Win') !== -1) {
        return 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return 'iOS';
        } else {
            return 'macOS';
        }
    } else {
        return 'Other';
    }
}
