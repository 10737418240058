import { CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import RECOVERY from "../../../imgComponents/reconsultaTable.png";
import RECOVERY_POS from "./recovery_pos";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
    return (
        <div>
           
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <RECOVERY_POS 
                            alertas={props?.alertas} 
                            handlesubmit={props?.handlesubmit} 
                            row={props?.row} 
                            setmodalT={props?.setmodalT}/>,
                    {
                        className: "custom-modal-3",
                        title: "AGREGAR PRODUCTOS A PROMOCIÓN",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={RECOVERY}
                        title="Recuperar Socio de Negocios"
                        className=""
                    ></img>
                </ListItemIcon>
                Recuperar Socio de Negocios
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
