//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog } from "react-st-modal";
//COMPONENTES
import NotificationSystem from 'react-notification-system';
//ESTILOS
import "./componentVentasBonificaciones/estilostable.css";
import { inflate } from 'pako';
//IMAGENES/ICONOS
import { refresh_token } from "../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";
import "./bonificaciones.css";
import { get_bonification_packages, get_bonification_packages_report, post_bonification_packages_report } from "../services/bonifications/bonifications";
import { get_bonus } from "../services/unilever/unilever";
import MODAL_TABLE from "../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import CREATE from "../imgComponents/create.png";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import SetBonuses_v2 from "./SetBonuses_v2";
import SEARCH_FILTERS2 from "./tools/shearch_filter2";
import dayjs from "dayjs";
import { date_current, previous_date } from "../main_components/date/date";
import { Box } from "@mui/material";
import AlertResponse from "../main_components/alerts/alertResponse";
import { update_null_identifiers } from "../main_components/methods_v2/auxiliar_funtion";

var JSON_object;
var params = {
  sheets_number: 0,
  columns: [],
  rows: 0,
  sheetName: [],
  headers: [],
  title: '',
  includeRowTitle: false,
  includeRowHeader: false,
  colorHeader: {
    red: 0,
    green: 0,
    blue: 0
  },
  colorTitles: {
    red: 0,
    green: 0,
    blue: 0
  }
}


const TableBonificaciones_V2 = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();
  var añoantes = date.getFullYear();
  var mesantes = Number(mes) - 1;

  if (mesantes === 0) {
    mesantes = `${12}`.padStart(2, "0");
    añoantes = añoantes - 1;
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const [data, setData] = useState([]);
  const fecha = new Date();
  const [ff, setff] = useState(dayjs(date_current));
  const [fi, setfi] = useState(dayjs(previous_date));
  const [isLoading, setIsLoading] = useState(false);
  const [MensajeBusqueda, setMensajeBusqueda] = React.useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");
  const [modalT, setmodalT] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");
  const notificationSystemRef = useRef();

  //proceso de consultar paquete de bonificaciones
  const handleSubmit = async (query_filters_flag, query_filters_data) => {
    setData([]);
    try {
      setIsLoading(true);
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          var d;
          try {
            if (query_filters_flag) {
              setfi(dayjs(query_filters_data.di))
              setff(dayjs(query_filters_data.df))
              d = await get_bonification_packages(query_filters_data.di, query_filters_data.df);
              setquery_filters({
                di: query_filters_data.di,
                df: query_filters_data.df,
              })
              setIsLoading(false);
            } else {
              setquery_filters({
                di: fi.format('YYYY-MM-DD'),
                df: ff.format('YYYY-MM-DD')
              });
              d = await get_bonification_packages(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'));
              var ndt = update_null_identifiers(d?.data?.bonificationPackageList, update_identifiers);
             
              setData(ndt === null ? [] : ndt);
              setIsLoading(false);
            }
            setData(ndt === null ? [] : ndt);
          } catch (err) {
            console.log("error", err);
            setData([]);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const consultar = async () => {

    setmodalGeneral(true);
    setMensajeBusqueda("Generando Reporte de Bonificaciones");
    setmodalT(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_bonification_packages_report(pqbo);
            if (d?.status === 200) {
              console.log("si hay paquete de bonificaciones");
              console.log(d?.data?.data);
              const buffer = base64ToArrayBuffer(d?.data?.data);
              const uncompressedData = inflate(buffer, { to: 'string' });
              const uncompressedJson = JSON.parse(uncompressedData);
              console.log(uncompressedJson);
              loadJSON(uncompressedJson, d);
            }
            if (d?.status === 205) {
              alert("El paquete de bonificación será procesado.\nSe le notificará a su correo cuando pueda consultarlo.\n\nConsidere que para un paquete de 200 ventas el tiempo de espera es de 1 minuto aproximadamente.");
              setmodalT(false);
              setmodalGeneral(false);
            }
          } catch (err) {
            console.log("AXIOS ERROR: ", err);
            setmodalT(false);
            setmodalGeneral(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }


  //creacion de google sheets
  var SHEET_ID = '';
  var CLIENT_ID = '301722987834-pec5a9vlojn6redpcneblrgd2ckqdj05.apps.googleusercontent.com';
  var API_KEY = 'AIzaSyBTswKLvFOwWbnlFqwQMqpUgUEL3LQu7ls';
  var DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];
  var SCOPES = "https://www.googleapis.com/auth/spreadsheets";
  var pqbo;

  useEffect(() => {
    initTokenClient();
  }, [])

  var tokenClient;
  var access_token;

  function getToken() {
    console.log("gettoken()");
    initTokenClient();
    tokenClient.requestAccessToken();
  }
  function initTokenClient() {

    const Google = window.google;

    tokenClient = Google?.accounts?.oauth2?.initTokenClient({
      client_id: "301722987834-pec5a9vlojn6redpcneblrgd2ckqdj05.apps.googleusercontent.com",
      scope: 'https://www.googleapis.com/auth/spreadsheets',
      discoveryDocs: DISCOVERY_DOCS,
      prompt: 'consent',
      callback: (tokenResponse) => {
        access_token = tokenResponse.access_token;
        //console.log(access_token)
        consultar();
      },
    });

    console.log(tokenClient);
  }
  //inicio de sesion anterior
  function handleClientLoad(pk) {
    pqbo = pk;
    console.log("metodo handleClientLoad " + pqbo);
    window.gapi.load('client:auth2', initClient);
  }
  function appendPre(message) {
    console.log("metodo appenPre");
    var pre = document.getElementById('content');
    var textContent = document.createTextNode(message + '\n');
    pre.appendChild(textContent);
  }
  function initClient() {
    console.log("metodo initCLiente");

    window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      //scope: SCOPES,
      //prompt: 'consent'
    }).then(function () {
      getToken();

    }, function (error) {
      console.log("error grave")
      appendPre(JSON.stringify(error, null, 2));
    });
  }


  /*Objetos del generador*/
  function getName() {
    var date = new Date();
    window.packageName = JSON_object[0][0].fileName;
    return window.packageName + "_" + date.getYear() + date.getMonth() + date.getDay() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
  }
  function createSheet() {
    window.gapi.client.sheets.spreadsheets.create(
      {
        properties: {
          title: getName()
        }
      }).then((response) => {
        SHEET_ID = response.result.spreadsheetId;
        console.log("Hoja creada\nID: " + SHEET_ID);

        setTimeout(() => {
          setmodalT(false);
          setmodalGeneral(false);
          alert("Se generó correctamente el reporte");
        }, 1000);

        addFormat();
      })
  }
  function addFormat() {
    var requests = getRequests()
    console.log(requests);
    window.gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: SHEET_ID
    }, {
      'requests': requests
    }).then(() => {
      fillRows()
    })
  }
  function getHeaders() {
    var aux = []
    //headers de cada hoja
    for (var h = 0; h < JSON_object[0].length; h++) {

      var headers = new Array()
      for (var rt = 0; rt < JSON_object[0][h].headers.length; rt++) {
        headers.push(JSON_object[0][h].headers[rt].headerText)
      }

      aux.push({
        "range": removeUnderScore(JSON_object[0][h].sheetName) + '!A2',
        "values": [headers]
      })

      aux.push(
        {
          "range": removeUnderScore(JSON_object[0][h].sheetName) + '!A1',
          "values": [[JSON_object[0][h].title]]
        }
      )
    }
    return aux;
  }
  function setHeaders() {
    var data = getHeaders()
    window.gapi.client.sheets.spreadsheets.values.batchUpdate({
      spreadsheetId: SHEET_ID
    }, {
      valueInputOption: "USER_ENTERED",
      data: data
    }).then(() => {
      console.log("Atributos de la Sheet generados");
      window.open('https://docs.google.com/spreadsheets/d/' + SHEET_ID + '/', '_blank');

    })
  }
  function removeUnderScore(text) {
    var aux = ''
    for (var s = 0; s < text.length; s++) {
      if (!text.charAt(s).match('_')) {
        aux += text.charAt(s)
      }
      else {
        aux += ' '
      }
    }
    return aux
  }
  function getRequests() {
    var aux = [];
    /*Crear hojas*/
    aux.push({
      "updateSheetProperties": {
        "properties": {
          "sheetId": 0,
          "title": removeUnderScore(JSON_object[0][0].sheetName)
        },
        "fields": "title"
      }
    })
    if (params.sheets_number > 1) {
      for (var h = 1; h < JSON_object[0].length; h++) {
        aux.push(
          {
            "addSheet": {
              "properties": {
                "sheetId": h,
                "title": removeUnderScore(JSON_object[0][h].sheetName)
              }
            }
          })
      }
    }
    else { }
    /* Pintar Headers */
    var setDataIndex = 0;
    if (params.includeRowHeader) {
      setDataIndex++;
      for (var h = 0; h < JSON_object[0].length; h++) {
        aux.push({
          "mergeCells": {
            "range": {
              "sheetId": h,
              "startRowIndex": 0,
              "endRowIndex": 1,
              "startColumnIndex": 0,
              "endColumnIndex": JSON_object[0][h].headers.length
            },
            "mergeType": "MERGE_ALL"
          }
        }, {
          "repeatCell": {
            "range": {
              "sheetId": h,
              "startRowIndex": 0,
              "endRowIndex": 1,
              "startColumnIndex": 0,
              "endColumnIndex": JSON_object[0][h].headers.length
            },
            "cell": {
              "userEnteredFormat": {
                "backgroundColor": {
                  "red": 0.0,
                  "green": 0.0,
                  "blue": 1
                },
                "horizontalAlignment": "CENTER",
                "textFormat": {
                  "foregroundColor": {
                    "red": 1.0,
                    "green": 1.0,
                    "blue": 1.0
                  },

                  "bold": true
                }
              }
            },
            "fields": "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)"
          }
        });

      }
    }
    if (params.includeRowTitle) {
      setDataIndex++;
      for (var h = 0; h < JSON_object[0].length; h++) {
        aux.push({
          "repeatCell": {
            "range": {
              "sheetId": h,
              "startRowIndex": 1,
              "endRowIndex": 2,
              "startColumnIndex": 0,
              "endColumnIndex": JSON_object[0][h].headers.length
            },
            "cell": {
              "userEnteredFormat": {
                "backgroundColor": {
                  "red": 217.00 / 255,
                  "green": 217.00 / 255,
                  "blue": 217.00 / 255
                },
                "horizontalAlignment": "CENTER",
                "textFormat": {
                  "foregroundColor": {
                    "red": 0.0,
                    "green": 0.0,
                    "blue": 0.0
                  },
                  "bold": true
                }
              }
            },
            "fields": "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)"
          }
        });
      }
    }
    /*Crear bordes*/
    for (var h = 0; h < JSON_object[0].length; h++) {
      aux.push({
        "updateBorders": {
          "range": {
            "sheetId": h,
            "startRowIndex": 0,
            "endRowIndex": JSON_object[h + 1].length + 2,
            "startColumnIndex": 0,
            "endColumnIndex": JSON_object[0][h].headers.length
          },
          "top": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "bottom": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "left": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "right": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "innerHorizontal": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "innerVertical": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          }
        }
      });
    }
    console.warn(aux);
    return aux;
  }
  function drawData(hojas) {
    for (var h = 0; h < JSON_object[0].length; h++) {
      window.gapi.client.sheets.spreadsheets.values.append({
        spreadsheetId: SHEET_ID,
        range: removeUnderScore(JSON_object[0][h].sheetName) + '!A3',
        valueInputOption: 'USER_ENTERED',
        resource: {
          values:
            hojas[h]
        }
      }).then(() => {
        console.log("Hoja cargada");
      });
    }
    setHeaders();
  }
  function fillRows() {
    var hojas = new Array()
    for (var h = 1; h < params.sheets_number; h++) {
      var aux2 = new Array();
      for (var r = 0; r < JSON_object[h].length; r++) {
        var aux = new Array;
        for (var rt = 0; rt < JSON_object[0][h - 1].headers.length; rt++) {
          var tableHeaders = JSON_object[0][h - 1].headers;
          aux.push(JSON_object[h][r][tableHeaders[rt].headerJs]);
        }
        aux2.push(aux);
      }
      hojas.push(aux2);
    }
    drawData(hojas);
  }
  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
  function loadJSON(json, res) {

    if (res.status === 200) {

      JSON_object = json;

      console.log(JSON_object);

      JSON_object[0][3].headers[12].headerJs = "quantity";

      params = {
        sheets_number: JSON_object.length,
        columns: JSON_object[0][0].columns,
        rows: JSON_object[0][0].rows,
        sheetName: JSON_object[0][0].sheetName,
        headers: JSON_object[0][0].headers,
        title: JSON_object[0][0].title,
        includeRowTitle: JSON_object[0][0].includeRowTitle,
        includeRowHeader: JSON_object[0][0].includeRowHeader
      }

      console.log(JSON_object);
      createSheet();
    } else {
      console.log("error");
    }
  }
  const handle_bonus = async (id) => {

    setIsLoading(true);
    setMensajeBusqueda("Generando interfaz...")

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            console.log(id)
            await get_bonus(id);
            setIsLoading(false);
            setMensajeBusqueda("")
            alert("Éxito: Paquete generado correctamente.")
          } catch (err) {
            setIsLoading(false);
            alert(JSON.stringify(err.response.data))
            setMensajeBusqueda("")

          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  var permisosUsuario = sessionStorage.getItem("roles_user");
  var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
  var permisos = permisoUsuarioReplace?.split(",");
  var dmsrole = permisos?.filter((x) => x === "ROLE_CONFIGURATION");

  const columns = useMemo(
    () => [

      {
        id: "date_register",
        header: "Fecha",
        accessorKey: "date_register",
      },
      {
        id: "package_number",
        header: "Número",
        accessorKey: "package_number",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "package_name",
        header: "Nombre",
        accessorKey: "package_name",
      },
      {
        id: "total_sales",
        header: "Total Ventas",
        accessorKey: "total_sales",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        id: "total_documents",
        header: "Documentos",
        accessorKey: "total_documents",
        muiTableBodyCellProps: {
          align: "right"
        }
      },
      {
        id: "total_product_units_compensate",
        header: "Productos / Piezas",
        accessorKey: "total_product_units_compensate",
        //Filter: SelectColumnFilter,
        muiTableBodyCellProps: {
          align: "right"
        }
      },
      {
        id: "total_product_compensate",
        header: "Productos",
        accessorKey: "total_product_compensate",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
      },

      {
        id: "total_delivery_commission",
        header: "Comisión",
        accessorKey: "total_delivery_commission",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        id: "total_bonification",
        header: "Bonificación",
        accessorKey: "total_bonification",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
    ],
    []
  );
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };
  const update_identifiers =
    [
      { date_register: 'string' },
      { package_number: 'string' },
      { package_name: 'String' },
      { total_sales: 'number' },
      { total_documents: 'string' },
      { total_product_units_compensate: 'number' },
      { total_product_compensate: 'number' },
      { total_delivery_commission: 'number' },
      { total_bonification: 'number' }
    ];
  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }
  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])

  return (
    <div className="">
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR REPORTES DE BONIFICACIONES", path: null },
        ]
      }
       cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={MensajeBusqueda}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>

      <div className="">
        <div >
          <br></br>
          <div className="new_table_v2">
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                      <img
                        title="Crear Paquete"
                        src={CREATE}
                        className=""
                        onClick={async () => {

                          const result = await CustomDialog(
                            <div>
                              <SetBonuses_v2
                                setmodalT={setmodalT}
                              />
                            </div>,
                            {
                              className: "custom-modal",
                              title: "Crear nuevo paquete",
                              showCloseIcon: true,
                              isCanClose: false,
                            }
                          );
                        }}
                      ></img>
                    </div>
                  ),
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => (
                <SEARCH_FILTERS2
                  fi={fi}
                  setfi={setfi}
                  ff={ff}
                  setff={setff}
                  handleSubmit={handleSubmit}
                />
              )}

              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  row={row}
                  handleSubmit={handleSubmit}
                  refresh_token={refresh_token}
                  //alertas={alertas}
                  handleClientLoad={handleClientLoad}
                  handle_bonus={handle_bonus}
                  dmsrole={dmsrole}
                />
              ]}
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Reporte de Bonificaciones"}
                  update_identifiers={update_identifiers}
                  settotal_rows={settotal_rows}
                  query_filters={query_filters}
                  query_filters_dt={query_filters_dt}
                  handle_get_consult={handleSubmit}
                  key={index}
                  handle_success_filter={handle_success_filter}
                  share_filter={true}
                  total_rows={total_rows !== "" ? total_rows : 0}
                />
              )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableBonificaciones_V2;
