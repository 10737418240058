//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
//COMPONENTES
import TableContainer from "../../../Table_Container_Tax";
import SubTable from "../../detail_expense/datail";
import TableDetailGrupExpensePolicy from "./detail_group_policy";
import { base64toBlob } from "../../../../main_components/donwloadfuncion/download";
import {
  download_expense,
  get_expenses_groups,
} from "../../../../services/expenses/expenses";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../../../expense.css";
//IMAGENES/ICONOS
import PDF from "../../../../imgComponents/pdf.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MaterialUISwitch } from "../../../../main_components/material_switch/switch";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../../main_components/methods_v2/export";
import ACTIONS_SPECIALS from "./tools/actions_specials";
import { Box } from "@mui/material";
import TableDetailGrupExpensePolicy_v2 from "./detail_group_policy_v2";

const EXPENSES_GROUP_DETAIL_V2 = ({ idGrupExpense }) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

  const [checkedUpdate, setcheckedUpdate] = React.useState(true);
  const [modalT, setmodalT]=useState(false);

  const handlecheckedUpdate = (event) => {
    setcheckedUpdate(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setIsLoading(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        setMenssage("Consultando grupo de gastos...");
        try {
          var dta = await get_expenses_groups(
            sessionStorage.getItem("IdGrupExpense")
          );
          const data = dta?.data?.sort((a, b) =>
            a.id < b.id ? 1 : a.id > b.id ? -1 : 0
          );
          console.log(data);
          setData(data);
          setOpen(false);
          setMenssage("");
          setIsLoading(false);
        } catch (err) {
          console.log("error", JSON.stringify(err.response));
          setData([]);
          setOpen(false);
          setMenssage("");
          setIsLoading(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_download = async (id, file_name) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        try {
          var dta = await download_expense(id);
          const data = dta.data;
          var blob = base64toBlob(data.file);
          const blobUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = blobUrl;
          var arr = file_name.split("/");
          downloadLink.download = arr[4] + ".pdf";
          downloadLink.click();
          setOpen(false);
        } catch (err) {
          setOpen(true);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  
  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   header: "Ac.",
      //   Cell: ({ valor, row }) => {
      //     return (
      //       <div>
      //         <img
      //           title="Descargar pdf"
      //           alt=""
      //           src={PDF}
      //           onClick={() =>
      //             handle_download(row.original.id, row.original.file_name)
      //           }
                
      //         ></img>
      //       </div>
      //     );
      //   },
      // },

      {
        id: "spend_folio_number",
        header: "No.",
        accessorKey: "spend_folio_number",
      },
      {
        id: "date",
        header: "F. Documento",
        accessorKey: "date",
      },
      {
        id: "concept",
        header: "Concepto",
        accessorKey: "concept",
      },
      {
        id: "description",
        header: "Descripción",
        accessorKey: "description",
      },
      {
        id: "ammount",
        header: "Importe",
        accessorKey: "ammount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "user_name",
        header: "Usuario",
        accessorKey: "user_name",
      },
      {
        id: "origin",
        header: "Origen",
        accessorKey: "origin",
      },

      { 
        id: "business_partner_name",
        header: "Proveedor", 
        accessorKey: "business_partner_name" 
      },
    ],
    []
  );

  
  const update_identifiers =
  [
    { spend_folio_number: 'string' },
    { date: 'string' },
    { concept: 'string' },
    { description: 'string' },
    { ammount: 'string' },
    { user_name: 'string' },
    { origin: 'string' },
    { business_partner_name: 'string' }
  ];

  return (
    <div >
      <MODAL_TABLE
    open={true}
    message={""}
    modalGeneral={false}
    modalT={modalT}
  ></MODAL_TABLE>

      <BREADCRUMBS
        niveles={[
          { label: "REPORTE DE GASTOS", path: "/expensesReport_v2" },
          { label: "REPORTE DE GASTOS AGRUPADOS", path: null },
        ]}
      ></BREADCRUMBS>
      <div className="switchRegister">
        <FormControlLabel
          control={
            <MaterialUISwitch
              sx={{ m: 1 }}
              onChange={handlecheckedUpdate}
              checked={checkedUpdate}
            />
          }
          label={
            checkedUpdate === true
              ? "Visualizar grupo de gastos"
              : "Ocultar grupo de gastos"
          }
        />
      </div>
      <div style={{ display: checkedUpdate === true ? "none" : "" }}>
        <div>
          <h3> DETALLES DEL GRUPO DE GASTOS</h3>
        </div>
        <br></br>
        <div className="new_table_v2" style={{"width":"95%", "margin":"0 auto"}}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          initialState={{ showGlobalFilter: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
                  muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderRowActionMenuItems={({ row, closeMenu }) => [
              <ACTIONS_SPECIALS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                row={row}
              />
          ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              file_name={"Reporte de Gastos Agrupados"}
              update_identifiers={update_identifiers}
            />
          )}
      />
    </div>
        
        {/* <div className="TableReport GrupExpenseDetail">
          <div style={{ overflowX: "auto" }}>
            <TableContainer
              visible={false}
              exportEx={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable verData={true} />
                  </div>
                );
              }}
            />
          </div>
        </div> */}
      </div>

      <TableDetailGrupExpensePolicy_v2 handleSubmit={handleSubmit}></TableDetailGrupExpensePolicy_v2>
    </div>
  );
};

export default EXPENSES_GROUP_DETAIL_V2;
