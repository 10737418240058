import React, { useEffect, useState } from 'react';
import { download_invoice } from '../../../services/invoices/invoice';
import { base64toBlob } from '../../donwloadfuncion/download';
import { PDFDocument, rgb } from 'pdf-lib';
import JSZip from 'jszip';
import { refresh_token } from '../../tokens/tokenrefresh';
import TextField from "@mui/material/TextField";
import "./invoice_com.css";
import loadimg from "../../../imgComponents/iconos/loading.gif";

export const INVOICE_COMPRIMED_V2 = (props) => {

  const [type, settype]=useState("UNIDO");
  const [load, setload]=useState(false);
  const [msj, setmsj]=useState("Iniciando...");
  const [progr, setProgr] = useState(0);
  var progress = 0;
  const totalinvoices = props?.page?.length;
  console.log(props)
  const handledow = ()=>{
    if(type==="UNIDO"){
      setload(true);
      handle_all_invoice(props?.page);
    }else if(type==="INDIVIDUAL"){
      setload(true);
      handle_ind_invoice(props?.page);
    }else if(type==="XML"){
      setload(true);
      handle_ind_invoice_xml(props?.page);
    }
  }
  
const handlefactura = async (tipo, id,factura)=>{
  try {
    const d = await download_invoice(tipo,factura,id);
    progress = progress + 1 / totalinvoices * 100;
    setProgr(progress);
    setmsj("Comprimiendo factura: " + factura);
    var dt = d?.data?.file;
    return dt;
  }
  catch(err){
    console.log(err);
    return null;
  }
 }

 const handle_ind_invoice_xml = async (props) =>{
  
  const zip = new JSZip();
  
  for(const item of props){
    const data = await handlefactura("xml",item.id, item.filename);
     if (data) {
      const decodedData = atob(data);
      const xmlBlob = new Blob([decodedData], { type: 'application/xml' });
      zip.file(`invoice_${item.id}.xml`, xmlBlob); // Cambiar la extensión a .xml
    }
  }

  setmsj("Descargando Zip");
  setTimeout(async() => {
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const outputPath = 'facturas.zip';
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = outputPath;
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
    setload(false);
    setProgr(0);
  }, 2000);
  
 }
 
  
const handle_all_invoice = async (props) =>{

  const invoiceData = [];

  for (const item of props) {
    const data = await handlefactura("pdf",item.id, item.filename);
    invoiceData.push(data);
  }
  
  const mergedPdfDoc = await PDFDocument.create();

   for (const data of invoiceData) {  

     if (data) {
       const pdfBytes = Uint8Array.from(atob(data), c => c.charCodeAt(0));
       const pdfDoc = await PDFDocument.load(pdfBytes);
   
       const numPages = pdfDoc.getPageCount();     
        if (numPages % 2 !== 0) {
          pdfDoc.addPage();
        }

       const pages = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
       pages.forEach((page) => {
         mergedPdfDoc.addPage(page);
      });
     }
  }

   // Convertir el documento combinado a un blob
   const mergedPdfBytes = await mergedPdfDoc.save();
   const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
   const outputPath = 'facturas.zip';
   const zip = new JSZip();
   setmsj("Descargando Zip");
   setTimeout(() => {
    zip.file('invoice_combined.pdf', blob);
    zip.generateAsync({ type: 'blob' }).then((content) => {
     const downloadLink = document.createElement('a');
     downloadLink.href = URL.createObjectURL(content);
     downloadLink.download = outputPath;
     downloadLink.click();
     URL.revokeObjectURL(downloadLink.href);
   });
   setload(false);
   setProgr(0);
   
   }, 2000);
  
}


const handle_ind_invoice = async (props)=>{
  const zip = new JSZip();
  for(const item of props){
    const data = await handlefactura("pdf",item.id, item.filename);   
    if (data) {
       // Decodificar base64
       //const decodedData = atob(data);
       //const pdfBlob = new Blob([decodedData], { type: 'application/pdf' });
       //zip.file(`invoice_${item.id}.pdf`, pdfBlob);      
       const decodedData = atob(data);
       const byteArray = new Uint8Array(decodedData.length);
       for (let i = 0; i < decodedData.length; i++) {
         byteArray[i] = decodedData.charCodeAt(i);
       }
       const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
       zip.file(`invoice_${item.id}.pdf`, pdfBlob);
    }
  }
  

  setmsj("Descargando Zip");
  setTimeout(async() => {
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const outputPath = 'facturas.zip';
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = outputPath;
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
    setload(false);
    setProgr(0);
  }, 2000);
}

  return(
    <>
      <div className='container-zip-comp'>
        {
          load===false?
          <>
          <div className='container-forms-inv'>
          <div className='grid-zip-1'>
            <TextField
              select
              label="Salida de zip"
              value={type}
              defaultValue={""}
              InputLabelProps={{shrink: true}}
              SelectProps={{native: true}}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => settype(event.target.value)}  
            >
              <option key={2} value={"UNIDO"}>{"PDF UNIDO"}</option>
              <option key={3} value={"INDIVIDUAL"}>{"PDF SEPARADOS"}</option>
              <option key={4} value={"XML"}>{"XML SEPARADOS"}</option>
            </TextField>
          </div>
          <div className='grid-zip-2'>
            <button className='button-zip' onClick={()=>handledow()}>Iniciar</button>
          </div>
        </div>
        <p className='num-fac-comp'>Facturas a comprimir: {totalinvoices}</p>
          </>
          :
        <div className='cargando-facturas'>
            <div className='loading-zip'>
              <img src={loadimg} alt=''></img>
              <p className='msj-zip'>{msj}</p>
            </div>
            <div className='load-progress'>
              <div className='progress-bar' style={{ width: `${progr}%` }}></div>
            </div>
        </div>
        }  
      </div>
    </>
  )

}

