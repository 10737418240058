//Paqueterias
import { useState, useEffect, useMemo, useRef } from "react";

//Componentes
import TableContainer from "../../../main_components/Table/TableContainer";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import NotificationSystem from 'react-notification-system';
import { get_price_list_products } from "../../../services/inventory/inventory";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import CREATE_UPDATE_FILE from "./tools/update_document";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import CREATELIST from "../../../imgComponents/create.png";
import {
  Box,
  Button,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { update_null_identifiers } from "../../../main_components/methods_v2/auxiliar_funtion";

//Estilos

const CONSULT_DETAIL_PRICE_LIST_V2 = (props) => {
  const [modalT, setmodalT] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const notificationSystemRef = useRef();

  const handle_detail_warehouse = async () => {
    setData([]);
    setmodalT(true);
    console.log("peticion aa")
    try {
      const isvaild = await refresh_token();
      if (isvaild) {
        setTimeout(async () => {
          try {
            const dt = await get_price_list_products(sessionStorage.getItem("price_list_products"));
            var ndt = update_null_identifiers(dt?.data, update_identifiers);
            setData(ndt);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setmodalT(false);
          }
        }, 1000);
      }
    } catch (err) {
      console.log("entra el error");
      setData([]);
      setmodalT(false);
    }
  };

  const alertas = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };

  useEffect(() => {
    handle_detail_warehouse();
  }, []);

  const handle_reconsult = (result) => {
    console.log(result);
    if (result?.confirm) {
      handle_detail_warehouse();
      alertas(result.msj, result.confirm);

    } else if (result?.confirm === false) {
      alertas(result.msj, result.confirm);
    }
  };
  const columns = useMemo(
    () => [

      {
        header: "Código",
        accessorKey: "code",
        id: "code",
        size: 200

      },
      {
        header: "Producto",
        accessorKey: "product_name",
        id: "product_name",
        size: 400
      },
      {
        header: "Linea",
        accessorKey: "brand",
        id: "brand",
        size: 200

      },
      {
        header: "Presentación",
        accessorKey: "minimum_sales_unit",
        id: "minimum_sales_unit",
        size: 250

      },
      {
        header: "Piezas",
        accessorKey: "pieces",
        id: "pieces",
        size: 200
      },
      {
        header: "Precio",
        accessorKey: "price",
        id: "price",
        size: 200
      },
    ],
    [data]
  );

  const update_identifiers =
    [
      { code: 'string' },
      { product_name: 'string' },
      { brand: 'string' },
      { minimum_sales_unit: 'string' },
      { pieces: 'string' },
      { price: 'string' }
    ];

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  return (
    <div>
      <div>
        <MODAL_TABLE
          open={true}
          message={""}
          modalGeneral={false}
          modalT={modalT}
        ></MODAL_TABLE>
      </div>

      <BREADCRUMBS niveles={
        [
          { label: "LISTA DE PRECIOS", path: "/price_list_v2" },
          { label: "PRODUCTOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        rowSelection={rowSelection}
      ></BREADCRUMBS>

      <div>
        <div className="new_table_v2">
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            onStateChange={handleStateChange}
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowActions
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                Header: () => (
                  <div>
                    <img
                      title="Agregar Producto"
                      src={CREATELIST}
                      className=""
                      onClick={async () => {
                        const result = await CustomDialog(
                          <CREATE_UPDATE_FILE
                            id={sessionStorage.getItem("price_list_products")}
                          ></CREATE_UPDATE_FILE>,
                          {
                            className: "custom-modal",
                            title: "Actualizar lista de precios por archivo",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                        {
                          handle_reconsult(result);
                        }
                      }}
                    ></img>
                  </div>
                ),
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }

            renderTopToolbarCustomActions={({ }) => (
              <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
                <FormControl required sx={{ width: 300 }}>
                  <Button variant="contained"
                    sx={{ height: '35px' }}
                    onClick={() => window.location = "/update_price_list_v2"}
                  >
                    MODIFICAR TODA LA LISTA
                  </Button>
                </FormControl>
                <FormControl required sx={{ width: 300 }}>
                  <Button variant="contained"
                    sx={{ height: '35px' }}
                    onClick={() => window.location = "/add_product_v2"}
                  >
                    AGREGAR PRODUCTOS
                  </Button>
                </FormControl>
              </Box>
            )}

            renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                row={row}
                handleSubmit={handle_detail_warehouse}
                setreconsult={setreconsult}
                refresh_token={refresh_token}
                alertas={alertas}
                data={data}
                handle_reconsult={handle_reconsult}
              />
            ]}

            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Productos de lista de precios"}
                update_identifiers={update_identifiers}
                settotal_rows={settotal_rows}
              />
            )
            }
          />
        </div>
      </div>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

    </div>
  );
};

export default CONSULT_DETAIL_PRICE_LIST_V2;
