import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Delete from "../../../imgComponents/borrar.png";
import edit from "../../../imgComponents/save.png";
import lupa from "../../../imgComponents/lupa.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_price_list } from "../../../services/inventory/inventory";
import CREATE_UPDATE from "./create_update";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const d = await delete_price_list(props?.row?.original?.id);
                   console.log(d);
                   props.alertas("Lista de Precios Eliminada", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.alertas("Error, no se pudo eliminar la Lista de Precios", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar la Lista de Precios?','Eliminar Lista de Precios','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Lista de Precios"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Lista de Precios
            </MenuItem>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await CustomDialog(
                      <CREATE_UPDATE
                        price_list={props?.row.original.name}
                        method={"UPDATE"}
                        id={props?.row.original.id}
                        handleSubmit={props?.handleSubmit}
                      ></CREATE_UPDATE>
                       ,
                    {
                        className: "custom-modal",
                        title: "EDITAR LISTA DE PRECIOS",
                        showCloseIcon: true,
                    });
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={edit}
                        title="Editar Lista de Precios"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Lista de Precios
            </MenuItem>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    props?.setmodalT(true);
                    sessionStorage.setItem("price_list_products", props?.row.original.id);
                    setTimeout(() => {
                        window.location="/price_list_products_v2"; 
                    }, 1000);
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={lupa}
                        title="Productos de Lista de Precios"
                        className=""
                    ></img>
                </ListItemIcon>
                Productos de Lista de Precios
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
