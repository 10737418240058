import {useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { post_warehouse, put_warehouse } from "../../../services/configuration/configuration";

function OPERATIONS_WAREHOUSE(props) {

const dialog = useDialog();
const [bandera, setbandera]=useState(true);
const [codew, setcodew]=useState(props.operation==="EDIT"?props.row.original.code:null);
const [namew, setnamew]=useState(props.operation==="EDIT"?props.row.original.warehouse_name:null);
const [descw, setdescw]=useState(props.operation==="EDIT"?props.row.original.description:null);
const [typew, settypew]=useState(props.operation==="EDIT"?props.row.original.type:"PRODUCTO");
const [allow_display, setallow_display]=useState(props.operation==="EDIT"?props.row.original.allow_display:true);
const [subtype, setsubtype]=useState(props.operation==="EDIT"?props.row.original.subtype:null);

const [vcodew, setvcodew]=useState(false);
const [vnamew, setvnamew]=useState(false);
const [vdescw, setvdescw]=useState(false);


const createneww = async ()=>{
  if(namew===null || codew===null || descw===null){
    props.alertas("Parámetros faltantes");
    namew===null?setvnamew(true):setvnamew(false);
    codew===null?setvcodew(true):setvcodew(false);
    descw===null?setvdescw(true):setvdescw(false);
  }else{

  props.setmodalT(true);
  setbandera(false);
    var data = {
      "warehouse_name": namew, 
      "code": codew,
      "description": descw,
      "type": typew,
      "allow_display": allow_display===true?true:(allow_display==="false"?false:true),
      "subtype": subtype===0?null:subtype
    }
      try {
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const dt = await post_warehouse(data);
              console.log(dt);
              props.alertas("Almacén registrado con éxito", true);
              props.setmodalT(false);
              dialog.close();
              props.handleSubmit(); 
            }catch(err){
              console.log(err);
              catchErrorModul("Almacén", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
              props.alertas("No se pudo registrar el almacén", false);
              props.setmodalT(false);
              setbandera(true);
            }      
          }, 1000);
        }else{}
      } catch (err) {  
        console.log(err);
      };
    }
  } 
  
const updateW = async () => {

props.setmodalT(true);
setbandera(false);
  var data = {
    "warehouse_name": namew, 
    "code": codew,
    "description": descw,
    "type": typew,
    "allow_display": allow_display===true?true:(allow_display==="false"?false:true),
    "subtype": subtype===0?null:subtype 
}

  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const dt = await put_warehouse(props.row.original.id, data);
          console.log(dt);
          props.alertas("Almacén actualizado con éxito", true);
          props.setmodalT(false);
          dialog.close();
          props.handleSubmit();
        }catch(err){
          console.log(err);
          catchErrorModul("Almacén", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          props.alertas("No se pudo actualizar el almacén", false);
          props.setmodalT(false);
          setbandera(true);
        }           
      }, 1000);
    }else{}
  } catch (err) {  
    console.log(err);
  };
}
 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}


return (
    <div style={{"textAlign":"center"}}>
      <div className="addrulesSE">
          <div className="gridwarehouse1">
            <div className="wh1">
            <TextField
              label="Código"
              required
              value={codew}
              disabled={!bandera}
              style={
                vcodew===true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcodew(event.target.value)}  
              InputLabelProps={{ shrink: true }}
              onSelect={()=>setvcodew(false)}
              onBlur={()=>codew===null?setvcodew(true):setvcodew(false)}
            ></TextField>
            </div>
            <div className="wh2">
            <TextField
             label="Nombre Almacén"
             value={namew}
             required
             style={
               vnamew===true?{"border":"2px solid red", "border-radius":"5px"}:{}
             }
             disabled={!bandera}
             sx={{ m: 1.5, width: "100%" }}
             onChange={(event) => setnamew(event.target.value.toUpperCase())}  
             InputLabelProps={{ shrink: true }}
             onSelect={()=>setvnamew(false)}
             onBlur={()=>namew===null?setvnamew(true):setvnamew(false)}
            ></TextField>
            </div>
          </div>
          <div className="gridwarehouse2">
          <div className="wh3">
          <TextField
               label="Descripción"
               required
               value={descw}
               style={
                 vdescw===true?{"border":"2px solid red", "border-radius":"5px"}:{}
               }
               onSelect={()=>setvdescw(false)}
               disabled={!bandera}
               sx={{ m: 1.5, width: "100%" }}
               onChange={(event) => setdescw(event.target.value)}  
               InputLabelProps={{ shrink: true }}
               onBlur={()=>descw===null?setvdescw(true):setvdescw(false)}
            ></TextField>
            </div>
          </div>
          <div className="gridwarehouse3">
            
          <div className="wh4">
          <TextField
               select
               value={allow_display}
               disabled={!bandera}
               label="Permitir Visualización"
               InputLabelProps={{shrink: true,}}
               SelectProps={{native: true,}}
               sx={{ m: 1.5, width: "100%" }}
               onChange={(event) => setallow_display(event.target.value)}  
             >
               <option key={1} value={true} selected>
                   {"SI"}
               </option>
               <option key={2} value={false}>
                   {"NO"}
               </option>
            </TextField>
          </div>
          <div className="wh5">
          <TextField
              select
              label="Tipo"
              value={typew}
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settypew(event.target.value)}  
            >
              <option key={1} value={"PRODUCTO"} selected >
                  {"PRODUCTO"}
              </option>
              <option key={2} value={"CONGELADORES"}>
                  {"CONGELADORES"}
              </option>
            </TextField>
          </div>
          <div className="wh6">
          <TextField
              select
              disabled={!bandera}
              label="Subtipo"
              value={subtype}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setsubtype(event.target.value)}  
            >
              <option key={1} value={0} selected >
                  {"Seleccione"}
              </option>
              <option key={2} value={"REPARTO"}>
                  {"REPARTO"}
              </option>
            </TextField>
          </div>
          </div>
         <br></br>
          <br></br>
          <Box>
            {
              bandera===true?              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={()=>{props.operation==="EDIT"?updateW():createneww()}}
          >
            {props.operation==="EDIT"?"Actualizar Almacén":"Crear Almacén"}
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
    </div>
    </div>
  );
}
export default OPERATIONS_WAREHOUSE;
