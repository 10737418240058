import { useEffect, useState } from "react"
import { get_route_list } from "../../../services/businesspartners/businesspartners";
import { get_employess } from "../../../services/humanResources/humanResources";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_route_users, post_managmnt_route, put_managmnt_route } from "../../../services/configuration/configuration";
import { Autocomplete, InputLabel, MenuItem, Select, TextField } from '@mui/material';
export const OP_ROUTE = (props) => {

  console.log(props);
  const [dataroute, setdataroute] = useState([]);
  const [dataemployes, setdataemployes] = useState([]);
  const [id_route, setid_route] = useState(props?.operation === "EDIT" ? props?.row?.original?.id_route_fk : null);
  const [id_employ, setid_employ] = useState(props?.operation === "EDIT" ? props?.row?.original?.id_supervisor_user_fk : null);
  const dialog = useDialog();

  useEffect(() => {
    rutas();
    employes();
  }, [])

  const rutas = async () => {
    props?.setmodalT(true);
    try {
      const d = await get_route_list();
      setdataroute(d?.data?.parametersList);
      props?.setmodalT(false);
    } catch (err) {
      console.log(err);
      props?.setmodalT(false);
    }
  }
  const employes = async () => {
    props?.setmodalT(true);
    try {
      const d = await get_route_users();
      // const dd = d?.data?.filter(item=>item.job_title === "SUPERVISOR ADMINISTRATIVO");
      // console.log(d?.data?.sales_employess);
      const ddd = order(d?.data?.sales_employess)
      setdataemployes(ddd);
      props?.setmodalT(false);
    } catch (err) {
      console.log(err);
      props?.setmodalT(false);
    }
  }
  function order(employees) {
    if (employees && Array.isArray(employees)) {
      employees.sort((a, b) => {
        const nameA = a.fullusername.toUpperCase(); // Ignorar mayúsculas y minúsculas
        const nameB = b.fullusername.toUpperCase(); // Ignorar mayúsculas y minúsculas
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // Los nombres son iguales
      });
    }
    return employees;
  }


  var dtta = {
    "id_route": id_route,
    "id_supervisor_user": id_employ
  }

  console.log(dtta)

  const update = async () => {
    var datas = {
      "id_route": id_route,
      "id_supervisor_user": id_employ
    }


    if (id_route == null) {
      props.alertas("El campo ruta no puede ir vacio", false)
      return false
    }
    if (id_employ == null) {
      props.alertas("El campo supervisor no puede ir vacío", false)
      return false
    }



    props?.setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await put_managmnt_route(datas, props?.row?.original?.id);
            console.log(d);
            props.alertas("Actualización Exitosa", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          } catch (err) {
            console.log(err);
            props.alertas("Error al actualizar", false);
            props.setmodalT(false);
          }
        }, 1500);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }

  const create = async () => {
    var datas = {
      "id_route": id_route,
      "id_supervisor_user": id_employ
    }

    if (id_route == null) {
      props.alertas("El campo ruta no puede ir vacio", false)
      return false
    }

    if (id_employ == null) {
      props.alertas("El campo supervisor no puede ir vacío", false)
      return false
    }


    try {
      var rf_token = await refresh_token();
      props.setmodalT(true);
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await post_managmnt_route(datas);
            console.log(d);
            props.alertas("Registro exitoso", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          } catch (err) {
            console.log(err);
            props.alertas("No se pudo realizar el registro", false);
            props.setmodalT(false);
          }
        }, 1500);
      } else { }
    } catch (err) {
      console.log(err);
    }

  }

  return (
    <div>


      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>
        <Autocomplete
          id="route-autocomplete"
          options={dataroute} // Tus opciones son las rutas disponibles
          getOptionLabel={(option) => option.route} // Utilizamos el campo `route` para mostrar la etiqueta
          style={{ width: "100%" }} // Ajustamos el estilo como en tu componente original
          value={dataroute.find(item => item.idRoute === id_route) || null} // Encuentra el objeto de ruta basado en el id seleccionado
          onChange={(event, newValue) => {
            setid_route(newValue ? newValue.idRoute : null); // Actualiza el estado con el nuevo id de ruta
          }}

          isOptionEqualToValue={(option, value) => option.idRoute === value.idRoute} // Compara las opciones basado en el id
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ruta"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          )}
        />

      </Box>

      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>
        <Autocomplete
          id="employ-autocomplete-2"
          options={dataemployes} // Tus opciones son los empleados disponibles
          getOptionLabel={(option) => option.fullusername} // Utilizamos el campo `fullusername` para mostrar la etiqueta
          style={{ width: "100%" }} // Ajustamos el estilo como en tu componente original
          value={dataemployes.find(item => item.id === id_employ) || null} // Encuentra el objeto de empleado basado en el ID seleccionado
          onChange={(event, newValue) => {
            setid_employ(newValue ? newValue.id : null);
          }}

          isOptionEqualToValue={(option, value) => option.id === value.id} // Compara las opciones basado en el ID
          renderInput={(params) => (
            <TextField
              {...params}
              label="Supervisor"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          )}
        />
      </Box>

      {/* <div className="cont-mang">
        <div className="contmag1">
          <TextField
            select
            required
            value={id_route}
            label="Ruta"
            InputLabelProps={{ shrink: true, }}
            SelectProps={{ native: true, }}
            sx={{ m: 1.5, width: "95%" }}
            onChange={(event) => setid_route(event.target.value)}
          >
            <option key={0} value={0} selected >
              {"Seleccione Ruta"}
            </option>
            {
              dataroute.map((item) => (
                <option key={item.idRoute} value={item.idRoute}>
                  {item.route}
                </option>
              ))
            }
          </TextField>
        </div>


        <div className="contmag2">
          <TextField
            select
            required
            value={id_employ}
            label="Supervisor"
            InputLabelProps={{ shrink: true, }}
            SelectProps={{ native: true, }}
            sx={{ m: 1.5, width: "95%" }}
            onChange={(event) => setid_employ(event.target.value)}
          >
            <option key={0} value={0} selected >
              {"Seleccione Supervisor"}
            </option>
            {
              dataemployes.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.fullusername}
                </option>
              ))
            }
          </TextField>
        </div>
      </div> */}
      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>
        <Button
          variant="contained"
          style={{ "backgroundColor": "green" }}
          sx={{ width: "50%" }}
          onClick={() => { props?.operation === "EDIT" ? update() : create() }}
        >{props?.operation === "EDIT" ? "ACTUALIZAR" : "REGISTRAR"}
        </Button>
        <Button
          style={{ "backgroundColor": "red" }}
          variant="contained"
          sx={{ width: "50%" }}
          onClick={() => {
            dialog.close();
          }
          }
        >
          cancelar
        </Button>
      </Box>
      <br></br>
    </div >
  )
}
