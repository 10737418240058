// Importaciones de paquetes
import React, { useEffect, useMemo, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";

// Importaciones de componentes
import { refresh_token } from "../../main_components/tokens/tokenrefresh";

import { get_pay_conditions } from "../../services/banck/banck";

import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
// import SEARCH_FILTERS from "./tools/shearch_filter";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { UPDATE_NULL_IDENTIFIERS } from "../../main_components/methods_v2/auxiliary_functions_new_method";
import { RowSelection, PAY_CONDITIONS, RESPONSE } from "./interface/interface";
import FORM_PAY_CONDITIONS from "./tools/form";
//IMG / ICONS

import CREATE from "../../imgComponents/create.png";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
const PAY_CONDITION: React.FC = () => {
  const [data, setData] = useState<PAY_CONDITIONS[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const [modalT, setmodalT] = useState<boolean>(false);
  const [open_form, setopen_form] = useState(false);

  const [currentRowData, setCurrentRowData] = useState(null);
  const [isNewEntry, setIsNewEntry] = useState(false);

  const handleOpenEditDialog = (new_value :any, isNew:boolean) => {
    console.log(new_value)
    setCurrentRowData(new_value);
    setIsNewEntry(isNew);
    setopen_form(true);
  };


  const handleAddNewRow = () => {
    const new_value = {
      term: 0,
      description: 'CONTADO',
    };
    handleOpenEditDialog(new_value, true);
  };
  const handle_close = () => {setopen_form(false);};

  const handleSaveData = async (newData:any, isNew:boolean) => {
    console.log(newData)
    console.log(isNew)

    try {

    } catch (error) {


    } finally {
    }

  };
  // const handleAddNewRow = () => {
  //   const newRow = {
  //     line: '',
  //     price: '',
  //   };

  //   handleOpenEditDialog(newRow, true);
  // };

  const columns: MRT_ColumnDef<PAY_CONDITIONS>[] = useMemo(
    () => [
      {
        id: "description",
        header: "description",
        accessorKey: "description",
        size: 300,
        muiTableHeaderCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "term",
        header: "term",
        accessorKey: "term",
        size: 180,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  const update_identifiers = [
    { id: "string" },
    { description: "string" },
    { term: "String" },
  ];

  const handle_get_pay_conditions = async (
    event?: React.SyntheticEvent
  ): Promise<void> => {
    event?.preventDefault();
    setIsLoading(true);
    setmodalT(true);
    setData([]);
    try {
      const rf_token: boolean = await refresh_token();
      if (rf_token) {
        setTimeout(async () => {
          try {
            const response: RESPONSE = await get_pay_conditions();
            const response_pay_conditions = UPDATE_NULL_IDENTIFIERS(
              response?.data,
              update_identifiers
            ) as PAY_CONDITIONS[];
            setData(response_pay_conditions);
          } catch (err) {
            console.error("Error:", err);
            setData([]);
          } finally {
            setIsLoading(false);
            setmodalT(false);
          }
        }, 1000);
      }
    } catch (err) {
      console.error("Error in refresh token or other process:", err);
    }
  };

  const handle_update_pay_conditions = async (
    event?: React.SyntheticEvent
  ): Promise<void> => {
    event?.preventDefault();
    setIsLoading(true);
    setmodalT(true);
    setData([]);
    try {
      const rf_token: boolean = await refresh_token();
      if (rf_token) {
        setTimeout(async () => {
          try {
          } catch (err) {
            console.error("Error:", err);
            setData([]);
          } finally {
            setIsLoading(false);
            setmodalT(false);
          }
        }, 1000);
      }
    } catch (err) {
      console.error("Error in refresh token or other process:", err);
    }
  };

  useEffect(() => {
    handle_get_pay_conditions();
  }, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }} className="new_table_v2">
      <BREADCRUMBS
        niveles={[{ label: "CONDICIONES DE PAGO", path: null }]}
      ></BREADCRUMBS>
      <FORM_PAY_CONDITIONS
        open={open_form}
        onClose={handle_close}
        data={currentRowData}
        onSave={handleSaveData}
        isNew={isNewEntry}
      ></FORM_PAY_CONDITIONS>
      <MODAL_TABLE
        open={true}
        message={""}
        message2={""}
        modalGeneral={""}
        modalT={modalT}
      ></MODAL_TABLE>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        // enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{
          color: "secondary",
          thickness: 5,
          size: 55,
        }}
        muiSkeletonProps={{ animation: "pulse", height: 28 }}
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "69vh",
            "@media (max-height: 810px)": { maxHeight: "65vh" },
          },
        }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode="pages"
        rowPinningDisplayMode="sticky"
        layoutMode="grid"
        muiTableHeadCellProps={{
          sx: { flex: "0 0 auto", whiteSpace: "normal !important" },
        }}
        muiTableBodyCellProps={{
          sx: { flex: "0 0 auto", whiteSpace: "normal !important" },
        }}
        muiPaginationProps={{
          color: "primary",
          shape: "rounded",
        }}
        displayColumnDefOptions={{
          "mrt-row-pin": {
            enableHiding: true,
            Header: () => <img src={CREATE} onClick={()=> handleAddNewRow()}></img>,
          },
          "mrt-row-actions": {
            enableHiding: true,
            Cell: ({ row }) => {
              // console.log(row);
              return (
                <div>
                  <DeleteIcon className="icons_general_delete"></DeleteIcon>
                  <SaveAsIcon className="icons_general_save"></SaveAsIcon>
                </div>
              );
            },
            size: 110,
          },
          "mrt-row-expand": {
            enableHiding: true,
          },
          "mrt-row-select": {
            enableHiding: true,
          },
        }}
        muiTableBodyRowProps={({ row, table }) => {
          const { density } = table.getState();
          return {
            sx: {
              height: row.getIsPinned()
                ? `${
                    density === "compact"
                      ? 30
                      : density === "comfortable"
                      ? 35
                      : 69
                  }px`
                : undefined,
            },
          };
        }}
        // renderRowActionMenuItems={({ row, closeMenu }) => [
        //   <RENDER_ROW_ACTION_MENU_ITEMS
        //     key={"actionmenu" + row?.original?.id}
        //     closeMenu={closeMenu}
        //     setmodalT={setmodalT}
        //     setmenssage={setmenssage}
        //     setmodalGeneral={setmodalGeneral}
        //     row={row}
        //     handle_get_invoice={handle_get_invoice}
        //     setreconsult={setreconsult}
        //     refresh_token={refresh_token}
        //   />,
        // ]}
        renderTopToolbarCustomActions={({}) => (
          // <SEARCH_FILTERS
          //   fi={fi}
          //   setfi={setfi}
          //   ff={ff}
          //   setff={setff}
          //   handle_search={handle_get_invoice}
          // ></SEARCH_FILTERS>
          <div></div>
        )}
        renderToolbarInternalActions={({ table }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table}
            file_name={"CONDICIONES DE PAGOS"}
            update_identifiers={[]}
          />
        )}
      />
    </div>
  );
};

export default PAY_CONDITION;
