import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import add from "../../../imgComponents/save.png";
import del from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_daily_staff } from "../../../services/reports/reports";
import CREATE_UPDATE from "./create_update";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {


    const delet = async ()=>{

        props?.setmodalT(true);

        try {
            var rf_token = await refresh_token();
            if(rf_token===true){
              setTimeout(async() => {
                try{
                  await delete_daily_staff(props?.row?.original?.id);
                  props?.alertas("Producto eliminado correctamente", true);
                  props?.setmodalT(false);
                  props?.handleSubmit();
                  
                }catch(err){
                  console.log("error", err);
                  props?.alertas("Error, vuelva a intentarlo", false);
                  props?.handleSubmit();
                  props?.setmodalT(false);
                }
              }, 1000);
            }else{}
          } catch (err) {
            console.log(err);
          }
    }



    return (
        <div>
           
            <MenuItem
                key={1}
                onClick={
                    async() =>{
                        props.closeMenu();
                        const result = await Confirm('¿Esta usted seguro de eliminar la asignación?','Eliminar Asignación','Si','No');
                        if(result){
                            delet()
                            }
                        }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={del}
                        title=" Eliminar Asignación"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Asignación
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                      <CREATE_UPDATE
                      subtabla={props.subtabla}
                      id={props?.row.original.id}
                      date={props?.row.original.date}
                      id_route_fk={props?.row.original.id_route_fk}
                      route={props?.row.original.route}
                      id_seller_employee_fk={props?.row.original.id_seller_employee_fk}
                      id_seller_asst_employee_fk={
                        props?.row.original.id_seller_asst_employee_fk
                      }
                      id_dealer_employee_fk={props?.row.original.id_dealer_employee_fk}
                      name_dealer_employee_fk={
                        props?.row.original.name_dealer_employee
                      }
                      name_seller_employee_fk={
                        props?.row.original.name_seller_employee
                      }
                      id_dealer_asst_employee_fk={
                        props?.row.original.id_dealer_asst_employee_fk
                      }
                      method={"UPDATE"}
                      alertas={props?.alertas}
                      handleSubmit={props?.handleSubmit}
                      setmodalT={props?.setmodalT}
                    ></CREATE_UPDATE>,
                        {
                            className: "custom-modal-3",
                            title: "ACTUALIZAR ASIGNACIÓN",
                            showCloseIcon: true,
                        }
                    );
                  } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={add}
                        title="Actualizar Asignación"
                        className=""
                    ></img>
                </ListItemIcon>
                  Actualizar Asignación
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
