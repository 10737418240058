//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef} from "react";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
//COMPONENTES
import AlertResponse from "../../../main_components/alerts/alertResponse";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import '../../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import CREATE from "../../../imgComponents/create.png";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { delete_expense_concept_list, get_expense_concept_list } from "../../../services/expenses/expenses";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import OPERATIONS_ASSIGNED_CONCEPTS from "./tools/op_assigned_concepts";
import { update_null_identifiers } from "../../../main_components/methods_v2/auxiliar_funtion";


const ASSIGNED_CONCEPTS_V2= () => {
  console.log(sessionStorage.getItem("id_catalogo"));
  console.log(sessionStorage.getItem("nombre_catalogo"));

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");


  useEffect(()=>{
    handleSubmit();
  },[]);

  const update_identifiers =
[
  { concept: 'string' },
  { enable: 'string' },
  { account: 'String' },
  { date_register: 'string'}
];


function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}


const handleSubmit = async () => {
    console.log("entra a consultar los conceptos asignados al catalogo");
    setmodalT(true);
    setopen(true);
    setData([]);
    setIsLoading(true);
    try{
      var rf_token = await refresh_token();
      if(rf_token === true){
        setTimeout(async() => {
          try{
            const d = await get_expense_concept_list(sessionStorage.getItem("id_catalogo"));
            var ndt = update_null_identifiers(d?.data?.concepts, update_identifiers);
            setData(ndt===null?[]: ndt);
            setopen(false);
            setmodalT(false);
            setIsLoading(false);
          }catch(err){
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }    
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
};


const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};

const columns = useMemo(
  () => [
    // {
    //   Header:()=>(
    //     <div>
    //       <img
    //         alt=""
    //         id="crearRol"
    //         src={CREATE}
    //         onClick={async () => {
    //           const result = await CustomDialog(               
    //          <div>
    //             <EditConceptC operation={"CREATE"} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></EditConceptC>
    //          </div>,
    //        {
    //          className: "modalTItle",
    //          title:"Agregar Concepto",
    //          showCloseIcon: true,
    //          isCanClose:false,
    //        }
    //    );
    //  }}
    //         title="Agregar Concepto"
    //         className=""
    //       ></img>
    //     </div>
    //   ),
      
    //   id: "Acciones",
    //   Cell: ({ valor, row }) => {

    //   const DeleteSector = async()=>{  
      
    //   setmodalT(true);
    //   refresh_token ();
    //   setData([]);
    //   setopen(true);
    
    //   try{
    //     var rf_token = await refresh_token();
    //     if(rf_token===true){
    //       setTimeout(async() => {
    //         try{
    //           const d = await delete_expense_concept_list(sessionStorage.getItem("id_catalogo"), row.original.id);
    //           console.log(d);
    //           alertas("Concepto Eliminado con éxito",true);
    //           handleSubmit();
    //         }catch(err){
    //           console.log("error", err);
    //           setopen(false);
    //           alertas("Error, no se pudo eliminar el concepto", false);
    //           handleSubmit();
    //         }
    //       }, 1000);
    //     }else{}
    //   }catch(err){
    //       console.log(err);
    //   }
    //   }
    //     return (
    //       <div>
    //         <img
    //           alt=""
    //           src={Delete}
    //           onClick={async() =>{
    //               const result = await Confirm('¿Esta usted seguro de eliminar el concepto?','Eliminar Concepto','Si','No');
    //               if(result){
    //                 DeleteSector()
    //                 }
    //               }            
    //             }
              
    //           title="Eliminar Concepto"
    //         ></img>
            
    //         <img
    //           alt=""
    //           src={Edit}
    //           onClick={
    //             async () => {
    //               const result = await CustomDialog(               
    //              <div>
    //                 <EditConceptC operation={"EDIT"} handleSubmit={handleSubmit} row={row} reconsultar={reconsultar} alertas={alertas}></EditConceptC>
    //              </div>,
    //            {
    //              className: "modalTItle",
    //              title:"Editar Concepto",
    //              showCloseIcon: true,
    //              isCanClose:false,
    //            }
    //        ); 
    //      }
    //     }
              
    //           title="Editar Concepto"
    //         ></img>
    //       </div>
    //     );
    //   },
    // },
    {
      id: "concept",
      header: "Concepto",
      accessorKey:"concept",
      size: 300,
    },
    {
      //Filter: SelectColumnFilter,
      id: "enable",
      header:"Estado",
      accessorKey: "enable",
      accessorFn:(d)=>{
        return d.enable==true?"Habilitado":"Deshabilitado"
      }
    },
    {
      id: "account",
      header: "Cuenta",
      accessorKey: "account",
      size: 200
    },
    {
      id: "date_register",
      header:"Fecha de Registro",
      accessorKey:"date_register",
      size: 300
    }
    
    ],
    []
);
  return (
    <>
        <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR CATALOGO DE CONCEPTOS", path: "/catalog_expense_concept_v2"},
                  {label: `CATALOGO: ${sessionStorage.getItem("nombre_catalogo")}`, path:null }
                ]
              }
          cards_dashboard={true}
          total_rows={total_rows !== "" ? total_rows : 0}
          filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
          visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
          total={data?.length}  
      ></BREADCRUMBS>
<div className="new_table_v2">
<NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
<MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
  <div >         
    
  <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        onStateChange={handleStateChange}
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img
                title="Asignar Concepto"
                src={CREATE}
                style={{"cursor":"pointer"}}
                className=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <OPERATIONS_ASSIGNED_CONCEPTS
                            operation={"CREATE"} 
                            handleSubmit={handleSubmit} 
                            alertas={alertas} 
                            setmodalT={setmodalT}
                        />,
                    {
                      className: "custom-modal-3",
                      title: "Asignar Concepto",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                }}
              ></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
         renderRowActionMenuItems={({ row, closeMenu }) => [
             <RENDER_ROW_ACTION_MENU_ITEMS
               closeMenu={closeMenu}
               setmodalT={setmodalT}
               setmenssage={setmenssage}
               setmodalGeneral={setmodalGeneral}
               row={row}
               handleSubmit={handleSubmit}
               setreconsult={setreconsult}
               refresh_token={refresh_token}
               alertas={alertas}
             />
          ]}
        
        renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              file_name={"Consultar Marcas"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
        }
      />
           
      
          </div>
        </div>
    </>
  );
}

export default ASSIGNED_CONCEPTS_V2;
