import { useEffect, useMemo, useRef, useState } from 'react';
import {
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import { CustomDialog, Confirm } from "react-st-modal";
import DELETE from "../../../../../imgComponents/borrar.png";
import VISUALIZAR from "../../../../../imgComponents/lupa.png";
import { refresh_token } from '../../../../../main_components/tokens/tokenrefresh';
import { delete_expense_group, delete_expenses_groups_detail } from '../../../../../services/expenses/expenses';

import SAVE from "../../../../../imgComponents/save.png";
import Spinner from "../../../../../imgComponents/S.gif";
import CREATE from "../../../../../imgComponents/create.png";
import ADD from "../../../../../imgComponents/add.png";
import UPDATE_POLICY from './update_policy';



const RENDER_ROW_ACTION_MENU_ITEMS_V2 = (props) => {

  const handle_delete_expenses_groups_detail = async (id) => {
      
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        props?.setmodalT(true);
        try {
          const d = await delete_expenses_groups_detail(
            sessionStorage.getItem("IdGrupExpense"),
            id
          );
          console.log(d)
          props?.setmodalT(false);
          props?.handleSubmit();
        } catch (err) {
          props?.setmodalT(false);
          alert("Error.," + err?.response?.data?.errorMessage);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };


    return (
        <div>
            <MenuItem
                key={"keymenu1"}
                sx={{ m: 0 }}
                onClick={async () => {
                   props.closeMenu();
                   const result = await CustomDialog(
                    <UPDATE_POLICY
                      codCocept={props?.row.original.concept}
                      codType={props?.row.original.type}
                      codId={props?.row.original.id}
                      codAction={"INJECTION"}
                      handleSubmit={props?.handleSubmit}

                    ></UPDATE_POLICY>,
                    {
                      className: "modalTItle",
                      title: "Inyectar poliza",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  )
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={ADD}
                        className="mapa_icono"
                        title="Inyectar Poliza"
                    ></img>
                </ListItemIcon>
                Inyectar Poliza
            </MenuItem>
            <MenuItem
                key={"keymenu1"}
                sx={{ m: 0 }}
                onClick={async () => {
                   props.closeMenu();
                   const result = await CustomDialog(
                    <UPDATE_POLICY
                      codCocept={props?.row.original.concept}
                      codType={props?.row.original.type}
                      codId={props?.row.original.id}
                      date={props?.row.original.export_date}
                      codAction={"UPDATE"}
                      handleSubmit={props?.handleSubmit}
                      
                    ></UPDATE_POLICY>,
                    {
                      className: "modalTItle",
                      title: "Editar poliza",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={SAVE}
                        className="mapa_icono"
                        title="Editar Poliza"
                    ></img>
                </ListItemIcon>
                Editar Poliza
            </MenuItem>
            <MenuItem
                key={"keymenu1"}
                sx={{ m: 0 }}
                onClick={async () => {
                   props.closeMenu();
                   const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta poliza?",
                    "Eliminar Poliza",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_expenses_groups_detail(props?.row.original.id);
                  } else {};
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={DELETE}
                        className="mapa_icono"
                        title="Eliminar Poliza"
                    ></img>
                </ListItemIcon>
                Eliminar Poliza
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS_V2;
