import React, { useEffect, useState } from 'react';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog } from 'react-st-modal';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { get_pointsofsale_list } from '../../../services/businesspartners/businesspartners';
import { get_employess } from '../../../services/humanResources/humanResources';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { get_cabinets_warehouse, get_status_cabinets, post_cabinets_movement } from '../../../services/assets/assets';
import { catchErrorModul } from '../../../main_components/catchErr/catchError';

export const MovementsFrezzer = (props) => {

  
  const dialog = useDialog();

  console.log(props);

  const [datawarehouse, setdatawarehouse]=useState([]);
  const [datastatus, setdatastatus]=useState([]);
  const [datastatusf, setdatastatusf]=useState([]);

  const [pointsale, setpointsale] = useState([]);
  const [dataemployes, setdataemployes] = useState([]);

  const [bandera, setbandera]=useState(false);
  const [note, setnote]=useState("");
  const [id_warehouse, setid_warehouse]=useState(props.movement==="almacen"?props.row.original.id_warehouse_fk:null);
  const [id_employes, setid_employes]=useState(props.movement==="cliente"?props.row.original.id_employees_fk:null);
  const [id_pos, setid_pos]=useState(props.movement==="puntoventa"?props.row.original.id_point_of_sale_fk:null);
  const [id_cabinets_state, setid_cabinets_state]=useState(props.movement==="puntoventa"?2:props.row.original.id_cabinets_state_fk);


useEffect(() => {
  handlestatus();
  props.movement==="almacen"?handlewarehouse():
    props.movement==="cliente"?handleemployess():
      props.movement==="puntoventa"?handlepointssale():
        console.log(props.movement==="almacen");
}, [])

useEffect(() => { 
  analisis(datastatusf);
}, [id_warehouse])






const analisis = (dt)=>{
  if(id_warehouse === "8" || id_warehouse === 8){
      var d = dt.filter(item=>item.id===3);
      setdatastatus(d);
  }else if(id_warehouse === "9" || id_warehouse === 9){
      var d = dt.filter(item=>item.id===1);
      setdatastatus(d);
  }else if(id_warehouse === "11" || id_warehouse === 11){
      var d = dt.filter(item=>item.id===5);
      var e = dt.filter(item=>item.id==12);
      const array = [...d, ...e];
      setdatastatus(array);
  }else if(id_warehouse === "12" || id_warehouse === 12){
      var d = dt.filter(item=>item.id===16);
      setdatastatus(d);
  }else if(id_warehouse === "13" || id_warehouse===13){
      var d = dt.filter(item=>item.id===11);
      setdatastatus(d);
  }else if(id_warehouse === "16" || id_warehouse === 16){
      var d = dt.filter(item=>item.id===3);
      setdatastatus(d);
  }else if(id_warehouse === "17" || id_warehouse === 17){
      setdatastatus([]);
  }else if(id_warehouse === "46" || id_warehouse === 46){
      var d = dt.filter(item=>item.id===1)
      setdatastatus(d);
  } 
}




const handlewarehouse = async () => {
  try {  
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_cabinets_warehouse();
          setdatawarehouse(d === null ? [] : d.data.warehouses);
        }catch(err){
          setdatawarehouse([]);
        }
       
      }, 1000);
    }else{}
  } catch (error) {
    console.error(error);
    
  } 
}
  
const handlepointssale=async()=>{
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_pointsofsale_list();
          setpointsale(d === null ? [] : d.data); 
        }catch(err){
          setpointsale([]);
        }
                 
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}

const handleemployess = async() => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_employess();
          console.log(d);
          setdataemployes(d === null ? [] : d.data);  
        }catch(err){
          setdataemployes([]);
        }       
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}

const handlestatus = async () => {
  try {  
    props.reconsultar(true);
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_status_cabinets();
          setdatastatusf( d === null ? [] : d.data.cabinet_status);
          props.reconsultar(false);
          if(props.movement==="almacen"){
            analisis(d === null ? [] : d?.data?.cabinet_status);
          }else if(props.movement==="cliente"){
            const dd = d?.data?.cabinet_status.filter(item=>item.id === 1);
            setdatastatus(dd);
            
          }else{
            setdatastatus(d === null ? [] : d?.data?.cabinet_status);
          }
        }catch(err){
          setdatastatus([]);
          props.reconsultar(false);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.error(error);
    
  }
}


const createmovement = async () => {


 if(props.movement==="almacen"){
  if(id_warehouse===null){
    props.alertas("Debe seleccionar un almacén");
    return null;
  }
 }
 if(props.movement==="cliente"){
  if(props.row.original.id_employees_fk===id_employes){
    props.alertas("No puede tener el mismo empleado");
    return null;
  }
  if(id_employes===null){
    props.alertas("Debe seleccionar un empleado");
    return null;
  }
 }
 if(props.movement==="puntoventa"){
  if(props.row.original.id_point_of_sale_fk===id_pos){
    props.alertas("No puede tener el mismo Punto de Venta");
    return null;
  }
  if(id_pos===null){
    props.alertas("Debe seleccionar un punto de venta");
    return null;
  }
 }

 props.reconsultar(true);
 setbandera(true);
  var data = {
    "movement":{
      "id_warehouse": parseInt(id_warehouse), //ID del almacen a donde se desea mover el congelador,
      "id_point_of_sale": parseInt(id_pos),//ID del punto de venta a donde se desea asignar el congelador
      "id_employee": parseInt(id_employes)//ID del empleado quien trasportará el congelador
    },
    "note": note, //String = Nota del usuario que realiza el movimiento
    "status": parseInt(id_cabinets_state)    //int  = ID del status del congelador
   }
   try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{

        var d = await post_cabinets_movement(props.id, data);
        console.log(d);
        props.alertas("Movimiento realizado con éxito", true);
        props.reconsultar(false);
        dialog.close();
        props.handleSubmit();
      }catch(err){
        catchErrorModul("Congeladores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
        props.alertas("No se pudo realizar el movimiento", false);
        props.reconsultar(false);
        setbandera(false);
       }
      }, 1000);
    }else{}
   }catch(err){
    catchErrorModul("Congeladores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
    props.alertas("No se pudo realizar el movimiento", false);
    props.reconsultar(false);
    setbandera(false);
   }
}

const handleOnSearch = () => {
  setid_pos(props.row.original.id_point_of_sale_fk);
};
const handleOnHover = () => {};
const handleOnSelect5 = (item) => {
    setid_pos(item.idPointSale);
};
const handleOnFocus5 = () => {
  console.log("Focused");
};
const handleOnClear = () => {
  console.log("Cleared");
  setid_pos(props.row.original.id_point_of_sale_fk);
};
  return (
    <div style={{textAlign:"center"}}>  
        <div className='grid-mov-almacen'>
            <div className="mov-almac-1">
          {
            props.movement==="puntoventa"?
           <div className='puntoventafrezz'>
              <ReactSearchAutocomplete
                items={pointsale?.parametersList}
                fuseOptions={{ keys: ["code","name"]}}
                resultStringKeyName="codeAndName"
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect5}
                onFocus={handleOnFocus5}
                onClear={handleOnClear}
                styling={{
                  height: "60px",
                  border: "1px solid gray",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  zIndex: 10,
                }}
                showIcon={true}
                placeholder={props.row.original.pos_name===null?"Buscar Punto de Venta":props.row.original.pos_name}    
              />
          </div>
            :
          <TextField
                  select
                  label={
                    props.movement==="almacen"?"Almacén":
                      props.movement==="cliente"?"Empleado":""
                  }
                  value={
                    props.movement==="almacen"?id_warehouse:props.movement==="cliente"?id_employes:""
                  }
                  disabled={bandera}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={
                    (event) => 
                      props.movement==="almacen"?setid_warehouse(event.target.value):
                        props.movement==="cliente"?setid_employes(event.target.value):null
                  }  
                 >
                
                {
                  props.movement==="almacen"?<option key={0} value={0}>{"Selecciona Almacén"}</option>:
                    props.movement==="cliente"?<option key={0} value={0}>{"Selecciona Empleado"}</option>:
                  <></>
                }
                {
                   props.movement==="almacen"?
                     datawarehouse.map(item=>(
                         <option key={item.id} value={item.id}>
                         {item.warehouse_name}
                         </option>
                     )):
                   props.movement==="cliente"?
                   dataemployes.map(item=>(
                     <option key={item.id} value={item.id}>
                     {item.name+" "+item.first_last_name+" "+item.second_last_name}
                     </option>
                    )):
                  <></>
                }
                </TextField>
          }
          </div>
            <div className="mov-almac-2">
                <TextField
                  select
                  label="Estado Congelador"
                  disabled={props.movement==="puntoventa"?true:bandera}
                  defaultValue={""}
                  value={id_cabinets_state}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_cabinets_state(event.target.value)}  
                 >
                {
                  props.movement==="puntoventa"?
                  <option key={2} value={2}>
                      {"Cliente"}
                  </option>:
                  <option key={0} value={0}>
                  {"Selecciona Estado Congelador"}
                  </option>
                }
                {
                  datastatus.map(item=>(
                    <option key={item.id} value={item.id}>
                    {item.description}
                    </option>
                  ))
                }
                </TextField>
          </div>
          <div className="mov-almac-3">
            <TextField
              label="Nota"
              disabled={bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={note}
              onChange={(event) => setnote(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
        </div>

        <Box>      
          <Button
            variant="contained"
            style={{"background-color":"green", "color":"white"}}
            sx={{ m: 1.5, width: "25ch" }}
             onClick={createmovement}
          >
            GUARDAR
          </Button>
            <Button
              style={{"background-color":"red", "color":"white"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
    </div>
  )
}
