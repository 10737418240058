//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
//COMPONENTES
import Set_tax from "./Set_Taxes";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import "./TableTax.css";
import "../../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import CREATE from "../../../imgComponents/create.png";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { get_taxes } from "../../../services/invoices/invoice";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../../main_components/alerts/alertResponse";
import SubTable from "./Get_Configure_Tax";
import SubTable2 from "../../../componentsNewDashboard/sales/Get_datail_Sales_v2";
import SubTable_v2 from "./Get_Configure_Tax_V2";
import { update_null_identifiers } from "../../../main_components/methods_v2/auxiliar_funtion";

const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const TableTaxes_V2 = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);  
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [modalT, setmodalT] = useState(false);
  const notificationSystemRef = useRef();

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  function alertas(msj,status){

    if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br', 
        autoDismiss: 60, 
      });
    }  
  }

  const handleSubmit = async(event) => {
    event?.preventDefault();
    setOpen(true);
    setmodalT(true);
    setMenssage("Consultando impuestos...");
    setIsLoading(true);

    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_taxes();
            const data = d === null ? [] : d.data;
            var ndt = update_null_identifiers(data, update_identifiers);
            setData(ndt);
            setOpen(false);
            setIsLoading(false);
            setmodalT(false); 
          }catch(err){
            console.log("error", err);
            setData([]);
            setIsLoading(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  

  useEffect(() => {
    handleSubmit();
  }, []);


  const columns = useMemo(
    () => [
      {
        header: "Código",
        accessorKey: "c_impuesto",
        size: 300
      },
      {
        header: "Impuesto",
        accessorKey: "tax_name",
        size: 300
      },
      {
        header: "Traslado",
        accessorKey: "transfer",
        accessorFn: (d) => {
          return d.transfer===true?"Si":"NO" 
        },
        size: 300
      },
      {
        header: "Retención",
        accessorKey: "retention",
        accessorFn: (d) => {
          return d.retention===true?"Si":"NO" 
        },
        size: 300
      }
    ],
    []
  );

  

  const update_identifiers =
    [
      { c_impuesto: 'string' },
      { tax_name: 'string' },
      { transfer: 'String' },
      { retention: 'string' },
    ];


  const [rec, setrec]=useState(false);

  return (
    <div >
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
      <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR IMPUESTOS", path: null},
                ]
              }
       cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length} 
      ></BREADCRUMBS>
      <div>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
        </div>

      <div className="new_table_v2" style={{"width":"95%", "margin":"0 auto"}}>

      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        onStateChange={handleStateChange}
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img
                title="Crear Impuesto"
                src={CREATE}
                className=""
                onClick={async () => {
                  const result = await CustomDialog(
                      <Set_tax handleSubmit={handleSubmit} setmodalT={setmodalT} alertas={alertas}></Set_tax>,
                    {
                      className: "modalTItle",
                      title: "Seleccione la configuracion del impuesto",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                }}
              ></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderDetailPanel={({ row }) => {
          return(
            <SubTable_v2 
              id={row?.original?.id}
              setmodalT={setmodalT}
              alertas={alertas}
              rec={rec}
              setrec={setrec}
            />  
          )
        }}

        renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              row={row}
              handleSubmit={handleSubmit}
              alertas={alertas}
              setrec={setrec}
           />
          ]}
        renderToolbarInternalActions={({ table }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table} 
            file_name={"Consultar Impuestos"}
            update_identifiers={update_identifiers}
          />
         )
         }
      />
          

        {/* <div >
          <TableContainer
            columns={columns}
            data={data}
            nametable={"Tabla Impuestos"}
            exportar={true}
            style={{ overflowx: 10 }}
            renderRowSubComponent={row => {
              return (
               <div style={{ padding: "20px" }}>
                  <SubTable/>
               </div>
              );
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default TableTaxes_V2;
