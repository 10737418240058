import React, { useEffect, useState, useMemo, useRef} from "react";
import {  useDialog } from "react-st-modal";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_products, post_promotion_details } from "../../../services/businesspartners/businesspartners";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { Box, Button } from "@mui/material";

export const ADDPRODUCTPROMOTIONV2 = (props) => {
  
  const dialog = useDialog();
  const[client,setclient] = useState(null);
  const [products, setproducts] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");
  const [modalT, setmodalT] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [editedUsers, setEditedUsers] = useState([]);
  const [editedUsers2, setEditedUsers2] = useState([]);


    useEffect(() => {
        handleproducts();
    }, [])
    
const handleproducts = async ()=>{
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_products();
            setproducts(d === null ? [] : d.data.productList);
          }catch(err){
            console.log(err);
          }      
        }, 1000);
      }else{}
    } catch (error) {
      console.log(error);
    } 
  }

  const addpromotion =async ()=>{

   
      if(rowSelection?.length === 0){
        props?.alertas("No ha seleccionado ningún producto", false);
        return null;
      }else{
  
        const updatedUsers = { ...editedUsers };
  
        for (const key in editedUsers2) {
          if (editedUsers2.hasOwnProperty(key)) {
            if (updatedUsers.hasOwnProperty(key)) {
              updatedUsers[key] = {
                ...updatedUsers[key],
                quantity: editedUsers2[key].quantity
              };
            }
          }
        }  
        const join_object = products?.map((originalItem) => {
          const editedItem = Object?.values(updatedUsers)?.find((editedObj) => editedObj?.id === originalItem?.id);
          if (editedItem) {
            return { ...originalItem, ...editedItem };
          } else {
            return originalItem;
          }
        });
        
        const arregloFiltrado = join_object?.filter(elemento => rowSelection[elemento.id]);
        const vacios = arregloFiltrado?.map(item => {
          if(item?.quantity && item?.cost){
            if (item?.quantity === "" || item?.quantity === null || item?.quantity.trim() === "" || item?.quantity <= 0 ||
            item?.cost <= 0 || item?.cost === undefined || item?.cost === "" ) {
              return item;
            } else {
              return null; 
            }
          }else{
            return item;
          }
        }).filter(item => item !== null);
        
        if(vacios.length > 0 ){
          props?.alertas("Falta asignar valores en algunos productos", false);
          return null;
        }else{
            filtrar(props?.row?.original?.id, arregloFiltrado);  
       } 
      }
  }

  const filtrar = async (id, arreglo) => {
 
    var arregloactualizado = arreglo.map(item=>{
     const d = {
       promoPrice: Number(item?.cost),
       productName: item.product_name,
       productCode: item.code,
       quantity: Number(item?.quantity)
     }
     return d;
    })
    var data = {
      details: arregloactualizado
    } 
     try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            props?.setmodalT(true   );
            const dt = await post_promotion_details(id, data);
            console.log(dt);
            props?.alertas("Promoción creada con éxito", true);
            props?.setmodalT(false);
            dialog?.close();     
            props?.handlesubmit();
          }catch(err){
            console.log("error post", err);
            // catchErrorModul("Promociones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            props.alertas("Error al agregar detalles a una promoción", false);
            props.setmodalT(false);
          }    
        }, 1000);
      }else{}
      } catch (err) {}
 }


const columns = useMemo(
    () => [  
       {
          header: "Código",
          accessorKey: "code",
          enableEditing: false,
        },
        {
         header: "Nombre",
         accessorKey: "product_name",
         enableEditing: false,
       },
       {
        header: "Precio",
        accessorKey: "cost",
        id: "cost",
        enableFilters: false,//deshabilita el filtrado
        enableRowOrdering: false,//deshabilita el orden de la columna
        enableGrouping: false,//deshabilita el grupo de la columna
        enableColumnDragging: false,//deshabilita el movimiento de la columna
        enableColumnActions: false,//deshabilita el las acciones
        muiEditTextFieldProps: ({ cell, row }) => ({ //permite la edición a nivel de input
          type: 'text',
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onChange: (event) => {
            const updatedValue = event.target.value;
            setEditedUsers(prev => ({
              ...prev,
              [row.id]: {
                ...row.original,
                "cost": updatedValue,
              }
            }));
          }
        }),
      },
      {
        header: "Cantidad",
        accessorKey: "quantity",
        id: "quantity",
        enableFilters: false,//deshabilita el filtrado
        enableRowOrdering: false,//deshabilita el orden de la columna
        enableGrouping: false,//deshabilita el grupo de la columna
        enableColumnDragging: false,//deshabilita el movimiento de la columna
        enableColumnActions: false,//deshabilita el las acciones
        muiEditTextFieldProps: ({ cell, row }) => ({ //permite la edición a nivel de input
          type: 'text',
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onChange: (event) => {
            const updatedValue = event.target.value;
            setEditedUsers2(prev => ({
              ...prev,
              [row.id]: {
                ...row.original,
                "quantity": updatedValue,
              }
            }));
          }
        }),
      }
    ],[]
  )
  

  return (
    <div style={{"textAlign":"center"}}>
        <div className="new_table_v2" style={{"width":"92%", margin: "0 auto"}}>      
            <MaterialReactTable
                columns={columns}
                data={products}
                enableGrouping
                enablePinning
                enableFacetedValues
                enableStickyHeader
                // onStateChange={handleStateChange}
                enableStickyFooter
                enableRowPinning
                enableColumnFilterModes
                editDisplayMode={'table'}
                enableEditing
                enableRowSelection
                getRowId={(row) => row?.id}
                onRowSelectionChange={setRowSelection}
                enableTooltips={false}
                localization={MRT_Localization_ES}
                state={{ rowSelection, isLoading: modalT }}
                enableColumnResizing
                enableColumnPinning
                enableColumnOrdering
                enableExpandAll={false}
                initialState={{
                    showGlobalFilter: true,
                    columnOrder: [
                    'code',
                    'product_name',
                    'cost',
                    'quantity',
                    'mrt-row-select'
                    ],
                }}
                muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                rowVirtualizerInstanceRef
                rowVirtualizerOptions={{ overscan: 5 }}
                columnVirtualizerOptions={{ overscan: 2 }}
                muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                positionToolbarAlertBanner="bottom"
                paginationDisplayMode='pages'
                rowPinningDisplayMode='sticky'
                layoutMode="grid"
                        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

                muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                displayColumnDefOptions={{
                    'mrt-row-pin': {
                    enableHiding: true,
                    },
                    'mrt-row-actions': {
                    enableHiding: true,
                    },
                    'mrt-row-expand': {
                    enableHiding: true,
                    },
                    'mrt-row-select': {
                    enableHiding: true,

                    }
                }}
                muiTableBodyRowDragHandleProps={({ table }) => ({
                    onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    if (hoveredRow && draggingRow) {
                        products.splice(
                        hoveredRow.index,
                        0,
                        products.splice(draggingRow.index, 1)[0],
                        );
                        setproducts([...products]);
                    }
                    },
                })}
                muiTableBodyRowProps={
                    ({ row, table }) => {
                    settotal_rows(table);
                    const { density } = table.getState();
                    return {
                        sx: {
                        height: row.getIsPinned()
                            ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                            }px`
                            : undefined,
                        },
                    };
                    }
                }
            />
        </div>
        <Box>
            <Button
              style={{"backgroundColor":"green"}}
              variant="contained"
              sx={{ m: 1.5, width: "30ch" }}
              onClick={addpromotion}
            >
              Agregar
            </Button>
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
              cancelar
            </Button>
          </Box>  
    </div>
  )
}
