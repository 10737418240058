import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/lupa.png";
import { CustomDialog } from "react-st-modal";
import { DETAILS_SALES_C } from './Details';

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <DETAILS_SALES_C
                            row={props?.row} 
                            alertas={props?.alertas} 
                            setmodalT={props.setmodalT}
                        />,
                    {
                        className: "custom-modal-3",
                        title: "Detalles",
                        showCloseIcon: true,
                        isCanClose: false,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Detalles"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
