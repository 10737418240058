import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { CustomDialog } from 'react-st-modal';
import AlertResponse from "../../main_components/alerts/alertResponse";
import CREATE from "../../imgComponents/create.png";
import WARNING from '../../main_components/alerts/warning';
import { useEffect, useMemo, useRef, useState } from 'react';
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { get_payment_accounts } from '../../services/banck/banck';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import { OP_PAYACC } from './tools/op_payacc';
import "./payment_accounts.css";
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import NotificationSystem from 'react-notification-system';
import { BREADCRUMBS } from '../../main_components/pagination/breadcrumbs';
import { update_null_identifiers } from '../../main_components/methods_v2/auxiliar_funtion';

export const PAYMENT_ACCOUNTS = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");

  useEffect(() => {
    handleSubmit();
  }, [])


  const handleSubmit = async () => {
    try {

      setmodalT(true);
      setIsLoading(true);
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_payment_accounts();
            var ndt = update_null_identifiers(d?.data, update_identifiers);
            setData(ndt);
            setIsLoading(false);
            setmodalT(false);
          } catch (err) {
            console.log(err);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1500);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }


  const columns = useMemo(
    () => [
      {
        id: "payment_method",
        header: "Nombre Cuenta de Pago",
        accessorKey: "payment_method",
        muiTableBodyCellProps: {
          align: "left"
        },
        size: 250
      },
      {
        id: "digits",
        header: "Ultimos 4 Digitos de Cuenta",
        accessorKey: "digits",
        muiTableBodyCellProps: {
          align: "center"
        },
        size: 250

      },
      {
        id: "payment_account",
        header: "Número Cuenta Contable",
        accessorKey: "payment_account",
        muiTableBodyCellProps: {
          align: "center"
        },
        size: 210
      },
      {
        id: "user_name",
        header: "Usuario que se Atribuye la Cuenta de Pago",
        accessorKey: "user_name",
        muiTableBodyCellProps: {
          align: "left"
        },
        size: 300
      },
      {
        id: "date_register",
        header: "Fecha de Registro",
        accessorKey: "date_register",
        muiTableBodyCellProps: {
          align: "left"
        },
        size: 250
      }

    ],
    []
  );
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  function alertas(msj, status) {

    if (status === "warning") {
      notificationSystemRef.current.addNotification({
        message: <WARNING msj={msj} view={true}></WARNING>,
        level: "success",
        position: "br",
        autoDismiss: 30,
      });
    } else if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const update_identifiers =
    [
      { payment_method: 'string' },
      { digits: 'string' },
      { payment_account: 'String' },
      { user_name: 'String' },
      { date_register: 'String' }
    ];

  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "CUENTAS DE PAGO", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <div className='new_table_v2'>
        <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
        <MODAL_TABLE
          open={true}
          message={""}
          modalGeneral={false}
          modalT={modalT}
        ></MODAL_TABLE>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          onStateChange={handleStateChange}
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    style={{ cursor: "pointer" }}
                    title="Registrar"
                    src={CREATE}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <OP_PAYACC
                          alertas={alertas}
                          setmodalT={setmodalT}
                          handleSubmit={handleSubmit}
                          operation={"CREATE"}

                        ></OP_PAYACC>,
                        {
                          className: "custom-modal-3",
                          title: "Registrar",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              size: 80,
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              row={row}
              handleSubmit={handleSubmit}
              refresh_token={refresh_token}
              alertas={alertas}

            />
          ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Cuentas de Pago"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>
    </>
  )
}
