import React from 'react'

export const BOTSMIT = (props) => {

const leng = (e)=>{
    if(e.target.value==="exit"){
        props.setbot(false);
    }
}

    return (
        <div>cOMO PUEDO AYUDARTE
            <input placeholder='escribe aqui tu ptegunta' onChange={(e)=>leng(e)}></input>
        </div>
    )
}
