import { CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { DESCRIPTION_CASE } from "../consultCases/descriptionCases/Description";
import DescriptionIcon from '@mui/icons-material/Description';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SupportTicketModal from "../comments/Comments";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <SupportTicketModal
                            key={1}
                            handleLoad={props?.handleLoad}
                            alertas={props?.alertas}
                            {...props?.row?.original}
                        />,
                    {
                        className: "custom-modal-2",
                        title: `Ticket: ${props?.row?.original?.ticket}`,
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <QuestionAnswerIcon
                        title="VER SEGUIMIENTO"
                    />
                </ListItemIcon>
                VER SEGUIMIENTO
            </MenuItem>,
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <DESCRIPTION_CASE
                            user_name={props?.row?.original?.user_name}
                            subject={props?.row?.original?.subject}
                            description={props?.row?.original?.description}
                        />,
                    {
                        className: "custom-modal",
                        title: `DESCRIPCIÓN DEL CASO: ${props?.row?.original?.ticket}`,
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <DescriptionIcon
                        title="DESCRIPCIÓN DEL CASO"
                    >
                    </DescriptionIcon>
                </ListItemIcon>
                DESCRIPCIÓN DEL CASO
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
