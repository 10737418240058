import { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import { CustomDialog, Confirm } from "react-st-modal";
import EMAIL from "../../../imgComponents/correoElectronico.png";
import ZIP from "../../../imgComponents/zip.png"
import { dowload_packages, send_packages } from '../../../services/unilever/unilever';
import { base64toBlobzip } from '../../../main_components/donwloadfuncion/download';
const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const handle_seend = async (id) => {

        props.setmodalT(true);
        try {
            await send_packages(id);
            props.setmodalGeneral(false);
            props.setmodalT(false);
            props.handle_get_invoice();
            alert("Paquete enviado correctamente")
        } catch (err) {
            props.setmodalGeneral(false);
            props.setmodalT(false);
        }

    };


    const handle_download = async (id, name) => {
        props.setmodalT(true);
        try {
            var rf_token = await props.refresh_token();
            if (rf_token === true) {
                setTimeout(async () => {
                    try {
                        var dta = await dowload_packages(id);
                        var dt = dta.data.file;
                        var blob = base64toBlobzip(dt);
                        const blobUrl = URL.createObjectURL(blob);
                        const downloadLink = document.createElement("a");
                        downloadLink.href = blobUrl;
                        downloadLink.download = name;
                        downloadLink.click();
                        props.setmodalT(false);
                    } catch (err) {
                    }
                    finally {
                        props.setmodalGeneral(false);
                        props.setmodalT(false);
                    }

                }, 1000);
            } else { }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div>
            <MenuItem
                key={"keymenu1"}
                onClick={() => handle_download(props.row.original.id, props.row.original.package_name)}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={ZIP}
                        className="mapa_icono"
                        title="Descargar paquete"
                    ></img>
                </ListItemIcon>
                Descargar Paquete
            </MenuItem>
            <MenuItem
                key={"keymenu2"}
                onClick={async () => {
                    const result = await Confirm(
                        "¿Esta usted seguro de enviar este paquete?",
                        "Evío de paquete DMS",
                        "Si",
                        "No"
                    );

                    if (result) {
                        handle_seend(props.row.original.id);
                    } else {
                    }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={EMAIL}
                        title="Enviar"
                    ></img>
                </ListItemIcon>
                Envíar Paquete
            </MenuItem>

        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
