//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./RecoveryPartner.css"
//COMPONENTES
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_pos_recover } from "../../services/businesspartners/businesspartners";
//IMAGENES/ICONOS
import RECOVERY from "../../imgComponents/reconsultaTable.png"
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RECOVERY_POS from "./tools/recovery_pos";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";


const RECOVERY_PARTNER_V2 = () => {

  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  var entrada = 0;
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const notificationSystemRef = useRef();
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");

  useEffect(() => {
    handlesubmit();
  }, []);


  const update_identifiers =
    [
      { code: 'string' },
      { name: 'string' },
      { code_buss: 'String' },
      { business_partner: 'string' },
      { rfc: 'string' },
    ];

  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handlesubmit = async (query_filters_flag, query_filters_data) => {
    if (entrada === 0) {
      setmenssage("Buscando Socios de Negocios...");
      entrada = entrada + 1;
      setmodalT(true);
    } else {
      setmodalT(true);
    }
    setData([]);
    setopen(true);
    setIsLoading(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            if (query_filters_flag) {
              const d = await get_pos_recover();
              setquery_filters(query_filters_data);
            } else {
              var d = await get_pos_recover();
              var ndt = update_null_identifiers(d?.data?.data, update_identifiers);
              setData(ndt);
              setopen(false);
              setmodalGeneral(false);
              setmodalT(false);
              setIsLoading(false);
            }
          } catch (err) {
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (error) { }
  };

  const columns = useMemo(
    () => [
      {
        id: "code",
        header: "Código Punto de Venta",
        accessorKey: "code",
        accessorFn: (d) => {
          return d?.point_of_sale?.code
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        id: "name",
        header: "Punto de Venta",
        accessorKey: "name",
        accessorFn: (d) => {
          return d?.point_of_sale?.name;
        },
        muiTableBodyCellProps: {
          align: 'justify'
        },
        size: 250,
      },
      {
        id: "code_buss",
        header: "Código Socio",
        accessorKey: "code_buss",
        accessorFn: (d) => {
          return d?.business_partner?.code;
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        id: "business_partner",
        header: "Socio de Negocios",
        accessorKey: "business_partner",
        accessorFn: (d) => {
          return d?.business_partner?.business_partner;
        },
        muiTableBodyCellProps: {
          align: 'justify'
        },
        size: 250
      },
      {
        id: "rfc",
        header: "RFC",
        accessorKey: "rfc",
        accessorFn: (d) => {
          return d?.business_partner?.rfc;
        },
        muiTableBodyCellProps: {
          align: 'justify'
        }
      },
    ],
    []
  );


  const handle_success_filter = (a, b) => {
    handlesubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])



  return (
    <>

      <div className="containeritems py-2">
        <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

        <MODAL_TABLE
          open={true}
          message={menssage}
          modalGeneral={modalGeneral}
          modalT={modalT}
        ></MODAL_TABLE>
        <BREADCRUMBS niveles={
          [
            { label: "RECUPERAR SOCIO DE NEGOCIOS", path: null },
          ]
        }
          cards_dashboard={true}
          total_rows={total_rows !== "" ? total_rows : 0}
          filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
          visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
          total={data?.length}
        ></BREADCRUMBS>

      </div>

      <div className="new_table_v2">
        <div>

          <div className="" style={{ "width": "95%", "margin": "0 auto" }}>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              enableRowPinning
              onStateChange={handleStateChange}
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  setmenssage={setmenssage}
                  setmodalGeneral={setmodalGeneral}
                  row={row}
                  handlesubmit={handlesubmit}
                  setreconsult={setreconsult}
                  refresh_token={refresh_token}
                  alertas={alertas}
                />
              ]}
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Recuperar Socio de Negocios"}
                  update_identifiers={update_identifiers}
                  settotal_rows={settotal_rows}
                  query_filters={query_filters}
                  query_filters_dt={query_filters_dt}
                  handle_get_consult={handlesubmit}
                  key={index}
                  handle_success_filter={handle_success_filter}
                  share_filter={true}
                  total_rows={total_rows !== "" ? total_rows : 0}
                />
              )
              }

            ></MaterialReactTable>
          </div>

        </div>
      </div>
    </>
  );
}

export default RECOVERY_PARTNER_V2;
