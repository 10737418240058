import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Stack,
  IconButton
} from '@mui/material';
import { CustomDialog } from "react-st-modal";

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import INTERFAZ_CLIENTS from '../../Interface/clients';
import INTERFAZ_ROUTES from '../../Interface/routes';
import INTERFAZ_CABINETS from '../../Interface/cabinets';
import INTERFAZ_SALES from '../../Interface/sales';
import INTERFAZ_TICKETS from '../../Interface/tickets';
import FormControl from '@mui/material/FormControl';
import clients from "../../../imgComponents/icons/clients.png"
import freezers from "../../../imgComponents/icons/freezers.png"
import store from "../../../imgComponents/icons/store.png"
import sales from "../../../imgComponents/icons/sales.png"
import tickets from "../../../imgComponents/icons/tickets.png"

const SEARCH_FILTERS = (props) => {
  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>

      <FormControl required sx={{ width: 100 }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={(e) => props.handle_search()}
        >
          CONSULTAR
        </Button>
      </FormControl>
      <FormControl required sx={{ width: 30 }}>
      </FormControl>

      <FormControl required sx={{ width: 200 }}>

        <Stack direction="row" spacing={2} >
          <img alt="" src={clients}
            className="reportInvoice"
            title="GENERAR INTERFAZ DE CLIENTES"
            onClick={async () => {
              await CustomDialog(<INTERFAZ_CLIENTS ></INTERFAZ_CLIENTS>,
                { className: "custom-modal", title: 'Interfaz de Clientes', showCloseIcon: true, });
            }}
          ></img>
          <img
            alt=""
            src={store}
            title="GENERAR INTERFAZ DE RUTAS"
            className="reportInvoice"
            onClick={async () => {
              await CustomDialog(<INTERFAZ_ROUTES ></INTERFAZ_ROUTES>,
                { className: "custom-modal", title: 'Interfaz de Rutas', showCloseIcon: true, });
            }}
          ></img>
          <img
            alt=""
            src={freezers}
            title="GENERAR INTERFAZ DE CONGELADORES"
            className="reportInvoice"
            onClick={async () => {
              await CustomDialog(<INTERFAZ_CABINETS ></INTERFAZ_CABINETS>,
                { className: "custom-modal", title: 'Interfaz de Congeladores', showCloseIcon: true, });
            }}
          ></img>
          <img
            alt=""
            src={sales}
            title="GENERAR INTERFAZ DE VENTAS"
            className="reportInvoice"
            onClick={async () => {
              await CustomDialog(<INTERFAZ_SALES ></INTERFAZ_SALES>,
                { className: "custom-modal", title: 'Interfaz de Ventas', showCloseIcon: true, });
            }}
          ></img>
          <img
            alt=""
            src={tickets}
            title="GENERAR INTERFAZ DE TICKETS"
            className="reportInvoice"
            onClick={async () => {
              await CustomDialog(<INTERFAZ_TICKETS ></INTERFAZ_TICKETS>,
                { className: "custom-modal", title: 'Interfaz de Tickets', showCloseIcon: true, });
            }}
          ></img>

        </Stack>



      </FormControl>
    </Box>




  );
};

export default SEARCH_FILTERS;
