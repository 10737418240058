//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
//COMPONENTES
import SubTableContainer from "../../SubTableContainer";
import CREATE_UPDATE from "./tools/create_update_detail";
import {
  get_expense_detail,
  delete_expense_detail,
} from "../../../services/expenses/expenses";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../../expense.css";
//IMAGENES
import DELETE from "../../../imgComponents/borrar.png";
import SAVE from "../../../imgComponents/save.png";
import ADD from "../../../imgComponents/add.png";
import reconsultaTable from "../../../imgComponents/reconsultaTable.png";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { Box } from "@mui/material";

function SubTable_v2(props) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const handleSubmit = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setReconsulta(true);
        try {
          var dt = await get_expense_detail(props.row.original.id);
          const data = dt.data;
          console.log("hola xf");
          setData(data);
          const cargos = data.filter((item) => item.efect === "CARGO");
          const abonos = data.filter((item) => item.efect === "ABONO");
          const sumCargos = cargos
            .map((cargoSum) => parseFloat(cargoSum.ammount))
            .reduce((previous, current) => {
              return previous + current;
            }, 0);
          const sumAbonos = abonos
            .map((abonoSum) => parseFloat(abonoSum.ammount))
            .reduce((previous, current) => {
              return previous + current;
            }, 0);
          if (sumCargos?.toFixed(2) - sumAbonos?.toFixed(2) === 0) {
            setEquality("Los datos cuadran");
            setSuccess_msg("");
            setError_msg("none");
          } else {
            setEquality("Los datos no cuadran");
            setError_msg("");
            setSuccess_msg("none");
          }
          setReconsulta(false);
        } catch (error) {
          setData([]);
          setReconsulta(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };


  const handle_delete_expense_detail = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        try {
          await delete_expense_detail(props.row.original.id, id);
          setOpen(false);
          handleSubmit();
        } catch (error) {
          setOpen(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);


  console.log("-----segundo nivel--------");
  console.log(props)

  const columns = useMemo(
    () => [
      // {
      //   header: () => {
      //     return (
      //       <div>
      //         <img
      //           alt=""
      //           title="Agregar detallee"
      //           src={ADD}
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <CREATE_UPDATE
      //                 method={"CREATE"}
      //                 row={props?.row?.original}
      //                 foreign={props.foreign}
      //                 codeffect={"CARGO"}
      //                 codamount={0}
      //               ></CREATE_UPDATE>,
      //               {
      //                 className: "modalTItle modalExpenseG",
      //                 title: "Selecciona la configuracion del detalle",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             // eslint-disable-next-line no-lone-blocks
      //             {
      //               handleReconsulta(result);
      //             }
      //           }}
      //           className="centerText, taxes"
      //         ></img>
      //       </div>
      //     );
      //   },
      //   id: "Acciones",

      //   Cell: ({ valor, row }) => {
      //     return (
      //       <div>
      //         <img
      //           title="Editar Detalle"
      //           src={SAVE}
      //           alt=""
      //           onClick={async () => {
      //             if (row.original.modifiable === true) {
      //               const result = await CustomDialog(
      //                 <CREATE_UPDATE
      //                   method={"UPDATE"}
      //                   id_detail={row.original.id}
      //                   row={props?.row?.original}
      //                   codaccounts={row.original.account}
      //                   codconcept={row.original.concept}
      //                   codamount={row.original.ammount}
      //                   codeffect={row.original.efect}
      //                   codidConcept={row.original.id_account}
      //                   foreign={props.foreign}
      //                 ></CREATE_UPDATE>,
      //                 {
      //                   title: "Editar detalle del gastos",
      //                   className: "modalTItle modalExpenseG",
      //                   showCloseIcon: true,
      //                   isCanClose: false,
      //                 }
      //               );
      //               // eslint-disable-next-line no-lone-blocks
      //               {
      //                 handleReconsulta(result);
      //               }
      //             } else {
      //               alert("Este detalle ya no se puede modificar");
      //             }
      //           }}
      //           className="centerText, taxes"
      //         ></img>

      //         <img
      //           title="Eliminar Detalle"
      //           src={DELETE}
      //           alt=""
      //           className="centerText, taxes"
      //           onClick={async () => {
      //             const result = await Confirm(
      //               "¿Esta usted seguro de eliminar este detalle del gasto?",
      //               "Eliminar Detalle",
      //               "Si",
      //               "No"
      //             );
      //             if (result) {
      //               handle_delete_expense_detail(row.original.id);
      //             } else {
      //             }
      //           }}
      //         ></img>
      //       </div>
      //     );
      //   },
      // },

      {
        id: "concept",
        header: "Concepto",
        accessorKey: "concept",
      },
      {
        id: "account",
        header: "Cuenta",
        accessorKey: "account",
      },
      {
        id: "ammount",
        header: "Importe",
        accessorKey: "ammount",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "efect",
        header: "Efecto",
        accessorKey: "efect",
      },

      {
        id: "invoice_supplier_concept",
        header: "Concepto Factura",
        accessorKey: "invoice_supplier_concept",
      },

      {
        id: "rfc",
        header: "RFC",
        accessorKey: "rfc",
      },

      {
        id :"uuid",
        header: "UUID",
        accessorKey: "uuid",
      },
      {
        id: "modifiable",
        header: "Modificable",
        accessorKey: "modifiable",
        accessorFn: (d) => {
          return d.modifiable === true ? "✅" : "❌";
        },
      },
    ],
    []
  );

  
  const update_identifiers =
  [
    { concept: 'string' },
    { account: 'string' },
    { ammount: 'string' },
    { efect: 'string'},
    { invoice_supplier_concept: 'string'},
    { rfc: 'string'},
    { uuid: 'string'},
    { modifiable: 'string'},
  ];

  if (Reconsulta === false) {
    return (
      <div>
        <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>

        <div className="subtablaexpensesw">
          <div class="success-msg" style={{ display: success_msg }}>
            <h2>
              <i class="fa fa-check"></i>
              {equality}
            </h2>
          </div>
          <div class="error-msg" style={{ display: error_msg }}>
            <h2>
              <i class="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
          <div className="new_table_v2">

          
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowActions
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            initialState={{ showGlobalFilter: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                Header: () => (
              <div>
               <img
                 alt=""
                 title="Agregar detallee"
                 src={ADD}
                 onClick={async () => {
                   const result = await CustomDialog(
                     <CREATE_UPDATE
                       method={"CREATE"}
                       row={props?.row?.original}
                       foreign={props.foreign}
                       codeffect={"CARGO"}
                       codamount={0}
                       handleSubmit={handleSubmit}
                     ></CREATE_UPDATE>,
                     {
                       className: "custom-modal",
                       title: "Selecciona la configuracion del detalle",
                       showCloseIcon: true,
                       isCanClose: false,
                     }
                   );
                 }}
                className="centerText, taxes"
               ></img>
             </div>
              ),
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderRowActionMenuItems={({ row, closeMenu }) => [
                  <RENDER_ROW_ACTION_MENU_ITEMS
                    closeMenu={closeMenu}
                    setmodalT={props?.setmodalT}
                    setmenssage={""}
                    setmodalGeneral={false}
                    row={row}
                    dataprincipal={props?.row}
                    handleSubmit={handleSubmit}
                    refresh_token={refresh_token}
                    alertas={props?.alertas}
                  />
             ]}
            renderToolbarInternalActions={({ table }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table} 
                  file_name={"Detalles del Gasto"}
                  update_identifiers={update_identifiers}
                  create_modul={"create_expense_details"}
                  foreing={props?.foreing}
                  setmodalT={props?.setmodalT}
                  handleSubmit={handleSubmit}
                  alertas={props?.alertas}
                  dataprincipal={props?.row}
                />
             )}
          /></div>
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default SubTable_v2;
