//PAQUETERIAS
import React, {Fragment } from "react";
import { Table} from "reactstrap";
//COMPONENTES
//ESTILOS
import "../../../../../components_cfdi/estilo.css";
import "../../../cashclosing.css";
import "../../../../../components_cfdi/modal.css";
//IMAGENES/ICONOS

const stylep1 = { color: "white", background: "rgb(81 171 66)" };
const stylepp1 = { color: "black", background: "white" };

const SUMMARY_CASHCLOSING = (props) => {


  return (
    <div >
      <div className="containerCutBox">
        <div className="expen-1 color-1">
          <h2>Resumen de liquidación</h2>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div className="TableReport TableGTotalCutBox">
        <div style={{ overflowX: "auto" }}>
          <Fragment>
            <Table>
              <thead>
                <tr>
                    <th>Tipo de Venta</th>
                    <th>Importe</th>
                    <th>Devolución</th>
                    <th>Bonificación</th>
                    <th>Venta Neta</th>
                    <th>Pagado</th>
                    <th>Adeudo</th>
                    <th>Total Ventas</th>
                </tr>
              </thead>

              <tbody style={{ display: props.modalT === false ? "" : "none" }}>
                <tr>
                  <td onClick={props.handleFIlterSalePrimaryC}>
                    <span style={props.filterVPCo === true ? stylep1 : stylepp1}>
                      Venta Primaria Contado
                    </span>
                  </td>
                  <td>{props.vPContadoTotal.toFixed(2)}</td>
                  <td>{props.vPSContadoDevolucion.toFixed(2)}</td>
                  <td>{props.vPSContadoBonificacion.toFixed(2)}</td>
                  <td>
                    {(
                      props.vPContadoTotal.toFixed(2) -
                      (props.vPSContadoDevolucion + props.vPSContadoBonificacion).toFixed(2)
                    ).toFixed(2)}
                  </td>
                  <td>{props.vPContadoPagado.toFixed(2)}</td>
                  <td>{props.vPContadoAdeudo.toFixed(2)}</td>
                  <td>
                    {(
                      props.vPContadoTotal.toFixed(2) -
                      (props.vPSContadoDevolucion + props.vPSContadoBonificacion).toFixed(2)
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td onClick={props.handleFIlterSalePrimaryCredit}>
                    <span style={props.filterVPCr === true ? stylep1 : stylepp1}>
                      Venta Primaria Crédito
                    </span>
                  </td>
                  <td>{props.vPCTotal.toFixed(2)}</td>
                  <td>{props.vPSDevolucion.toFixed(2)}</td>
                  <td>{props.vPSBonificacion.toFixed(2)}</td>
                  <td>
                    {(
                      props.vPCTotal.toFixed(2) -
                      (props.vPSDevolucion + props.vPSBonificacion).toFixed(2)
                    ).toFixed(2)}
                  </td>
                  <td>{props.vPCPagado.toFixed(2)}</td>
                  <td>{props.vPCAdeudo.toFixed(2)}</td>
                  <td>
                    {(
                      props.vPCTotal.toFixed(2) -
                      (props.vPSDevolucion + props.vPSBonificacion).toFixed(2)
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td onClick={props.handleFIlterSaleSecundaryC}>
                    <span style={props.filterVSCo === true ? stylep1 : stylepp1}>
                      Venta Secundaria Contado
                    </span>
                  </td>
                  <td>{props.vSContadoTotal.toFixed(2)}</td>
                  <td>{props.vSSContadoDevolucion.toFixed(2)}</td>
                  <td>{props.vSSContadoBonificacion.toFixed(2)}</td>
                  <td>
                    {(
                      props.vSContadoTotal.toFixed(2) -
                      (props.vSSContadoDevolucion + props.vSSContadoBonificacion).toFixed(2)
                    ).toFixed(2)}
                  </td>
                  <td>{props.vSContadoPagado.toFixed(2)}</td>
                  <td>{props.vSContadoAdeudo.toFixed(2)}</td>
                  <td>
                    {(
                      props.vSContadoTotal.toFixed(2) -
                      (props.vSSContadoDevolucion + props.vSSContadoBonificacion).toFixed(2)
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td onClick={props.handleFIlterSaleSecundaryCredit}>
                    <span style={props.filterVSCr === true ? stylep1 : stylepp1}>
                      Venta Secundaria Crédito
                    </span>
                  </td>
                  <td>{props.vSCTotal.toFixed(2)}</td>
                  <td>{props.vSSDevolucion.toFixed(2)}</td>
                  <td>{props.vSSBonificacion.toFixed(2)}</td>
                  <td>
                    {(
                      props.vSCTotal.toFixed(2) -
                      (props.vSSDevolucion + props.vSSBonificacion).toFixed(2)
                    ).toFixed(2)}
                  </td>
                  <td>{props.vSCPagado.toFixed(2)}</td>
                  <td>{props.vSCAdeudo.toFixed(2)}</td>
                  <td>
                    {(
                      props.vSCTotal.toFixed(2) -
                      (props.vSSDevolucion + props.vSSBonificacion).toFixed(2)
                    ).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td onClick={props.handleFIlterDebit}>
                    <span style={props.filterVO === true ? stylep1 : stylepp1}>
                      Venta Obsequio
                    </span>
                  </td>
                  <td>{props.obTotal.toFixed(2)}</td>
                  <td>{props.obDevolucion.toFixed(2)}</td>
                  <td>{props.obBonificacion.toFixed(2)}</td>
                  <td>
                    {(
                      props.obTotal.toFixed(2) -
                      (props.obDevolucion + props.obBonificacion).toFixed(2)
                    ).toFixed(2)}
                  </td>
                  <td>{props.obPagado.toFixed(2)}</td>
                  <td>{props.obAdeudo.toFixed(2)}</td>
                  <td>{"0.00"}</td>
                </tr>

                <tr>
                  <td>
                    Gran Total
                  </td>
                  <td>
                    {(
                      props.vPContadoTotal +
                      props.vPCTotal +
                      props.vSContadoTotal +
                      props.vSCTotal +
                      props.obTotal
                    ).toFixed(2)}
                  </td>
                  <td>
                    {(
                      props.vPSContadoDevolucion +
                      props.vPSDevolucion +
                      props.vSSContadoDevolucion +
                      props.vSSDevolucion +
                      props.obDevolucion
                    ).toFixed(2)}
                  </td>
                  <td>
                    {(
                      props.vPSContadoBonificacion +
                      props.vPSBonificacion +
                      props.vSSContadoBonificacion +
                      props.vSSBonificacion +
                      props.obBonificacion
                    ).toFixed(2)}
                  </td>
                  <td>
                    {(
                      props.vPContadoTotal.toFixed(2) -
                      (props.vPSContadoDevolucion + props.vPSContadoBonificacion).toFixed(
                        2
                      ) +
                      (props.vPCTotal.toFixed(2) -
                        (props.vPSDevolucion + props.vPSBonificacion).toFixed(2)) +
                      (props.vSContadoTotal.toFixed(2) -
                        (props.vSSContadoDevolucion + props.vSSContadoBonificacion).toFixed(
                          2
                        )) +
                      (props.vSCTotal.toFixed(2) -
                        (props.vSSDevolucion + props.vSSBonificacion).toFixed(2)) +
                      (props.obTotal.toFixed(2) -
                        (props.obDevolucion + props.obBonificacion).toFixed(2))
                    ).toFixed(2)}
                  </td>
                  <td>
                    {(
                      props.vPContadoPagado +
                      props.vPCPagado +
                      props.vSContadoPagado +
                      props.vSCPagado +
                      props.obPagado
                    ).toFixed(2)}
                  </td>
                  <td>
                    {(
                      props.vPContadoAdeudo +
                      props.vPCAdeudo +
                      props.vSContadoAdeudo +
                      props.vSCAdeudo +
                      props.obAdeudo
                    ).toFixed(2)}
                  </td>
                  <td>
                    {(
                      props.vPContadoTotal.toFixed(2) -
                      (props.vPSContadoDevolucion + props.vPSContadoBonificacion).toFixed(
                        2
                      ) +
                      (props.vPCTotal.toFixed(2) -
                        (props.vPSDevolucion + props.vPSBonificacion).toFixed(2)) +
                      (props.vSContadoTotal.toFixed(2) -
                        (props.vSSContadoDevolucion + props.vSSContadoBonificacion).toFixed(
                          2
                        )) +
                      (props.vSCTotal.toFixed(2) -
                        (props.vSSDevolucion + props.vSSBonificacion).toFixed(2))
                    ).toFixed(2)}
                  </td>{" "}
                </tr>
              </tbody>
              <tbody style={{ display: props.modalT === true ? "" : "none" }}>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="sk-fading-circle">
                      <div className="sk-circle1 sk-circle"></div>
                      <div className="sk-circle2 sk-circle"></div>
                      <div className="sk-circle3 sk-circle"></div>
                      <div className="sk-circle4 sk-circle"></div>
                      <div className="sk-circle5 sk-circle"></div>
                      <div className="sk-circle6 sk-circle"></div>
                      <div className="sk-circle7 sk-circle"></div>
                      <div className="sk-circle8 sk-circle"></div>
                      <div className="sk-circle9 sk-circle"></div>
                      <div className="sk-circle10 sk-circle"></div>
                      <div className="sk-circle11 sk-circle"></div>
                      <div className="sk-circle12 sk-circle"></div>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default SUMMARY_CASHCLOSING;
