import React, { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../../main_components/tokens/tokenrefresh";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";
import { get_accountingA } from "../../../../services/finances/finances";
import { post_expense_concept_list, put_expense_concept_list } from "../../../../services/expenses/expenses";


function OPERATIONS_ASSIGNED_CONCEPTS(props) {

const dialog = useDialog();
const [nameC, setnameC]=useState(props.operation==="EDIT"?props.row.original.concept:null);
const [cuenta, setcuenta]=useState(props.operation==="EDIT"?props.row.original.account:null);
const [enable, setenable]=useState(props.operation==="EDIT"?props.row.original.enable:true);
const [bandera, setbandera]=useState(true);
const [datacuentas, setdatacuentas]=useState([]); 
const [codecuenta, setcodecuenta]=useState(props.operation==="EDIT"?props.row.original.account:null);
const [namecuenta, setnamecuenta]=useState(null);
const cuentacopy = props.operation==="EDIT"?props.row.original.account:null;
const [vnamec, setvnamec]=useState(false);
const [vcuenta, setvcuenta]=useState(false);

useEffect(()=>{
    handlecuentas();   
},[]); 

const handlecuentas= async ()=>{

  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_accountingA();
          setdatacuentas(d === null ? [] : d.data.acc_accounts);
          if(props.operation==="EDIT"){
            const itemToSelect = (d === null ? [] : d.data.acc_accounts.find(item => item.code === codecuenta))?.name;
            setnamecuenta(itemToSelect);        
          }  
        }catch(err){
          console.error(err);
          setdatacuentas([]);
        }
      }, 1000);
    }else{} 
  } catch (err) {
      console.log(err);
  }
}


//crear un nuevo concepto en el catalogo
const createconcepto=async()=>{

  if(nameC==null || cuenta==null){
    props.alertas("Parámetros faltantes", false);
    nameC===null?setvnamec(true):setvnamec(false);
    cuenta===null||cuenta===0?setvcuenta(true):setvcuenta(false);
  }else{
  props.setmodalT(true);
  setbandera(false);
      
      var data = {
        "enable": enable,//boolean = Habilitado | Deshabilitado
        "concept": nameC,//String = Concepto0
        "account": cuenta//String = Cuenta
      }
  
      try{
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async () => {
            try{
              const d = await post_expense_concept_list(sessionStorage.getItem("id_catalogo"), data);
              console.log(d);
              props.setmodalT(false);
              props.alertas("Concepto agregado con éxito al catálogo de Gastos",true);  
              dialog.close();
              props.handleSubmit();
            }catch(err){
              console.log("error post", err);
              catchErrorModul("Conceptos del Cátalogo", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
              if(err.response.status==409){
                props.alertas("El concepto ya existe en el catálogo",false);
              }else{
                props.alertas("No se pudo agregar el concepto",false);
              }
                setbandera(true);  
                props.setmodalT(false);
              }
          }, 1000);
        }else{}
      }catch(err){
        console.log(err);
      }
    }
  }

const updateconcepto = async()=>{

if(cuenta===0){
  props.alertas("La cuenta ingresada no es aceptada", false)
}else{

props.setmodalT(true);
setbandera(false);
  var data = {
    "enable": enable,//boolean = Habilitado | Deshabilitado
    "concept": nameC,//String = Concepto0
    "account": cuenta//String = Cuenta
}
  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async () => {
        try{
          const d = await put_expense_concept_list(sessionStorage.getItem("id_catalogo"), props.row.original.id, data);
          console.log(d);
          props.alertas("Concepto actualizado con éxito", true);
          props.setmodalT(false);
          dialog.close();
          props.handleSubmit();
        }catch(err){
          console.log("error put", err);
          catchErrorModul("Conceptos del Cátalogo", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          props.alertas("No se pudo actualizar el concepto", false)
          props.setmodalT(false);
          setbandera(true);  
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
 }
}

const handleOnSearch = (string, results) => {
  if(props.operation==="EDIT"){
    setcuenta(cuentacopy);
  }else{
    setcuenta(0);
  }
  
};
const handleOnHover = (result) => {};
const handleOnSelect5 = (item) => {
  if(props.operation==="EDIT"){
    setcuenta(item.code);
    setcodecuenta(item.code);
    setnamecuenta(item.name);
  }else{
    setcuenta(item.code);
    setvcuenta(false);
  }
    
};
const handleOnFocus5 = () => {
  //console.log("Focused");
  if(props.operation==="EDIT"){

  }else{
    setvcuenta(false);
  }
};
const handleOnClear = () => {
  //console.log("Cleared");
};

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}


  return (
    <div style={{"textAlign":"center"}}>
      <div className="addpointsalecss">
          <div className="gridcatalogogastos">
            <div className="catalogo1">
            <TextField
             label="Nombre del Concepto"
             required
             sx={{ m: 1.5, width: "100%" }}
             value={nameC}
             disabled={!bandera}
             onChange={(event) => setnameC(event.target.value.toUpperCase())}  
             InputLabelProps={{ shrink: true }}
             style={
               vnamec==true?{"border":"2px solid red", "border-radius":"5px"}:{}
             }
             onSelect={(event)=>setvnamec(false)}
             onBlur={
               (nameC==null||nameC=="")?(event)=>setvnamec(true):(event)=>setvnamec(false)
             }  
           ></TextField>
            <p className="p_alert" style={vnamec==true?{"display":"block","textAlign":"center"}:{"display":"none"}}>Completa este campo </p>
 
            </div>
          </div>
          <div className="gridcatalogogastos2">
          <div className="catalogo2">
          <TextField
              select
              label="Habilitado"
              disabled={!bandera}
              value={enable}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setenable(!enable)}  
            >
              <option key={1} value={true} selected >
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>
            </div>

             {props.operation==="EDIT"?
            
            <div className="catalogo3b">
            <ReactSearchAutocomplete
                      items={datacuentas}
                      fuseOptions={{ keys: ["code","name"]}}
                      resultStringKeyName="codeAndName"
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect5}
                      onFocus={handleOnFocus5}
                      onClear={handleOnClear}
                      styling={{
                        border: "1px solid gray",
                        borderRadius: "4px",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        zIndex: 10,
                      }}
                      showIcon={true}
                      placeholder={"Buscar Nueva Cuenta Contable"}    
                    /> 
           </div>
            :<div className="catalogo3b">
            <ReactSearchAutocomplete    
                      items={ datacuentas}
                      fuseOptions={{ keys: ["code","name"]}}
                      resultStringKeyName="codeAndName"
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect5}
                      onFocus={handleOnFocus5}
                      onClear={handleOnClear}
                      onBlur={
                        (cuenta==null || cuenta==0)?(event)=>setvcuenta(true):(event)=>setvcuenta(false)
                      } 
                      styling={{
                        border: "1px solid gray",
                        borderRadius: "4px",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        zIndex: 10,
                      }}
                      showIcon={true}
                      placeholder="Buscar Cuenta"      
                    /> 
                    <p className="p_alert" style={vcuenta==true?{"display":"block","paddingTop":"20px", "textAlign":"center"}:{"display":"none"}}>Completa este campo </p>
          
           </div>
            }
        </div>
          <div className="gridcatalogogastos3" style={props.operation==="EDIT"?{"display":""}:{"display":"none"}}>
            <div className="catalogo4">
            <TextField
              label="Código Cuenta Contable Actual"
              sx={{ m: 1.5, width: "100%" }}
              value={codecuenta}
              disabled
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="catalogo5">
              <TextField
                label="Nombre Cuenta Contable Actual"
                
                sx={{ m: 1.5, width: "100%" }}
                value={namecuenta}
                disabled
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
          </div>
      
          <br></br>
          <br></br>
          <Box>
            {
              bandera==true?
              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={()=>{props.operation==="EDIT"?updateconcepto():createconcepto()}}
          >
            {props.operation==="EDIT"?"Guardar Cambios":"Agregar Concepto"}
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "30ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default OPERATIONS_ASSIGNED_CONCEPTS;
