import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./render_row_action_menuItems";

const TABLE_NOTES = (props) => {

  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");


  const update_identifiers =
    [
      { code: 'string' },
      { name: 'string' },
      { expense_concept: 'String' },
      { efect: 'string' },
      { type: 'string' }
    ];

  

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const columns = useMemo(
    () => [
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "version",
        header: "Versión",
        accessorKey: "version",
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        id: 'date',
        header: "Fecha",
        accessorKey: "date",
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        id: 'title',
        header: "Titulo",
        accessorKey: "title",
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
    ],
    []
  );

  return (
    <>
      <div className='new_table_v2'>
        <div style={{width:"90%", margin: "0 auto"}}>
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <>
            <MaterialReactTable
              columns={columns}
              data={props?.data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: props?.isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                      props?.data.splice(
                      hoveredRow.index,
                      0,
                      props?.data.splice(draggingRow.index, 1)[0],
                    );
                    props.setdata([...props?.data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={props?.setmodalt}
                  setmenssage={""}
                  setmodalGeneral={false}
                  row={row}
                  handleSubmit={props?.get_avisos}
                  alertas={props?.alertas}
                  setIsLoading={props?.setIsLoading}
                  isDomainValid={props?.isDomainValid}
                />
              ]}
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Avisos od2"}
                  update_identifiers={update_identifiers}
                  key={index}
                  settotal_rows={settotal_rows}
                />
              )
              }
            />

          </>
        </div>
      </div>
    </>
  );
}

export default TABLE_NOTES;
