//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
//ESTILOS
import "./rulesSE.css";
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { get_expense_income_rules } from "../../services/expenses/expenses";
import OPERATIONS_RULES_SE from "./tools/op_rules_se";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { Box } from "@mui/material";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const RULES_SE_V2 = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");


  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const update_identifiers =
    [
      { user_name: 'string' },
      { enable: 'string' },
      { lower_limit_days: 'String' },
      { lower_limit_week: 'string' },
      { lower_limit_month: 'string' },
      { blocked: 'string' },
      { lower_limit_ammount: 'string' },
      { upper_limit_ammount: 'string' }
    ];



  const handleSubmit = async (query_filters_flag, query_filters_data) => {

    setmodalT(true);
    setopen(true);
    setData([]);
    setIsLoading(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            if (query_filters_flag) {
              const d = await get_expense_income_rules();
              setquery_filters(query_filters_data);
            } else {
              const d = await get_expense_income_rules();
              const data = d === null ? [] : d.data.exp_income_rules;
              var ndt = update_null_identifiers(data, update_identifiers);
              setData(ndt);
              setopen(false);
              setmodalT(false);
              setIsLoading(false);
            }
          } catch (err) {
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "user_name",
        header: "Usuario",
        accessorKey: "user_name"
      },
      {
        id: "enable",
        header: "Habilitado",
        accessorKey: "enable",
        accessorFn: (d) => {
          return d.enable === true ? "Habilitado" : "Deshabilitado"
        }
      },
      {
        id: "lower_limit_days",
        header: "Días Antes",
        accessorKey: "lower_limit_days",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "lower_limit_week",
        header: "Semanas Antes",
        accessorKey: "lower_limit_week",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "lower_limit_month",
        header: "Meses Antes",
        accessorKey: "lower_limit_month",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "blocked",
        header: "Bloqueado",
        accessorKey: "blocked",
        accessorFn: (d) => {
          return d.blocked === true ? "SI" : "NO"
        },
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "lower_limit_ammount",
        header: "Monto Inferior",
        accessorKey: "lower_limit_ammount",
        Cell: ({ cell }) => {
          return (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '9ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          )
        },
        muiTableBodyCellProps: {
          align: "right"
        }
      },
      {
        id: "upper_limit_ammount",
        header: "Monto Superior",
        accessorKey: "upper_limit_ammount",
        Cell: ({ cell }) => {
          return (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '9ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          )
        },
        muiTableBodyCellProps: {
          align: "right"
        }
      }

    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])


  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR REGLAS DE GASTOS", path: null },
        ]
      }
       cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <div className="new_table_v2">
        <div >
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            onStateChange={handleStateChange}
            enableColumnFilterModes
            enableRowActions
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            rowSelection
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                Header: () => (
                  <div>
                    <img
                      title="Registrar Regla de Gastos"
                      src={CREATE}
                      style={{ "cursor": "pointer" }}
                      className=""
                      onClick={async () => {
                        const result = await CustomDialog(
                          <OPERATIONS_RULES_SE
                            operation={"CREATE"}
                            handleSubmit={handleSubmit}
                            setmodalT={setmodalT}
                            alertas={alertas}
                          />,
                          {
                            className: "custom-modal-3",
                            title: "Registrar Regla de Gastos",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                      }}
                    ></img>
                  </div>
                ),
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                setmenssage={setmenssage}
                setmodalGeneral={setmodalGeneral}
                row={row}
                handleSubmit={handleSubmit}
                setreconsult={setreconsult}
                refresh_token={refresh_token}
                alertas={alertas}
              />
            ]}

            renderToolbarInternalActions={({ table, index }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Consultar Reglas de Gastos"}
                update_identifiers={update_identifiers}
                settotal_rows={settotal_rows}
                query_filters={query_filters}
                query_filters_dt={query_filters_dt}
                handle_get_consult={handleSubmit}
                key={index}
                handle_success_filter={handle_success_filter}
                share_filter={true}
                total_rows={total_rows !== "" ? total_rows : 0}
              />
            )
            }
          />

        </div>
      </div>
    </>
  );
}

export default RULES_SE_V2;
