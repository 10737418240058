//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import { get_products } from "../../services/inventory/inventory";
import CREATE_UPDATE from "./tools/create_update";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { recovery_products } from "../../services/inventory/inventory";

import CREATE from "../../imgComponents/create.png";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import "./products.css";
import { GENERATE_COLUMNS } from "../../main_components/Table/generate_columns";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import TAX_V2 from "./tools/tax_v2";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const PRODUCTS_V2 = (props) => {

  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const showcolumns = GENERATE_COLUMNS("productos", 17);
  const [data, setData] = useState([]);
  const [modalT, setmodalT] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [deleted, setdeleted] = useState(false);
  const notificationSystemRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");



  const handleSubmit = async (a, b) => {
    setmodalT(true);
    setMenssage("Productos...");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            if (props.subtabla === true) {
              console.log("entra dato")
              const dt = await get_products(sessionStorage.getItem("id_product_line"), deleted);
              var ndt = update_null_identifiers(dt?.data, update_identifiers);
              setData(ndt);
              setmodalT(false);
              if (a) {
                alertas(b, a)
              }
            } else {
              const dt = await get_products(null, deleted);
              var ndt = update_null_identifiers(dt?.data, update_identifiers);
              setData(ndt);
              setmodalT(false);
              if (a) {
                alertas(b, a)
              }
            }

          } catch (err) {
            setData([]);
            setMenssage("");
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_reconsult = (a, b) => {
    if (a) {
      handleSubmit(a, b);
    } else if (a === false) {
      alertas(b, a);
    }
  }
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handle_recovery = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            await recovery_products(id)
            handleSubmit();
            handle_reconsult(true, "Producto recuperado correctamente")
          } catch (err) {
            alertas(JSON.stringify(err?.response?.data), false)
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    handleSubmit();
  }, [deleted]);

  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  }


  const columns = useMemo(
    () => [
      {
        header: "Código",
        accessorKey: "code",
        id: "code",
      },
      {
        header: "Nombre del producto",
        accessorKey: "product_name",
        id: "product_name",
      },
      {
        header: "Descripción",
        accessorKey: "description",
        id: "description",
      },
      {
        header: "Código de barras",
        accessorKey: "barcode",
        id: "barcode",
      },
      {
        header: "Tipo",
        accessorKey: "type",
        // Filter: SelectColumnFilter,
        // filter:"equals",
        id: "type",
      },
      {
        header: "Unidad",
        accessorKey: "unit",
        // Filter: SelectColumnFilter,
        // filter:"equals",
        id: "unit",
      },
      {
        header: "Conversión",
        accessorKey: "unit_conversion",
        id: "unit_conversion",
      },
      {
        header: "Minima",
        accessorKey: "minimum_sales_unit",
        // Filter: SelectColumnFilter,
        // filter:"equals",
        id: "minimum_sales_unit",
      },
      {
        header: "Unidad SAT",
        accessorKey: "clave_unidad",
        id: "clave_unidad",
      },
      {
        header: "Piezas",
        accessorKey: "pieces",
        id: "pieces",
      },
      {
        header: "Peso",
        accessorKey: "weight",
        id: "weight",
      },
      {
        header: "Clasificación",
        accessorKey: "brand",
        // Filter: SelectColumnFilter,
        // filter:"equals",
        id: "brand",
      },
      {
        header: "Activo",
        // Filter: SelectColumnFilter,
        // filter:"equals",
        accessorKey: "enable",
        accessorFn: (d) => {
          return d.enable === true ? "Si" : "NO";
        },
        id: "enable",
      },
      {
        header: "Clave SAT8",
        accessorKey: "clave_prod_serv",
        id: "clave_prod_serv",
      },
      {
        header: "Clave DMS",
        accessorKey: "min_sales_unit_dms",
        // Filter: SelectColumnFilter,
        // filter:"equals",
        id: "min_sales_unit_dms",
      },
      {
        header: "Costo",
        id: "cost",
        accessorKey: "cost",
        accessorFn: (d) => {
          return d.cost
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.cost)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.cost);
        },
      },
      {
        header: "Fecha de Registro",
        accessorKey: "date_register",
        id: "date_register",
      },
    ],
    []
  );
  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };


  const update_identifiers =
    [
      { code: 'string' },
      { product_name: 'string' },
      { description: 'String' },
      { barcode: 'string' },
      { type: 'string' },
      { unit: 'string' },
      { unit_conversion: 'string' },
      { minimum_sales_unit: 'string' },
      { clave_unidad: 'string' },
      { pieces: 'string' },
      { weight: 'string' },
      { brand: 'string' },
      { enable: 'string' },
      { clave_prod_serv: 'string' },
      { min_sales_unit_dms: 'string' },
      { cost: 'string' },
      { date_register: 'string' }
    ];


  return (
    <div>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR PRODUCTOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>

      <div className="new_table_v2">
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          onStateChange={handleStateChange}
          enableColumnFilterModes
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    title="Crear Concepto"
                    src={CREATE}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <CREATE_UPDATE
                          subtabla={props.subtabla}
                          name_line={props.subtabla === true ? sessionStorage.getItem("id_product_line") : 4}
                          code={""}
                          description={""}
                          enable={true}
                          method={"CREATE"}
                          name_supplier={null}
                          id_supp={null}
                          product_name={""}
                          barcode={""}
                          type={"CONGELADOS"}
                          product_line_data={"CLIENTE"}
                          only_promo={false}
                          cost={1}
                          pieces={1}
                          clave_prod_serv={""}
                          clave_unidad={""}
                          clave_unidad_2={""}
                          weight={0.1}
                          iva0={true}
                          iva16={false}
                          ieps8={false}
                          minimum_sales_unit={"PIEZA"}
                          minimum_sales_unit2={"PIEZA"}
                          unit={"LITROS"}
                          unit_dms={"ZCU"}
                          unit_conversion={0}
                          brand={"MORDISKO"}
                          corrugado={""}
                          boxed_parts={0}
                          handleSubmit={handleSubmit}

                        ></CREATE_UPDATE>,
                        {
                          className: "custom-modal-3",
                          title: "Seleccione la configuracion del concepto",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderTopToolbarCustomActions={({ }) => (
            <div className="selectProduct" style={{ display: props.subtabla === true ? "none" : "" }}>
              <select name="" id="" onChange={(e) => setdeleted(e.target.value)}>
                <option value={false}>Vigentes</option>
                <option value={true}>Eliminados</option>
              </select>
            </div>
          )}
          renderDetailPanel={({ row }) => {
            return (
              <TAX_V2 id={row.original.id} />
            )
          }}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              setmenssage={""}
              setmodalGeneral={false}
              row={row}
              handleSubmit={handleSubmit}
              refresh_token={refresh_token}
              alertas={alertas}
            />
          ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Consultar Productos"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>
    </div>
  );
};

export default PRODUCTS_V2;
