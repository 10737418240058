//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import "./annual_s.css";
import TextField from "@mui/material/TextField";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { get_semaphore } from "../../services/reports/reports";
import { pagination } from "../../main_components/pagination/pagination";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
 
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";
import { Box } from '@mui/material';
import AUXILIAR_FILTER from "../../main_components/methods_v2/auxiliar_filters";
import { SEARCH_FILTER_WEB_MOVIL } from "../../main_components/methods_v2/search_filter_web_movil";

const SUMMARY_ANNUAL_V2 = () => {

  const update_identifiers =
[
  { code_pos: 'string' },
  { deleted_logical_pos: 'string' },
  { name_pos: 'String' },
  { name_route: 'string' },
  { Enero: 'number' },
  { Febrero: 'number'},
  { Marzo: 'number' },
  { Abril: 'number' },
  { Mayo: 'number' },
  { Junio: 'number' },
  { Julio: 'number' },
  { Agosto: 'number' },
  { Septiembre: 'number'},
  { Octubre: 'number' },
  { Noviembre: 'number' },
  { Diciembre: 'number' },
  { sumatotal: 'number' },
];

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [datasemaforo, setdatasemaforo]=useState([]);
  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [yy, setyy]=useState(null);
  const [vyy, setvyy]=useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  
  const [total_ene, settotal_ene]=useState([]);
  const [total_feb, settotal_feb]=useState([]);
  const [total_mar, settotal_mar]=useState([]);
  const [total_abr, settotal_abr]=useState([]);
  const [total_may, settotal_may]=useState([]);
  const [total_jun, settotal_jun]=useState([]);
  const [total_jul, settotal_jul]=useState([]);
  const [total_ago, settotal_ago]=useState([]);
  const [total_sep, settotal_sep]=useState([]);
  const [total_oct, settotal_oct]=useState([]);
  const [total_nov, settotal_nov]=useState([]);
  const [total_dic, settotal_dic]=useState([]);
  const [gtotal, setgtotal]=useState([]);
  const [total_rows, settotal_rows] = useState("");
  
function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}


useEffect(() => {
  handlesemaforo();
}, [])


const handlesemaforo=async()=>{
  try {
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          var d = await get_semaphore();
          const semaphores = (d === null ? [] : d.data.semaphores);
          const updatedSemaphores = semaphores.map(item => ({
            ...item,
            minimum: item.minimum * 12,
            max: item.max * 12
          }));
          setdatasemaforo(updatedSemaphores);
          //setdatasemaforo(d === null ? [] : d.data.semaphores);  
        }catch(err){
          console.log(err);
          setdatasemaforo([]);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}

const handleSubmit = async () => {

if(yy===null || yy===''){
  alertas("Parámetros incompletos", false);
  return null;
}
if(vyy===true){
   alertas("Año no admitido", false);
   return null;
}
    setIsLoading(true);
    setmodalT(true);
    setopen(true);
    setData([]);

    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await pagination(3, "sumary", yy);
            const ndata = d.flatMap((result) => result?.report || []);
            
            settotal_ene(ndata?.map(obj=>obj.Enero).filter(d=>d!==null));
            settotal_feb(ndata?.map(obj=>obj.Febrero).filter(d=>d!==null))
            settotal_mar(ndata?.map(obj=>obj.Marzo).filter(d=>d!==null))
            settotal_abr(ndata?.map(obj=>obj.Abril).filter(d=>d!==null))
            settotal_may(ndata?.map(obj=>obj.Mayo).filter(d=>d!==null))
            settotal_jun(ndata?.map(obj=>obj.Junio).filter(d=>d!==null))
            settotal_jul(ndata?.map(obj=>obj.Julio).filter(d=>d!==null))
            settotal_ago(ndata?.map(obj=>obj.Agosto).filter(d=>d!==null))
            settotal_sep(ndata?.map(obj=>obj.Septiembre).filter(d=>d!==null))
            settotal_oct(ndata?.map(obj=>obj.Octubre).filter(d=>d!==null));
            settotal_nov(ndata?.map(obj=>obj.Noviembre).filter(d=>d!==null));
            settotal_dic(ndata?.map(obj=>obj.Diciembre).filter(d=>d!==null))

            sumatotales(ndata===null?[] : ndata);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
};

const sumatotales = (n)=>{  

  const newData = n.map(item => {
    let sumaTotal = 0;
    for (let month in item) {
      if (month !== 'code_pos' && month !== 'id_pos' && month !== 'deleted_logical_pos' && month !== 'id_route' 
      && month !== 'name_pos' && month !== 'name_route'
      ) {
        sumaTotal += item[month];
      }
    }
  
    return {
      ...item,
      sumaTotal: parseFloat((sumaTotal).toFixed(2)),
      promedio: parseFloat((sumaTotal / 12).toFixed(2)),
    };
  });


  console.log(newData);

  setgtotal(newData?.map(obj=>obj.sumaTotal).filter(d=>d!==null))
  setData(newData);
}

const handleyy = (event)=>{

  if(event.target.value<=2030 && event.target.value>=2010){
    setvyy(false);
  }else{
    setvyy(true);
  }
}
const columns = useMemo(
  () => [
    {
      id: "code_pos",
      header: "Código",
      accessorKey:"code_pos",
      Footer: () => (
        <div>
          <div>Sub Total: </div>
          <div>Gran Total: </div>
        </div>
      ),
    },
    {
      id: "deleted_logical_pos",
      header:"Vigente",
      accessorKey:"deleted_logical_pos",
      accessorFn:(d)=>{
        return d.deleted_logical_pos===true?"NO":"SI";
      },
      //Filter: SelectColumnFilter,
    },
    {
      id: "name_pos",
      header: "Punto de Venta",
      accessorKey:"name_pos"
    },
    {
      id: "name_route",
      header: "Ruta",
      accessorKey: "name_route"
    },
    {
      id: "Enero",
      header: "Enero",
      accessorKey: "Enero",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      }, 
      Footer: (d) => {
      const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Enero)
      return (
        <div style={{ textAlign: "right" }}>
          <div>{Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(calculate_total(subtotal))}</div>
          <div>{Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(calculate_total(total_ene))}</div>
        </div>
      )
    },   
    },
    {
      id: "Febrero",
      header: "Febrero",
      accessorKey: "Febrero",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },  
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Febrero)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_feb))}</div>
          </div>
        )
      },  
    },
    {
      id: "Marzo",
      header: "Marzo",
      accessorKey: "Marzo",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Marzo)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_mar))}</div>
          </div>
        )
      },     
    },
    {
      id: "Abril",
      header: "Abril",
      accessorKey: "Abril",
      Cell: ({ cell }) => {
        return (
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },  
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Abril)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_abr))}</div>
          </div>
        )
      },  
    },
    {
      id: "Mayo",
      header: "Mayo",
      accessorKey: "Mayo",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Mayo)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_may))}</div>
          </div>
        )
      },   
    },
    {
      id: "Junio",
      header: "Junio",
      accessorKey: "Junio",
      Cell: ({ cell }) => {
        return (
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Junio)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_jun))}</div>
          </div>
        )
      },  
    },
    {
      id: "Julio",
      header: "Julio",
      accessorKey: "Julio",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      }, 
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Julio)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_jul))}</div>
          </div>
        )
      },      
    },
    {
      id: "Agosto",
      header: "Agosto",
      accessorKey: "Agosto",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },   
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Agosto)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_ago))}</div>
          </div>
        )
      },  
    },
    {
      id: "Septiembre",
      header: "Septiembre",
      accessorKey: "Septiembre",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },  
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Septiembre)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_sep))}</div>
          </div>
        )
      },  
    },
    {
      id: "Octubre",
      header: "Octubre",
      accessorKey: "Octubre",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      }, 
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Octubre)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_oct))}</div>
          </div>
        )
      },    
    },
    {
      id: "Noviembre",
      header: "Noviembre",
      accessorKey: "Noviembre",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>)
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Noviembre)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_nov))}</div>
          </div>
        )
      },  
    },
    {
      id: "Diciembre",
      header: "Diciembre",
      accessorKey: "Diciembre",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
        )
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.Diciembre)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total_dic))}</div>
          </div>
        )
      },  
    },
    {
      id: "sumaTotal",
      header: "Total",
      accessorKey: "sumaTotal",
      Cell: ({ cell }) => {
        return (
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
        )
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.sumaTotal)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(gtotal))}</div>
          </div>
        )
      },  
    },
    {
      id: "semaforo",
      header: "Semaforo",
      accessorKey: "semaforo",
      accessorFn: (d)=>{
        const item = datasemaforo.find((item) => d.sumaTotal >= (item.minimum) && d.sumaTotal <= (item.max));
        return (item.color_hex==="#ff0000"? "🔴": 
                  item.color_hex === "#43e000" ? "🟢" :
                    item.color_hex === "#fff700" ? "🟡": "Sin semáforo")
        //return (item ? <div className="semaforo-celda" style={{"backgroundColor": item.color_hex}}/>:"Sin semáforo")
      },
      Cell: ({ cell }) => {
        return (
        <div className="semaforo-svg-anul">
          {cell.getValue()}
        </div>
        )
      },
      muiTableBodyCellProps:{
        align: "center"
      }
    }
    ],
    [
      datasemaforo, 
      total_ene, 
      total_feb,
      total_mar,
      total_abr,
      total_may,
      total_jun,
      total_jul,
      total_ago,
      total_sep,
      total_oct,
      total_nov,
      total_dic,
      gtotal
    ]
);

const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};


  
const [minimenu, setminimenu]=useState(false);
const [openmini, setopenmini]=useState(false);

 useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        console.log('Screen width is less than 480px');
        setminimenu(true);
      } else {
        setminimenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const filters_custom = (
    <div className="custom-year-search">
      <TextField
        label="Año"
        value={yy}
        type={"number"}
        required
        sx={{ m: 1.5, width: "100%" }}
        onChange={(event) =>{setyy(event.target.value)}} 
        onBlur={handleyy}
        InputLabelProps={{ shrink: true }}
      ></TextField>
      <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
          Buscar
      </button>        
    </div>
  );

return (
    <>
<div className="TableSummaryAnual">
  <div >         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={""}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    <>
    <BREADCRUMBS niveles={
      [
        {label:"REPORTE SUMARIA ANUAL", path: null}, 
      ]
    }
       
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}       
    ></BREADCRUMBS>
      <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
       

      <div className="new_table_v2">            
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        onStateChange={handleStateChange}
        enableColumnFilterModes
        // enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderTopToolbarCustomActions={({ }) => {
          if(minimenu===false){
            return(
            <>
              {filters_custom}
            </>
            )
          }
        }}
         renderToolbarInternalActions={({ table }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Consultar Sumaria Anual"}
             update_identifiers={update_identifiers}
             settotal_rows={settotal_rows}
           />
         )
        }
      />
      </div>
      </>           
    </div>
   </div>
    </>
  );
}

export default SUMMARY_ANNUAL_V2;
