//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import { CustomDialog, Confirm } from "react-st-modal";

//COMPONENTES

//ESTILOS

import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_debts_detail, delete_debts_detail } from "../../../services/banck/banck";
import DELETE from "../../../imgComponents/borrar.png";

function DETAIL_PAID_BY(props) {
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState([]);


  const handleSubmit = async (event) => {
    event?.preventDefault();
    setisLoading(true);
    try {
      const dt = await get_debts_detail(props.row.id);
      console.log(dt);
      setData(dt.data);
      setisLoading(false);
    } catch (err) {
      setData([]);
    }
  };

  const handle_delete_debts_detail = async (id) => {
    try {
      setisLoading(true);
      const dt = await delete_debts_detail(props.row.id, id);
      console.log(dt);
      setisLoading(false);
      props.handle_success_filter(false, [], "Pago eliminado con exito");
    } catch (err) { }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        header: "Acciones",
        Cell: ({ row }) => {
          return (
            <div>
              <img
                alt=""
                title="Eliminar pago"
                src={DELETE}
                onClick={async () => {
                  if (
                    sessionStorage.getItem("ROLE_INVOICING") ===
                    "ROLE_INVOICING"
                  ) {
                    const result = await Confirm(
                      "¿Esta usted seguro de eliminar este pago?",
                      "Eliminar Pago",
                      "Si",
                      "No"
                    );
                    if (result) {
                      handle_delete_debts_detail(row.original.id);
                    } else {
                    }
                  } else {
                    alert("No tienes permisos para eliminar un pago");
                  }
                }}
              ></img>
            </div>
          );
        },
      },
      {
        header: "Fecha de pago",
        accessorKey: "date",
      },
      {
        header: "Método de pago",
        accessorKey: "payment_method",
      },
      {
        header: "UUID",
        accessorKey: "uuid",
      },
      {
        header: "Nota",
        accessorKey: "note",
      },
      {
        header: "Total",
        accessorKey: "total",
      },
      {
        header: "Número de cuenta",
        accessorKey: "last_four_digits",
        accessorFn: (d) => {
          return d.last_four_digits !== null
            ? `xxxx-xxxx-xxxx-${d.last_four_digits}`
            : d.last_four_digits;
        },
      },
      {
        header: "Pagado por",
        accessorKey: "name_user_of_payment",
      },

    ],
    []
  );




  console.log(props.row.paid)
  console.log(props.row.id)

  return (
    <div>

      <div style={{ display: props.row.paid > 0 ? "inline-block" : "none" }}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnActions={false}
          enableColumnFilters
          enablePagination
          enableSorting
          enableBottomToolbar={false}
          enableTopToolbar={false}
          state={{ isLoading: isLoading }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          muiTableBodyRowProps={{ hover: false }}
        />
      </div>
      <div style={{ display: props.row.paid <= 0 ? "inline-block" : "none" }}>
        <p>NO SE ENCONTRARON PAGOS</p>
      </div>
    </div>

  );
}

export default DETAIL_PAID_BY;
