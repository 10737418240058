//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
//COMPONENTES
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { get_price_list_products } from "../../../../services/inventory/inventory";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../../../main_components/pagination/breadcrumbs";
import { update_price_list_products } from "../../../../services/inventory/inventory";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../../main_components/methods_v2/export";
import {
  Box,
  Button,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import UpgradeIcon from '@mui/icons-material/Upgrade';
//IMAGENES/ICONOS

export const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});
const UPDATE_PRICE_LIST_V2 = () => {
  const [dataTable, setDataTable] = useState([]);

  const [modalT, setmodalT] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [editedUsers, setEditedUsers] = useState([]);


  const handle_update_product = async () => {
    const ndt = dataTable.filter(d => d.price >= 0).map((d) => {
      var dt = {
        code: d.code,
        price: d.price
      }
      return dt
    });
    var send_price = ndt.filter((d) => d.price !== "");
    console.log(send_price)
    console.log("aaa")
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            if (send_price.length > 0) {
              await update_price_list_products(send_price, sessionStorage.getItem("price_list_products"));
              window.location = "/price_list_products";
            } else {
              alert("La lista de precios no puede quedar vacia a la hora de modificar"
              )
            }
          } catch (err) {
            alert(JSON.stringify(err?.response))
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };



  const handle_get_product = async () => {
    setDataTable([]);
    setmodalT(true);
    try {
      const isvaild = await refresh_token();
      if (isvaild) {
        setTimeout(async () => {
          try {
            const dt = await get_price_list_products(sessionStorage.getItem("price_list_products"));

            setDataTable(dt.data);
            setmodalT(false);
          } catch (error) {
            setDataTable([]);
            setmodalT(false);
          }

        }, 1500);
      }
    } catch (err) {
      setDataTable([]);
      setmodalT(false);
    }
  };

  const handle_send_data = async () => {
    setmodalT(true);
    console.log(Object.values(editedUsers))
    console.log(dataTable)

    const join_object = dataTable.map((originalItem) => {
      const editedItem = Object.values(editedUsers).find((editedObj) => editedObj.id === originalItem.id);

      if (editedItem) {
        return { ...originalItem, ...editedItem };
      } else {
        return originalItem;
      }
    });

    var obj_send_product = join_object.filter((d) => d.price !== "").map((d) => { return { code: d.code, price: Number(d.price) } });
    try {
      if (obj_send_product?.length > 0) {
        await update_price_list_products(obj_send_product, sessionStorage.getItem("price_list_products"));

        window.location = "/price_list_products_v2";
      } else {
        alert("La lista de precios no puede quedar vacia a la hora de modificar"
        )
      }
    } catch (err) {
      alert(JSON.stringify(err?.response))
    } finally {
      setmodalT(false);
    }

  }

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const columns = useMemo(
    () => [

      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        enableEditing: false,
      },
      {
        id: "product_name",
        header: "Producto",
        accessorKey: "product_name",
        size: 400,
        enableEditing: false,
      },
      {
        id: "brand",
        header: "Linea",
        accessorKey: "brand",
        enableEditing: false,
      },
      {
        id: "minimum_sales_unit",
        header: "Presentación",
        accessorKey: "minimum_sales_unit",
        enableEditing: false,
      },
      {
        id: "pieces",
        header: "Piezas",
        accessorKey: "pieces",
        enableEditing: false,
      },
      {
        header: "Precio",
        accessorKey: "price",
        id: "price",
        enableFilters: false,//deshabilita el filtrado
        enableRowOrdering: false,//deshabilita el orden de la columna
        enableGrouping: false,//deshabilita el grupo de la columna
        enableColumnDragging: false,//deshabilita el movimiento de la columna
        enableColumnActions: false,//deshabilita el las acciones
        muiEditTextFieldProps: ({ cell, row }) => ({ //permite la edición a nivel de input
          type: 'number',
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          // disabled: !rowSelection[row.id], //habilita las columnas que esten seleccionadas
          onBlur: (event) => {
            // const validationError = !validateRequired(event.currentTarget.value)
            //   ? 'Required'
            //   : undefined;
            // setValidationErrors({
            //   ...validationErrors,
            //   [cell.id]: validationError,
            // });
          },
          onChange: (event) => {
            const updatedValue = event.target.value;
            setEditedUsers(prev => ({
              ...prev,
              [row.id]: {
                ...row.original,
                "price": updatedValue,
              }
            }));
          }
        }),
      }
    ],
    [rowSelection]
  );


  useEffect(() => {
    handle_get_product();
  }, []);

  return (
    <div >
      <BREADCRUMBS niveles={
        [
          { label: "LISTA DE PRECIOS", path: "/price_list_v2" },
          { label: "PRODUCTOS", path: "/price_list_products_v2" },
          { label: `MODIFICAR PRODUCTOS`, path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={dataTable?.length}
        rowSelection={rowSelection}
      ></BREADCRUMBS>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>

      <div className="new_table_v2">
        <MaterialReactTable
          columns={columns}
          data={dataTable}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          onStateChange={handleStateChange}
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          editDisplayMode={'table'} //habilita la edicion en cualquier campo de la tabla
          enableEditing //habilta la edición en tabla
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          // initialState={{
          //   showGlobalFilter: true,
          // columnOrder: [ //Organiza la piciones en como quieres que se precenten tus datos
          //   'code',
          //   'product_name',
          //   'brand',
          //   'minimum_sales_unit',
          //   'pieces',
          //   'price',
          //   'mrt-row-select',
          // ],
          // }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,

            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                dataTable.splice(
                  hoveredRow.index,
                  0,
                  dataTable.splice(draggingRow.index, 1)[0],
                );
                setDataTable([...dataTable]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderTopToolbarCustomActions={({ }) => (
            <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
              <FormControl required sx={{ width: 300 }}>
                <Button variant="contained"
                  sx={{ height: '35px' }}
                  onClick={() => handle_send_data()}
                >
                  ACTUALIZAR LISTA DE PRECIOS<UpgradeIcon></UpgradeIcon>
                </Button>
              </FormControl>
            </Box>
          )}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Productos"}
              // update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>

    </div>
  );
};

export default UPDATE_PRICE_LIST_V2;
