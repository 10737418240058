//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
} from '@mui/material';
//COMPONENTES

import TableContainer_data from "../table_movements";
import { get_warehouse_existence } from "../../../services/inventory/inventory";
import { create_warehouse_movements } from "../../../services/inventory/inventory";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { get_warehouse } from "../../../services/inventory/inventory";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import TableChartIcon from '@mui/icons-material/TableChart';
import PanToolIcon from '@mui/icons-material/PanTool';
import { MaterialReactTable } from 'material-react-table';

import { calculate_total } from "../../../main_components/methods_v2/auxiliary_functions";
//ESTILOS
import "./movements.css";
//IMAGENES/ICONOS
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";

export const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});
const WAREHOUSE_MOVEMENTS_LIST = (props) => {

  const [dataTable, setDataTable] = useState([]);
  const [dataTableOriginal, setdataTableOriginal] = useState([]);

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [reference, setreference] = useState("");
  const [note, setnote] = useState("");
  const [validate_reference, setvalidate_reference] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [originalData] = useState(dataTable);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [quantity, setquantity] = useState(0);
  const [origin, setorigin] = useState(0);
  const [destination, setdestination] = useState(0);
  const [warehouse, setwarehouse] = useState(0);
  const [almacenO, setalmacenO] = useState(0);
  const [almacenD, setalmacenD] = useState(0);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tableData_data, setTableData_data] = useState([]);
  console.log(tableData_data)
  const handleProductChange = (event, newValue) => {
    setSelectedProduct(newValue);
  };

  const handleQuantityChange = (event) => {
    const quantity = event.target.value;
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      quantity: quantity,
    }));
  };

  const handleAddToTable = () => {
    console.log(selectedProduct);
    try {
      if (selectedProduct) {
        setTableData_data((prevData) => {
          // Clonar prevData para evitar mutaciones no deseadas
          const newData = [...prevData];

          if (sessionStorage.getItem("origin") === "-1") {
            // Agregar selectedProduct directamente a newData
            newData.push(selectedProduct);
          } else {
            // Agregar selectedProduct directamente a newData
            newData.push(selectedProduct);

            // Verificar si alguna cantidad supera el origen
            const hasQuantityGreaterThanOrigin = sumQuantityById(newData).some(item => item.quantity > item.origin);

            if (hasQuantityGreaterThanOrigin) {
              alert("La suma de las cantidades del producto con el código " + selectedProduct.code + " supera el origen");
              return prevData; // Devolver prevData sin cambios
            }
          }

          // Limpiar selectedProduct después de agregarlo
          setSelectedProduct(null);

          // Devolver newData actualizado
          return newData;
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };



  const handleRemoveFromTable = (productToRemove) => {
    setTableData_data((prevData) =>
      prevData.filter((product) => product !== productToRemove)
    );
  };




  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    setMenssage("Productos...");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_warehouse_existence(
              sessionStorage.getItem("origin")
            );
            const dt2 = await get_warehouse_existence(
              sessionStorage.getItem("destination")
            );
            setData(dt.data);
            setData2(dt2.data);

            const ndatadestination = dt2.data.map((e) => ({
              id: e.id_products_fk,
              destination: e.quantity,
            }));
            console.log(dt.data)
            console.log(ndatadestination)

            if (sessionStorage.getItem("origin") === "-1") {
              const ndata = dt2.data.map((e, index) => ({
                code: e.code,
                id: e.id_products_fk,
                description: e.description,
                origin: 0,
                quantity: 0,
                destination: e.quantity,
              }));

              console.log(ndata);
              setDataTable(ndata);
              setdataTableOriginal(ndata)
            } else {
              const ndata = dt.data.map((e, index) => {
                const matchingItem = ndatadestination.find(item => item.id === e.id_products_fk);
                console.log(matchingItem)

                const destination = matchingItem ? matchingItem.destination : 0;
                console.log(destination)

                return {
                  code: e.code,
                  id: e.id_products_fk,
                  description: e.description,
                  origin: e.quantity,
                  quantity: 0,
                  destination: destination,
                };
              });

              console.log(ndata)
              setDataTable(ndata);
              setdataTableOriginal(ndata)

            }

            setmodalT(false);
          } catch (err) {
            setDataTable([]);
            setMenssage("");
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_warehouse = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_warehouse();
            const data = dt.data;
            var dtAdd = {
              id: -1,
              warehouse_name: "ENTRADA",
            };
            var dtAdd2 = {
              id: -2,
              warehouse_name: "SELECCIONA UN ALMACEN",
            };

            data.push(dtAdd);
            data.push(dtAdd2);
            console.log(data)
            const origenWarehose = data.filter((d) => d.id === Number(sessionStorage.getItem("origin")));
            const destinationWarehose = data.filter((d) => d.id === Number(sessionStorage.getItem("destination")));
            setalmacenO(origenWarehose[0]?.warehouse_name)
            setalmacenD(destinationWarehose[0]?.warehouse_name);
            setwarehouse(data);
          } catch (error) {
            setwarehouse([]);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };



  const sumQuantityById = (data) => {
    const result = {};

    data.forEach((item) => {
      const id = item.id;
      const origin = item.origin;

      const quantity = Number(item.quantity);

      if (result[id]) {
        result[id].quantity += Number(quantity);
      } else {
        result[id] = { id, quantity, origin };
      }
    });

    const resultList = Object.values(result);

    return resultList;
  };


  const handle_create_movement = async () => {

    const ndt = tableData_data.filter((d) => d.quantity !== "").map((d) => { return { id: d.id, quantity: Number(d.quantity) } }).filter(d => isNaN(d.quantity) !== true).map((d) => {
      console.log(d)
      var dt = {
        id: d.id,
        quantity: d.quantity
      }
      return dt
    });



    var env_data = {
      origin: sessionStorage.getItem("origin"),
      destination: sessionStorage.getItem("destination"),
      note: note,
      reference: reference,
      movement: sumQuantityById(ndt),
    };

    console.log(env_data);

    if (ndt.length > 0) {
      setmodalT(true);
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              await create_warehouse_movements(env_data);
              setmodalT(false);
              window.location = "/movements_v2"
            } catch (err) {
              setmodalT(false);
              alert(JSON.stringify(err.response))
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Realiza almenos un movimiento");
    }
  };



  useEffect(() => {
    handleSubmit();
    handle_warehouse();
  }, []);



  useEffect(() => {
    console.log(dataTable)
    setSkipPageReset(false);
    var ntest = dataTable.filter((d) => d.quantity !== "");
    console.log(ntest);

    setorigin(
      dataTable
        .map((a) => Number(a.origin))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    setdestination(
      dataTable
        .map((a) => Number(a.destination))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );

  }, [dataTable]);

  useEffect(() => {
    setDataTable(dataTable);
  }, [dataTable]);


  const columns = useMemo(
    () => [
      {
        header: "Código",
        accessorKey: "code",
      },
      {
        header: "Descripción",
        accessorKey: "description",
      },
      {
        header: "Cantidad",
        accessorKey: "quantity",
        Footer: (d) => {
          const total_ = d.table.getCoreRowModel().rows.map(obj => obj.original).map(obj => obj.quantity)
          return (
            <div style={{ textAlign: "center" }}>
              <div>{calculate_total(total_)}</div>

            </div>
          )
        },
      },
      {
        header: "Acciones",
        accessorFn: (d) => {
          return (
            <Button
              variant="outlined"
              onClick={() => handleRemoveFromTable(d)}
            >
              Eliminar
            </Button>);
        },
      },

    ],
    [tableData_data]
  );




  return (
    <div >

      <BREADCRUMBS niveles={
        [
          { label: "MOVIMIENTO DE PRODUCTOS", path: "/movements_v2" },
          { label: "GENERAR MOVIMIENTO POR TABLA", path: "/werehouse_movements_v2" },
          { label: "GENERAR MOVIMIENTO POR LISTA", path: null },
        ]
      }
      ></BREADCRUMBS>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', marginBottom: "10px" }}>

        <ValidationTextField
          label="Referencia"
          placeholder="Referencia"
          variant="outlined"
          sx={{ width: "50%" }}
          defaultValue="Success"
          name="capital_regime"
          value={reference}
          onChange={(e) => {
            const value = e.target.value;
            if (typeof value === "string") {
              setreference(value.toUpperCase());
            } else {
              setreference(value);
            }
          }}

          InputLabelProps={{
            shrink: true,
          }}
        />

        <ValidationTextField
          label="Nota"
          placeholder="Nota"
          variant="outlined"
          sx={{ width: "50%" }}
          defaultValue="Success"
          name="capital_regime"
          value={note}
          onChange={(e) => {
            const value = e.target.value;
            if (typeof value === "string") {
              setnote(value.toUpperCase());
            } else {
              setnote(value);
            }
          }}

          InputLabelProps={{
            shrink: true,
          }}
        />

      </Box>

      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', marginBottom: "10px" }}>
        <Autocomplete
          id="product-search"
          options={dataTable}
          sx={{ width: "40%" }}
          getOptionLabel={(option) => `${option.code} - ${option.description}`}
          value={selectedProduct}
          onChange={handleProductChange}
          renderInput={(params) => <TextField {...params} label="Buscar producto" />}
        />
        <ValidationTextField
          id="Origen"
          label="Origen"
          sx={{ m: .3, width: "10%" }}
          type="number"
          value={selectedProduct ? selectedProduct.origin : 0}
          onChange={handleQuantityChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ValidationTextField
          id="quantity"
          label="Cantidad"
          sx={{ m: .3, width: "10%" }}
          type="number"
          value={selectedProduct ? selectedProduct.quantity : 0}
          onChange={handleQuantityChange}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Button variant="contained" onClick={handleAddToTable} sx={{ width: "20%" }}
        >
          Agregar
        </Button>
        <FormControl required sx={{ width: 280 }}>
          <Button variant="contained"
            sx={{ height: '35px', backgroundColor: "red" }}
            onClick={() => props.setmanual_movement(false)}
          >
            <TableChartIcon></TableChartIcon>MOVIMIENTO POR TABLA
          </Button>
        </FormControl>
        <FormControl required sx={{ width: 280 }}>
          <Button variant="contained"
            sx={{ height: '35px', backgroundColor: "green" }}
            onClick={() => handle_create_movement()}
          >
            <ProductionQuantityLimitsIcon></ProductionQuantityLimitsIcon>CREAR MOVIMIENTO
          </Button>
        </FormControl>
      </Box>


      <MaterialReactTable
        columns={columns}
        data={tableData_data}
        enableColumnActions
        enableColumnFilters
        enablePagination
        enableSorting
        muiTableBodyRowProps={{ hover: false }}
        initialState={{
          showColumnFilters: true,
          pageSize: 1000
        }}
        muiPaginationProps={{
          showRowsPerPage: true,
          shape: 'rounded',
        }}

        paginationDisplayMode={'pages'}
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", color: "black" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

      />;


      {/* <div className="containerMovementsProduct2">
        <div className="pdc-1 color-1">
          <ValidationTextField
            label="Referencia"
            placeholder="Referencia"
            variant="outlined"
            sx={{ m: 2, width: "90%" }}
            defaultValue="Success"
            name="capital_regime"
            value={reference}
            onChange={(e) => {
              const value = e.target.value;
              if (typeof value === "string") {
                setreference(value.toUpperCase());
              } else {
                setreference(value);
              }
            }}

            InputLabelProps={{
              shrink: true,
            }}
          />

          <ValidationTextField
            label="Nota"
            placeholder="Nota"
            variant="outlined"
            sx={{ m: 2, width: "90%" }}
            defaultValue="Success"
            name="capital_regime"
            value={note}
            onChange={(e) => {
              const value = e.target.value;
              if (typeof value === "string") {
                setnote(value.toUpperCase());
              } else {
                setnote(value);
              }
            }}

            InputLabelProps={{
              shrink: true,
            }}
          />

        </div>

        <div className="pdc-2 color-2">
          <ValidationTextField
            label="Nota"
            placeholder="Nota"
            variant="outlined"
            sx={{ m: 2, width: "90%" }}
            defaultValue="Success"
            name="capital_regime"
            value={note}
            onChange={(e) => {
              const value = e.target.value;
              if (typeof value === "string") {
                setnote(value.toUpperCase());
              } else {
                setnote(value);
              }
            }}

            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="pdc-3">
          <Button
            sx={{ width: "100%" }}
            style={{
              display: sessionStorage.getItem("origin") === "-1" ? "none" : "",
            }}
            variant="contained"
            onClick={() => handle_move_all_product()}
          >
            Mover Todo el Producto
          </Button>
        </div>
        <div className="pdc-4">
          <Button
            sx={{ width: "100%" }}

            variant="contained"
            onClick={async () => {

              const result = await Confirm(
                "¿Esta usted seguro de relizar este movimiento?",
                "Realizar movimiento",
                "Si",
                "No"
              );
              if (result) {
                handle_create_movement();
              } else {
              }

            }}
          >
            Crear Movimiento
          </Button>
        </div>
      </div> */}

    </div>
  );
};

export default WAREHOUSE_MOVEMENTS_LIST;
