//PAQUETERIAS
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NotificationSystem from "react-notification-system";
//COMPONENTES
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { custom_multiple_filter, calculate_total } from '../../main_components/methods_v2/auxiliary_functions';
import { formattedCurrentDate, formattedPreviousDate } from "../../main_components/date/day";
import SEARCH_FILTERS from './tools/shearch_filter';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import RETURNS_SALES from './tools/returns_sales';
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import AlertResponse from '../../main_components/alerts/alertResponse';
import { reports_pag } from '../../services/reports/reports_pag';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { SEARCH_FILTER_WEB_MOVIL } from '../../main_components/methods_v2/search_filter_web_movil';

const REPORT_SALES = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const notificationSystemRef = useRef();
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState("all");
  const [tipodoc, setTipoDoc] = useState("null");
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [id_businesspartners, setid_businesspartners] = useState(null);
  const [id_pointsofsale, setid_pointsofsale] = useState(null);
  const [name_ticket, setname_ticket] = useState("");
  const [name_route, setname_route] = useState("TODAS");
  const [id_sale_type_fk, setid_sale_type_fk] = useState("TODAS");
  const [name_freezer, setname_freezer] = useState("");
  const [sale_returns, setsale_returns] = useState(0);

  const [invoicing, setinvoicing] = useState(true);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [restriction_dt, setrestriction_dt] = useState("SC");
  const [id_shearch_dt, setid_shearch_dt] = useState("");
  const [red_data, setred_data] = useState([]);
  const [yello_data_data, setyello_data_data] = useState([]);
  const [green_data, setgreen_data] = useState([]);

  const [g_total, setg_total] = useState(0);
  const [g_subtotal, setg_subtotal] = useState(0);
  const [g_tax, setg_tax] = useState(0);

  const [g_debts, setg_debts] = useState(0);
  const [g_discount, setg_discount] = useState(0);


  const [g_returns, setg_returns] = useState(0);
  const [g_paid, setg_paid] = useState(0);
  const [usStateList, setusStateList] = useState([]);

  const update_identifiers =
    [
      { date: "string" },
      { scan: "string" },
      { latitude: "string" },
      { longitude: "string" },
      { note: "number" },
      { discount: "number" },
      { tax: "number" },
      { subtotal: "number" },
      { total: "number" },
      { ticket: "string" },
      { special_folio_number: "string" },
      { uuid: "string" },
      { route: "string" },
      { device_date: "string" },
      { downloadable: "string" },
      { synchronized: "string" },
      { delivered: "string" },
      { deleted_logical: "string" },
      { date_register: "string" },
      { bill: "string" },
      { externdoc: "string" },
      { close: "string" },
      { folio_dms: "string" },
      { no_invoice: "string" },
      { reference1: "string" },
      { reference2: "string" },
      { reference3: "string" },
      { folio_invoice: "string" },
      { name_business_partner: "string" },
      { rfc_business_partner: "string" },
      { name_pos: "string" },
      { code_pos: "string" },
      { generate_debt_pos: "string" },
      { pay_condition_pos: "string" },
      { debt: "number" },
      { payed: "number" },
      { name_person_user: "string" },
      { saletype: "string" },
      { payment_method: "string" },
      { rows: "string" },
      { complete: "string" },
      { paper: "string" },
      { invoice_date: "string" },
      { cancelled: "string" },
      { type_user_of_register: "string" },
      { ticket: "string" },
      { code_pos: "string" },
      { code_partner: "string" },
      { route: "string" },
      { scan: "string" }



    ];


  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
          if (isToUpdate && item[identifier] === null) {
            item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
          }
        }
      }
      return item;
    });
  };

  const update_null_identifiers_ticket = (original_obj, identifiers_to_update) => {
    for (const identifier in original_obj) {
      if (original_obj.hasOwnProperty(identifier)) {
        const isToUpdate = identifiers_to_update.some((update) => update.hasOwnProperty(identifier));
        if (isToUpdate && original_obj[identifier] === null) {
          const updateType = identifiers_to_update.find((update) => update[identifier]);
          original_obj[identifier] = updateType === 'number' ? 0 : '';
        }
      }
    }
    return original_obj;
  };


  const handle_get_sales = async (query_filters_flag, query_filters_data) => {
    setIsLoading(true);
    setData([]);
    setopenmini(false);
    try {
      await refresh_token();
      try {
        var dt;
        var dta;
        if (query_filters_flag) {
          setrestriction_dt(query_filters_data.bp !== "" ? "SN" : query_filters_data.pos !== "" ? "PDV" : query_filters_data.sale != "" ? "TK" : query_filters_data.scan != "" ? "CG" : "SC");
          setid_shearch_dt(query_filters_data?.id_shearch_dt);
          setfi(dayjs(query_filters_data.di));
          setff(dayjs(query_filters_data.df));
          setsale_returns(query_filters_data.sale_returns);
          setid_sale_type_fk(query_filters_data.saletype);

          dt = await reports_pag(
            8,
            "sales_report",
            query_filters_data.di,
            query_filters_data.df,
            query_filters_data.route === "null" ? null : query_filters_data.route,
            query_filters_data.saletype === "null" ? null : query_filters_data.saletype,
            query_filters_data.scan === "null" ? null : query_filters_data.scan,
            query_filters_data.pos === "null" ? null : query_filters_data.pos,
            query_filters_data.bp === "null" ? null : query_filters_data.bp,
            query_filters_data.sale === "null" ? null : query_filters_data.sale,
            query_filters_data.sale_returns,

          );

        } else {
          setquery_filters({
            di: fi.format('YYYY-MM-DD'),
            df: ff.format('YYYY-MM-DD'),
            shearch_dt: id_shearch_dt,
            route: name_route,
            saletype: id_sale_type_fk,
            scan: name_freezer,
            pos: id_pointsofsale,
            bp: id_businesspartners,
            sale: name_ticket,
            sale_returns: sale_returns
          });

          dt = await reports_pag(
            8,
            "sales_report",
            fi.format('YYYY-MM-DD'),
            ff.format('YYYY-MM-DD'),
            name_route === "TODAS" ? null : name_route,
            id_sale_type_fk === "TODAS" ? null : id_sale_type_fk,
            name_freezer,
            id_pointsofsale,
            id_businesspartners,
            name_ticket,
            sale_returns === 0 ? null : sale_returns);
        }
        if (name_ticket !== "") {
          dta = update_null_identifiers_ticket(dt, update_identifiers)
        } else if (query_filters_data?.sale === undefined ? "" : query_filters_data?.sale !== "") {
          dta = update_null_identifiers_ticket(dt, update_identifiers)
        }
        else {
          dta = update_null_identifiers(dt, update_identifiers)
        }
        setg_total(dta?.map(obj => obj.total).filter(d => d !== null));
        setg_subtotal(dta?.map(obj => obj.subtotal).filter(d => d !== null));
        setg_tax(dta?.map(obj => obj.tax).filter(d => d !== null));
        setg_debts(dta?.map(obj => obj.debt).filter(d => d !== null));
        setg_discount(dta?.map(obj => obj.discount).filter(d => d !== null));
        setg_paid(dta?.map(obj => obj.payed).filter(d => d !== null));


        setData(dta);
        // var green_data = dta.filter((d) => d.debt_flag === 0);
        // var yello_data = dta.filter((d) => d.debt_flag === 1);
        // var red_data = dta.filter((d) => d.debt_flag === 2);

        // setyello_data_data(
        //   ((yello_data.length / dta.length) * 100).toFixed(2)
        // );
        // setred_data(((red_data.length / dta.length) * 100).toFixed(2));
        // setgreen_data(((green_data.length / dta.length) * 100).toFixed(2));
        const list = Array.from(new Set(dta?.map(obj => obj.route)));
        setusStateList(list);
      } catch (error) {
        console.log(error);
        setData([]);
        setIsLoading(false);
        return null;
      } finally {
        setIsLoading(false);

      }
    } catch (err) {
      setData([]);
      setIsLoading(false);
      setmodalT(false);
      console.log(err);

    }

  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handle_success_filter = (a, b, c) => {
    handle_get_sales(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={c} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  };


  const columns = useMemo(
    () => [

      {
        id: "route-list",
        header: "Ruta",
        accessorKey: "route",
        size: 145,
        id: "route-list",
        filterVariant: 'multi-select',
        filterSelectOptions: usStateList,
        filtferFn: custom_multiple_filter,
        enableColumnFilterModes: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
        Footer: () => (
          <div>
            <div>Sub Total: </div>
            <div>Gran Total: </div>
          </div>
        ),
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      

      {
        id: "folio_invoice",
        header: "Factura",
        accessorKey: "folio_invoice",
        size: 150,
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "special_folio_number",
        header: "Folio Especial",
        accessorKey: "special_folio_number",
        size: 200,
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "ticket",
        header: "Tikect",
        accessorKey: "ticket",
        size: 145,
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "scan",
        header: "Congelador",
        accessorKey: "scan",
        size: 145,
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "code_pos",
        header: "Código Punto de venta",
        accessorKey: "code_pos",
        muiTableBodyCellProps: {
          align: 'center',
        },
        size: 200
      },
      {
        id: "name_pos",
        header: "Punto de venta",
        accessorKey: "name_pos",
        muiTableBodyCellProps: {
          align: 'justify',
        },
        size: 200

      },
      {
        id: "code_partner",
        header: "Código Socio de Negocios",
        accessorKey: "code_partner",
        muiTableBodyCellProps: {
          align: 'center',
        },
        size: 200

      },
      {
        id: "name_business_partner",
        header: "Socio de Negocios",
        accessorKey: "name_business_partner",
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },
      {
        id: "date",
        header: "Fecha",
        accessorKey: "date",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "saletype",
        header: "Tipo",
        accessorKey: "saletype",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "rfc_business_partner",
        header: "RFC",
        accessorKey: "rfc_business_partner",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
     
      {
        header: "Tipo de socio",
        accessorKey: "partner_type",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "subtotal",
        header: "Sub Total",
        accessorKey: "subtotal",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_subtotal))}</div>
            </div>
          )
        },
      },
      {
        id: "discount",
        header: "Descuentos",
        accessorKey: "discount",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.discount)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_discount))}</div>
            </div>
          )
        },
      },
      {
        id: "tax",
        header: "Impuestos",
        accessorKey: "tax",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.tax)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_tax))}</div>
            </div>
          )
        },
      },

      {
        id: "total",
        header: "Total",
        accessorKey: "total",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.total)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_total))}</div>
            </div>
          )
        },
      },

      {
        id: "debt",
        header: "Adeudo",
        accessorKey: "debt",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.debt)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_debts))}</div>
            </div>
          )
        },
      },
      {
        id: "payed",
        header: "Pagado",
        accessorKey: "payed",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.payed)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_paid))}</div>
            </div>
          )
        },
      },
      {
        id: "payment_method",
        header: "Método de Pago",
        accessorKey: "payment_method",
        muiTableBodyCellProps: {
          align: 'center',
        },
        size: 200
      },
      {
        id: "pay_condition_pos",
        header: "Crédito",
        accessorKey: "pay_condition_pos",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "name_person_user",
        header: "Vendedor",
        accessorKey: "name_person_user",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "complete",
        header: "Completa",
        accessorKey: "complete",
        accessorFn: (d) => {
          return d.complete === false ? "❌" : "✅";
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "generate_debt_pos",
        header: "Gen. Deuda",
        accessorKey: "generate_debt_pos",
        accessorFn: (d) => {
          return d.generate_debt_pos === false ? "NO" : "SI";
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "close",
        header: "Venta Cerrada",
        accessorKey: "close",
        accessorFn: (d) => {
          return d.close === true ? "SI" : "NO";
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "note",
        header: "Nota",
        accessorKey: "note",
        accessorFn: (d) => {
          return d?.note?.replace(/<br>/g, '');
        },
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },






    ],
    [g_total, g_debts, g_returns, g_paid, data, usStateList],
  );

  useEffect(() => {
    handle_get_sales();
  }, []);


  
  
  const [minimenu, setminimenu]=useState(false);
  const [openmini, setopenmini]=useState(false);

   useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 480) {
          console.log('Screen width is less than 480px');
          setminimenu(true);
        } else {
          setminimenu(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const filters_custom = (
      <SEARCH_FILTERS
      fi={fi}
      setfi={setfi}
      ff={ff}
      setff={setff}
      tipodoc={tipodoc}
      setTipoDoc={setTipoDoc}
      cancel={cancel}
      setCancel={setCancel}
      rfc={rfc}
      setRFC={setRFC}
      sdn={sdn}
      setSDN={setSDN}
      handle_get_sales={handle_get_sales}
      setid_businesspartners={setid_businesspartners}
      setid_pointsofsale={setid_pointsofsale}
      invoicing={invoicing}
      setinvoicing={setinvoicing}
      restriction_dt={restriction_dt}
      id_shearch_dt={id_shearch_dt}
      setid_shearch_dt={setid_shearch_dt}
      setrestriction_dt={setrestriction_dt}
      red_data={red_data}
      yello_data={yello_data_data}
      green_data={green_data}
      setname_freezer={setname_freezer}
      setname_ticket={setname_ticket}
      setname_route={setname_route}
      setid_sale_type_fk={setid_sale_type_fk}
      id_sale_type_fk={id_sale_type_fk}
      name_route={name_route}
      setsale_returns={setsale_returns}
      sale_returns={sale_returns}
    ></SEARCH_FILTERS>
    );


  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>
      <BREADCRUMBS niveles={
        [
          { label: "REPORTE DE VENTAS" },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        modul="CONSULTAR_FACTURAS_V1"
        path="/consultaCFDI"
      ></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        onStateChange={handleStateChange}
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}

        muiExpandButtonProps={({ row }) => ({
          children: row?.original?.returns?.length === undefined ? <span title='SIN DEVOLUCIÓNES'><ExpandMoreIcon></ExpandMoreIcon></span> : row?.original?.returns?.length === 0 ? <span title='SIN DEVOLUCIÓNES'><ExpandMoreIcon></ExpandMoreIcon></span> : <span title='DETALLES DE DOVOLUCIONES'><ExpandMoreIcon></ExpandMoreIcon></span>,
        })}

        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);

            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderDetailPanel={({ row }) =>
          row?.original?.returns?.length === undefined ? "Sin devolución" : row?.original?.returns?.length > 0 ? (
            <RETURNS_SALES
              row={row?.original?.returns}
              id={row?.original?.id}
              columns={columns}
              handle_success_filter={handle_success_filter}

            />
          ) : "Sin devolución"
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS
            key={row.original.id}
            closeMenu={closeMenu}
            setmodalT={setmodalT}
            setmenssage={setmenssage}
            setmodalGeneral={setmodalGeneral}
            row={row}
            handle_get_sales={handle_get_sales}
            refresh_token={refresh_token}
            handle_success_filter={handle_success_filter}
          />
        ]}

        renderTopToolbarCustomActions={({ }) => {
          if(minimenu===false){
            return(
            <>
              {filters_custom}
            </>
            )
          }
        }}
        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx}
            share_filter={true}
            query_filters={query_filters}
            settotal_rows={settotal_rows}
            handle_success_filter={handle_success_filter}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"FACTURAS DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
            update_identifiers={update_identifiers}
          />
        )
        }
      />
      {/* </LocalizationProvider> */}
    </div >
  );
};

export default REPORT_SALES;