import { useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useDialog} from "react-st-modal";
import { catchErrorModul } from "../main_components/catchErr/catchError";
import { refresh_token  } from "../main_components/tokens/tokenrefresh";
import { post_bonification_packages } from "../services/bonifications/bonifications";

function SetBonuses_v2(props) {
  const dialog = useDialog();
  const[numpack, setnumpack] = useState(0);
  const[namepack, setnamepack] = useState("");

  const handelonCLik = async()=>{

  if (numpack === 0 || namepack === "") {
      alert("Datos vacios");
    } else {
      var data = {
        numberBP: numpack,
        nameBP: namepack,
      };    
      try{
        props?.setmodalT(true);
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
                 const d = await post_bonification_packages(data);
                 console.log(d);
                 sessionStorage.setItem("idpack", d?.data?.bonificationPackageID);
                //  sessionStorage.setItem("name_pack", namepack);
                //  sessionStorage.setItem("num_pack", numpack); 
                // window.location = "/add_sales_bonificaciones_v2";  
                 const queryParams = {
                   name_pack: namepack,
                   num_pack: numpack,
                 };
                 const queryString = Object.keys(queryParams)
                   .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]))
                   .join('&');
                 const nuevaURL = "/add_sales_bonificaciones_v2" + (queryString ? '?' + queryString : '');
                 window.location.href = nuevaURL;
                 props?.setmodalT(false);
                
            }catch(err){
              // catchErrorModul("Bonificaciones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
              props?.setmodalT(false);
              console.log("error", err);
              alert("Paquete ya existente");
            }
          }, 1000);
        }else{}
      }catch(err){
        console.log(err);
      }
    }
  }

  return (
    <div style={{"textAlign":"center"}}>
      <div>
          <Box>
            <TextField
              label="Nombre"
              required
              sx={{ m: 1.5, width: "25ch" }}
              onChange={(event) => setnamepack(event.target.value)}
              InputLabelProps={{ shrink: true }}

            ></TextField>
            <TextField
              label="Número"
              type={"number"}
              required
              sx={{ m: 1.5, width: "25ch" }}
              onChange={(event) => setnumpack(event.target.value)}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
          </Box>
          <br></br>
          <br></br>
          <Box>
            <Button
              style={{"background-color":"green"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={handelonCLik}
            >
              Crear
            </Button>
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
              cancelar
            </Button>
          </Box>
      </div>
    </div>
  );
}

export default SetBonuses_v2;
