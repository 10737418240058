//PAQUETERIAS
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NotificationSystem from "react-notification-system";
import { CustomDialog } from "react-st-modal";

//COMPONENTES
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { custom_multiple_filter, calculate_total } from '../../../main_components/methods_v2/auxiliary_functions';
import { get_invoices } from '../../../services/invoices/invoice';
import { formattedCurrentDate, formattedPreviousDate } from "../../../main_components/date/day";
import SEARCH_FILTERS from './tools/shearch_filter';
import MODAL_TABLE from '../../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import DETAIL_INVOICE_V2 from './detail/detail_invoce_v2';
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import AlertResponse from '../../../main_components/alerts/alertResponse';
import { pagination_v2 } from '../../../main_components/pagination/pagination_v2';
import { get_status_merhod_version_2 } from '../../../services/invoices/invoice';
import CANCEL_INVOICE_IMG from "../../../imgComponents/tache.png"
import STATUS_INVOICE_IMG from "../../../imgComponents/re-facturar.png";

import CANCEL_INVOICE_METHOD_2 from '../cancel_invoice/cancel_invoice_method_2';
//CSS
import "./container_v2.css"
import { update_null_identifiers } from '../../../main_components/methods_v2/auxiliar_funtion';
import { SEARCH_FILTER_WEB_MOVIL } from '../../../main_components/methods_v2/search_filter_web_movil';


const INVOICE_V2 = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const notificationSystemRef = useRef();
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState("all");
  const [tipodoc, setTipoDoc] = useState("null");
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  const [g_total, setg_total] = useState([]);
  const [g_subtotal, setg_subtotal] = useState([]);
  const [g_tax, setg_tax] = useState([]);
  const [g_discount, setg_discount] = useState([]);
  const [usStateList, setusStateList] = useState([]);

  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");

  const [updateData, setUpdateData] = useState(false);




  const update_identifiers =
    [{ subtotal: 'number' },
    { discount: 'number' },
    { tax: 'number' },
    { total: 'number' },
    { invoice_date: "string" },
    { serie: "string" },
    { folio: "string" },
    { name_business_partner: "string" },
    { rfc_business_partner: "string" },
    { tipodecomprobante: "string" },
    { username: "string" },
    { payment_method: "string" },
    { uuid_sat: "string" },
    { name_pos: "string" },
    { ticket: 'string' },
    { forma_pago: 'string' },
    { amount_of_debt: 'string' },
    { amount_paid: 'string' },
    { paid: 'string' },
    { installment: 'string' },
    { currency: 'string' },
    { filename: 'string' },
    { filename_cancel: 'string' },
    { status: 'string' },
    { its_cancelable: 'string' },
    { cancelation_status: 'string' },
    { cancel: 'string' },
    { note: 'string' },
    { seriefolio: 'string' },
    { payment_method: "string" }
    ];


  const handle_get_invoice = async (query_filters_flag, query_filters_data) => {
    setIsLoading(true);
    setData([]);
    setopenmini(false);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var dt;
            console.log(query_filters_flag)
            if (query_filters_flag) {
              console.log(query_filters_data)
              setfi(dayjs(query_filters_data.di))
              setff(dayjs(query_filters_data.df))
              dt = await get_invoices(query_filters_data.di, query_filters_data.df, query_filters_data.rfc === "null" ? null : query_filters_data.rfc, query_filters_data.b_partner === "null" ? null : query_filters_data.b_partner, query_filters_data.type === "null" ? null : query_filters_data.type, query_filters_data?.cancel === "all" ? null : `${query_filters_data.cancel}`);
              setquery_filters({
                di: query_filters_data.di,
                df: query_filters_data.df,
                rfc: query_filters_data.rfc,
                b_partner: query_filters_data.b_partner,
                type: query_filters_data.type,
                cancel: `${query_filters_data.cancel}`,
              })
              console.log(`${query_filters_data.cancel}`)

            } else {
              setquery_filters({
                di: fi.format('YYYY-MM-DD'),
                df: ff.format('YYYY-MM-DD'),
                rfc: rfc || null,
                b_partner: sdn || null,
                type: tipodoc || null,
                cancel: cancel || null,
              })
              // dt = await get_invoices(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'), rfc, sdn, tipodoc, cancel === "all" ? null : cancel);

              // var test = await pagination_v2(10, "invoice")
              var params = {
                di: fi.format('YYYY-MM-DD'),
                df: ff.format('YYYY-MM-DD'),
                rfc: rfc,
                b_partner: sdn,
                type: tipodoc,
                cancel: cancel === "all" ? null : cancel,
              }

              var dt = await pagination_v2(3, "invoices", null, null, null, null, null, null, null, params)
              console.log(dt)
            }
            setg_total(dt?.map(obj => obj.total).filter(d => d !== null))
            setg_discount(dt?.map(obj => obj.discount).filter(d => d !== null))
            setg_subtotal(dt?.map(obj => obj.subtotal).filter(d => d !== null))
            setg_tax(dt?.map(obj => obj.tax).filter(d => d !== null))
            const list = Array.from(new Set(dt.data?.map(obj => obj.serie)));
            dt = dt?.slice().sort((a, b) => a.invoice_date < b.invoice_date);
            var ndt = update_null_identifiers(dt, update_identifiers);
            setusStateList(list);
            setData(ndt);
            console.log(ndt);


            const chartData = ndt.reduce((acc, curr) => {
              const posName = curr.name_pos; // El nombre del punto de venta
              const total = 1; // El total de la factura

              if (!acc[posName]) {
                acc[posName] = { name: posName, value: 0 };
              }

              acc[posName].value += total;

              return acc;
            }, {});

            const pieData = Object.values(chartData);

            console.log(pieData)


          } catch (err) {
            setData([]);
          } finally {
            setIsLoading(false);

          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };
  const handle_success_filter = (a, b) => {
    handle_get_invoice(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });

  }


  const handle_get_status_merhod_version_2 = async (id) => {
    setmodalT(true);

    try {
      await get_status_merhod_version_2(id);
      setTimeout(() => {
        handle_get_invoice();
        setmodalT(false);
      }, 1000);


    } catch (err) {
      setmodalT(false);

    }
  }


  const columns = useMemo(
    () => [
      {
        id: "Acciones",
        header: "Cancelación v2",
        accessorFn: (d) => {
          return (
            <div>
              <img title='Cancelar Factura' src={CANCEL_INVOICE_IMG} alt='cancelar' style={{ cursor: "pointer", width: "25px", display: d.status === "Cancelado" ? "none" : "" }}
                onClick={async () => {
                  await CustomDialog(
                    <CANCEL_INVOICE_METHOD_2
                      id={d?.id}
                      setUpdateData={setUpdateData}
                      serie={d?.serie}
                      folio={d?.folio}
                      flag_cancel={d.cancel}
                    ></CANCEL_INVOICE_METHOD_2>,
                    {
                      className: "custom-modal-2",
                      title: `Seleccione el motivo por el cual desea cancelar la factura ${d?.serie}${d?.folio}`,
                      showCloseIcon: true,
                    }
                  );
                }}
              >
              </img>
              <img title='Actualizar Estado' src={STATUS_INVOICE_IMG} alt='cancelar' style={{ cursor: "pointer", width: "25px", display: d.cancel === true ? "" : "none" }}
                onClick={() => handle_get_status_merhod_version_2(d.id)}
              >
              </img>
            </div>
          )
        },
        size: 200,
        enableFacetedValues: true,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
        Footer: () => (
          <div>
            <div>Sub Total: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },

      {
        id: "invoice_date",
        header: "Fecha",
        accessorKey: "invoice_date",
        size: 145,
        muiTableBodyCellProps: {
          align: 'center',
        },
        // Footer: () => (
        //   <div>
        //     <div>Sub Total: </div>
        //     <div>Gran Total: </div>
        //   </div>
        // ),
      },
      {
        id: "ticket",
        header: "Ticket",
        accessorKey: "ticket",
        size: 145,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "name_pos",
        header: "Punto de Venta",
        accessorKey: "name_pos",
        accessorFn: (d) => (d.name_pos === null ? "" : d.name_pos),
        size: 200,
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },
      {
        header: "Serie",
        accessorKey: "serie-list",
        id: "serie-list",
        size: 140,
        accessorFn: (d) => d.serie,
        filterVariant: 'multi-select',
        enableFacetedValues: true,
        filterFn: custom_multiple_filter,
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableColumnFilterModes: false
      },

      {
        id: "folio",
        header: "Folio",
        accessorKey: "folio",
        size: 140,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "seriefolio",
        accessorKey: "seriefolio",
        header: "Serie/Folio",
        accessorFn: (d) => {
          return d.serie + d.folio
        },
        size: 180,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "name_business_partner",
        header: "Cliente",
        accessorKey: "name_business_partner",
        size: 150,
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },
      {
        header: "RFC",
        accessorKey: "rfc_business_partner",
        id: "rfc_business_partner",
        size: 140,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "subtotal",
        header: "Subtotal",
        filterFn: 'between',
        accessorKey: 'subtotal',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.subtotal).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_subtotal))}</div>
            </div>
          )
        },
      },
      {
        id: "discount",
        header: "Descuento",
        accessorKey: 'discount',
        Cell: ({ cell }) => {
          return (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '9ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          )
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.discount).filter(d => d !== null).filter(d => d !== "")
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_discount))}</div>

            </div>
          )
        },
      },
      {
        header: "Impuesto",
        accessorKey: 'tax',
        id: "tax",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.tax).filter(d => d !== "")
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_tax))}</div>
            </div>
          )
        },
      },
      {
        id: "total",
        header: "Total",
        accessorKey: 'total',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.total).filter(d => d !== "")

          return (
            <div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_total))}</div>
            </div>

          )
        },
      },

      {
        id: "payment_method",
        header: "Método Pago",
        accessorKey: "payment_method",
        size: 180,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "forma_pago",
        header: "Forma Pago",
        accessorKey: "forma_pago",
        size: 180,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "tipodecomprobante",
        header: "Comprobante",
        accessorKey: "tipodecomprobante",
        size: 180,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "status",
        header: "Estado en OD",
        accessorKey: "status",
        accessorFn: (d) => { return d.cancel === true ? "CANCELADO" : "VIGENTE"; },
        className: "justify_data",
        size: 180,
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'select',
      },
      {
        header: "Usuario",
        accessorKey: "username",
        id: "username",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        header: "Estado en SAT",
        accessorKey: "status",
        id: "status_f",
        maxWidth: 120,
        minWidth: 80,
        width: 120,
        className: "justify_data",
        filterVariant: 'select',
      },
      {
        header: "Estado de cancelación",
        accessorKey: "cancelation_status",
        id: "cancelation_status",
        maxWidth: 120,
        minWidth: 80,
        width: 200,
        className: "justify_data"
      },
      {
        header: "¿Es cancelable?",
        accessorKey: "its_cancelable",
        id: "its_cancelable",
        maxWidth: 120,
        minWidth: 80,
        width: 150,
        className: "justify_data"
      },
      {
        header: "UUID_SAT",
        accessorKey: "uuid_sat",
        id: "uuid_sat",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
    ],
    [g_discount, g_subtotal, g_tax, g_total, usStateList],
  );


  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])



  useEffect(() => {
    if (updateData === true) {
      handle_get_invoice();
      setUpdateData(false);
    }
  }, [updateData]);



  
  
  const [minimenu, setminimenu]=useState(false);
  const [openmini, setopenmini]=useState(false);

   useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 480) {
          console.log('Screen width is less than 480px');
          setminimenu(true);
        } else {
          setminimenu(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const filters_custom = (
      <SEARCH_FILTERS
            fi={fi}
            setfi={setfi}
            ff={ff}
            setff={setff}
            tipodoc={tipodoc}
            setTipoDoc={setTipoDoc}
            cancel={cancel}
            setCancel={setCancel}
            rfc={rfc}
            setRFC={setRFC}
            sdn={sdn}
            setSDN={setSDN}
            handle_get_invoice={handle_get_invoice}
      ></SEARCH_FILTERS>
    );


  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR FACTURAS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableFilterMatchHighlighting={false}
        enableColumnOrdering
        onStateChange={handleStateChange}
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", color: "black" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", fontSize: "15px" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderDetailPanel={({ row }) => {
          if (row.getIsExpanded() === true) {
            sessionStorage.setItem("dataSuntablefiltro", row.original.uuid_sat);
            sessionStorage.setItem("dataSuntablefiltroID", row.original.id);
          }
          return <DETAIL_INVOICE_V2
            reconsult={reconsult}
            data={data}
            tipo={row}
            columns={columns}
            defaultPageSize={3}
            showPagination={false}
          />
        }}
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS
            key={row.original.id}
            closeMenu={closeMenu}
            setmodalT={setmodalT}
            setmenssage={setmenssage}
            setmodalGeneral={setmodalGeneral}
            row={row}
            handle_get_invoice={handle_get_invoice}
            setreconsult={setreconsult}
            refresh_token={refresh_token}
          />
        ]}

        renderTopToolbarCustomActions={({ }) => {
          if(minimenu===false){
            return(
            <>
              {filters_custom}
            </>
            )
          }
        }}
        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx}
            share_filter={true}
            query_filters={query_filters}
            settotal_rows={settotal_rows}
            handle_success_filter={handle_success_filter}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"FACTURAS DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
            update_identifiers={update_identifiers}
            general_public_report={true}
            zip={true}
            query_filters_dt={query_filters_dt}
            handle_get_consult={handle_get_invoice}
          />
        )
        }
      />
    </div >
  );
};

export default INVOICE_V2;