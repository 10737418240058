import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";

import { update_expense } from "../../../services/expenses/expenses";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Modal_Table from "../../../main_components/modal/modal_Table";
import {
  get_expense_paymentaccounts,
  get_expense_concept_list_data,
} from "../../../services/expenses/expenses";
import "../../expense.css";

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const UPDATE_EXPENSE = ({
  codCocept,
  codDescripcion,
  codIdPayment,
  codImporte,
  codAccount,
  codidConcept,
  id_expense,
  foreign,
  origin,
  concept,
}) => {
  const [open, setOpen] = useState(false);
  const [Concepto, setConcepto] = useState(codCocept);
  const [ConceptoUpdate, setConceptoUpdate] = useState(codCocept);

  const [Descripcion, setDescripcion] = useState(codDescripcion);
  const [importe, setImporte] = useState(codImporte);
  const [pro_servData, setpro_servData] = useState([]);
  const [accounts, setAccounts] = useState(codAccount);
  const [idaccounts, setidAccounts] = useState(codidConcept);
  const [accountspayment, setaccountspayment] = useState(codIdPayment);
  const [accountsList, setaccountsList] = useState([]);
  const [modalT, setmodalT] = useState(false);

  const [selectaccountsList, setselectaccountsList] = useState(codIdPayment);

  const handelDescripcion = (event) => {
    setDescripcion(event?.target?.value);
  };

  const handelUnidad = (event) => {
    setImporte(event?.target?.value);
  };

  const handelAccount = (event) => {
    if (event?.target?.value === "9090909090909") {
      setselectaccountsList(null);
    } else {
      setselectaccountsList(event?.target?.value);
      setaccountspayment(event?.target?.value);
    }
  };

  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };

  const dialog = useDialog();

  const handleOnSearch = (string, results) => {};

  const handleOnHover = (result) => {};
  const handleOnSelect = (item) => {

    console.log(item);

    setidAccounts(item.id_account);
    setAccounts(item.account);
    setConceptoUpdate(item.concept);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleOnClear = () => {
    console.log("Cleared");
  };

  var data = {
    expense: {
      id_account:
        origin === "FACTURAS"
          ? null
          : origin === "PROVEEDORES"
          ? null
          : idaccounts,
      concept:
        origin === "FACTURAS"
          ? Concepto
          : origin === "PROVEEDORES"
          ? Concepto
          : ConceptoUpdate,
      id_payment: Number(accountspayment),
      description: Descripcion,
      amount: Number(importe),
    },
  };

  console.log(data);

  const handleSubmit = async (event) => {

    console.log(origin)

    event?.preventDefault();
    var data = {
      expense: {
        id_account:
          origin === "FACTURAS"
            ? null
            : origin === "PROVEEDORES"
            ? null
            : idaccounts,
        concept:
          origin === "FACTURAS"
            ? Concepto
            : origin === "PROVEEDORES"
            ? Concepto
            : ConceptoUpdate,
        id_payment: Number(accountspayment),
        description: Descripcion,
        amount: Number(importe),
      },
    };

    console.log(data);

    setmodalT(true);

    try {
      await update_expense(data, id_expense);
      setmodalT(false);
      dialog.close(true);
    } catch (err) {
      setmodalT(false);
      var error = err?.response?.data?.errorMessage;
      alert("Error.," + error);
    }
  };

  const handleprod_concept_list = async () => {
    try {
      var dt = await get_expense_concept_list_data(foreign);
      const data = dt.data;
      const dataStatic = {
        id_account: "9090909090909",
        concept: "SELECCIONE UNA OPCIÓN",
        account: "SELECCIONE UNA OPCIÓN",
      };
      data[data.length] = dataStatic;
      setpro_servData(data);
    } catch (error) {
      setpro_servData([]);
    }
  };

  const handleAccountsList = async () => {
    try {
      var dt = await get_expense_paymentaccounts();

      const data = dt.data;
      const dataStatic = {
        id: "9090909090909",
        payment_method: "SELECCIONE UNA OPCIÓN",
      };
      data[data.length] = dataStatic;
      setaccountsList(data);
    } catch (error) {
      setaccountsList([]);
    }
  };

  useEffect(() => {
    handleprod_concept_list();
    handleAccountsList();
  }, []);

  return (
    <div>
      <div>
        <Modal_Table modalT={modalT}></Modal_Table>
      </div>
      <div>
        <form>
          <Box style={{display:origin==="FACTURAS"?"none":origin==="PROVEEDORES"?"none":"" }}>
            <div style={{ width: "90%", margin: "0 auto" }}>
              <ReactSearchAutocomplete
                items={pro_servData}
                fuseOptions={{ keys: ["concept"] }}
                resultStringKeyName={"concept"}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                onClear={handleOnClear}
                showIcon={true}
                placeholder="Buscar concepto..."
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 10,
                }}
              />
            </div>
          </Box>

          <Box style={{display:origin==="FACTURAS"?"none":origin==="PROVEEDORES"?"none":"" }} className="expen-7">
            <ValidationTextField
              label="Concepto"
              variant="outlined"
              placeholder="Cuenta"
              sx={{ m: 2, width: "50%" }}
              value={ConceptoUpdate}
              InputLabelProps={{ shrink: true }}
            />
            <ValidationTextField
              label="Cuenta"
              variant="outlined"
              placeholder="Cuenta"
              sx={{ m: 2, width: "34%" }}
              value={accounts}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box style={{display:origin==="FACTURAS"?"":origin==="PROVEEDORES"?"":"none" }} className="expen-7">
            <ValidationTextField
              label="Concepto"
              variant="outlined"
              placeholder="Cuenta"
              sx={{ m: 2, width: "89%" }}
              value={Concepto}
              onChange={(e)=>setConcepto(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box>
            <TextareaAutosize
              label="Descripciónn"
              variant="outlined"
              aria-label="empty textarea"
              value={Descripcion}
              placeholder="Descripción"
              className="TexAreaAutosize"
              onChange={handelDescripcion}
            />
          </Box>
          <Box>
            <ValidationTextField
              label="Importe"
              variant="outlined"
              placeholder="Importe"
              type="number"
              sx={{ m: 2, width: "89%" }}
              onChange={handelUnidad}
              onBlur={handelImportOnBurl}
              value={importe}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box>
            <TextField
              select
              label="Cuenta de pago"
              value={selectaccountsList}
              sx={{ m: 1.5, width: "89%" }}
              onChange={handelAccount}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {accountsList.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.payment_method}
                </option>
              ))}
            </TextField>
          </Box>

          <br></br>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Actualizar
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default UPDATE_EXPENSE;
