import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_models } from "../../../services/assets/assets";
import OPERATIONS_MODEL from "./op_model";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const dt = await delete_models(props?.id, props?.row?.original?.id);
                   console.log(dt);
                   props.alertas("Modelo Eliminado", true);
                   props.setmodalT(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.alertas("Error, no se pudo eliminar modelo", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar Modelo?','Eliminar Modelo','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Modelo"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Modelo
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                         <OPERATIONS_MODEL 
                            operation={"EDIT"}  
                            row={props?.row} 
                            handleSubmit={props?.handleSubmit} 
                            alertas={props?.alertas} 
                            setmodalT={props.setmodalT}
                            id={props?.id}
                         />,
                    {
                        className: "custom-modal",
                        title: "EDITAR MODELO",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Modelo"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Modelo
            </MenuItem>        
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
