export const UPDATE_NULL_IDENTIFIERS = (originalObj: Record<string, any>[], identifiersToUpdate: Record<string, any>[]): Record<string, any>[] => {
    return originalObj.map((item) => {
        for (const identifier in item) {
            if (item.hasOwnProperty(identifier)) {
                const isToUpdate = identifiersToUpdate.some((update) => update[identifier] !== undefined);
                if (isToUpdate && item[identifier] === null) {
                    item[identifier] = typeof identifiersToUpdate.find((update) => update[identifier]) === 'number' ? 0 : '';
                }
            }
        }
        return item;
    });
};
