export function calculate_total(n) {
  try {
    return n?.reduce((acumulado, actual) => acumulado + actual, 0);
  } catch (error) {
    return 0
  }
}


export const custom_multiple_filter = (row, columnId, filterValue, column) => {
  console.log(filterValue)
  try {
    if (filterValue?.length === 0) {
      console.log(filterValue)
      return true;
    }
    if (!Array.isArray(filterValue)) {
      console.log(columnId)
      console.log(row?.original?.hasOwnProperty(columnId))
      return false;
    }
    if (!row.original.hasOwnProperty(columnId)) {
      console.log(columnId)

      const cleanColumnId = columnId.replace('-list', '');

      console.log(row?.original?.hasOwnProperty(cleanColumnId))
      return filterValue.includes(row.original[cleanColumnId]);
    }

    return filterValue.includes(row.original[columnId]);
  } catch (e) {
    console.log(e)

  }
};