import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import { Box } from '@mui/material';
import { calculate_total } from '../../../main_components/methods_v2/auxiliary_functions';
export const DETAILS = (props) => {

  console.log(props)
  const [data, setData] = useState(props?.row?.original?.products);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");


  console.log(props?.row?.original);

  const update_identifiers =
  [
    { code: 'string' },
    { product_name: 'string' },
    { price: 'String' },
    { quantity: 'string' },
    { total: 'string' }
  ];
    
  const columns = useMemo(
    () => [
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        muiTableBodyCellProps: {
          align: 'center',
        },
        Footer: () => (
          <div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        id: "product_name",
        header: "Nombre Producto",
        accessorKey: "product_name",
        muiTableBodyCellProps:{
          align: "left"
        }
      },
      {
        id: "price",
        header: "Precio",
        accessorKey: "price",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const numericValue = value !== undefined ? parseFloat(value) : 0;
        return(
          <Box>
            {
              numericValue.toLocaleString('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "quantity",
        header: "Cantidad",
        accessorKey: "quantity",
        muiTableBodyCellProps: {
          align: 'right',
        }
      },
      {
        id: "total",
        header: "Total",
        accessorKey: "total",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const numericValue = value !== undefined ? parseFloat(value) : 0;
        return(
          <Box>
            {
              numericValue.toLocaleString('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
           align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => parseFloat(obj?.total))
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
            </div>
          )
        },   
      },
    ],
    []
  );
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };
  return (
    <div className='details_bonus_products'>
      <div className='grid-information-product-bonus'>
        <p>PUNTO DE VENTA: <span>{props?.row?.original?.point_of_sale}</span></p>
        <p className='ticket-bonus-bon'>TICKET: <span>{props?.row?.original?.ticket}</span></p>
        <p>FECHA: <span>{props?.row?.original?.timestamp_requesting}</span></p>
      </div>
      <div className='grid-information-product-bonus'>
        <p>CÓDIGO: <span>{props?.row?.original?.code_number === "None" ? "NO APLICA": props?.row?.original?.code_number}</span></p>
        <p>LOTE: <span>{props?.row?.original?.lote}</span></p>
        <p>TOTAL: <span>${props?.row?.original?.requesting_total}</span></p>
      </div>
      <div className='grid-information-product-bonus'>
        <p>MOTIVO: <span>{props?.row?.original?.reason}</span></p>
        <p>ESTADO: <span>{props?.row?.original?.status_bonus ? props?.row?.original?.status_bonus : "NO APLICA"}</span></p>
        <p>AUTORIZACIÓN: <span>{props?.row?.original?.requires_authorization === true ? "✔️": "❌"}</span></p>
      </div>
      <div className='new_table_v2'>
        <MaterialReactTable
             columns={columns}
             data={data}
             enableGrouping
             enableFacetedValues
             enableStickyHeader
             enableStickyFooter
             enableRowPinning
             onStateChange={handleStateChange}
             enableColumnFilterModes
             enableRowActions={false}
             enableRowSelection={false}
             getRowId={(row) => row?.id}
             onRowSelectionChange={setRowSelection}
             enableTooltips={false}
             localization={MRT_Localization_ES}
             state={{ rowSelection, isLoading: isLoading }}
             enableColumnResizing
             enableColumnPinning
             enableColumnOrdering
             enableExpandAll={false}
             initialState={{ showGlobalFilter: true }}
             muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
             muiSkeletonProps={{ animation: 'pulse', height: 28 }}
             rowVirtualizerInstanceRef
             rowVirtualizerOptions={{ overscan: 5 }}
             columnVirtualizerOptions={{ overscan: 2 }}
             muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
             positionToolbarAlertBanner="bottom"
             paginationDisplayMode='pages'
             rowPinningDisplayMode='sticky'
             layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiTableFooterCellProps={{sx: { flex: '0 0 auto', whiteSpace: "normal !important" }}}
             muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
             muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderToolbarInternalActions={({ table, index }) => (
                 <RENDER_TOOLBAR_INTERNAL_ACTIONS
                   key={index}
                   table={table}
                   file_name={"Reporte de Bonificaciones"}
                   update_identifiers={update_identifiers}
                 />
               )
              }
            />  
        </div>
    </div>
  )
}
