import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import PDF from "../../../imgComponents/pdf.png";
import SOCIO from "../../../imgComponents/socio.png";
import PUNTOVENTA from "../../../imgComponents/puntoventa.png";
import DETAILS from "../../../imgComponents/lupa.png";
import DESCAPITALIZAR from "../../../imgComponents/descapitalizar.png";
import EditFreez from "./EditFreez";
import { delete_cabinets, get_cabinets_comodato } from "../../../services/assets/assets";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { MovementsFrezzer } from "./MovementsFrezzer";
import WAREHOUSE from "../../../imgComponents/warehouse.png";
import { DetailsFrezz } from "./DetailsFrezz";
import { ConfirmDesc } from "./ConfirmDesc";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    var permisosUsuario = sessionStorage.getItem("roles_user");
    var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
    var permisos = permisoUsuarioReplace?.split(",");
    var permisoeditfrezz = permisos?.filter((x) => x === "ROLE_ASSETS_CONFIGURATION");

    
const get_comodato = async (id) => {
    props?.setmodalT(true);
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          var d = await get_cabinets_comodato(id);
          const decodedData = atob(d=== null ? [] : d.data.base64);
          const byteArray = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; ++i) {
            byteArray[i] = decodedData.charCodeAt(i);
          }
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = 'comodato.pdf';
          document.body.appendChild(link);
          link.click();
          props?.alertas("PDF Descargado con éxito", true);
          props?.setmodalT(false);
        }, 1000);
      }else{}
    }catch(error){
      console.log("error", error);
      if(error?.response?.status===409){
        props?.alertas("El congelador no está asignado a un punto de venta", false);
      }else{
        props?.alertas("No se pudo descargar el PDF, vuelva a intentarlo más tarde", false);
      }
      props?.setmodalT(false);
    }
  };

    function descargarcomodato(){
        get_comodato(props?.row.original.id);
    }

    const Deleteitem =async () =>{
        props.setmodalT(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const d = await delete_cabinets(props?.row?.original?.id);
                   props.alertas("Congelador Eliminado", true);
                   props.setmodalT(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.alertas("Error, no se pudo eliminar el congelador", false);
                 }
               }, 1000);
             }else{}
        }catch(err){
        console.log(err);
        }
    }

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm( `¿Esta usted seguro de eliminar el Congelador con Número Económico ${props?.row?.original?.economic_number}?`,'Eliminar Congelador','Si','No');
                    if(result){
                        Deleteitem()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt="" src={Delete} title="Eliminar Congelador" className=""
                    ></img>
                </ListItemIcon>
                Eliminar Congelador
            </MenuItem>
            {
               permisoeditfrezz.length===1?
               <MenuItem
               key={2}
               onClick={ async () =>{
                   props.closeMenu();
                   await CustomDialog(
                        <EditFreez
                            row={props?.row} 
                            handleSubmit={props?.handleSubmit} 
                            alertas={props?.alertas} 
                            reconsultar={props?.setmodalT}
                        ></EditFreez>,
                   {
                       className: "custom-modal-3",
                       title: "EDITAR CONGELADOR",
                       showCloseIcon: true,
                   }
                   );
                   } 
               }
               sx={{ m: 0 }}
           >
               <ListItemIcon>
                   <img 
                      alt="" src={Edit} title="Editar Congelador" className=""
                   ></img>
               </ListItemIcon>
               Editar Congelador
           </MenuItem>:
           <></>
            }
            {
              props?.row?.original?.id_cabinets_state_fk===2 ? props?.row?.original?.id_point_of_sale_fk!==null ?
              <MenuItem
               key={3}
               onClick={()=>descargarcomodato()}
               sx={{ m: 0 }}
           >
               <ListItemIcon>
                   <img 
                      alt="" src={PDF} title="Descargar Comodato" className=""
                   ></img>
               </ListItemIcon>
               Descarga Comodato
           </MenuItem>:<></>:<></>
            }

            <MenuItem
               key={4}
               onClick={ async () =>{
                   props.closeMenu();
                   await CustomDialog(
                    <MovementsFrezzer 
                        row={props?.row} 
                        movement={"almacen"} 
                        id={props?.row.original.id} 
                        reconsultar={props?.setmodalT} 
                        handleSubmit={props?.handleSubmit} 
                        alertas={props?.alertas}
                    ></MovementsFrezzer>,
                   {
                       className: "custom-modal",
                       title: "CAMBIAR ALMACÉN",
                       showCloseIcon: true,
                   }
                   );
                   } 
               }
               sx={{ m: 0 }}
           >
               <ListItemIcon>
                   <img 
                      alt="" src={WAREHOUSE} title="Cambiar Almacén" className="iconolitle"
                   ></img>
               </ListItemIcon>
               Cambiar Almacén
           </MenuItem>
            {
              props?.descapitalizado===false?
                <>
                <MenuItem
                    key={5}
                    onClick={ async () =>{
                        props.closeMenu();
                        await CustomDialog(
                            <MovementsFrezzer 
                                row={props?.row} 
                                movement={"cliente"} 
                                id={props?.row.original.id} 
                                reconsultar={props?.setmodalT} 
                                handleSubmit={props?.handleSubmit} 
                                alertas={props?.alertas}
                            ></MovementsFrezzer>,
                        {
                            className: "custom-modal",
                            title: "ASIGNAR EMPLEADO",
                            showCloseIcon: true,
                        }
                        );
                        } 
                    }
                    sx={{ m: 0 }}
                >
                    <ListItemIcon>
                        <img 
                            alt="" src={SOCIO} title="Asignar Empleado" className="iconolitle"
                        ></img>
                    </ListItemIcon>
                    Asignar Empleado
                </MenuItem>

                <MenuItem
                    key={5}
                    onClick={ async () =>{
                        props.closeMenu();
                        await CustomDialog(
                            <MovementsFrezzer 
                                row={props?.row} 
                                movement={"puntoventa"} 
                                id={props?.row.original.id} 
                                reconsultar={props?.setmodalT} 
                                handleSubmit={props?.handleSubmit} 
                                alertas={props?.alertas}
                            />,
                        {
                            className: "custom-modal",
                            title: "ASIGNAR PUNTO DE VENTA",
                            showCloseIcon: true,
                        }
                        );
                      } 
                    }
                    sx={{ m: 0 }}
                >
                    <ListItemIcon>
                        <img 
                            alt="" src={PUNTOVENTA} title="Asignar Punto de Venta" className="iconolitle"
                        ></img>
                    </ListItemIcon>
                    Asignar Punto de Venta
                </MenuItem>
                <MenuItem
                    key={5}
                    onClick={ async () =>{
                        props.closeMenu();
                        await CustomDialog(
                            <DetailsFrezz row={props?.row}></DetailsFrezz>,
                        {
                            className: "custom-modal-3",
                            title: "DETALLES DEL CONGELADOR",
                            showCloseIcon: true,
                        }
                        );
                      } 
                    }
                    sx={{ m: 0 }}
                >
                    <ListItemIcon>
                        <img 
                            alt="" src={DETAILS} title="Detalles del Congelador" className="iconolitle"
                        ></img>
                    </ListItemIcon>
                    Detalles del Congelador
                </MenuItem>
                {
                props?.row.original.id_warehouse_fk===null?<></>:
                <MenuItem
                    key={5}
                    onClick={ async () =>{
                        props.closeMenu();
                        await CustomDialog(
                            <ConfirmDesc 
                                setSelectedRows={props?.setRowSelection} 
                                detalles={[props?.row.original]} 
                                array={[props?.row.original.id]} 
                                handleSubmit={props?.handleSubmit} 
                                alertas={props?.alertas} 
                                reconsultar={props?.setmodalT}
                            ></ConfirmDesc>,
                        {
                            className: "custom-modal-2",
                            title: "DESCAPITALIZAR CONGELADOR",
                            showCloseIcon: true,
                        }
                        );
                    } 
                    }
                    sx={{ m: 0 }}
                >
                    <ListItemIcon>
                        <img 
                            alt="" 
                            src={DESCAPITALIZAR} 
                            title="Descapitalizar Congelador" 
                            className="iconolitle"
                        ></img>
                    </ListItemIcon>
                    Descapitalizar Congelador
                </MenuItem>
                }
              </>:<></> 
            }
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
