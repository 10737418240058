export function traducirMensajesDeError(errores,traducciones) {
    console.log(errores);

  
    let mensajeTraducido = errores;
    let traduccionEncontrada = false;
  
    traducciones.forEach((traduccion) => {
      const regexp = new RegExp(traduccion.err, 'g');
      if (mensajeTraducido.match(regexp)) {
        mensajeTraducido = mensajeTraducido.replace(regexp, traduccion.traslator);
        traduccionEncontrada = true;
      }
    });
  
    console.log(mensajeTraducido);
  
    if (!traduccionEncontrada) {
      return errores;
    }
  
    return mensajeTraducido;
  }
  