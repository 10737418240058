import datnew from "./COMPLETE_DATA.json";
import datclient from "./CLIENTES_PROPIOS.json";

import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import mapboxgl from 'mapbox-gl';
import axios from "axios";
import {TextField } from '@material-ui/core';
import "./map.css";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import RouteIcon from '@mui/icons-material/Route';
import DirectionsOffIcon from '@mui/icons-material/DirectionsOff';
import AlertResponse from "../main_components/alerts/alertResponse";
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../main_components/methods_v2/export";
import { route_opt } from "../componentSociosNegocios/componentSectors/details/tools/route_opt";
import { calculate_total } from "../main_components/methods_v2/auxiliary_functions";



export const REPORT_ROUTE_FULL = () => {

  //  datclient = datclient?.filter(item=>item.id_route_fk === 7);

  const[data, setData]=useState([]);
  const [data2, setdata2]=useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");
  const [type_dc, setype_dc]=useState("driving");
  const [geometry_address, setgeometry_address] = useState([]);
  const notificationSystemRef = useRef();
  const [anima, setanima]=useState(true);
  const [secc, setsecc]=useState("");
  const [datamap, setdatamap]=useState([]);


  const update_identifiers =
  [
    { code: 'string' },
    { name: 'string' },
    { lat: "string" },
    { lng: "string" },
    { route: "string" },
    { grupo: 'String' },
    { partner_type: "string" }
  ];

  const filtro = () =>{
    setData(datclient);
  }


  function alertas(msj,status){

    if(status==true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'tr', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'tr', 
        autoDismiss: 60, 
      });
    }  
  }
  
const CustomActionMenu = () =>{
    return (
      <>  
      <div className="controlss-pers">
        <span title="Trazar Ruta"><ForkRightIcon className="button-icon-mapb-pers" onClick={()=>markers_normal()}></ForkRightIcon></span> 
        <span title="Seguimiento Ruta"><RouteIcon className="button-icon-mapb-pers2" onClick={()=>{anima===true?animacion():alertas("Espera termine el proceso")}}></RouteIcon></span>  
        <span title="Eliminar Ruta"><DirectionsOffIcon className="button-icon-mapb-pers3" onClick={()=>delete_layer()}></DirectionsOffIcon></span>
        </div>
    
      </>
    )
  }

  
const delete_layer = () =>{
    const map = mapInstance.current;
    if (map && map.getStyle()) {
      const layerId = 'route'; 
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    }
    setanima(true);
  }
  const markers_normal = () =>{
    console.log("se ejecuta")
    const map = mapInstance.current;
    map.addLayer({
            id: 'route',
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                 type: 'LineString',
                 coordinates: geometry_address.map(item => [item[0], item[1]])
                }
              }
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
             'line-color': '#21BFE9',
              'line-width': 8
            }
         });
  }
  const animacion = ()=>{
    setanima(false);
    const map = mapInstance.current;
    if (map) {
  
      if (map.getLayer('route')) {
        map.removeLayer('route');
      }
      if (map.getSource('route')) {
        map.removeSource('route');
      }
      map.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [],
          },
        },
      });
      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#21BFE9',
          'line-width': 8,
        },
      });
  
      const coordinates = geometry_address.map(item => [item[0], item[1]]);
      let i = 0;
      let animationFrameId;
      const drawLine = () => {
        if (i < coordinates.length) {
          const source = map.getSource('route');
          if (!source) return; // Asegúrate de que la fuente existe antes de intentar acceder a sus datos
          const data = source._data;
          data.geometry.coordinates.push(coordinates[i]);
          source.setData(data);
          i++;
          animationFrameId = requestAnimationFrame(drawLine);
        } else {
          setanima(true);
        }
      };
      drawLine();    
    }
  }
  
  function chunkArray(array, chunkSize) {
    const chunks = [];
     for (let i = 0; i < array.length; i += chunkSize) {
       chunks.push(array.slice(i, i + chunkSize));
     }
     return chunks;
  }
  function loopChunks(chunks) {
    for (let i = 1; i < chunks.length; i++) {
        const lastElement = chunks[i - 1][chunks[i - 1].length - 1]; 
        chunks[i].unshift(lastElement);
    }
    return chunks;
  }

  const columns = useMemo(
    () => [
      {
        id: "orderSector",
        header: "Orden",
        accessorKey:"orderSector"
      },
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        Footer: () => (
          <div>
            <div>Sub Total: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        id: "name",
        header: "Nombre",
        accessorKey: "name"
      },
      {
        id: "lat",
        header: "latitud",
        accessorKey: "lat"
      },
      {
        id: "lng",
        header: "longitud",
        accessorKey: "lng"
      },
      {
        id: "route",
        header: "ruta",
        accessorKey: "route"
      },
      {
        id: "grupo",
        accessorKey: "grupo",
        header: "Clasificación"
      },
      {
        id: "partner_type",
        accessorKey: "partner_type",
        header: "tipo de partner"
      },
      {
        id: "enero_cash",
        accessorKey: "enero_cash",
        header: "Enero",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.enero_cash)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "enero_avg_liters",
        accessorKey: "enero_avg_liters",
        header: "Enero Total litros",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.enero_avg_liters)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "febrero_cash",
        accessorKey: "febrero_cash",
        header: "Febrero",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.febrero_cash)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "febrero_avg_liters",
        accessorKey: "febrero_avg_liters",
        header: "Febrero Total litros",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.febrero_avg_liters)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "marzo_cash",
        accessorKey: "marzo_cash",
        header: "Marzo",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.marzo_cash)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "marzo_avg_liters",
        accessorKey: "marzo_avg_liters",
        header: "Marzo Total litros",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.marzo_avg_liters)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "abril_cash",
        accessorKey: "abril_cash",
        header: "Abril",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.abril_cash)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "abril_avg_liters",
        accessorKey: "abril_avg_liters",
        header: "Abril Total litros",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.abril_avg_liters)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "mayo_cash",
        accessorKey: "mayo_cash",
        header: "Mayo",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.mayo_cash)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "mayo_avg_liters",
        accessorKey: "mayo_avg_liters",
        header: "Mayo total litros",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.mayo_avg_liters)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "junio_avg_cash",
        accessorKey: "junio_avg_cash",
        header: "Junio",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.junio_avg_cash)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "junio_avg_liters",
        accessorKey: "junio_avg_liters",
        header: "Junio total litros",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.junio_avg_liters)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "amount",
        accessorKey: "amount",
        header: "Total ventas",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.amount)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "average",
        accessorKey: "average",
        header: "Promedio ventas",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.average)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      },
      {
        id: "profit",
        accessorKey: "profit",
        header: "profit",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.profit)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
            </div>)
        }
      }
    ],
    []
  );

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiaXNyYWVscm9zZXRlIiwiYSI6ImNsdG9scmx1cDAwb3kyaXBrbmI2b25hOTkifQ.egPWw8gSRONYzrssiZb5JA';
    const map = new mapboxgl.Map({
      container: mapRef.current, 
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [data[0]?.lng ? data[0]?.lng : -97.07318829119332, data[0]?.lat ? data[0]?.lat : 18.85020875064652], 
      zoom: 12
    })
    mapInstance.current = map; 

  if(data?.length>0){

    map.on('load', async ()=>{
      map.addControl(new mapboxgl.NavigationControl());
      get_coordenadas();

       data.forEach(item=>{
         const marker =  new mapboxgl.Marker({
            color: item?.color ? item?.color : "green",
           //  draggable: true,
          })
         .setLngLat([item?.lng, item?.lat])
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(`${item.code} - ${item.name}`))
          .addTo(map)
          const labelElement = document.createElement('div');
          // labelElement.textContent = item.orderSector;
          labelElement.className = 'marker-label'; 
          marker.getElement().appendChild(labelElement);
        });

        // data2.forEach(item=>{
        //   const marker =  new mapboxgl.Marker({
        //      color: "purple",
        //     //  draggable: true,
        //   })
        //   .setLngLat([item?.lng, item?.lat])
        //   .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(`${item.code} - ${item.name}`))
        //   .addTo(map)
        //   const labelElement = document.createElement('div');
        //   labelElement.textContent = item.orderSector;
        //   labelElement.className = 'marker-label'; 
        //    marker.getElement().appendChild(labelElement);
        //  });
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        }
      });
      map.addControl(draw);

      map.on('draw.create', updateArea);
      map.on('draw.delete', updateArea);
      map.on('draw.update', updateArea);
      function updateArea(e) {
        const data = draw.getAll();
        if (data.features.length > 0) {
          agrup(data);
        }
      }
    });
    return () => {
      map.remove();
    };
  } 
}, [data]);
 

const[vertices, setvertices]=useState([]);

const agrup = (d) =>{

   console.log(d?.features[0]?.geometry?.coordinates[0]);
   var dat = d?.features[0]?.geometry?.coordinates[0]?.map(item=>{
        return {
            lat: item[1],
            lng: item[0]
        }
   })
   console.log(dat);
   setvertices(dat);
   conjuntospp(dat);
}

function conjuntos(){
    const filteredCoordinates = datnew.filter((item) => {
       return isPointInPolygon([item.lat, item.lng], vertices);
    });
    setdatamap(filteredCoordinates);
}
function conjuntospp(dat){
    const filteredCoordinates = datnew.filter((item) => {
       return isPointInPolygon([item.lat, item.lng], dat);
    });
    setdatamap(filteredCoordinates);
} 

function isPointInPolygon(point, polygon) {
    let isInside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i].lat, yi = polygon[i].lng;
      const xj = polygon[j].lat, yj = polygon[j].lng;
  
      const intersect = ((yi >= point[1]) !== (yj >= point[1]))
        && (point[0] <= (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
      if (intersect) {
        isInside = !isInside;
      }
    }
    return isInside;
  }


const mapRef = useRef(null);
const mapInstance = useRef(null);

const get_coordenadas = () =>{
    var urlcoordenadas = "";
    const lotes = chunkArray(data, 24);
    const lotesConLoop = loopChunks(lotes);
  
    const ddd = Promise.all(lotesConLoop?.map(async(item)=>{
      urlcoordenadas = "";
      item.map((d, i)=>{    
        if(item.length-1 === i){
          urlcoordenadas = urlcoordenadas + `${d?.lng}%2C${d?.lat}`;
        }else{
          urlcoordenadas = urlcoordenadas + `${d?.lng}%2C${d?.lat}%3B`;
        }
      })
      const datageomey = await get_geometry(urlcoordenadas);  
      return datageomey;
    })).then(results => {
      return results.flat();
    });
    
    ddd.then(geometryData => {
       setgeometry_address(geometryData);
    });
    return ddd
  };
  const get_geometry = async(d)=>{
    const url = `https://api.mapbox.com/directions/v5/mapbox/${type_dc}/${d}?alternatives=false&continue_straight=false&geometries=geojson&language=en&overview=full&steps=true&access_token=pk.eyJ1IjoiaXNyYWVscm9zZXRlIiwiYSI6ImNsdG9scmx1cDAwb3kyaXBrbmI2b25hOTkifQ.egPWw8gSRONYzrssiZb5JA`;
    try {
       const response = await axios.get(url);
       const dtt = response?.data?.routes[0]?.geometry?.coordinates;
       return dtt;
     } catch (error) {
       console.log(error);
    }
  }

  
const analisis_fc = async(data) =>{  
  try{
    const d = await route_opt(data, "fc", "walking");
    console.log(d);
    d.map((item, index)=>{
      item.orderSector = index+1  
    })
    if(data.length === d.length){  
      setData(d);
    }else{
      const dNotInData = data.filter(dataItem => 
        !d.some(dItem => dItem.code === dataItem.code)
      ); 
      dNotInData.unshift(d[d.length-1]);
      setTimeout(async() => {
        const m = await route_opt(dNotInData, null, type_dc);  
        m.unshift(...d);
        m.map((item, index)=>{
          item.orderSector = index+1
        })
        setData(m);
      }, 10000);
    }
  }catch(err){
    console.log(err);
  }
}


const fcroute = () =>{
  analisis_fc(datnew);
}

  return (
    <div className='new_table_v2'>
        <div className="">
        {/* <TextField
            select
            label="Sección"
            defaultValue={""}
            InputLabelProps={{shrink: true}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%"}}
            onChange={(event) => setsecc(event.target.value)}  
        >      
        <option key={100} value={"all"}>
           {"selecciona"}
        </option>
        {
          seccions?.map((item, index)=>(
            <option
              key={index}
              value={item.value}
            >
              {item.label}
            </option>
          ))
        }
        </TextField> */}
        <button onClick={()=>filtro()}>Buscar</button>
        <button onClick={()=>conjuntos()}>Agrupar</button>
        <button onClick={()=>fcroute()}>trazar ruta</button>
        <p>Puntos de venta seleccionados: {datamap?.length}</p>
        <p>Puntos de venta totales: {data?.length}</p>
        </div>
        <div className="cont-map-box">
            <div className="control-pers-mapb">
                <CustomActionMenu></CustomActionMenu>
            </div>
            <div ref={mapRef} style={{ height: '80vh', width: '100%' }} />  
         <MaterialReactTable
              columns={columns}
              data={datamap}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Cuentas Contables"}
                  update_identifiers={update_identifiers}
                  key={index}
                />
              )
              }
            />
        
        </div>
    </div>
  )
}
