import { useEffect, useMemo, useRef, useState } from 'react';
import { CustomDialog } from "react-st-modal";
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import VIEW from "../../../imgComponents/lupa.png";
import MAP from "../../../imgComponents/direccion.png";

import DETAIL_SALE from './detail_sale';
import MAP_WITH_MARKER from './map_with_marker';

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {


    return (
        <div>
            <MenuItem
                key={props.row.original.id}
                onClick={async () => {
                    props.closeMenu();
                    await CustomDialog(
                        <DETAIL_SALE
                            id={props.row.original.id}
                            typeSale={"SALE"}
                        ></DETAIL_SALE>,
                        {
                            className: "modalTItle",
                            title: "Detalle de venta",
                            showCloseIcon: true,
                            isCanClose: false,
                        }
                    );
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        title=""
                        src={VIEW}
                    ></img>
                </ListItemIcon>
                Ver detalles
            </MenuItem>

            <MenuItem
                key={props.row.original.id}
                onClick={async () => {
                    props.closeMenu();
                    await CustomDialog(
                        <MAP_WITH_MARKER
                            id={props.row.original.id}
                            lat={props.row.original.latitude}
                            lng={props.row.original.longitude}    
                        ></MAP_WITH_MARKER>,
                        {
                            className: "custom-modal",
                            title: "Ubicación de la venta",
                            showCloseIcon: true,
                            isCanClose: false,
                        }
                    );
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        style={{width:"25px"}}
                        alt=""
                        title=""
                        src={MAP}
                    ></img>
                </ListItemIcon>
                Ver Ubicación
            </MenuItem>


            

        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
