//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

//COMPONENTES

import { get_price_list_products_different } from "../../../services/inventory/inventory";
import { get_price_list_products } from "../../../services/inventory/inventory";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { update_price_list_products } from "../../../services/inventory/inventory";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import {
  Box,
  Button,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { get_warehouse_existence } from "../../../services/inventory/inventory";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import PanToolIcon from '@mui/icons-material/PanTool';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WAREHOUSE_MOVEMENTS_LIST from "./warehouse_movement_list";
import { get_warehouse } from "../../../services/inventory/inventory";
import { CustomDialog, Confirm } from "react-st-modal";
import MOVEMENTS_v2 from "../movements_v2";
import { get_detil_warehouse } from "../../../services/inventory/inventory";
import { calculate_total } from "../../../main_components/methods_v2/auxiliary_functions";
import { create_warehouse_movements } from "../../../services/inventory/inventory";
//ESTILOS

//IMAGENES/ICONOS

export const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});
const WAREHOUSE_MOVEMENTS_V2 = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [dataTable_search, setDataTable_search] = useState([]);
  const [reference, setreference] = useState("");
  const [note, setnote] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [dataTablePriceList, setdataTablePriceList] = useState([]);
  const [modalT, setmodalT] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [edit_quantity, setQuantity] = useState([]);
  const [cquantity_dt, setcquantity_dt] = useState(0);
  const [manual_movement, setmanual_movement] = useState(false);
  const [almacenO, setalmacenO] = useState(0);
  const [almacenD, setalmacenD] = useState(0)
  const [g_total_quantity, setg_total_quantity] = useState(0)

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_warehouse_existence(
              sessionStorage.getItem("origin")
            );
            const dt2 = await get_warehouse_existence(
              sessionStorage.getItem("destination")
            );
            setData(dt.data);
            setData2(dt2.data);
            const ndatadestination = dt2.data.map((e) => ({
              id: e.id_products_fk,
              destination: e.quantity,
            }));
            console.log(dt.data)
            console.log(ndatadestination)
            if (sessionStorage.getItem("origin") === "-1") {
              const ndata = dt2.data.map((e, index) => ({
                code: e.code,
                id: e.id_products_fk,
                description: e.description,
                origin: 0,
                quantity: 0,
                destination: e.quantity,
              }));

              ndata.sort((a, b) => b.origin - a.origin);
              setDataTable(ndata);
              setDataTable_search(ndata);
            } else {
              const ndata = dt.data.map((e, index) => {
                const matchingItem = ndatadestination.find(item => item.id === e.id_products_fk);
                console.log(matchingItem)

                const destination = matchingItem ? matchingItem.destination : 0;
                console.log(destination)

                return {
                  code: e.code,
                  id: e.id_products_fk,
                  description: e.description,
                  origin: e.quantity,
                  quantity: 0,
                  destination: destination,
                };
              });

              console.log(ndata)
              ndata.sort((a, b) => b.origin - a.origin);
              setDataTable(ndata);
              setDataTable_search(ndata);
            }

            setmodalT(false);
          } catch (err) {
            setDataTable([]);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_move_all_product = () => {
    setDataTable([]);
    setmodalT(true);
    const ndata = data.map((e, index) => ({
      code: e.code,
      description: e.description,
      origin: e.quantity,
      id: e.id_products_fk,
      quantity: e.quantity,
      destination: data2[index]?.quantity,
    }));
    ndata.sort((a, b) => b.origin - a.origin);
    setTimeout(() => {
      setDataTable(ndata);
      setmodalT(false);
    }, 1000);
    console.log(ndata)
  }

  const handle_create_movement = async () => {

    const join_object = dataTable.map((originalItem) => {
      const editedItem = Object.values(edit_quantity).find((editedObj) => editedObj.id === originalItem.id);

      if (editedItem) {
        return { ...originalItem, ...editedItem };
      } else {
        return originalItem;
      }
    });

    var obj_send_product = join_object.filter((d) => d.quantity !== "").map((d) => { return { id: d.id, quantity: Number(d.quantity) } }).filter(d => isNaN(d.quantity) !== true);

    const ndt = obj_send_product
      .filter((d) => d.quantity && d.quantity !== 0)
      .map((d) => {
        var dt = {
          id: d.id,
          quantity: d.quantity,
        };
        return dt;
      });
    var env_data = {
      origin: sessionStorage.getItem("origin"),
      destination: sessionStorage.getItem("destination"),
      note: note,
      reference: reference,
      movement: ndt,
    };
    console.log(env_data);
    if (ndt.length > 0) {
      setmodalT(true);
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              await create_warehouse_movements(env_data);
              setmodalT(false);
              window.location = "/movements_v2";
            } catch (err) {
              setmodalT(false);
              alert(JSON.stringify(err.response));
            }
          }, 1000);
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Realiza almenos un movimiento");
    }
  };
  const columns = useMemo(
    () => [

      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        enableEditing: false,
        size: 200,

      },
      {
        id: "description",
        header: "Descripción",
        accessorKey: "description",
        enableEditing: false,
        size: 400,

      },
      {
        header: "Cantidad",
        accessorKey: "quantity",
        id: "quantity",
        enableFilters: false,//deshabilita el filtrado
        enableRowOrdering: false,//deshabilita el orden de la columna
        enableGrouping: false,//deshabilita el grupo de la columna
        enableColumnDragging: false,//deshabilita el movimiento de la columna
        enableColumnActions: false,//deshabilita el las acciones
        muiEditTextFieldProps: ({ cell, row }) => ({ //permite la edición a nivel de input
          type: 'number',
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],

          onChange: (event) => {
            var quantity = event.target.value;
            if (sessionStorage.getItem("origin") === "-1") {
            } else {
              if (quantity > row.original.origin) {
                quantity = row.original.origin;
              }
            }

            if (quantity < 0) {
              quantity = 0;
            }


            if (quantity.toString().startsWith('0') && quantity !== 0) {
              quantity = quantity.toString().replace(/^0+/, 0);
            }
            event.target.value = quantity;
            setQuantity(prev => ({
              ...prev,
              [row.id]: {
                ...row.original,
                "quantity": quantity,
              }
            }));
            const errorMessage = event.target.value === "" ? "Campo obligatiorio" : undefined;
            setValidationErrors({
              ...validationErrors,
              [cell.id]: errorMessage,
            });
          },

        }),
        size: 200,
        muiTableBodyCellProps: {
          align: 'left',
        },
        muiTableFooterCellProps: {
          align: 'left',
        },
        Footer: (d) => {
          return (
            <div style={{ textAlign: "left" }}>
              <div>{calculate_total(g_total_quantity)}</div>
            </div>
          )
        },
      },

      {
        id: "Origen",
        header: "Origen",
        accessorKey: "origin",
        enableEditing: false,
        size: 200,
      },
      {
        id: "destination",
        header: "Destino",
        accessorKey: "destination",
        enableEditing: false,
        size: 200,
      },

    ],
    [dataTable, validationErrors, edit_quantity, g_total_quantity]
  );




  useEffect(() => {
    const join_object = dataTable.map((originalItem) => {
      const editedItem = Object.values(edit_quantity).find((editedObj) => editedObj.id === originalItem.id);

      if (editedItem) {
        return { ...originalItem, ...editedItem };
      } else {
        return originalItem;
      }
    });

    var obj_send_product = join_object.filter((d) => d.price !== "").map((d) => { return { code: d.code, quantity: Number(d.quantity) } }).filter(d => isNaN(d.quantity) !== true);
    const total = obj_send_product.map(obj => isNaN(obj.quantity) ? 0 : obj.quantity);
    setg_total_quantity(total);
  }, [edit_quantity])

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handle_detail_warehouse = async (id) => {
    try {
      const isvaild = await refresh_token();
      if (isvaild) {
        setTimeout(async () => {
          try {
            const dt = await get_detil_warehouse(id);
            dataTable.forEach((productoPrincipal) => {
              const productoSecundario = dt.data.find(
                (producto) => producto.code === productoPrincipal.code
              );

              if (productoSecundario) {
                if (productoSecundario.quantity <= productoPrincipal.origin) {
                  productoPrincipal.quantity = productoSecundario.quantity;
                } else {
                  console.log("supera el dato");
                  if (sessionStorage.getItem("origin") === "-1") {
                    productoPrincipal.quantity = productoSecundario.quantity;
                    console.log("es origen");
                  } else {
                    productoPrincipal.quantity = 0;
                  }
                }
              }
            });
            var dta = dataTable;

            const total = dta.map(obj => isNaN(obj.quantity) ? 0 : obj.quantity);
            setg_total_quantity(total);
            console.log(dta);
            setDataTable([]);

            setDataTable(dta);
          } catch (err) {
            console.log([]);
          }
        }, 1500);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_warehouse = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_warehouse();
            const data = dt.data;
            var dtAdd = {
              id: -1,
              warehouse_name: "ENTRADA",
            };
            var dtAdd2 = {
              id: -2,
              warehouse_name: "SELECCIONA UN ALMACEN",
            };

            data.push(dtAdd);
            data.push(dtAdd2);
            console.log(data)
            const origenWarehose = data.filter((d) => d.id === Number(sessionStorage.getItem("origin")));
            const destinationWarehose = data.filter((d) => d.id === Number(sessionStorage.getItem("destination")));
            setalmacenO(origenWarehose[0]?.warehouse_name)
            setalmacenD(destinationWarehose[0]?.warehouse_name);
          } catch (error) {
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
    handle_warehouse();
  }, []);



  const handle_movement_zero = async () => {

    const id_row_selection = Object.keys(rowSelection);
    console.log(id_row_selection)
    console.log(dataTable)
    const obj_filter = dataTable.filter(objeto => id_row_selection.includes(objeto.id.toString()));
    console.log(obj_filter)

    const movement_zero = obj_filter.map(objeto => ({
      id: objeto.id,
      quantity: objeto.quantity
    }));

    console.log(movement_zero)

    var env_data = {
      origin: sessionStorage.getItem("origin"),
      destination: sessionStorage.getItem("destination"),
      note: note,
      reference: reference,
      movement: movement_zero,
    };

    console.log(env_data);
    setmodalT(true);

    try {
      await create_warehouse_movements(env_data);
      setmodalT(false);
      window.location = "/movements_v2";
    } catch (err) {
      setmodalT(false);
      alert(JSON.stringify(err.response));
    }




  }


  return (
    <div >
      <div style={{ display: manual_movement ? "none" : "" }}
      >
        <BREADCRUMBS niveles={
          [
            { label: "MOVIMIENTO DE PRODUCTOS", path: "/movements_v2" },
            { label: "GENERAR MOVIMIENTO POR TABLA", path: null },
          ]
        }
          cards_dashboard={true}
          total_rows={total_rows !== "" ? total_rows : 0}
          filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
          visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
          total={dataTable?.length}
          rowSelection={rowSelection}
        ></BREADCRUMBS>
      </div>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <div>
        <h4>MOVIMIENTO DEL ALMACÉN: {almacenO} AL ALMACÉN:  {almacenD}</h4>
      </div>
      <Box sx={{ display: manual_movement ? 'none' : 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
        <ValidationTextField
          label="Referencia"
          placeholder="Referencia"
          variant="outlined"
          sx={{ width: "50%" }}
          defaultValue="Success"
          name="capital_regime"
          value={reference}
          onChange={(e) => {
            const value = e.target.value;
            if (typeof value === "string") {
              setreference(value.toUpperCase());
            } else {
              setreference(value);
            }
          }}

          InputLabelProps={{
            shrink: true,
          }}
        />

        <ValidationTextField
          label="Nota"
          placeholder="Nota"
          variant="outlined"
          sx={{ width: "50%" }}
          defaultValue="Success"
          name="capital_regime"
          value={note}
          onChange={(e) => {
            const value = e.target.value;
            if (typeof value === "string") {
              setnote(value.toUpperCase());
            } else {
              setnote(value);
            }
          }}

          InputLabelProps={{
            shrink: true,
          }}
        />

      </Box>

      <div className="new_table_v2" style={{ display: manual_movement ? "none" : "" }}>
        <MaterialReactTable
          columns={columns}
          data={dataTable}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          onStateChange={handleStateChange}
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          editDisplayMode={'table'} //habilita la edicion en cualquier campo de la tabla
          enableEditing //habilta la edición en tabla
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ isLoading: modalT }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{
            showGlobalFilter: true,
            showColumnFilters: true
            // columnOrder: [ //Organiza la piciones en como quieres que se precenten tus datos
            //   'code',
            //   'product_name',
            //   'brand',
            //   'minimum_sales_unit',
            //   'pieces',
            //   'price',
            //   'mrt-row-select',
            // ],
          }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,

            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                dataTable.splice(
                  hoveredRow.index,
                  0,
                  dataTable.splice(draggingRow.index, 1)[0],
                );
                setDataTable([...dataTable]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderTopToolbarCustomActions={({ }) => (
            <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', }}>
              <FormControl required sx={{ width: 200 }}>
                <Button variant="contained"
                  sx={{ height: '35px', fontSize: '11.5px' }}
                  onClick={() => handle_move_all_product()}
                >
                  <PrecisionManufacturingIcon></PrecisionManufacturingIcon>MOVER TODO
                </Button>
              </FormControl>
              <FormControl required sx={{ width: 280 }}>
                <Button variant="contained"
                  sx={{ height: '35px', fontSize: '11.5px', backgroundColor: "orange" }}
                  // onClick={() => handle_send_data()}
                  onClick={async () => {
                    const result = await CustomDialog(
                      <MOVEMENTS_v2 copy={true}></MOVEMENTS_v2>,
                      {
                        className: "modalProduct",
                        title: "Movimiento de producto",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                    if (result !== undefined) {
                      handle_detail_warehouse(result);
                    } else {
                      console.log("se cancelo");
                    }
                  }}
                >
                  <ContentCopyIcon></ContentCopyIcon>COPIAR MOVIMIENTO
                </Button>
              </FormControl>
              <FormControl required sx={{ width: 280 }}>
                <Button variant="contained"
                  sx={{ height: '35px', fontSize: '11.5px', backgroundColor: "red" }}
                  onClick={() => setmanual_movement(true)}

                >
                  <PanToolIcon></PanToolIcon>MOVIMIENTO MANUAL
                </Button>
              </FormControl>
              <FormControl required sx={{ width: 280 }}>
                <Button variant="contained"
                  sx={{ height: '35px', backgroundColor: "green", fontSize: '11.5px' }}
                  onClick={async () => {
                    const result = await Confirm(
                      "¿Esta usted seguro de generar este movimiento?",
                      "Generar Movimiento",
                      "Si",
                      "No"
                    );
                    if (result) {
                      handle_create_movement();
                    } else {
                    }

                  }}
                >
                  <ProductionQuantityLimitsIcon></ProductionQuantityLimitsIcon>CREAR MOVIMIENTO
                </Button>
              </FormControl>
              <FormControl required sx={{ width: 280 }}>
                <Button variant="contained"
                  sx={{ height: '35px', backgroundColor: "#82a8b1", fontSize: '11.5px' }}
                  onClick={async () => {

                    const result = await Confirm(
                      "¿Esta usted seguro de generar este movimiento en ceros?",
                      "Movimiento en Cerros",
                      "Si",
                      "No"
                    );
                    if (result) {
                      handle_movement_zero();
                    } else {
                    }

                  }}
                >
                  <ProductionQuantityLimitsIcon></ProductionQuantityLimitsIcon>MOVIMIENTO EN CEROS
                </Button>
              </FormControl>
            </Box>
          )}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Productos"}
              // update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>
      <div style={{ display: manual_movement ? "" : "none" }}>
        <WAREHOUSE_MOVEMENTS_LIST setmanual_movement={setmanual_movement}></WAREHOUSE_MOVEMENTS_LIST>

      </div>

    </div>
  );
};

export default WAREHOUSE_MOVEMENTS_V2;
