//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../main_components/modal/modal_Table";
//COMPONENTES

import AlertResponse from "../main_components/alerts/alertResponse";

//ESTILOS
import "../components_cfdi/estilo.css";
import '../componentSociosNegocios/componentSectors/bonuses.css';
import "../components_cfdi/modal.css";
import "./accountinga.css";
//IMAGENES/ICONOS
import Delete from "../imgComponents/borrar.png";
import CREATE from "../imgComponents/create.png";
import Edit from "../imgComponents/save.png";
import { refresh_token } from "../main_components/tokens/tokenrefresh";
import { delete_accountingA, get_accountingA } from "../services/finances/finances";
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";
import { SelectColumnFilter } from "../components_cfdi/filters";
import TableContainer from "../main_components/Table/TableContainer";
import OPERATION_ACCOUNTING_ACCOUNT from "./tools/op_accounting_account";

const TableAccountingAccount = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  var consulta = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(() => {
    handleSubmit();
  }, []);


  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }
  //proceso de consultar todas las cuentas contables
  const handleSubmit = async () => {
    if (consulta === 0) {
      consulta = consulta + 1;
      setmenssage("Consultando Cuentas Contables...");
      setmodalGeneral(true);
      setmodalT(true);
    } else {
      setmodalT(true);
    }
    setopen(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_accountingA();
            const data = (d === null ? [] : d.data.acc_accounts);
            setData(data);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          } catch (err) {
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div>
            <img
              alt=""
              id="crearCCl"
              src={CREATE}
              onClick={async () => {
                const result = await CustomDialog(
                  <div>
                    <OPERATION_ACCOUNTING_ACCOUNT operation={"CREATE"} handleSubmit={handleSubmit} alertas={alertas} setmodalT={setmodalT} />
                  </div>,
                  {
                    className: "modalTItle",
                    title: "Agregar cuenta contable",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
              title="Agregar cuenta contable"
              className="butonhov"

            ></img>
          </div>
        ),

        id: "Acciones",
        Cell: ({ valor, row }) => {
          const DeleteSector = async () => {

            setmodalT(true);
            setopen(true);
            setData([]);
            try {
              var rf_token = await refresh_token();
              if (rf_token === true) {
                setTimeout(async () => {
                  try {
                    const d = await delete_accountingA(row.original.id);
                    console.log(d);
                    alertas("Cuenta Contable Eliminada", true);
                    handleSubmit();
                  } catch (err) {
                    console.log("error", err);
                    setopen(false);
                    alertas("Error, no se pudo eliminar la cuenta contable", false);
                    handleSubmit();
                  }
                }, 1000);
              } else { }
            } catch (err) {
              console.log(err);
            }
          }
          return (
            <div>
              <img
                alt=""
                src={Delete}
                onClick={async () => {
                  const result = await Confirm('¿Esta usted seguro de eliminar la cuenta contable?', 'Eliminar Cuenta Contable', 'Si', 'No');
                  if (result) {
                    DeleteSector()
                  }
                }
                }
                className="butonhov"
                title="Eliminar Cuenta Contable"
              ></img>
              &nbsp;
              <img
                alt=""
                src={Edit}
                onClick={
                  () => {
                    CustomDialog(
                      <div>
                        <OPERATION_ACCOUNTING_ACCOUNT operation={"EDIT"} row={row} handleSubmit={handleSubmit} alertas={alertas} setmodalT={setmodalT} />
                      </div>,
                      {
                        className: "modalTItle",
                        title: "Editar Cuenta Contable",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                  }
                }
                className="butonhov"
                title="Editar Cuenta Contable"
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Código",
        accessor: "code"
      },
      {
        Header: "Nombre",
        accessor: "name"
      },
      {
        Header: "Concepto",
        accessor: "expense_concept"
      },
      {
        Header: "Efecto",
        accessor: "efect",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Tipo",
        accessor: "type",
        Filter: SelectColumnFilter,
      }
    ],
    []
  );
  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR CUENTAS CONTABLES", path: null },
        ]
      }

        new_version={true}
        modul="CONSULTAR_CUENTAS_CONTABLES_V2"
        path="/consult_accounting_account_v2"
      ></BREADCRUMBS>
      <br /><br /><br />
      <div className="TableAccounting">
        <div >
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <>

            <StickyTable>
              <TableContainer
                paginado={"Cuentas Contables"}
                consultas={"Cuentas Contables Consultadas"}
                exportar={true}
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                Gmodal={open}
                nametable={"Cuentas Contables"}

              />
            </StickyTable>
          </>
        </div>
      </div>
    </>
  );
}

export default TableAccountingAccount;
