import { useMemo, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { StickyTable} from "react-sticky-table";
import TableContainer from "../../../main_components/Table/TableContainer";

function DETAILS_SALES_REAL(props) {

console.log(props.row.original);
const [data, setdata]=useState(props.row.original);

const dialog = useDialog();



const columns = useMemo(
  () => [
    {
      Header:"Código",
      accessor:"code"
    },
    {
      Header:"Nombre",
      accessor:"product_name"
    },
    {
      Header:"Cantidad",
      accessor:"quantity"
    },
    {
      Header:"Total",
      accessor:(d)=>{
        return d.total
        ? Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.total)
        : Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.total);
      },  
    }
  ]
);


return (
    <div style={{"width": "95%", "margin":"0 auto", textAlign:"center"}}>
      <div className="detailstitle">
        <h2>PUNTO DE VENTA: {data.name}</h2>
      </div>
      <div className="griddetailsTR">
        <div className="itemdetails1">
        <label className="labeltextarea">Nota:</label> 
          <textarea 
            className="notadetails"
            value={data.sales[0].note === null || "" ? " " : data.sales[0].note.replace(/<br\s*\/?>/gi, '\n')}
          >
          </textarea>
        </div>
        <div className="itemdetails2">
          <TextField
            label="Total: "
            value={"$"+data.sales[0].total}
            sx={{ m: 1.5, width: "100%" }}
          ></TextField>
        </div>
        <div className="itemdetails3">
          <TextField
            label="Tipo de Venta: "
            value={data.sales[0].saletype}
            sx={{ m: 1.5, width: "100%" }}
          ></TextField>
        </div>
      </div>
      <div className="SubTableSalesTimeReal">
        <div > 
        <div className="titletable">
          <h1>Detalles </h1>
         </div>     
          <>

          {
            data.sales[0].details.length===0?
            <><p className="sindetails">Sin Detalles en la Venta</p></>:
            <StickyTable>
            <TableContainer
              paginado={"Productos"}
              consultas={"Productos consultados"}
              exportar={true}
              columns={columns}
              data={data.sales[0].details}
              style={{ overflowx: 10 }}
            />
          </StickyTable>
          }

           
          </>   
          </div>
        </div>
        <br></br>
        
          <Box>
          
            <Button
              style={{"backgroundColor":"red", "color":"white", "height":"40px"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
               onClick={() => {
                  dialog.close();
                } 
              }
            >
            cerrar
          </Button>
          </Box>
    </div>
  );
}
export default DETAILS_SALES_REAL;