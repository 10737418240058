//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";


import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//IMAGENES
import Spinner from "../../imgComponents/S.gif";
import reconsultaTable from "../../imgComponents/reconsultaTable.png";

import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { dashboard1_clasificacion_details, no_sales_clasification_details } from "../../services/reports/dashboard";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import ViewDetail_Detail_v2 from "./ViewDetail_Detail_Sales_v2";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ViewDetail_v2({ datei,datef,route,id_detail,pointSale,address, customer, nosale}) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [ReconsultaDta, setReconsultaDta] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

  const dialog = useDialog();

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setReconsulta(true);

    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            console.log("entra aqui xd");

            var d = [];

            if(nosale===true){
              d = await no_sales_clasification_details(customer?.replaceAll(' ', '_')?.replaceAll("/","-"), id_detail, datei, datef, route);
           
            }else{
              d = await dashboard1_clasificacion_details(customer?.replaceAll(' ', '_')?.replaceAll("/","-"), id_detail, datei, datef, route);
            }

            const data = d === null ? [] : d.data;
            setData(data);
            const cargos = data.filter((item) => item.efect === "CARGO");
            const abonos = data.filter((item) => item.efect === "ABONO");
            const sumCargos = cargos
              .map((cargoSum) => parseFloat(cargoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
    
            const sumAbonos = abonos
              .map((abonoSum) => parseFloat(abonoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            console.log("==>cargos<==");
            console.log(sumCargos);
            console.log("==>abonos<==");
            console.log(sumAbonos);
            if (sumCargos - sumAbonos === 0) {
              setEquality("Los datos cuadran");
              console.log("Los datos cuadran");
              setSuccess_msg("");
              setError_msg("none");
            } else {
              setEquality("Los datos no cudran");
              console.log("Los datos no cudran");
              setError_msg("");
              setSuccess_msg("none");
            }
            setReconsulta(false);
          }catch(err){
            setData([]);
            setReconsulta(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }

    /*
    axios
      .get(
        `https://zaqp4ihdn9.execute-api.us-east-1.amazonaws.com/prod/dashboard1/${sessionStorage.getItem("clasificacion").replaceAll(' ', '_')?.replaceAll("/","-")}/${id_detail}`,
        {
          params: {
            datei: datei,
            datef: datef,
            route: route,
          },

          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setData(data);
        const cargos = data.filter((item) => item.efect === "CARGO");
        const abonos = data.filter((item) => item.efect === "ABONO");

        const sumCargos = cargos
          .map((cargoSum) => parseFloat(cargoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);

        const sumAbonos = abonos
          .map((abonoSum) => parseFloat(abonoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);
        console.log("==>cargos<==");
        console.log(sumCargos);
        console.log("==>abonos<==");
        console.log(sumAbonos);
        if (sumCargos - sumAbonos === 0) {
          setEquality("Los datos cuadran");
          console.log("Los datos cuadran");
          setSuccess_msg("");
          setError_msg("none");
        } else {
          setEquality("Los datos no cudran");
          console.log("Los datos no cudran");
          setError_msg("");
          setSuccess_msg("none");
        }

        setReconsulta(false);
      })
      .catch((err) => {
        setData([]);
        setReconsulta(false);
      });
      */
  };


  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [

      // {
      //   id: "expander",
      //   header: "Acciones",
      //   Cell: ({ row }) => {
      //     function guardardato() {
      //       sessionStorage.setItem("idTicket", row.original.ticket);
      //     }

      //     function Denegado() {
      //       alert("No tienes el rol necesario para ver los detalles del gasto");
      //     }
         
      //       return (
      //         <a
      //           title="Consultar detalles"
      //           {...row.getToggleRowExpandedProps()}
      //         >
      //           {row?.isExpanded ? (
      //             <span title="Consultar detalles">🔼</span>
      //           ) : (
      //             <span
      //               title="Consultar detalles"
      //               onClick={guardardato}
      //             >
      //               🔽
      //             </span>
      //           )}
      //         </a>
      //       );
      //   },
      // },

      {
        header: "Fecha 11",
        accessorKey: "date",
      },
      {
        header: "Ticket",
        accessorKey: "ticket",
      },
      {
        header: "Congelador Asignado",
        accessorKey: "freezer_asigned",
      },
      {
         header: "Congelador Escaneado",
         accessorKey: "freezer_scanned",
         accessorFn: (d)=>{
           const dta1=d.freezer_asigned.includes(d.freezer_scanned);    
           return (<div>
                      <p style={{display:dta1===true?"none":"", color:"red"}}>{d.freezer_scanned}</p>
                      <p style={{display:dta1===true?"":"none", color:"green"}}>{d.freezer_scanned}</p>
                  </div>
                );
        }
      },     
      {
        id:"total",
        header: "Total",
        accessorKey: "total", 
        size: 100,
        accessorFn: (d) => {
           return d.total
             ? Intl.NumberFormat("es-MX", {
                 style: "currency",
                 currency: "MXN",
               }).format(d.total)
             : Intl.NumberFormat("es-MX", {
                 style: "currency",
                 currency: "MXN",
               }).format(d.total);
         },
        muiTableBodyCellProps: {
          align: "right"
        }
       }, 
            
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  
                  variant="h6"
                  component="h2"
                >
                  <img src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

        <div className="TableDetailTIket">
          <div className="success-msg" style={{ display: success_msg }}>
            <h3>
              {pointSale}
              <br></br>
              {address}
            </h3>
          </div>
          <div className="error-msg" style={{ display: error_msg }}>
            <h2>
              <i className="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
        <div className="new_table_v2">
          <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        // enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={
          { 
            showGlobalFilter: true, 
            columnVisibility:{
              cost: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
              profit: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
            }
          }
        }
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderDetailPanel={({ row }) => {

          if (row.getIsExpanded() === true) {
            sessionStorage.setItem("idTicket", row.original.ticket);
          }

          return(
            <ViewDetail_Detail_v2
                verData={true}
                datei={datei}
                datef={datef}
                id_detail={id_detail}
                customer={customer} 
            />
            )     
        }}
        
         renderToolbarInternalActions={({ table }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Detalles de Ventas"}
             //update_identifiers={update_identifiers}
           />
         )
         }
      />
          </div>
          
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default ViewDetail_v2;
