//PAQUETERIAS
import React, { useState } from "react";
import { useDialog, Confirm } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
//COMPONENTES
import JOIN_INVOICE_CANCEL_2 from "./join_invoice_cancel _v2";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { cancel_invoice_method_version_2, cancel_invoice_sat_method_version_2 } from "../../../services/invoices/invoice";
import JOIN_INVOICE_CANCEL_METHOD_2 from "./join_invoice_cancel _mothod_2 ";
//ESTILOS
import "../../estilo.css";
import "../../modal.css";
import "../../table.css";
//IMAGENES




const CANCEL_INVOICE_METHOD_2 = (props) => {



  const m_cancel = [
    { value: "", label: "Seleccione un motivo de cancelación" },
    { value: "01", label: "Comprobante emitido con errores con relación" },
    { value: "02", label: "Comprobante emitido con errores sin relación" },
    { value: "03", label: "No se llevó a cabo la operación" },
    {
      value: "04",
      label: "Operación nominativa relacionada en una factura global",
    },
  ];

  const [selectMCancel, setselectMCancel] = useState("");
  const [frelation, setfrelation] = useState(false);
  const [relacionF, setrelacionF] = useState(false);
  const [btonCancelSinRetaion, setbtonCancelSinRetaion] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [message_cancel, setmessage_cancel] = useState("");


  const dialog = useDialog();

  const handleMCancel = (event) => {
    if (event?.target?.value === "01") {
      setrelacionF(true);
      setfrelation(true);
      setselectMCancel(event.target.value);
      setbtonCancelSinRetaion(false);
    } else if (event?.target?.value === "04") {
      setrelacionF(true);
      setfrelation(true);
      setselectMCancel(event.target.value);
      setbtonCancelSinRetaion(false);
    } else {
      setrelacionF(false);
      setfrelation(false);
      setselectMCancel(event.target.value);
      setbtonCancelSinRetaion(true);
    }
  };

  const handlehancelR = async () => {
    setmessage_cancel("Cancelando factura en el sistema od2")
    setmodalT(true);
    var data = {
      reason: selectMCancel,
    };
    try {
      await cancel_invoice_method_version_2(props?.id, data);
      setTimeout(() => {
        handle_cancel_invoice_sat(data);
      }, 2000);
    } catch (err) {
      if (err?.code === "ERR_NETWORK") {
        alert("El proceso no se pudo completar, vuelve a intentarlo mas tarde por favor.")
        dialog.close(true);
      } else {
        alert(JSON.stringify(err?.response?.data?.errorMessage?.es === undefined ? err?.response?.data : err?.response?.data?.errorMessage?.es));
        catchErrorModul("Cancelar Factura OD", JSON.stringify(data), JSON.stringify(err?.response));
        dialog.close(true);
      }
      setmodalT(false);
    }
  };

  const handle_cancel_invoice_sat = async (data_invoice) => {
    setmessage_cancel("Cancelando factura en el SAT")

    try {
      await cancel_invoice_sat_method_version_2(props?.id, data_invoice);
      alert("Exito se cancelo la factura")
      setmodalT(false);
      props?.setUpdateData(true);
      dialog.close(true);
    } catch (err) {
      if (err?.code === "ERR_NETWORK") {
        alert("El proceso no se pudo completar, vuelve a intentarlo mas tarde por favor.")
        dialog.close(true);
      } else {
        alert(JSON.stringify(err?.response?.data?.errorMessage?.es === undefined ? err?.response?.data : err?.response?.data?.errorMessage?.es));
        catchErrorModul("Cancelar Factura SAT", JSON.stringify(data_invoice), JSON.stringify(err?.response));
        dialog.close(true);
      }
      setmodalT(false);
    }
  }


  const handle_direct_cancel_invoice_sat = async () => {
    var data = {
      reason: selectMCancel,
    };
    setmodalT(true);

    setmessage_cancel("Cancelando factura en el SAT")

    try {
      await cancel_invoice_sat_method_version_2(props?.id, data);
      alert("Exito se cancelo la factura")
      setmodalT(false);
      props?.setUpdateData(true);
      dialog.close(true);
    } catch (err) {
      if (err?.code === "ERR_NETWORK") {
        alert("El proceso no se pudo completar, vuelve a intentarlo mas tarde por favor.")
        dialog.close(true);
      } else {
        alert(JSON.stringify(err?.response?.data?.errorMessage?.es === undefined ? err?.response?.data : err?.response?.data?.errorMessage?.es));
        catchErrorModul("Cancelar Factura SAT", JSON.stringify(data), JSON.stringify(err?.response));
        dialog.close(true);
      }
      setmodalT(false);
    }
  }




  return (
    <div className="">
      <MODAL_TABLE open={true} modalGeneral={modalT} modalT={modalT} message={message_cancel}></MODAL_TABLE>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '98%', margin: "0 auto", marginBottom: "10px" }}>
        <TextField
          select
          label="Tipo de cancelación"
          value={selectMCancel}
          sx={{ m: 2, width: "100%" }}
          onChange={handleMCancel}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            native: true,
          }}
        >
          {m_cancel.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '98%', margin: "0 auto", marginBottom: "10px" }}>
        <p className="contentMCancel">Motivo de cancelación: {selectMCancel}</p>
      </Box>
      <div
        style={{ display: relacionF === false ? "none" : "" }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '95%', margin: "0 auto", marginBottom: "10px" }}>

          <JOIN_INVOICE_CANCEL_METHOD_2
            // handleSubmit={props?.handle_get_invoice()}
            open={frelation}
            reason_cancel={selectMCancel}
            setUpdateData={props?.setUpdateData}
            id_cancel={props?.id}
            flag_cancel={props?.flag_cancel}
          ></JOIN_INVOICE_CANCEL_METHOD_2>
        </Box>
      </div>
      <div style={{ display: btonCancelSinRetaion === false ? "none" : "" }}>

        <Box sx={{ display: 'flex', justifyContent: 'center', width: '98%', margin: "0 auto", marginBottom: "10px" }}>
          <Button
            onClick={async () => {
              const result = await Confirm(
                "¿Esta usted seguro de cancelar esta factura?",
                `Cancelar Factura ${props?.serie}${props?.folio}`,
                "Si",
                "No"
              );
              if (result) {
                handlehancelR();
              }
            }}
            sx={{ m: 2, width: "100%" }}
            variant="contained"
            style={{ background: "green", display: props?.flag_cancel === true ? "none" : "" }}
          >
            CANCELAR FACTURA V2
          </Button>

          <Button
            onClick={async () => {
              const result = await Confirm(
                "¿Esta usted seguro de cancelar esta factura directo al SAT?",
                `Cancelar Factura ${props?.serie}${props?.folio}`,
                "Si",
                "No"
              );
              if (result) {
                handle_direct_cancel_invoice_sat();
              }
            }}
            sx={{ m: 2, width: "100%" }}
            variant="contained"
            style={{ background: "green", display: props?.flag_cancel === true ? "" : "none" }}
          >
            CANCELAR FACTURA V2 SAT
          </Button>



          <Button
            onClick={() => {
              dialog.close();
            }}
            sx={{ m: 2, width: "100%" }}
            variant="contained"
            style={{ background: "red" }}
          >
            SALIR
          </Button>

        </Box>
      </div>



    </div>
  );
};

export default CANCEL_INVOICE_METHOD_2;
