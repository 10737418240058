import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog } from 'react-st-modal';

import TextField from "@mui/material/TextField";

export const CONFIRM_UPLOAD= (props) => {

    const dialog = useDialog();
    const [conf, setconf]=useState("");
    const [v, setv]=useState(true);


    const handlecheck = (e)=>{
        
        if(e==="CONFIRMAR"){
            setconf(e);
            setv(false);
        }else{
            setconf(e);
            setv(true);
        }
    }

  return (
    <div className='container-confirm'>

        <div className='input-confir'>
        <p className='confirmar'>Escribe la palabra <span>"CONFIRMAR"</span> para continuar.</p>
        <input
            placeholder="CONFIRMAR"
            required
            sx={{ m: 1.5, width: "100%"}}
            onChange={(e) => handlecheck(e.target.value)}
            InputLabelProps={{ shrink: true }}
        ></input>
        </div>
        <br/>
        <Box>
            <Button
                style={{"backgroundColor":"green"}}
                variant="contained"
                sx={{ m: 1.5, width: "30ch" }}
                disabled={v}
                onClick={()=>{
                    dialog.close();
                    props.put_address();
                }}
            >Actualizar
            </Button>
            <Button
                style={{"backgroundColor":"red"}}
                variant="contained"
                sx={{ m: 1.5, width: "20ch" }}
                onClick={() => {
                    dialog.close();
                    } 
                }
            >cancelar
            </Button>
          </Box>
    </div>
  )
}
