import { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import { CustomDialog, Confirm } from "react-st-modal";
import SAVE from "../../../imgComponents/save.png";
import DELETE from "../../../imgComponents/borrar.png"
import { dowload_packages, send_packages } from '../../../services/unilever/unilever';
import { base64toBlobzip } from '../../../main_components/donwloadfuncion/download';
import { delete_business_partner } from '../../../services/businesspartners/businesspartners';
import CREATE_UPDATE from './set_bussines_p';
const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const [id_bp, setid_bp] = useState("");
    console.log(props.row.getCanPin())

    const handle_delete = async (id) => {
        props.setIsLoading(true);
        try {
            await delete_business_partner(id);
            props.setIsLoading(false);
            props.handleSubmit();
        } catch (err) {
            alert(err);
            props.setIsLoading(false);
        }
    };


    return (
        <div>
            <MenuItem
                key={"keymenu1"}
                sx={{ m: 0 }}
                onClick={async () => {
                    props.closeMenu();
                    const result = await CustomDialog(
                        <CREATE_UPDATE
                            method={"UPDATE"}
                            actualiza={false}
                            supplierdata={false}
                            code={props.row.original.code}
                            business_partner={props.row.original.business_partner}
                            tax_regime={props.row.original.tax_regime}
                            name={props.row.original.name}
                            rfc={props.row.original.rfc}
                            first_last_name={props.row.original.first_last_name}
                            second_last_name={props.row.original.second_last_name}
                            balance={props.row.original.balance}
                            credit_days={props.row.original.credit_days}
                            business_partner_type={props.row.original.business_partner_type}
                            capital_regime={props.row.original.capital_regime}
                            partner_type={props.row.original.partner_type}
                            person_type={props.row.original.person_type}
                            cfdi_use={props.row.original.cfdi_use}
                            phone={props.row.original.phone}
                            email={props.row.original.email}
                            metodo_pago={props.row.original.metodo_pago}
                            paymethod={props.row.original.paymethod}
                            allow_invoicing={props.row.original.allow_invoicing}
                            street={props.row.original.street}
                            outdoor_number={props.row.original.outdoor_number}
                            inside_number={props.row.original.neighborhood}
                            neighborhood={props.row.original.neighborhood}
                            zip_code={props.row.original.zip_code}
                            city={props.row.original.city}
                            state={props.row.original.state}
                            country={props.row.original.country}
                            reference={props.row.original.reference}
                            address_type={props.row.original.address_type}
                            subsidiary={props.row.original.subsidiary}
                            tax_residence={props.row.original.tax_residence === null ? "" : props.row.original.tax_residence}
                            num_reg_id_trib={props.row.original.num_reg_id_trib}
                            town={props.row.original.town}
                            partner_type_data={props.row.original.partner_type}
                            uso_cfdi_data={props.row.original.cfdi_use}
                            state_data={props.row.original.state}
                            address_type_data={props.row.original.address_type}
                            tax_regime_data={props.row.original.tax_regime}
                            subsidiary_data={props.row.original.subsidiary}
                            metodo_pago_data={props.row.original.metodo_pago}
                            account_number={props.row.original.account_number}
                            id={props.row.original.id}
                            account_data={props.row.original.name_accounting_account + "-" + props.row.original.code_accounting_account}
                            id_accounting_account_fk={props.row.original.id_accounting_account_fk}
                            new_version={true}
                        ></CREATE_UPDATE>,
                        {
                            className: "custom-modal-2",
                            title: "Actualizar socio de negocio",
                            showCloseIcon: true,
                            isCanClose: false,
                        }
                    );
                    // eslint-disable-next-line no-lone-blocks
                    if (result?.reconsult) {
                        // console.log(result.id_bp.data.business_partner.id);
                        // props.handleSuccess(Number(result.id_bp.data.business_partner.id))
                        props?.setbp_dta(result.data_bp.data.business_partner);
                    }
                }}

            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={SAVE}
                        className="mapa_icono"
                    ></img>
                </ListItemIcon>
                Editar Socio De Negocios
            </MenuItem>
            <MenuItem
                key={"keymenu2"}
                onClick={async () => {
                    props.closeMenu();
                    const result = await Confirm(
                        "¿Esta usted seguro de eliminar este socio de negocios?",
                        "Eliminar Socio de Negocios",
                        "Si",
                        "No"
                    );

                    if (result) {
                        handle_delete(props.row.original.id)
                    } else {
                    }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={DELETE}
                        className="mapa_icono"
                    ></img>
                </ListItemIcon>
                Eliminar Socio de Negocios
            </MenuItem>

        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
