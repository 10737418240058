import './description.css'

export const DESCRIPTION_CASE = ({
    subject = "",
    user_name = "",
    description = "",
    ticket = "",
}) => {

  return (
    <div className="description-container" style={{"textAlign":"center"}}>   
      <div className="description-content">
        {description}
      </div>
      <br/>
    </div>
  )
}
