import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import {
  post_commissions,
  put_commissions,
} from "../../../../services/reports/commissions";
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";
import { InputAdornment } from "@mui/material";
import { get_route_list } from "../../../../services/businesspartners/businesspartners";
export const OPERATIONS_COMMISSIONS = (props) => {
  console.log(props);

  const dialog = useDialog();
  const [fi, setfi] = useState(
    props.operation === "EDIT"
      ? props.row.original.date_start.split(" ")[0]
      : ""
  );
  const [ff, setff] = useState(props.operation === "EDIT" ? props.row.original.date_end.split(" ")[0] : "");
  const [sale_type, setsale_type] = useState(props.operation === "EDIT" ? props.row.original.sale_type : "SELECT");
  const [seller_own_sales_com, setseller_own_sales_com] = useState(props.operation === "EDIT" ? props.row.original.seller_own_sales_com : 0);
  const [dealer_own_sales_com, setdealer_own_sales_com] = useState(props.operation === "EDIT" ? props.row.original.dealer_own_sales_com : 0);
  const [assistant_own_sales_com, setassistant_own_sales_com] = useState(props.operation === "EDIT" ? props.row.original.assistant_own_sales_com : 0);
  const [seller_sec_sales_com, setseller_sec_sales_com] = useState(props.operation === "EDIT" ? props.row.original.seller_sec_sales_com : 0);
  const [dealer_sec_sales_com, setdealer_sec_sales_com] = useState(props.operation === "EDIT" ? props.row.original.dealer_sec_sales_com : 0);
  const [assistant_sec_sales_com, setassistant_sec_sales_com] = useState(props.operation === "EDIT" ? props.row.original.assistant_sec_sales_com : 0);
  const [id_route, setid_route] = useState(props.operation === "EDIT" ? props.row.original.id_route_fk===null?"all":props.row.original.id_route_fk : "all");
  const [route_list, setroute_list] = useState([]);
console.log(id_route);
  const [band, setband] = useState(true);

  const [vfi, setvfi] = useState(false);
  const [vff, setvff] = useState(false);
  const [vst, setvst] = useState(false);
  const [vsosc, setvsosc] = useState(false);
  const [vdosc, setvdosc] = useState(false);
  const [vaosc, setvaosc] = useState(false);
  const [vsssc, setvssc] = useState(false);
  const [vdssc, setvdssc] = useState(false);
  const [vassc, setvassc] = useState(false);



  const handle_get_router_list = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
          try {
            const d = await get_route_list();
            console.log(d.data.parametersList);
            const nuevoObjeto = {
                "idRoute": "all",
                "route": "Todos"
            };
            d.data.parametersList.unshift(nuevoObjeto);
            console.log(d);
            setroute_list(d.data.parametersList);
          } catch (err) {
            console.log(err);
          }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  
  const updatecommss = async () => {
    if (fi === "" || fi === " " || ff === "" || ff === " ") {
      props.alertas("Fechas Faltantes", false);
      return null;
    }
    var data = {
      seller_own_sales_com: parseFloat(seller_own_sales_com),
      dealer_own_sales_com: parseFloat(dealer_own_sales_com),
      assistant_own_sales_com: parseFloat(assistant_own_sales_com),
      seller_sec_sales_com: parseFloat(seller_sec_sales_com),
      dealer_sec_sales_com: parseFloat(dealer_sec_sales_com),
      assistant_sec_sales_com: parseFloat(assistant_sec_sales_com),
      date_start: fi,
      date_end: ff,
      sale_type: sale_type,
      id_route: id_route==="all"?null:id_route,

    };

    props.setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await put_commissions(props.row.original.id, data);
            console.log(d);
            props.alertas("Comisión actualizada con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          } catch (err) {
            console.log(err);
            catchErrorModul(
              "Comisiones",
              JSON.stringify(data),
              JSON.stringify(
                err?.response === undefined ? "Token expirado" : err?.response
              )
            );
            console.log("error post", err);
            props.alertas("No se pudo actualizar la comisión", false);
            props.setmodalT(false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const analisis = () => {
    if (
      sale_type === "SELECT" ||
      seller_own_sales_com === 0 ||
      seller_own_sales_com < 0 ||
      dealer_own_sales_com === 0 ||
      dealer_own_sales_com < 0 ||
      assistant_own_sales_com === 0 ||
      assistant_own_sales_com < 0 ||
      seller_sec_sales_com === 0 ||
      seller_sec_sales_com < 0 ||
      dealer_sec_sales_com === 0 ||
      dealer_sec_sales_com < 0 ||
      assistant_sec_sales_com === 0 ||
      assistant_sec_sales_com < 0 ||
      fi === "" ||
      fi === " " ||
      ff === "" ||
      ff === " "
    ) {
      sale_type === "SELECT" ? setvst(true) : setvst(false);
      seller_own_sales_com > 0.0 ? setvsosc(false) : setvsosc(true);
      dealer_own_sales_com > 0.0 ? setvdosc(false) : setvdosc(true);
      assistant_own_sales_com > 0.0 ? setvaosc(false) : setvaosc(true);
      seller_sec_sales_com > 0.0 ? setvssc(false) : setvssc(true);
      dealer_sec_sales_com > 0.0 ? setvdssc(false) : setvdssc(true);
      assistant_sec_sales_com > 0.0 ? setvassc(false) : setvassc(true);
      fi === "" || fi === " " ? setvfi(true) : setvfi(false);
      ff === "" || ff === " " ? setvff(true) : setvff(false);
      props.alertas("Valores Faltantes", false);
    } else {
      props.operation === "EDIT" ? updatecommss() : createcommss();
    }
  };

  const createcommss = async () => {
    var data = {
      seller_own_sales_com: parseFloat(seller_own_sales_com),
      dealer_own_sales_com: parseFloat(dealer_own_sales_com),
      assistant_own_sales_com: parseFloat(assistant_own_sales_com),
      seller_sec_sales_com: parseFloat(seller_sec_sales_com),
      dealer_sec_sales_com: parseFloat(dealer_sec_sales_com),
      assistant_sec_sales_com: parseFloat(assistant_sec_sales_com),
      date_start: fi,
      date_end: ff,
      sale_type: sale_type,
      id_route: id_route==="all"?null:id_route,
    };

    props.setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await post_commissions(data);
            console.log(d);
            props.alertas("Comisión registrada con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          } catch (err) {
            console.log(err);
            catchErrorModul(
              "Comisiones",
              JSON.stringify(data),
              JSON.stringify(
                err?.response === undefined ? "Token expirado" : err?.response
              )
            );
            console.log("error post", err);
            props.alertas("No se pudo registrar la comisión", false);
            props.setmodalT(false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(()=>{
    handle_get_router_list();
  },[])
  return (
    <>
      <div className="container-form-commss" style={{"textAlign": "center"}}>
        <div className="grid-commss1">
          <div className="commss1">
            <TextField
              select
              required
              value={sale_type}
              label="Tipo de Venta"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setsale_type(event.target.value)}
              style={
                vst === true
                  ? { border: "2px solid red", borderRadius: "5px" }
                  : {}
              }
              onSelect={() => setvst(false)}
              onBlur={() =>
                sale_type === "SELECT" ? setvst(true) : setvst(false)
              }
            >
              <option key={0} value={"SELECT"} selected>
                {"Selecciona"}
              </option>
              <option key={1} value={"PREVENTA"}>
                {"PREVENTA"}
              </option>
              <option key={2} value={"VENTA DIRECTA"}>
                {"VENTA DIRECTA"}
              </option>
            </TextField>
            <p
              className="p_alert"
              style={vst === true ? { display: "block" } : { display: "none" }}
            >
              Selecciona el tipo de venta
            </p>
          </div>
          <div className="commss2">
            <label className="Fechainicial">Fecha Inicial</label>
            <input
              type="text"
              className="fi"
              required
              value={fi}
              style={{ width: "100%" }}
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha inicial"
              onChange={(event) => setfi(event.target.value)}
              onBlur={() =>
                fi === "" || fi === " " ? setvfi(true) : setvfi(false)
              }
            ></input>
            <p
              className="p_alert"
              style={vfi === true ? { marginTop:"10px", display: "block" } : { display: "none" }}
            >
              Falta asignar fecha inicial
            </p>
          </div>
          <div className="commss3">
            <label className="Fechainicial">Fecha Final</label>
            <input
              type="text"
              required
              value={ff}
              style={{ width: "100%" }}
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha Final"
              onChange={(event) => setff(event.target.value)}
              onBlur={() =>
                ff === "" || ff === " " ? setvff(true) : setvff(false)
              }
            ></input>
            <p
              className="p_alert"
              style={vff === true ? { marginTop:"10px", display: "block" } : { display: "none" }}
            >
              Falta asignar fecha final
            </p>
          </div>

          <div className="commssn">
            <TextField
                select
                child
                label="Ruta"
                value={id_route}
                sx={{ m: 1.5, width: "100%"}}
                onChange={(e)=>setid_route(e.target.value)}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {route_list?.map((option) => (
                  <option
                    key={option.idRoute}
                    value={option.idRoute}
                    selected={option.idRoute}
                  >
                    {option.route}
                  </option>
                ))}
              </TextField>
           
          </div>
        </div>
        


        <div className="grid-commss2">
          <div className="commss4">
            <TextField
              label="Comisión preventa sobre ventas propias"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={seller_own_sales_com}
              onChange={(event) => setseller_own_sales_com(event.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={
                vsosc === true
                  ? { border: "2px solid red", borderRadius: "5px" }
                  : {}
              }
              onSelect={() => setvsosc(false)}
              onBlur={() =>
                seller_own_sales_com > 0 ? setvsosc(false) : setvsosc(true)
              }
            ></TextField>
            <p
              className="p_alert"
              style={
                vsosc === true ? { display: "block" } : { display: "none" }
              }
            >
              El campo debe ser mayor a 0
            </p>
          </div>
          <div className="commss5">
            <TextField
              label="Comisión reparto sobre ventas propias"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={dealer_own_sales_com}
              onChange={(event) => setdealer_own_sales_com(event.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={
                vdosc === true
                  ? { border: "2px solid red", borderRadius: "5px" }
                  : {}
              }
              onSelect={() => setvdosc(false)}
              onBlur={() =>
                dealer_own_sales_com > 0 ? setvdosc(false) : setvdosc(true)
              }
            ></TextField>
            <p
              className="p_alert"
              style={
                vdosc === true ? { display: "block" } : { display: "none" }
              }
            >
              El campo debe ser mayor a 0
            </p>
          </div>
          <div className="commss6">
            <TextField
              label="Comisión ayudante sobre ventas propias"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={assistant_own_sales_com}
              onChange={(event) =>
                setassistant_own_sales_com(event.target.value)
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={
                vaosc === true
                  ? { border: "2px solid red", borderRadius: "5px" }
                  : {}
              }
              onSelect={() => setvaosc(false)}
              onBlur={() =>
                assistant_own_sales_com > 0 ? setvaosc(false) : setvaosc(true)
              }
            ></TextField>
            <p
              className="p_alert"
              style={
                vaosc === true ? { display: "block" } : { display: "none" }
              }
            >
              El campo debe ser mayor a 0
            </p>
          </div>
        </div>
        <div className="grid-commss3">
          <div className="commss7">
            <TextField
              label="Comisión preventa sobre ventas secundarias"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={seller_sec_sales_com}
              onChange={(event) => setseller_sec_sales_com(event.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={
                vsssc === true
                  ? { border: "2px solid red", borderRadius: "5px" }
                  : {}
              }
              onSelect={() => setvssc(false)}
              onBlur={() =>
                seller_sec_sales_com > 0 ? setvssc(false) : setvssc(true)
              }
            ></TextField>
            <p
              className="p_alert"
              style={
                vsssc === true ? { display: "block" } : { display: "none" }
              }
            >
              El campo debe ser mayor a 0
            </p>
          </div>
          <div className="commss8">
            <TextField
              label="Comisión reparto sobre ventas secundarias"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={dealer_sec_sales_com}
              onChange={(event) => setdealer_sec_sales_com(event.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={
                vdssc === true
                  ? { border: "2px solid red", borderRadius: "5px" }
                  : {}
              }
              onSelect={() => setvdssc(false)}
              onBlur={() =>
                dealer_sec_sales_com > 0 ? setvdssc(false) : setvdssc(true)
              }
            ></TextField>
            <p
              className="p_alert"
              style={
                vdssc === true ? { display: "block" } : { display: "none" }
              }
            >
              El campo debe ser mayor a 0
            </p>
          </div>
          <div className="commss9">
            <TextField
              label="Comisión ayudante sobre ventas secundarias"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={assistant_sec_sales_com}
              onChange={(event) =>
                setassistant_sec_sales_com(event.target.value)
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={
                vassc === true
                  ? { border: "2px solid red", borderRadius: "5px" }
                  : {}
              }
              onSelect={() => setvassc(false)}
              onBlur={() =>
                assistant_sec_sales_com > 0 ? setvassc(false) : setvassc(true)
              }
            ></TextField>
            <p
              className="p_alert"
              style={
                vassc === true ? { display: "block" } : { display: "none" }
              }
            >
              El campo debe ser mayor a 0
            </p>
          </div>
        </div>

        <Box>
          <Button
            variant="contained"
            style={{ "background-color": "green" }}
            sx={{ m: 1.5, width: "30ch" }}
            disabled={!band}
            onClick={() => analisis()}
          >
            {props.operation === "EDIT" ? "GUARDAR CAMBIOS" : "CREAR COMISIÓN"}
          </Button>
          :
          <Button
            style={{ "background-color": "red" }}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            disabled={!band}
            onClick={() => {
              dialog.close();
            }}
          >
            cancelar
          </Button>
        </Box>
      </div>
    </>
  );
};
