//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import { CustomDialog, Confirm } from "react-st-modal";

//COMPONENTES

//ESTILOS

import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_debts_detail, delete_debts_detail } from "../../../services/banck/banck";
import VIEW from "../../../imgComponents/lupa.png";
import DETAIL_SALE from "./detail_sale";
function RETURNS_SALES(props) {
  const [data, setData] = useState(props.row);
  const [isLoading, setisLoading] = useState(true);
  console.log(props.row.id)

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false)
    }, 100);
  }, []);

  const columns = useMemo(
    () => [

      {
        header: "Ac.",
        id: "AC-sub",
        size: 80,
        accessorFn: (d) => <img src={VIEW} title="VER DETALLES" style={{ cursor: "pointer" }}
          onClick={async () => {
            await CustomDialog(
              <DETAIL_SALE
                id={d?.id}
                typeSale={"SALES_RETURNS"}
              ></DETAIL_SALE>,
              {
                className: "modalTItle",
                title: "Detalle de venta",
                showCloseIcon: true,
                isCanClose: false,
              }
            );
          }}></img>

      },
      {
        header: "Tipo",
        id: "type-sub",
        accessorKey: "type",
        size: 80,
      },
      {
        header: "Ticket",
        id: "ticket-sub",
        accessorKey: "ticket",
        size: 80,

      },
      {
        header: "Cliente",
        id: "name_pos-sub",
        accessorKey: "name_pos",
        size: 130,
      },
      {
        header: "Fecha",
        id: "date-sub",
        accessorKey: "date",
        size: 150,
      },
      {
        header: "Tipo",
        id: "saletype-sub",
        accessorKey: "saletype",
        size: 80,
      },
      {
        header: "Sub Total",
        id: "subtotal-sub",
        accessorKey: "subtotal",
        size: 80,

      },
      {
        header: "Descuentos",
        id: "discount-sub",
        accessorKey: "discount",
        size: 80,

      },
      {
        header: "Impuestos",
        id: "tax-sub",
        accessorKey: "tax",
        size: 80,

      },
      {
        header: "Total",
        id: "total-sub",
        accessorKey: "total",
        size: 80,

      },
      {
        header: "Vendedor",
        id: "name_person_user-sub",
        accessorKey: "name_person_user",
        size: 130,

      },
      {
        header: "N. Crédito",
        id: "pagado-sub",
        accessorKey: "pagado",
        size: 80,


      },
      {
        header: "Credito",
        id: "folio_invoice-sub",
        accessorKey: "folio_invoice",
        size: 80,

      },
    ],

    []
  );

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters
        enablePagination
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        state={{ isLoading: isLoading }}
        muiCircularProgressPropsave_datas={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        muiTableBodyRowProps={{ hover: false }}
      />

    </div>

  );
}

export default RETURNS_SALES;
