import axios from "axios";
import config from "../../config";

const urlbp = `${config?.baseURLAss}`;


/////////// V E H I C U L O S ///////////////////////

export const get_vehicle = async () => {
  const url = `${urlbp}/development/vehicles`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const delete_vehicle = async (id) => {
  const url = `${urlbp}/development/vehicles/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post_vehicle = async (data) => {
  const url = `${urlbp}/development/vehicles`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const put_vehicle = async (id, data) => {
  const url = `${urlbp}/development/vehicles/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};







////////////////////M A R C A S ///////////////////////////

//consultar todas las marcas
export const get_brands = async () => {
  const url = `${urlbp}/development/brands`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//ELIMINAR MARCA
export const delete_brands = async (id) => {
  const url = `${urlbp}/development/brands/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Crear MARCA
export const post_brands = async (data) => {
  const url = `${urlbp}/development/brands`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//actualizar MARCA
export const put_brands = async (id, data) => {
  const url = `${urlbp}/development/brands/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


////////////////////// M O D E L O S //////////////////////////////

//consultar todos los modelos
export const get_models = async (id) => {
  const url = `${urlbp}/development/brands/${id}/models`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//ELIMINAR MODELO
export const delete_models = async (id_brands, id_model) => {
  const url = `${urlbp}/development/brands/${id_brands}/models/${id_model}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//Crear MODELO
export const post_models = async (id_brands, data) => {
  const url = `${urlbp}/development/brands/${id_brands}/models`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//actualizar MODELO
export const put_models = async (id_brands, id_model, data) => {
  const url = `${urlbp}/development/brands/${id_brands}/models/${id_model}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


////////////////CONGELADORES ////////////////////////

//Crear congelador
export const post_cabinets = async (data) => {
  const url = `${urlbp}/development/cabinets`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//actualizar congelador

export const put_cabinets = async (id, data) => {
  const url = `${urlbp}/development/cabinets/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//ESTADOS DE UN CONGELADOR
export const get_status_cabinets = async () => {
  const url = `${urlbp}/development/cabinets/status`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//almacenes de congeladores
export const get_cabinets_warehouse = async () => {
  const url = `${urlbp}/development/cabinets/warehouse`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//modelos de congeladores
export const get_cabinets_models = async () => {
  const url = `${urlbp}/development/cabinets/models`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//modelos de congeladores
export const get_cabinets_brands = async () => {
  const url = `${urlbp}/development/cabinets/brands`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



//detalles de un congelador
export const get_cabinets_details = async (id) => {
  const url = `${urlbp}/development/cabinets/${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//comodato del congelador
export const get_cabinets_comodato = async (id) => {
  const url = `${urlbp}/development/cabinets/${id}/contract`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_cabinets_movement = async (id) => {
  const url = `${urlbp}/development/cabinets/${id}/movement`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//descapitalizar congelador
export const cabinets_descapitalizar = async (data) => {
  const url = `${urlbp}/development/cabinets/decapitalize`;
  try {
    const response = await axios.post(url, data,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//eliminar un congelador
export const delete_cabinets = async (id) => {
  const url = `${urlbp}/development/cabinets/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//crear movimiento congelador
export const post_cabinets_movement = async (id, data) => {
  const url = `${urlbp}/development/cabinets/${id}/movement`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//consultar los movimientos de un almacen de congeladores
export const get_movement_warehouse_cabinets = async (id, fi, ff) => {
  const url = `${urlbp}/development/cabinets/warehouse/${id}/movement`;
  try {
    const response = await axios.get(url, {
      params:{di: fi ,df: ff},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_movement_pos_cabinets = async (id, fi, ff) => {
  const url = `${urlbp}/development/cabinets/points-of-sale/${id}/movement`;
  try {
    const response = await axios.get(url, {
      params:{di: fi ,df: ff},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_movement_cabinets_sales = async (id, fi, ff) => {
  const url = `${urlbp}/development/cabinets/sales`;
  try {
    const response = await axios.get(url, {
      params:{di: fi ,df: ff, scan: id},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


