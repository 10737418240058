//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import NotificationSystem from 'react-notification-system';

//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { get_producst_line } from "../../services/inventory/inventory";
import CREATE_UPDATE from "./tools/create_update";
import { delete_producst_line } from "../../services/inventory/inventory";

import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import VIEW from "../../imgComponents/lupa.png";

import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/visualizar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import PRODUCTS from "../products/products";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
const PRODUCTS_LINE = () => {
  const [data, setData] = useState([]);
  const [menssage, setMenssage] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const dialog = useDialog();
  const notificationSystemRef = useRef();

  const handleSubmit = async (a,b) => {
    setmodalT(true);
    setData([]);

    setMenssage("Consultando impuestos...");
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt = await get_producst_line();
            setData(dt.data);
            if(a){
              handle_alert(b,a)
            }
            setmodalT(false);
          } catch (err) {
            setData([]);
            setMenssage("");
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handle_products = async (id) => {
    sessionStorage.setItem("id_product_line", id);
    window.location = "/products";
  };
  useEffect(() => {
    handleSubmit();
  }, []);
  const handle_reconsult = (result) => {
    if (result?.confirm) {
      handleSubmit(result?.confirm, result.msj);
    } else if(result?.confirm === false){
      handle_alert(result.msj,result.confirm);
    }
  };

  const handle_alert = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: "Acciones",
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("id_product_line", row.original.id);
          }

          return (
            <a {...row.getToggleRowExpandedProps()} title="Ver productos">
              {row?.isExpanded ? (
                <span title="Ver productos">🔼</span>
              ) : (
                <span title="Ver productos" onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
      },
      {
        Header: () => {
          return (
            <img
              alt=""
              title="Crear"
              src={CREATE}
              style={{display:sessionStorage.getItem("ROLE_INVENTORY_PRODUCTS_LINE")==="ROLE_INVENTORY_PRODUCTS_LINE"?"":"none"}}
              onClick={async () => {
                const result = await CustomDialog(
                  <CREATE_UPDATE method={"CREATE"}></CREATE_UPDATE>,
                  {
                    className: "modalTItle",
                    title: "Crear linea de productos",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
                // eslint-disable-next-line no-lone-blocks
                {
                  handle_reconsult(result);
                }
              }}
              className="centerText, taxes"
            ></img>
          );
        },
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function Redireccion() {
            window.location = "/consult_configure_taxes";
          }
          const handle_delete_producst_line = async (id) => {
            try{
              var rf_token=await refresh_token();
              if(rf_token===true){
                setTimeout(async() => {
                  try {
                    await delete_producst_line(id);
                    setMenssage("Eliminando lista de producto...");
                    setmodalT(true);
                    handle_alert("Linea de producto eliminada correctamente",true);
                  } catch (err) {
                    setmodalT(false);
                    setMenssage("");
                    console.log();
                    handle_alert(JSON.stringify(err.response.data),false);
                  }
                }, 1000);
              }else{}
            }catch(err){
              console.log(err);
            }
            
          };

          return (
            <div>
              <img
                alt=""
                title="Actualizar"
                src={SAVE}
                style={{display:sessionStorage.getItem("ROLE_INVENTORY_PRODUCTS_LINE")==="ROLE_INVENTORY_PRODUCTS_LINE"?"":"none"}}
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE
                      name_line={row.original.name_line}
                      code={row.original.code}
                      description={row.original.description}
                      enable={row.original.enable}
                      method={"UPDATE"}
                      id={row.original.id}
                      name_supplier={row.original.business_partner}
                      id_supp={row.original.id_business_partner_provider_fk}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalTItle",
                      title: "Actualizar linea de productos",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handle_reconsult(result);
                  }
                }}
                className="centerText, taxes"
              ></img>
            
              <img
                alt=""
                title="Eliminar Linea de productos"
                src={DELETE}
                style={{display:sessionStorage.getItem("ROLE_INVENTORY_PRODUCTS_LINE")==="ROLE_INVENTORY_PRODUCTS_LINE"?"":"none"}}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta linea de productos?",
                    "Eliminar liena de productos",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_producst_line(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Nombre de la linea",
        accessor: "name_line",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Proveedor",
        accessor: "business_partner",
      },
      {
        Header: "Activo",
        accessor: (d) => {
          return d.enable === true ? "Si" : "NO";
        },
      },
    ],
    []
  );

  return (
    <div >
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <BREADCRUMBS niveles={
            [
              {label: "CONSULTAR LINEA DE PRODUCTOS", path: null},
            ]
          }
          new_version={true}
          modul="LINEA DE PRODUCTOS V2"
          path="/products_line_v2"  
      ></BREADCRUMBS>

      <div className="TableTaxes">
        <div >
          <TableContainer
            columns={columns}
            data={data}
            exportar={true}
            nametable={"Línea de Productos"}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <PRODUCTS subtabla={true}/>
                </div>
              );
            }}

            
          />
        </div>
      </div>
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

    </div>
  );
};

export default PRODUCTS_LINE;
