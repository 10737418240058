
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const VIEW_FILES_DESCRIPTION = (props) => {
  
    console.log(props);

    const download_files = (data) =>{
        const link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${data?.file}`;
        link.download = data?.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    }

  return (
    <div className="list-files-upload-ticket-decription">
        <div className='list-files-upload-items'>
            {
                props?.data?.map((data, index)=>(
                    <div key={index} className='list-item-descripcion-des'>
                        <div className='buttons-actions'>
                            <CloudDownloadIcon onClick={()=>download_files(data)}></CloudDownloadIcon>     
                        </div>
                        <p>
                            <span className="file-name">{data?.file_name}</span>
                        </p>   
                    </div>
                ))
            }
        </div>
    </div>
  )
}
