//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';

import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import {Box, Button} from '@mui/material';
import { formattedCurrentDate } from "../../main_components/date/day";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { SEARCH_FILTER_WEB_MOVIL } from "../../main_components/methods_v2/search_filter_web_movil";
import { get_pos_routes } from "../../services/reports/reports";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

export const COMPARATIVE = () => {

  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  var consulta = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [date, setdate]= useState(dayjs(formattedCurrentDate));

  const [datcol, setdatcol] = useState([{id: "month", header: "Mes", accessorKey: "month"}]);

  const update_identifiers =
    [
      { route: 'string' },
      { saletype: 'string' },
      { date_register: 'String' },
      { code_pos: 'string' },
      { name_pos: 'string' },
      { indicator_january: 'string' },
      { indicator_february: 'string' },
      { indicator_march: 'string' },
      { indicator_april: 'string' },
      { indicator_may: 'string' },
      { indicator_june: 'string' },
      { indicator_july: 'string' },
      { indicator_sugust: 'string'},
      { indicator_september: 'string' },
      { indicator_october: 'string' },
      { indicator_november: 'string' },
      { indicator_december: 'string' }
    ];


  const columns = useMemo(
    () => [
      {
        id: "route",
        header: "Ruta",
        accessorKey: "route",
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'select',
      },
      {
        id: "saletype",
        header: "Tipo",
        accessorKey: "saletype",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "date_register",
        header: "Fecha Registro",
        accessorKey: "date_register",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "code_pos",
        header: "Código Punto de Venta",
        accessorKey: "code_pos",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "name_pos",
        header: "Nombre Punto de Venta",
        accessorKey: "name_pos",
        muiTableBodyCellProps: {
          align: 'left',
        }
      },
      ... datcol
    ], 
    [datcol]);

    const handleStateChange = (newState) => {
      const filteredRowsCount = newState.filteredRowModel
      settotal_rows(filteredRowsCount);
    };
  
    function alertas(msj, status) {

      if (status === true) {
        notificationSystemRef.current.addNotification({
          message: <AlertResponse msj={msj} view={true}></AlertResponse>,
          level: 'success',
          position: 'br',
          autoDismiss: 10,
        });
      } else {
        notificationSystemRef.current.addNotification({
          message: <AlertResponse msj={msj} view={false}></AlertResponse>,
          level: 'error',
          position: 'br',
          autoDismiss: 60,
        });
      }
    }

    useEffect(() => {
      handlesubmit();
    }, [])
    

    const handlesubmit = async() =>{
      try{
        setData([]);
        setdatcol([{id: "month", header: "Mes", accessorKey: "month"}])
        setmodalT(true);
        setIsLoading(true);
        var refresh = await refresh_token();
        if(refresh){
            setTimeout(async() => {
                try{
                    const d = await get_pos_routes(date.format('MM-YYYY'));
                    console.log(d);  
                    setIsLoading(false);
                    setmodalT(false);
                    setData(d?.data);
                    indicator(d?.data);  
                    
                }catch(err){
                    console.log(err);
                    setmodalT(false);
                    setIsLoading(false);
                    alertas("La consulta no se pudo realizar; es posible que no existan registros en la fecha especificada.", false);
                }
            }, 1000);
        }
    }catch(err){
        console.log(err);
    }
    }


    const monthMapping = {
      january: "Enero",
      february: "Febrero",
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Septiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre"
    };
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    
    const asciiMapping = {
      "✗": "❌", 
      "✓": "✔️"  
    };

    const indicator = (data) =>{
      const indicatorColumns = Object.keys(data[0])
      .filter(key => key.startsWith('indicator_'))
      .map(key => {
        const monthKey = key.replace('indicator_', '').toLowerCase(); 
        const monthInSpanish = monthMapping[monthKey];

        return {
          id: key,
          header: capitalizeFirstLetter(monthInSpanish),
          accessorKey: key,
          muiTableBodyCellProps: {
            align: 'center',
          },
          size: 150,
          Cell: ({ cell }) => asciiMapping[cell.getValue()] || cell.getValue()
      
        };
    });
      console.log(indicatorColumns);
      setdatcol(indicatorColumns);
    }


    const filters_custom = (
      <div className="custom-year-search">
        <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
          <DatePicker
            views={['year', 'month']}
            sx={{ width: 150, m: 1.5}}
            label="Fecha"
            value={date}
            onChange={(newValue) => setdate(newValue)}
            locale="es"
            format="MM/YYYY"
          />
        </LocalizationProvider>  
        <FormControl required sx={{ width: "auto" }}>
          <Button variant="contained"
            sx={{ height: '35px' }}
            onClick={(e) => handlesubmit()}
          >
            CONSULTAR
          </Button>
        </FormControl> 
      </div>
    );
  
    
const [minimenu, setminimenu]=useState(false);
const [openmini, setopenmini]=useState(false);

 useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        console.log('Screen width is less than 480px');
        setminimenu(true);
      } else {
        setminimenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="new_table_v2">
        <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
        <BREADCRUMBS niveles={
        [
          { label: "PROMEDIOS DE VENTAS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <SEARCH_FILTER_WEB_MOVIL
            minimenu={minimenu} 
            setminimenu={setminimenu}
            openmini={openmini} 
            setopenmini={setopenmini}
            filterMovComponent={filters_custom}
            renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL>
          <>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowSelection
              enableRowActions
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => {
                if(minimenu===false){
                  return(
                  <>
                    {filters_custom}
                  </>
                  )
                }
              }}
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  setmenssage={setmenssage}
                  setmodalGeneral={setmodalGeneral}
                  row={row}
                  setreconsult={setreconsult}
                  refresh_token={refresh_token}
                  alertas={alertas}
                />
              ]}
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Promedios de ventas"}
                  update_identifiers={update_identifiers}
                  key={index}
                  settotal_rows={settotal_rows}
                  share_filter={true}
                  total_rows={total_rows !== "" ? total_rows : 0}

                />
              )
              }
            />

          </>

    </div>
  )
}
