//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import '../../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../../components_cfdi/modal.css";
import "./goals.css";
//IMAGENES/ICONOS
import Delete from "../../../imgComponents/borrar.png";
import CREATE from "../../../imgComponents/create.png";
import Edit from "../../../imgComponents/save.png";
import CreateGoal from "./CreateGoal";
import EditGoal from "./EditGoal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { delete_goal, get_goals } from "../../../services/reports/reports";
import TableContainer from "../../../main_components/Table/TableContainer";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";

const TableGoal_V2= (props) => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [my, setmy]=useState(props.my===null?null:props.my);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");

  
useEffect(()=>{
  handleSubmit();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);




const update_identifiers =
[
  { route: 'string' },
  { month: 'string' },
  { goal: 'String' },
];

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}

const handleSubmit = async () => {

    setmodalT(true);
    setopen(true);
    setData([]);
    
    var year = null;
    var month = null
    if(my===null){
    }else{
      const partes = my.split("-"); // Divide la cadena en dos partes: año y mes
      year = parseInt(partes[0]); // Convierte el año en un número entero
      month = parseInt(partes[1]); // Convierte el mes en un número entero
    }
    
    try {
      var rf_token = await refresh_token();
      if(rf_token ===true){
        setTimeout(async() => {
          try{
            setIsLoading(true);
            var d = await get_goals(year, month);
            props.setdatameta(d===null?[]:d.data);
            const sortedRoutes = [...d.data].sort((a, b) => parseInt(a.route) - parseInt(b.route));
            setData(sortedRoutes);
            setopen(false);
            setmodalT(false);
            setIsLoading(false);

          }catch(err){
            setData([]);
            setIsLoading(false);
            setopen(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}     
    } catch (err) {
      console.log(err);
    }
};

const columns = useMemo(
  () => [
    // {
    //   header:()=>(
    //     <div>
    //       <img
    //         id="crearMeta"
    //         src={CREATE}
    //         onClick={() => {
    //           CustomDialog(               
    //          <div>
    //             <CreateGoal handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar} setactualizacionmeta={props.setactualizacionmeta} setfiltrometas={props.setfiltrometas}></CreateGoal>
    //           </div>,
    //        {
    //          className: "modalTItle",
    //          title:"Crear nueva Meta",
    //          showCloseIcon: true,
    //          isCanClose:false,
    //        }
    //    );
    //  }}
    //         title="Crear Meta"
    //         className=""
    //         alt=""
    //       ></img>
    //     </div>
    //   ),
      
    //   id: "Acciones",
    //   Cell: ({ row }) => {
    //   const DeleteSector = async () => {  
    //   setmodalT(true);
    //   setData([]);
    //   setopen(true);
    //     try {
    //       var rf_token = await refresh_token();
    //       if(rf_token===true){
    //         setTimeout(async() => {
    //           try{
    //             const dt = await delete_goal(row.original.id);
    //             console.log(dt);
    //             alertas("Meta Eliminada", true);
    //             props.setfiltrometas(false);
    //             props.setactualizacionmeta(true);
    //             handleSubmit();
    //           }catch(err){
    //             console.log("error", err);
    //             setopen(false);
    //             alertas("Error, no se pudo eliminar la meta", false);
    //             setmodalT(false);
    //             handleSubmit();
    //           }
    //         }, 1000);
    //       }else{}
    //     } catch (err) {
    //      console.log(err);
    //     } 
    //   }
    //     return (
    //       <div>
    //         <img
    //           src={Delete}
    //           onClick={async() =>{
    //               const result = await Confirm('¿Esta usted seguro de eliminar la meta?','Eliminar Meta','Si','No');
    //               if(result){
    //                 DeleteSector()
    //                 }
    //               }            
    //             }
              
    //           title="Eliminar Meta"
    //           alt=""
    //         ></img>
    //           &nbsp;
    //         <img
    //           src={Edit}
    //           onClick={
    //             () => {
    //             CustomDialog(               
    //              <div>
    //                <EditGoal row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar} setactualizacionmeta={props.setactualizacionmeta} setfiltrometas={props.setfiltrometas}></EditGoal>
    //              </div>,
    //            {
    //              className: "modalTItle",
    //              title:"Editar Meta",
    //              showCloseIcon: true,
    //              isCanClose:false,
    //            }
    //        ); 
    //      }
    //     }
              
    //           title="Editar Meta"
    //           alt=""
    //         ></img>
    //       </div>
    //     );
    //   },
    // },
    {
      id: "route",
      header: "Ruta",
      accessorKey:"route",
      filterVariant: 'select',
      muiTableBodyCellProps: {
        align: 'center'
      }
    },
    {
      id: "month",
      header: "Fecha",
      accessorKey: "month",
      accessorFn: (d)=>{
        var dia = d.month===1?"Enero":d.month===2?"Febrero":d.month===3?"Marzo":d.month===4?"Abril":
        d.month===5?"Mayo":d.month===6?"Junio":d.month===7?"Julio":d.month===8?"Agosto":d.month===9?"Septiembre":
        d.month===10?"Octubre":d.month===11?"Noviembre":d.month===12?"Diciembre":"Sin Fecha";
        return (dia + " de " + d.year)
      }
    },
    {
      id: "goal",
      header:"Meta",
      accessorKey: "goal",
      accessorFn: (d) => {
        return d.goal
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.goal)
          : 
          Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.goal);
      },   
    }
    
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);


const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};


return (
    <>
<div className="content-table-goals">
  <div className="new_table_v2">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={""}
    modalGeneral={false}
    modalT={modalT}
  ></MODAL_TABLE>
         
          <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyheader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                     <img
                        id="crearMeta"
                        title="Agregar meta"
                        src={CREATE}
                        onClick={() => {
                          CustomDialog(               
                            <CreateGoal 
                              handleSubmit={handleSubmit} 
                              alertas={alertas} 
                              reconsultar={reconsultar} 
                              setactualizacionmeta={props.setactualizacionmeta} 
                              setfiltrometas={props.setfiltrometas}
                          ></CreateGoal>,
                      {
                        className: "custom-modal",
                        title:"Crear nueva Meta",
                        showCloseIcon: true,
                        isCanClose:false,
                      }
                  );
                }}
              ></img>
                    </div>
                  ),
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-actions': {
                  enableHiding: true
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => (
                <div className="input-goal">
                <div className="grid-imp-1">
                  <label>Fecha</label>
                  < input 
                      type="month" 
                      value={my} 
                      onChange={(e)=>setmy(e.target.value)} 
                  />
                </div>
                <div className="grid-imp-2">
                  <button className="button" onClick={handleSubmit}>Buscar</button>  
                </div>   
              </div>               
              )}
              renderRowActionMenuItems={({ row, closeMenu }) => [
                  <RENDER_ROW_ACTION_MENU_ITEMS
                    row={row} 
                    handleSubmit={handleSubmit} 
                    alertas={alertas} 
                    reconsultar={reconsultar} 
                    setactualizacionmeta={props.setactualizacionmeta} 
                    setfiltrometas={props.setfiltrometas}
                    closeMenu={closeMenu}
                  />
                ]}
              renderToolbarInternalActions={({ table, index }) => (
                  <RENDER_TOOLBAR_INTERNAL_ACTIONS
                    table={table}
                    file_name={"Metas"}
                    update_identifiers={update_identifiers}
                    key={index}
                    settotal_rows={settotal_rows}
                    total_rows={total_rows !== "" ? total_rows : 0}
                  />
                )
               }
            />
            
          </div>
        </div>
    </>
  );
}

export default TableGoal_V2;
