// eslint-disable-next-line import/no-anonymous-default-export
export default {
    baseURLBP : "https://m0fsi6ifd9.execute-api.us-east-1.amazonaws.com",
    baseURLIVC : "https://inxmul26u8.execute-api.us-east-1.amazonaws.com",
    baseURLCONF: "https://07qo45ae85.execute-api.us-east-1.amazonaws.com",
    baseURLREPORT: "https://zaqp4ihdn9.execute-api.us-east-1.amazonaws.com",
    baseURLHR: "https://yqqevspzyi.execute-api.us-east-1.amazonaws.com",
    baseURLClC : "https://b7z3hke4a8.execute-api.us-east-1.amazonaws.com",
    baseURLAss: "https://ua5rm92fs2.execute-api.us-east-1.amazonaws.com",
    baseURLINT: "https://m5c1a5ucj2.execute-api.us-east-1.amazonaws.com",
    baseURLPVD:"https://h93zxd4wrb.execute-api.us-east-1.amazonaws.com",
    baseURLDB:"https://zaqp4ihdn9.execute-api.us-east-1.amazonaws.com",
    baseURLFINANCE:"https://kvc88jn6xd.execute-api.us-east-1.amazonaws.com",
    baseURLBon: "https://xlkjajemk3.execute-api.us-east-1.amazonaws.com",
    baseURLSUPPORT:"https://os9zgx7w8d.execute-api.us-east-1.amazonaws.com",
    baseURLSAT:"https://sfcwwjm12j.execute-api.us-east-1.amazonaws.com",
    baseURLUNILEVER:"https://n1rj4tkb3d.execute-api.us-east-1.amazonaws.com",    
    baseURLFILTERS:"https://2cjszakod9.execute-api.us-east-1.amazonaws.com",    

}
