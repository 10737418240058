//PAQUETERIAS
import { useEffect, useState, useMemo } from "react";
import { useDialog } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { dashboard1, no_sales_clasificacion } from "../../services/reports/dashboard";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_SALES from "./tools/render_row_action_sales";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";
import "./view_detail.css";
//IMAGENES
import Spinner from "../../imgComponents/S.gif";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%", 
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SubTable2 ({ row, datei, datef, route, nosale }) {

  const[cust, setcust]=useState(row?.original?.customer);

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const dialog = useDialog();

  
  const update_identifiers =
  [
    { route: 'string' },
    { code: 'string' },
    { name: 'string' },
    { address: 'string' },
    { freezers: 'string' },
    { frequency: 'string' },
    { visit_days: 'string' },
    { date: 'string' }
  ];



  const handleSubmit = async(event) => {
    event?.preventDefault();
    setReconsulta(true);
    
    try{
      var rf_token= await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            
            var d = []; 

            if(nosale===true){
              d = await no_sales_clasificacion(cust?.replaceAll(' ', '_')?.replaceAll("/","-"), datei, datef, route);
            }else{
              d = await dashboard1(cust.replaceAll(' ', '_')?.replaceAll("/","-"), datei, datef, route);
            }
                  
            const data = d === null ? [] : d.data;
            console.log(data);
            const data2= data.slice().sort((a, b) => (Number(a.route)) > (Number(b.route)));
            console.log(data2);
            setData(data2);
            const cargos = data.filter((item) => item.efect === "CARGO");
            const abonos = data.filter((item) => item.efect === "ABONO");
            const sumCargos = cargos
              .map((cargoSum) => parseFloat(cargoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
    
            const sumAbonos = abonos
              .map((abonoSum) => parseFloat(abonoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            console.log("==>cargos<==");
            console.log(sumCargos);
            console.log("==>abonos<==");
            console.log(sumAbonos);
            if (sumCargos - sumAbonos === 0) {
              setEquality("Los datos cuadran");
              console.log("Los datos cuadran");
              setSuccess_msg("");
              setError_msg("none");
            } else {
              setEquality("Los datos no cudran");
              console.log("Los datos no cudran");
              setError_msg("");
              setSuccess_msg("none");
            }
            setReconsulta(false);
          }catch(err){
            setData([]);
            setReconsulta(false);
            console.log(err);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   header: "Acciones",
      //   id: "Acciones",

      //   Cell: ({ valor, row }) => {

      //     return (
      //       <div>

      //         <img
      //           title="Ver detalle venta"
      //           src={VER}
      //           className="centerText"
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <div>
      //               <br></br>
      //               <ViewDetail
      //                 id_detail={row.original.id}
      //                 datei={datei}
      //                 datef={datef}
      //                 route={route} 
      //                 pointSale={row.original.name}
      //                 address={row.original.address}
      //               ></ViewDetail>
      //               </div>,
      //               {
      //                 title: " ",
      //                 className: "saleDetailTikect",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //           }}
      //         ></img>
      //          <img
      //           title="Ver detalles del congelador"
      //           src={FREZ}
      //           className="centerText"
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <div>
      //               <br></br>
      //               <ViewDetail_frezer
      //                 id_detail={row.original.id}
      //                 datei={datei}
      //                 datef={datef}
      //                 route={route} 
      //                 pointSale={row.original.name}
      //                 address={row.original.address}
      //               ></ViewDetail_frezer>
      //               </div>,
      //               {
      //                 title: " ",
      //                 className: "saleDetailTikect",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //           }}
      //         ></img>
      //       </div>
      //     );
      //   },
      // },

      
      {
        id: "route",
        header: "Ruta",
        accessorKey: "route",
      },
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
      },
      {
        id: "name",
        header: "Punto de venta",
        accessorKey: "name",
      },
      { 
        id: "address",
        header: "Domicilio",
        accessorKey: "address",
      },
      {
        id: "freezers",
        header: "Congeladores",
        accessorKey: "freezers",
      },

      {
        id: "frequency",
        header: "Frecuencia",
        accessorKey: "frequency",
      },

      {
        id: "visit_days",
        header: "Día de visita",
        accessorKey: "visit_days",
      },

      {
        id: "date",
        header: "Fecha",
        accessorKey: "date",
      },
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div className="new_table_v2" style={{"width":"95%", "margin": "0 auto"}} >
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  
                  variant="h6"
                  component="h2"
                >
                  <img src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

    <div className="">
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={
          { 
            showGlobalFilter: true, 
            columnVisibility:{
              cost: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
              profit: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
            }
          }
        }
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_SALES
            closeMenu={closeMenu}
            row={row}
            handleSubmit={handleSubmit}
            setreconsult={setreconsult}
            refresh_token={refresh_token}
            customer={cust}
            datei={datei}
            datef={datef}
            route={route}
            nosale={nosale}
          />
        ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              file_name={"Detalles de Ventas"}
              update_identifiers={update_identifiers}
           />
          )
         }
      />
         
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default SubTable2;
