//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES

import SubTable from "./Get_datail_Sales";
import Rutas from "../../dataDahsboard/routes.json";
import { refresh_token } from "../../main_components/tokens/tokenrefresh.jsx";

//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../dashboar.css";
//IMAGENES/ICONOS

import Spinner from "../../imgComponents/S.gif";
import { get_dashboard1 } from "../../services/reports/dashboard";
import TableContainer from "../../main_components/Table/TableContainer";


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewDashboardDetailSales = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();

  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${año}-${mesantes}-${dia}`;

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [origen, setOrigen] = useState("");

  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");
  const [selectRutas, setselectRutas] = useState("all");


  const handleSubmit = async(event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando...");
    setData([]);
    
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_dashboard1(fi, ff, selectRutas);
            const data = d === null ? [] : d.data;
            const data2 = (data.filter(dash=>dash.customer!=="Grand Total"));
            console.log(data2);
            setData(data2);
            setOpen(false);
            setMenssage("");
          }catch(err){
            console.log("error", err);
            setData([]);
            setOpen(false);
            setMenssage("");
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }


/*
    setTimeout(() => {
    axios
      .get(
        `https://zaqp4ihdn9.execute-api.us-east-1.amazonaws.com/prod/dashboard1`,
        {
          params: {
            datei: fi,
            datef: ff,
            route: selectRutas,
          },
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        const data2 = (data.filter(dash=>dash.customer!=="Grand Total"));

        console.log(data2);


        setData(data2);

        setOpen(false);
        setMenssage("");
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setOpen(false);

        setMenssage("");
      });
    }, 2000);
*/
  };

  const handleRutas = (event) => {
    setselectRutas(event.target.value);
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };


  const columns = useMemo(
    () => [
       {
         id: "expander",
         Header: "Acciones",
         Cell: ({ row }) => {
           function guardardato() {
             sessionStorage.setItem("clasificacion", row.original.customer);
           }

           function Denegado() {
             alert("No tienes el rol necesario para ver los detalles del gasto");
           }
          
             return (
               <a
                 title="Ver detalles"
                 {...row.getToggleRowExpandedProps()}
               >
                 {row?.isExpanded ? (
                   <span title="Ver detalles">🔼</span>
                 ) : (
                   <span
                     title="Ver detalles"
                     onClick={guardardato}
                   >
                     🔽
                   </span>
                 )}
               </a>
             );
         },
       },

      { 
        Header: "Clasificación",
        accessor: "customer",
      },
      {
        Header: "Ventas",
        accessor: (d) => {
          return d.sales
              ? Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(d.sales)
              : Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(d.sales); 
          },
      },
      {
        Header: "Participación",
        // accessor: (d) => {
        //   return (d.participation.toFixed(2))  
        // },
        accessor: (d) => {
          return d.participation+"%"
        },
      },
      {
        Header: "Puntos de venta",
        accessor: (d) => {
          return (d.customers.toFixed(2))  
        },
      },
      {
        Header: "Con venta",
        accessor: "withsale",
      },
      {
        Header: "Sin venta",
        accessor: "withoutsale",
      },
      {
        Header: "Congeladores",
        accessor: "freezers",
      },
      {
        id:"Costo",
        show:sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT")==="ROLE_REPORT_SALES_MANAGEMENT"?true:false,
        Header: "Costo",
        accessor: (d) => {
          return d.cost
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.cost)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.cost); 
        },
      },
      {
        id:"Ganancia",
        show:sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT")==="ROLE_REPORT_SALES_MANAGEMENT"?true:false,
        Header: "Ganancia",
        accessor: (d) => {
          return d.profit
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.profit)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.profit); 
        },
      },
    ],
    []
  );

  return (
    <div >
      <div>
      <Modal
          open={open}
          className="nModalNew"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga nModalNew">
              <Typography
                id="modal-modal-title"
                
                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"
                
                sx={{ mt: 2 }}
              ></Typography>
            </div>
          </Box>
        </Modal>
      </div>

      
      <div className="containerExpenseReport2">
        <div className="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div className="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="expen-3 color-5 topSelect">
          <TextField
            select
            label="Rutas"
            value={selectRutas}
            sx={{ width: "100%" }}
            onChange={handleRutas}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            {Rutas.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </TextField>
        </div>

        <div className="expen-4 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>
      <div className="TableReport tableDashboard TableSalesDashboard">
        <div style={{ overflowX: "auto" }}>
          <TableContainer
            exportar={true}
            columns={columns}
            data={data}
            Gtotal={!open}
            Grantotal={true}
            salesD={true}
            campSUbtable={true}
            Gmodal={open}
            nametable={"Detalles de Venta"}
            GrantotalCabeceras={["Acciones", "Clasificación"]}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable 
                  verData={true}
                  datei={fi}
                  datef={ff}
                  route={selectRutas} 
                  />
                </div>
              );
            }}
           
          />
        </div>
      </div>
    </div>
  );
};

export default NewDashboardDetailSales;
