//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import dayjs from 'dayjs';

//COMPONENTES
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { ValidationTextField } from "../../../main_components/lable/labe";
import { Box } from '@mui/material';


//ESTILOS

//IMAGENES/ICONOS

import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import { get_detil_warehouse } from "../../../services/inventory/inventory";
import { calculate_total } from "../../../main_components/methods_v2/auxiliary_functions";
import { update_null_identifiers } from "../../../main_components/methods_v2/auxiliar_funtion";
const CONSULT_DETAIL_WAREHOUSE_v2 = (props) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});


  const [g_total, setg_total] = useState(0);
  const handle_detail_warehouse = async () => {
    try {
      const isvaild = await refresh_token();
      setIsLoading(true)
      if (isvaild) {
        setTimeout(async () => {
          try {
            const dt = await get_detil_warehouse(props.id);
            var ndt = update_null_identifiers(dt?.data, update_identifiers);
            setData(ndt);
          } catch (err) {
            setData([]);
          } finally {
            setIsLoading(false)
          }
        }, 1500)
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false)

    }
  };
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  useEffect(() => {
    handle_detail_warehouse();
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Partida",
        accessorKey: "row",
        id: "row",
      },
      {
        header: "Código",
        accessorKey: "code",
        id: "code",
      },
      {
        header: "Producto",
        accessorKey: "description",
        id: "description",
      },
      {
        header: "Cantidad",
        accessorKey: "quantity",
        id: "quantity",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.quantity) ? 0 : obj.quantity)
          const totalquantity = data.map(obj => isNaN(Number(obj.quantity)) ? 0 : Number(obj.quantity))
          console.log(totalquantity)

          return (
            <div style={{ textAlign: "center", margin: "0 auto" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(totalquantity)}</div>
            </div>
          )
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        header: "Tipo",
        accessorKey: "type",
        id: "type",
        accessorFn: (d) => {
          return props.reference;
        },
      },
    ],
    [data]
  );

  const update_identifiers =
    [
      { row: 'string' },
      { code: 'String' },
      { type: 'string' },
      { description: 'string' },
      { quantity: 'number' }
    ];

  return (
    <div >

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '98%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': {
            width: '89.4%'
          },

        }}
      >
        <ValidationTextField
          label="Fecha"
          variant="outlined"
          sx={{ m: 2, width: "100%" }}
          value={props.date.slice(0, -7)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ValidationTextField
          label="Tipo de movimiento"
          variant="outlined"
          sx={{ m: 2, width: "100%" }}
          value={props.movement_type}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ValidationTextField
          label="Almacen"
          variant="outlined"
          sx={{ m: 2, width: "100%" }}
          value={props.warehouse_name}
          InputLabelProps={{
            shrink: true,
          }}
        />

      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '98%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': {
            width: '89.4%'
          },

        }}
      >
        <ValidationTextField
          label="Partidas"
          variant="outlined"
          sx={{ m: 2, width: "100%" }}
          value={props.rows}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ValidationTextField
          label="Venta de devolución"
          variant="outlined"
          sx={{ m: 2, width: "100%" }}

          value={props.returns}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ValidationTextField
          label="Venta de referencia"
          variant="outlined"
          sx={{ m: 2, width: "100%" }}
          value={props.sale}
          InputLabelProps={{
            shrink: true,
          }}
        />


      </Box>


      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '98%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': {
            width: '89.4%'
          },

        }}
      >

        <ValidationTextField
          label="Nota"
          variant="outlined"
          sx={{ m: 2, width: "100%" }}
          value={props.note}
          InputLabelProps={{
            shrink: true,
          }}
        />

      </Box>

      <div className="new_table_v2">
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          onStateChange={handleStateChange}
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }


          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Movimiento de Productos"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>
    </div>
  );
};

export default CONSULT_DETAIL_WAREHOUSE_v2;
