import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_expense_income_rules_users, post_expense_income_rules, put_expense_income_rules } from "../../../services/expenses/expenses";

function OPERATIONS_RULES_SE(props) {

useEffect(()=>{
  handleSubmit();
},[]);

const dialog = useDialog();


const [datauser, setdatauser]=useState([]);
const [user, setuser]=useState(props.operation==="EDIT"?props.row.original.user_name:null);
const [iduser, setiduser]=useState(null);
const [bloq, setbloq]=useState(props.operation==="EDIT"?props.row.original.blocked:false);
const [enable, setenable]=useState(props.operation==="EDIT"?props.row.original.enable:true);
const [daysBefore, setdaysBefore]=useState(props.operation==="EDIT"?props.row.original.lower_limit_days:0);
const [weeksBefore, setweeksBefore]=useState(props.operation==="EDIT"?props.row.original.lower_limit_week:0);
const [monthsBefore, setmonthsBefore]=useState(props.operation==="EDIT"?props.row.original.lower_limit_month:0);
const [limInf, setlimInf]=useState(props.operation==="EDIT"?props.row.original.lower_limit_ammount:0.0);
const [limSup, setlimSup]=useState(props.operation==="EDIT"?props.row.original.upper_limit_ammount:0.0);
const [vuser, setvuser]=useState(false);

const [bandera, setbandera]=useState(true);  
const [textdays, settextdays]=useState(false);
const [textweek, settextweek]=useState(false);
const [textmonth, settextmonth]=useState(false);


const handleSubmit = async () => {  
  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const d = await get_expense_income_rules_users();
          setdatauser(d === null ? [] : d.data.users);
        }catch(err){
          setdatauser([]);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
};
const createnewrule = async () => {

  if(user===null || user===undefined || iduser===null){
    props.alertas("Parámetros faltantes", false);
    user===null || user===undefined? setvuser(true):setvuser(false);
    return null;
  }
  
  var data = {
      "enable": enable, //boolean = Habilitado | Deshabilitado
      "id_user": iduser,//int = ID del usuario que tendrá la regla de negocio
      "user_name": user,//string = Nombre del usuario que tendrá la regla de negocio
      "lower_limit_days": daysBefore,//Días antes de la fecha
      "lower_limit_week": weeksBefore,  //Semanas antes de la fecha
      "lower_limit_month": monthsBefore, //Meses antes de la fecha
      "blocked": bloq, //boolean = Bloqueado SI | NO
      "upper_limit_ammount": limSup, //float = Monto superior del gasto
      "lower_limit_ammount": limInf//float = Monto inferior del gasto
  }
  
  props.setmodalT(true);
  setbandera(false);
  
    try{
      var rf_token = await refresh_token();
      if(rf_token === true){
        setTimeout(async() => {
          try{
            const d = await post_expense_income_rules(data);
            console.log(d);
            props.alertas("Regla de gastos registrada con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          }catch(err){
            console.log("error post", err);
            catchErrorModul("Regla de envio de Gastos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            if(err.response.status===500){
              props.alertas("El usuario ya tiene una regla de gastos asignada", false);
            }else{
              props.alertas("No se pudo registrar la regla de gastos", false);
            }
            props.setmodalT(false);
            setbandera(true);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
   }
} 
const updaterule =async () =>{

props.setmodalT(true);
setbandera(false);
  var data = {
    "enable": enable, //boolean = Habilitado | Deshabilitado
    "user_name": user,//string = Nombre del usuario que tendrá la regla de negocio
    "lower_limit_days": daysBefore,//Días antes de la fecha
    "lower_limit_week": weeksBefore,  //Semanas antes de la fecha
    "lower_limit_month": monthsBefore, //Meses antes de la fecha
    "blocked": bloq, //boolean = Bloqueado SI | NO
    "upper_limit_ammount": limSup, //float = Monto superior del gasto
    "lower_limit_ammount": limInf//float = Monto inferior del gasto
}

  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const d = await put_expense_income_rules(props.row.original.id, data);
          console.log(d);
          props.alertas("Regla de gastos actualizada con éxito", true);
          props.setmodalT(false);
          dialog.close();
          props.handleSubmit();
        }catch(err){
          console.log("error put", err);
          catchErrorModul("Regla de envio de Gastos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          props.alertas("No se pudo registrar la regla de gastos", false);
          props.setmodalT(false);
          setbandera(true);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
} 
function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

return (
    <div style={{"textAlign": "center"}}>
      <div className="addrulesSE">
          <div className="gridrule1">
            <div className="rule1">
              {
                props.operation==="EDIT"?
                <TextField
                  label="Usuario"
                  value={user}
                  sx={{ m: 1.5, width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                  disabled
                ></TextField>:
                <TextField
                  select
                  label="Usuario"
                  required
                  InputLabelProps={{shrink: true,}}
                  SelectProps={{native: true,}}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) =>{
                    setiduser(parseInt(event.target.value));
                    setuser(datauser.find(item=>item.id==event.target.value)?.username)
                    }
                  } 
                  style={
                    vuser===true?{"border":"2px solid red", "border-radius":"5px"}:{}
                  }
                  onSelect={()=>setvuser(false)}
                  onBlur={
                    iduser==null?()=>setvuser(true):()=>setvuser(false)
                  }  
                >
                  <option key={0} value={null} selected >
                      {"Selecciona Usuario"}
                  </option>
                {
                datauser.map((item)=>(
                  <option key={item.id} value={item.id}>
                      {item.fullusername}
                  </option>
                  ))
                }
                </TextField>
                }
            </div>
            <div className="rule2">
            <TextField
              select
              label="Habilitado"
              value={enable}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setenable(event.target.value==="true"?true:false)}  
            >
              <option key={1} value={true} selected >
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>
            </div>
          </div>
          <div className="gridrule2">
          <div className="rule3">
            <TextField
              select
              label="Bloqueado"
              value={bloq}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setbloq(event.target.value==="true"?true:false)}  
            >
              <option key={1} value={false} selected >
                  {"NO"}
              </option>
              <option key={2} value={true}>
                  {"SI"}
              </option>
            </TextField>
            </div>
            
            <div className="rule4">
            <TextField
              label="Monto Inferior del Gasto"
              value={limInf}
              type={"number"}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setlimInf(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="rule5">
            <TextField
              label="Monto Superior del Gasto"
              type={"number"}
              value={limSup}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setlimSup(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            
          </div>
          <div className="gridrule3">
            
          <div className="rule6">
            <TextField
              label="Días Antes de la Fecha"
              type={"number"}
              value={daysBefore}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) =>{
                const value = event.target.value;
                if (value >= 0 && value <= 15) {
                  setdaysBefore(value);
                }
              }}   
              onSelect={()=> settextdays(true)}
              onBlur={()=>settextdays(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <p className="gendoc" style={textdays===true?{"display":"block"}:{"display":"none"}}>Número de días antes que tiene de generación del documento (0-15)</p>
            </div>
            <div className="rule7">
            <TextField
              label="Semanas Antes de la Fecha"
              type={"number"}
              value={weeksBefore}
              sx={{ m: 1.5, width: "95%" }}
              onChange={(event) =>{
                const value = event.target.value;
                if(value >=0 && value <=8){
                  setweeksBefore(event.target.value)
                }
              }} 
              onSelect={()=> settextweek(true)}
              onBlur={()=>settextweek(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <p className="gendoc" style={textweek===true?{"display":"block"}:{"display":"none"}}>Número de semanas antes que tiene de generación del documento (0-8)</p>
            </div>
            <div className="rule8">
            <TextField
              label="Meses Antes de la Fecha"
              type={"number"}
              value={monthsBefore}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) =>{
                const value = event.target.value;
                if(value >=0 && value <=13){
                  setmonthsBefore(event.target.value);
                }
              }}  
              onSelect={()=> settextmonth(true)}
              onBlur={()=>settextmonth(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
             <p className="gendoc" style={textmonth===true?{"display":"block"}:{"display":"none"}}>Número de meses antes que tiene de generación del documento (0-13)</p>
            </div>
            
          </div>
         <br></br>
          <br></br>
          <Box>
            {
              bandera===true?              
            <Button
            variant="contained"
            style={{"backgroundColor":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={()=>{props.operation==="EDIT"?updaterule():createnewrule()}}
            >
              {props.operation==="EDIT"?"Actualizar":"Registrar"} 
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"backgroundColor":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default OPERATIONS_RULES_SE;
