import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../imgComponents/save.png";
import Delete from "../../imgComponents/borrar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import Gsheets from "../../imgComponents/gsheets.png"
import Spinner from "../../imgComponents/S.gif";
import ADD from "../../imgComponents/agregar.png"
import EDIT from "../../imgComponents/save.png";
import DMS from "../../imgComponents/reportSales.png"
import Package from "../../imgComponents/package.png"
import { post_bonification_packages_report } from "../../services/bonifications/bonifications";


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const CrearPaquete = async (id) => {
        
        props?.setmodalT(true);
        var dato = {
          bonificacion: 'bonificaciones',
        }
        try {
          var rf_token = await refresh_token();
          if (rf_token === true) {
            setTimeout(async () => {
              try {
                const d = await post_bonification_packages_report(id, dato);
                if (d?.status === 202) {
                 props?.setmodalT(false);
                 alert("El paquete de bonificación será procesado.\nSe le notificará a su correo cuando pueda consultarlo.\n\nConsidere que para un paquete de 200 ventas el tiempo de espera es de 1 minuto aproximadamente.");
                }
              } catch (err) {
                props?.setmodalT(false);
                console.log("AXIOS ERROR: ", err);
              }
            }, 1000);
          } else { }
        } catch (err) {
          console.log(err);
        }
      }

      const addsales = () =>{
        sessionStorage.setItem("idpack", props?.row.original.id);
        sessionStorage.setItem("name_pack", props?.row.original.package_name);
        sessionStorage.setItem("num_pack", props?.row.original.package_number);
        window.location = "/add_sales_bonificaciones_v2";
      }

      const editsales = () =>{
        sessionStorage.setItem("id_pack_edit", props?.row.original.id);
        sessionStorage.setItem("name_pack", props?.row.original.package_name);
        sessionStorage.setItem("num_pack", props?.row.original.package_number);
        window.location = "/edit_add_bonuses_v2";
      }

    return (
        <div>
            <MenuItem
                key={1}
                onClick={() =>{
                    props.closeMenu(false);
                    CrearPaquete(props?.row.original.id)
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Package}
                        title="Crear Reporte"
                        className=""
                    ></img>
                </ListItemIcon>
                Crear Reporte
            </MenuItem>
            <MenuItem
                key={2}
                onClick={() =>{
                    props?.closeMenu(false);
                    console.log(props?.row.original.flename);
                    if (props?.row.original.flename === null) {
                        alert("Debe crear primero el paquete para poder consultarlo")
                      } else {
                        props?.handleClientLoad(props?.row.original.id);
                      }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Gsheets}
                        title="Consultar Reporte"
                        className=""
                    ></img>
                </ListItemIcon>
                Consultar Reporte
            </MenuItem>
            <MenuItem
                key={3}
                onClick={() =>{
                    props?.closeMenu(false);
                    addsales();
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={ADD}
                        title="Agregar Ventas"
                        className=""
                    ></img>
                </ListItemIcon>
                Agregar Ventas
            </MenuItem>
            <MenuItem
                key={3}
                onClick={() =>{
                    props?.closeMenu(false);
                    editsales();
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={EDIT}
                        title="Editar Ventas"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Ventas
            </MenuItem>
            {
                props?.dmsrole.length===1?
                <MenuItem
                key={3}
                onClick={async() =>{
                    props?.closeMenu(false);
                    const result = await Confirm(
                        "¿Esta usted seguro de Generar DMS?",
                        "Generar DMS",
                        "Si",
                        "No"
                      );
                      if (result) {
                        props?.handle_bonus(props?.row?.original?.id);
                      }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        style={{"width":"20px"}}
                        src={DMS}
                        title="Generar DMS"
                        className=""
                    ></img>
                </ListItemIcon>
                Generar DMS
            </MenuItem>:<></>
            }
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
