import React, { useEffect, useRef } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import iconopdf from "../../imgComponents/pdf dowload.png";
import iconogs from "../../imgComponents/export-gs.png";
import iconfiledow from "../../imgComponents/archivo-des.png";
import { INPUTS_CHAT } from './inputs';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { get_online_users } from '../../services/chat/chat';
import { refresh_token } from '../../main_components/tokens/tokenrefresh';

export const CONVERSATION_NOW = (props) => {
    
const commentsEndRef = useRef(null);
const scrollToBottom = () => {
    setTimeout(() => {
        commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
}

useEffect(() => {
    scrollToBottom();
}, [props.conversation_data]);

const copiarcontenido = (text) =>{
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, textarea.value.length);
  try {
    document.execCommand('copy');
    console.log('Contenido copiado exitosamente.');
  } catch (err) {
    console.error('No se pudo copiar el contenido.', err);
  }

  document.body.removeChild(textarea);
}

const getonline = async() =>{
  try{
    var rf_token = await refresh_token();
    if(rf_token){
      setTimeout(async() => {
        try{
          const d = await get_online_users();
          console.log(d?.data?.online);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
}

useEffect(() => {
  getonline();
}, [])

  return (
    <>
    <div className='chat-history-user'>
      <div className='my-conversation'>
        <div className='back'>
            <ArrowBackIosIcon onClick={()=>{props?.setclose(true)}}/>
        </div>
        <div className='chat-actual-mini'>
            <p>{props?.usuario_destino}</p>
        </div>
        <div className='close-chat-active'>
          <HighlightOffIcon onClick={()=>{props?.setopen(false)}}></HighlightOffIcon>
        </div>
      </div>
      <div className='conversation-mini'>
            {
              props.conversation_data.map((item, itemIndex)=>(
                <div className="conv-chats" key={itemIndex}>
                  <p className='fecha-conversation'>{item.fecha}</p>
                  {
                    item?.conversation?.map((conv, index)=>{
                    
                    var filenameurl = "";
                    var tipofile = "";
                    var link  = false;
                    var mensajeConSaltosDeLinea = "";
                    var query = false;
                    
                    if(conv?.tipo==="text"){
                        mensajeConSaltosDeLinea = conv?.mensaje.replace(/\n/g, '<br>');
                        const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([^\s]+\.[^\s]+)/;
                        link = urlRegex.test(conv?.mensaje);

                      if (mensajeConSaltosDeLinea.startsWith("query_filters")) {
                          query = true;
                      } 

                    }else if(conv?.tipo==="archivo"){
                      const urlParts = conv?.mensaje.split('/');
                      filenameurl = urlParts[urlParts.length - 1];
                      const ext = filenameurl?.split(".");
                      tipofile = ext[1];
                    }
                    return(  
                      conv.origen === props?.od?(
                      <div className='messages-container' ref={commentsEndRef}>
                        <div key={index} className='mss-emisor'>
                          {  
                            conv.tipo==="text"?
                              link===true?
                                <a className="mensaje-link" 
                                  href={conv?.mensaje}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  >{conv?.mensaje}</a>:
                              query===true?
                                  <div className='copy-query'>
                                    <div className=''>
                                      <ContentCopyIcon onClick={()=>copiarcontenido(mensajeConSaltosDeLinea)}/>
                                    </div>
                                    <br></br>
                                    <p className="text-n" dangerouslySetInnerHTML={{__html: mensajeConSaltosDeLinea}}></p>
                                  </div>
                              :<p className="text-n" dangerouslySetInnerHTML={{ __html: mensajeConSaltosDeLinea }}></p>:
                      
                            conv?.tipo==="image"?
                              (<img src={conv.mensaje}></img>):
                            
                            conv?.tipo ==="audio"?
                              <audio src={conv?.mensaje} controls className='reproductor-audio-smit-chat'/>:
                            
                            conv?.tipo==="archivo"?
                              <div className='mensaje-files'>
                                {
                                  tipofile==="pdf"?
                                    <>
                                      <img src={iconopdf}></img>
                                      <a href={conv.mensaje} download>
                                          {filenameurl}
                                      </a>
                                    </>:
                                  tipofile==="xlsx" || tipofile==="csv"?
                                    <>
                                      <img src={iconogs}></img>
                                      <a href={conv.mensaje} download>
                                        {filenameurl}
                                      </a>
                                    </>:
                                    <>
                                      <img src={iconfiledow}></img>
                                      <a href={conv.mensaje} download>
                                        {filenameurl}
                                      </a>
                                    </>
                                }
                              </div>
                              :
                            <></>
                          }
                          <p className='fecha'>{conv.hora}</p>
                        </div>
                      </div>):
                      (<div className='messages-container' ref={commentsEndRef}>
                        <div key={index} className='mss-receptor'>
                        {  
                            conv.tipo==="text"?
                              link===true?
                                <a className="mensaje-link" 
                                  href={conv?.mensaje}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  >{conv?.mensaje}</a>:
                              query===true?
                                <div className='copy-query'>
                                    <div className=''>
                                      <ContentCopyIcon onClick={()=>copiarcontenido(mensajeConSaltosDeLinea)}/>
                                    </div>
                                    <br></br>
                                    <p className="text-n" dangerouslySetInnerHTML={{__html: mensajeConSaltosDeLinea}}></p>
                                </div>:
                                <p className="text-n" dangerouslySetInnerHTML={{ __html: mensajeConSaltosDeLinea }}></p>:
    
                            conv?.tipo==="image"?
                              (<img src={conv.mensaje}></img>):
                            conv?.tipo ==="audio"?
                              <audio src={conv?.mensaje} controls className='reproductor-audio-smit-chat'/>:
                            conv?.tipo==="archivo"?
                              <div className='mensaje-files'>
                                {
                                  tipofile==="pdf"?
                                    <>
                                      <img src={iconopdf}></img>
                                      <a href={conv.mensaje} download>
                                          {filenameurl}
                                      </a>
                                    </>:
                                  tipofile==="xlsx" || tipofile==="csv"?
                                    <>
                                      <img src={iconogs}></img>
                                      <a href={conv.mensaje} download>
                                        {filenameurl}
                                      </a>
                                    </>:
                                    <>
                                      <img src={iconfiledow}></img>
                                      <a href={conv.mensaje} download>
                                        {filenameurl}
                                      </a>
                                    </>
                                }
                              </div>
                              :
                            <></>
                          }
                          <p className='fecha'>{conv.hora}</p>
                        </div>
                      </div>)
                    )})
                  }  
                </div>
              ))
            }
      </div>
      <div className='input-chat-mini'>
        <INPUTS_CHAT data={props}></INPUTS_CHAT>
      </div>
    </div>
    </>
    
  )
}
