//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "../../SubTableContainer";
import CREATE_DETAIL_POLICY from "./create_detail_policy";
import UPDATE_DETAIL_POLICY from "./update_detail_policy";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import {
  get_policy_detail,
  delete_policy_detail,
} from "../../../services/expenses/expenses";
//ESTILOS
// import "../../../componentsCFDI/estilo.css";
// import "../../../componentsCFDI/modal.css";
// import "../../../componentsCFDI/table.css";
import "../../expense.css";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//IMAGENES
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import SAVE from "../../../imgComponents/save.png";
import ADD from "../../../imgComponents/add.png";
import reconsultaTable from "../../../imgComponents/reconsultaTable.png";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function DETAILS_POLICY_V2(props) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [ReconsultaDta, setReconsultaDta] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);


  const dialog = useDialog();
  console.log(props.row.original);
  const handleSubmit = async (event) => {
    event?.preventDefault();
   
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setReconsulta(true);

        try {
          var dta = await get_policy_detail(props.row.original.id);
          const data = dta.data;
          setData(data);
          const cargos = data.filter((item) => item.effect === "CARGO");
          const abonos = data.filter((item) => item.effect === "ABONO");
          const sumCargos = cargos
            .map((cargoSum) => parseFloat(cargoSum.ammount))
            .reduce((previous, current) => {
              return previous + current;
            }, 0);
          const sumAbonos = abonos
            .map((abonoSum) => parseFloat(abonoSum.ammount))
            .reduce((previous, current) => {
              return previous + current;
            }, 0);
          if (sumCargos?.toFixed(2) - sumAbonos?.toFixed(2) === 0) {
            setEquality("Los datos cuadran");
            console.log("Los datos cuadran");
            setSuccess_msg("");
            setError_msg("none");
          } else {
            setEquality("Los datos no cuadran");
            console.log("Los datos no cuadran");
            setError_msg("");
            setSuccess_msg("none");
          }
          setReconsulta(false);
        } catch (err) {
          setData([]);
          setReconsulta(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  const handle_delete_policy_detail = async (id) => {
  
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        await delete_policy_detail(props.row.original.id, id);
        try {
          setOpen(false);
          handleSubmit();
        } catch (err) {
          var error = err?.response?.data?.errorMessage;
          alert("Error.," + error);
          setOpen(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  
  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   header: () => {
      //     return (
      //       <div>
      //         <img
      //           title="Agregar detalle"
      //           src={ADD}
      //           alt=""
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <CREATE_DETAIL_POLICY 
      //               id_policy={props.row.original.id}
      //               ></CREATE_DETAIL_POLICY>,
      //               {
      //                 className: "modalTItle",
      //                 title: "Selecciona la configuracion del detalle",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             {
      //               handleReconsulta(result);
      //             }
      //           }}
      //           className="centerText, taxes"
      //         ></img>
      //       </div>
      //     );
      //   },
      //   id: "Acciones",

      //   Cell: ({ valor, row }) => {
      //     return (
      //       <div>
      //         <img
      //           title="Editar Detalle"
      //           src={SAVE}
      //           alt=""
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <UPDATE_DETAIL_POLICY
      //                 id_policy={props.row.original.id}
      //                 id_detail={row.original.id}
      //                 codaccounts={row.original.accounting_account}
      //                 codconcept={row.original.concept}
      //                 codamount={row.original.ammount}
      //                 codeffect={row.original.effect}
      //                 codidConcept={row.original.id_account}
      //               ></UPDATE_DETAIL_POLICY>,
      //               {
      //                 title: "Editar detalle del gasto",
      //                 className: "modalTItle",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             {
      //               handleReconsulta(result);
      //             }
      //           }}
      //           className="centerText, taxes"
      //         ></img>

      //         <img
      //           title="Eliminar Detalle"
      //           src={DELETE}
      //           alt=""
      //           className="centerText, taxes"
      //           onClick={async () => {
      //             const result = await Confirm(
      //               "¿Esta usted seguro de eliminar este detalle del gasto?",
      //               "Eliminar Detalle",
      //               "Si",
      //               "No"
      //             );
      //             if (result) {
      //               handle_delete_policy_detail(row.original.id);
      //             } else {
      //             }
      //           }}
      //         ></img>
      //       </div>
      //     );
      //   },
      // },

      {
        id: "concept",
        header: "Concepto",
        accessorKey: "concept",
      },
      {
        id: "accounting_account",
        header: "Cuenta",
        accessorKey: "accounting_account",
      },
      {
        id: "ammount", 
        header: "Importe",
        accessorKey: "ammount",
      },
      {
        header: "Efecto",
        accessorKey: "effect",
        id: "effect"
      },

      {
        header: "RFC",
        accessorKey: "rfc",
        id: "rfc"
      },

      {
        id: "uuid",
        header: "UUID",
        accessorKey: "uuid",
      },
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        
        <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>

        <div className="TableConsultarTodo2">
          <div class="success-msg" style={{ display: success_msg }}>
            <h2>
              <i class="fa fa-check"></i>
              {equality}
            </h2>
          </div>
          <div class="error-msg" style={{ display: error_msg }}>
            <h2>
              <i class="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
          
          
        </div>
        <div className="new_table_v2">
          <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () =>(
              <div>
                  <img
                    title="Registrar Detalle"
                    src={ADD}
                    style={{ "cursor": "pointer" }}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <CREATE_DETAIL_POLICY 
                         id_policy={props.row.original.id}
                        ></CREATE_DETAIL_POLICY>,
                        {
                          className: "custom-modal",
                          title: "Registrar Detalle",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
           <RENDER_ROW_ACTION_MENU_ITEMS
             closeMenu={closeMenu}
             setmodalT={props?.setmodalT}
             row={row}
             id = {props?.row?.original?.id}
             handleSubmit={handleSubmit}
             setreconsult={setreconsult}
             refresh_token={refresh_token}
             alertas={props?.alertas}
           />
         ]}
         renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              id = {props?.row?.original?.id}
              file_name={"Polizas_detalles"}
              //update_identifiers={update_identifiers}
              create_modul={"policy_details"}
              setmodalT={props?.setmodalT}
              handleSubmit={handleSubmit}
              alertas={props?.alertas}
            />
          )
          }
      />

          </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default DETAILS_POLICY_V2;
