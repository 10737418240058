import { useRef, useState } from 'react';
import "./config.css";
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { put_config_user } from '../../../services/chat/chat';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import loadgif from "../../../imgComponents/iconos/loading.gif";


export const CONFIG = (props) => {

  const[fondo, setfondo]=useState(false);
  const[base64, setbase64]=useState("");
  const[ext, setext]=useState("");
  const[nombre, setnombre]=useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [load, setload]=useState(false);

  const handleImageUpload = (e) => {
    const selectedFile = e?.target?.files[0];
  
    if(selectedFile===undefined){

    }else{
        const d = selectedFile?.name?.split(".");
        setnombre(d[0]);
        setext(d[1]);
        
        if (selectedFile) {
            const reader = new FileReader();
            const imageUrl = URL.createObjectURL(selectedFile);
            setImageSrc(imageUrl);

            reader.onload = (event) => {
                const base64Image = event.target.result;
                setbase64(base64Image);
            };
            reader.readAsDataURL(selectedFile);
        }
    }
  };
  const actualizar = async()=>{

    setload(true);
    var data ={
        "extension": ext,
        "base64": base64,
        "image": nombre, 
    }
    
    var rf_token = await refresh_token();
    if(rf_token===true){
        setTimeout(async() => {
            try{
                const d = await put_config_user(props.usuario, data);
                console.log(d);
                props.handleconfiguser("reconsult");
                setbase64("");
                setext("");
                setnombre("");
                setImageSrc(null);

                setTimeout(() => {
                    setload(false);
                }, 1500);
            }catch(err){
                console.log(err);
                setload(false);
            }
        }, 1500);
    }else{}

    console.log(data);
  }

  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  }; 

  return (
    <div className='container-config'>
        <div className='title-new-chat-mss'>
            <ArrowBackIcon onClick={()=>props.settools(false)}></ArrowBackIcon>
            <h2 className='conf-title'>Configuración</h2>
        </div>
        
        <div className='opcionlist'>    
            <div className='fondo' onClick={()=>{setfondo(!fondo)}}>
                <AddPhotoAlternateIcon></AddPhotoAlternateIcon>
                <p>Fondo de Pantalla de Chat</p>
            </div>
            {
                load===true?
                    <div className='load-img-upload'>
                        <img src={loadgif} alt=''></img>
                        <p>Actualizando fondo...</p>
                    </div>:
                fondo===true?
                <>
                 <div className='upload_img'>
                    <div className='upload-img-event'
                        onClick={handleDivClick}
                    >
                        <input
                            type='file'
                            accept='.jpg, .jpeg, .png'
                            onChange={(e) => handleImageUpload(e)} 
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                        <p>Da clic para seleccionar imagen</p>
                        <div className=''>
                            {imageSrc && <img src={imageSrc} alt='Imagen seleccionada' className='imgseleccc' />}
                        </div>
                    </div>
                    
                    {
                        imageSrc?
                        <div className='boton-fondos'>
                            <div className="bbtt1" onClick={()=>actualizar()}>
                                <p>Cambiar</p>
                            </div>
                            <div className='bbtt2' onClick={
                                    ()=>{
                                        setfondo(false)
                                        setbase64("");
                                        setext("");
                                        setnombre("");
                                        setImageSrc(null);
                                    }
                                }>
                                <p>Cancelar</p>
                            </div>
                        </div>:
                        <></>
                    }
                 </div> 
              </>:<></>
            }
        </div>
    </div>
  )
}
