import { useEffect, useRef, useState } from 'react';
import "./chat.css";
import { refresh_token } from '../main_components/tokens/tokenrefresh';
import { get_config_user, get_conversation_users, get_new_message, put_message_multimedia, put_new_message } from '../services/chat/chat';
import SearchIcon from '@mui/icons-material/Search';
import { LOADING_CHAT } from './tools/loading';
import { NEWCHAT } from './tools/new_chat/newchat';
import AddCommentIcon from '@mui/icons-material/AddComment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BANDEJA } from './tools/inbox/bandeja';
import { get_users } from '../services/configuration/configuration';
import { CONFIG } from './tools/config_user/config';
import { UPLOAD_IMAGE_CHAT } from './tools/upload_mult';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SendIcon from '@mui/icons-material/Send';
import iconopdf from "../imgComponents/pdf dowload.png";
import iconogs from "../imgComponents/export-gs.png";
import iconfile from "../imgComponents/archivo.png";
import recordgif from "../imgComponents/audio.gif";
import { HISTORY_CONVERSATION } from './tools/history_conversation/history';
import { BOTSMIT } from './tools/bot/botsmit';

export const CONVERSATION = (props) => {


  const [newmess, setnewmess] = useState("");
  const [conversation_data, set_conversation_data] = useState([]);
  const [socket, setsocket] = useState(null);
  const [data, setdata] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [databandeja, setdatabandeja] = useState([]);
  const [datauser, setdatauser] = useState([]);
  const [tools, settools] = useState(false);
  const od = props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email;
  const [newchat, setnewchat] = useState(false);
  const [tiposend, settiposend] = useState("text");
  const [imageSrc, setImageSrc] = useState(null);
  const [filesrc, setfilesrc] = useState(null);
  const [close, setclose] = useState(false);

  const [audiosrc, setaudiosrc] = useState(null);
  const [record, setrecord] = useState(false);

  const [dataimg, setdataimg] = useState({});
  const [dataaudio, setdataaudio] = useState(null);

  const [databandejasuport, setdatabandejasuport] = useState([{
    email: "soporte@herramientasinformaticas.com",
    bandeja: false
  }]);
  const audioRef = useRef(null);


  //web socket
  useEffect(() => {
    const newsocket = new WebSocket(`wss://ivilfu3by6.execute-api.us-east-1.amazonaws.com/chat_smit?email=${props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email}&name=${props?.tipo === "soporte" ? "soporte" : props?.origen?.attributes?.name}`);
    // Listen for the WebSocket to open
    newsocket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened');
    });
    newsocket.addEventListener("message", event => {
      console.log("Mensaje recibido:", event.data);
      //opcion_message(event.data);
      handleReceiveMessage(event.data);
    });

    newsocket.addEventListener("close", event => {
      console.log("Conexión cerrada:", event);
    });

    setsocket(newsocket);
    setdatabandeja(props?.usuarios);

    return () => {
      newsocket.close();
    };
  }, []);



  //consulta de bandeja de entrada
  useEffect(() => {
    get_user_chat(props?.tipo === "soporte" ? "soporte@herramientasinformaticas.com" : props?.origen?.attributes?.email);
    get_user_company();
    handleconfiguser("");
  }, [])

  const get_user_chat = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          const d = await get_conversation_users(id);
          d.data.sort((a, b) => {
            const dateA = new Date(`${a.conversacion.fecha}T${a.conversacion.hora}`);
            const dateB = new Date(`${b.conversacion.fecha}T${b.conversacion.hora}`);
            return dateB - dateA;
          });

          setdata(d?.data);
          setdatabandeja(d?.data);
        } catch (err) {
          console.log(err);
        }
      } else { }
    } catch (err) {
      console.log(err);
    }
  }
  const get_user_company = async () => {
    try {

      const d = await get_users();

      const dlast = d?.data?.users?.filter(item => item.email !== (props.origen === "soporte" ? props.origen : props?.origen?.attributes?.email));

      const datanew = {
        id: 7777,
        username: "SoporteSmit",
        accountnonexpired: true,
        accountnonlocked: true,
        credentialsnonexpired: true,
        editable: true,
        email: "soporte@herramientasinformaticas.com",
        failedattempts: 0,
        fullusername: "Soporte Smit",
        id_company_fk: 1,
        job: "SOPORTE",
        phone: "2727033141",
        user_enable: true,
      }
      dlast[dlast.length] = datanew;
      setdatauser(dlast);
    } catch (err) {
      console.log(err);
    }
  }

  const handleReceiveMessage = (message) => {
    console.log(message);
    console.log(localStorage.getItem("id_chat_destino_smit"));
    get_hanlemessage();
  };

  const select = (id, name) => {

    if (id === localStorage.getItem("id_chat_destino_smit") && localStorage.getItem("band_chats") === "true") {
      localStorage.setItem("band_chats", "false");
      localStorage.setItem("id_chat_destino_smit", "none");
      setclose(false);
    } else {
      localStorage.setItem("id_chat_destino_smit", id);
      localStorage.setItem("name_chat_destino_smit", name)
      setclose(true);
      const newDataBandeja = [...databandejasuport];
      newDataBandeja.forEach((item) => {
        if (item.email === id) {
          item.bandeja = false;
        }
      });
      setdatabandejasuport(newDataBandeja);

      localStorage.setItem("band_chats", "true");
      get_hanlemessage();
    }
  }

  const filtrarchat = (e) => {
    const lowercasedKeyword = e.toLowerCase();
    setFilterKeyword(e);
    const filteredResult = data.filter(item => item?.name_destino?.toLowerCase().includes(lowercasedKeyword));
    setdatabandeja(filteredResult);
  }

  const send_message_socket = async (mss) => {

    if (socket.readyState === WebSocket.OPEN) {
      const messageObject = {
        action: 'sendMessage',
        message: mss,
        "email-origen": props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email,
        "email-destino": localStorage.getItem("id_chat_destino_smit")
      };
      socket.send(JSON.stringify(messageObject));
    } else {
      console.log('WebSocket is not in the OPEN state. Message not sent.');
    }
  }
  const get_hanlemessage = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {

        try {
          const d = await get_new_message(props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email, localStorage.getItem("id_chat_destino_smit"));
          d.data.sort((a, b) => {
            const dateA = new Date(a.fecha);
            const dateB = new Date(b.fecha);
            return dateA - dateB;
          });
          set_conversation_data(d?.data);
          var imageUrl = localStorage.getItem("imgfondo");;
          var conversationElement = document?.querySelector('.conversation');
          conversationElement.style.backgroundImage = 'url("' + imageUrl + '")';

        } catch (err) {
          console.log(err);
        }
      } else { }
    } catch (err) {
      console.log(err);
    }
  }

  const [bot, setbot] = useState(false);


  const handlenewmessage = async () => {

    if (newmess === "/start") {
      setbot(true);
    }

    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
    const hours = String(fechaActual.getHours()).padStart(2, '0');
    const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
    const seconds = String(fechaActual.getSeconds()).padStart(2, '0');

    const fechaFormateada = `${year}-${month}-${day}`;
    const horaFormateada = `${hours}:${minutes}:${seconds}`

    var data = {}
    var id = "";

    data = {
      fecha: fechaFormateada,
      origen: props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email,
      usuario_origen: props?.tipo === "soporte" ? "smit" : props?.origen?.attributes?.name,
      destino: localStorage.getItem("id_chat_destino_smit"),
      usuario_destino: localStorage.getItem("name_chat_destino_smit"),
      hora: horaFormateada,
      message: newmess,
      tipo: "text"
    }
    id = props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email;

    if (newmess.trim() !== "") {

      setnewmess("");

      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              const d = await put_new_message(data, id);
              send_message_socket(data.message);
              get_hanlemessage();
              get_user_chat(props.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email);
            } catch (err) {
              console.log(err);
            }
          }, 1000);
        } else { }

      } catch (err) {
        console.log(err);
      }
    } else {

    }
  }
  const handlenewmessageimg = async () => {

    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
    const hours = String(fechaActual.getHours()).padStart(2, '0');
    const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
    const seconds = String(fechaActual.getSeconds()).padStart(2, '0');

    const fechaFormateada = `${year}-${month}-${day}`;
    const horaFormateada = `${hours}:${minutes}:${seconds}`
    var data = {}
    var id = "";

    data = {
      fecha: fechaFormateada,
      origen: props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email,
      usuario_origen: props?.tipo === "soporte" ? "smit" : props?.origen?.attributes?.name,
      destino: localStorage.getItem("id_chat_destino_smit"),
      usuario_destino: localStorage.getItem("name_chat_destino_smit"),
      hora: horaFormateada,
      tipo: dataimg?.tipo,
      ext: dataimg?.ext,
      name_archivo: dataimg?.name_archivo,
      base64: dataimg?.base64
    }
    id = props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email;
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await put_message_multimedia(id, data);
            send_message_socket("");
            get_hanlemessage();
            get_user_chat(props.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email);
            setnewmess("");
            settiposend("text");
            setImageSrc("");
            setfilesrc("");
          } catch (err) {
            console.log(err);
            setnewmess("");

          }
        }, 1000);
      } else { }

    } catch (err) {
      console.log(err);
    }

  }
  const handlenewmessageaud = async () => {


    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
    const hours = String(fechaActual.getHours()).padStart(2, '0');
    const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
    const seconds = String(fechaActual.getSeconds()).padStart(2, '0');
    const fechaFormateada = `${year}-${month}-${day}`;
    const horaFormateada = `${hours}:${minutes}:${seconds}`

    var id = "";
    var audib64 = "";
    const blob = dataaudio.blob;

    try {
      audib64 = await convertirBlobABase64(blob);
    } catch (error) {
      console.error('Error al convertir Blob a base64:', error);
    }
    var data = {
      fecha: fechaFormateada,
      origen: props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email,
      usuario_origen: props?.tipo === "soporte" ? "smit" : props?.origen?.attributes?.name,
      destino: localStorage.getItem("id_chat_destino_smit"),
      usuario_destino: localStorage.getItem("name_chat_destino_smit"),
      hora: horaFormateada,
      tipo: "audio",
      ext: "webm",
      name_archivo: `${fechaFormateada}-${horaFormateada}-audio`,
      base64: audib64
    }
    id = props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email;

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await put_message_multimedia(id, data);
            send_message_socket("");
            get_hanlemessage();
            get_user_chat(props.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email);
            setnewmess("");
            settiposend("text");
            setImageSrc("");
            setfilesrc("");
            setdataaudio(null);
            setaudiosrc("");
          } catch (err) {
            console.log(err);
            setnewmess("");

          }
        }, 1000);
      } else { }

    } catch (err) {
      console.log(err);
    }
  };
  const convertirBlobABase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const result = fileReader.result;
        resolve(result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };

      fileReader.readAsDataURL(blob);
    });
  };


  const nuevochat = () => {
    setnewchat(true);
  }
  //configuracion del usario
  const handleconfiguser = async (rec) => {

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_config_user(props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email);
            if (d?.data.length !== 0) {
              if (rec !== "") {
                localStorage.setItem("imgfondo", d?.data?.fondo);
                if (close === true) {
                  var imageUrl = d?.data?.fondo;;
                  var conversationElement = document?.querySelector('.conversation');
                  conversationElement.style.backgroundImage = 'url("' + imageUrl + '")';
                }
              } else {
                localStorage.setItem("imgfondo", d?.data?.fondo);
              }
            }
          } catch (err) {
            console.log(err);
          }
        }, 1500);
      } else { }
    } catch (err) {
      console.log(err);
    }

  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        if (tiposend === "text") {
          handlenewmessage();
        }
      }
    }
  }
  useEffect(() => {
    if (dataaudio && dataaudio.blob) {
      const audioBlob = dataaudio.blob;
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;

    }
  }, [dataaudio])



  return (

    <div className='grid-chat'>
      <div className='chats-archivados'>
        {
          tools === true ?
            <CONFIG settools={settools} usuario={props?.origen?.attributes?.email} handleconfiguser={handleconfiguser}></CONFIG> :
            newchat === false ?
              <>
                <div className='configuracion'>
                  <div className=''></div>
                  <div className=''></div>
                  <div className=''></div>
                  <div className='new_mss_icon'>
                    <AddCommentIcon onClick={() => nuevochat()}></AddCommentIcon>
                  </div>
                  <div className='ppp'>
                    <MoreVertIcon onClick={() => settools(true)}></MoreVertIcon>
                  </div>

                </div>
                <div className='search-user'>
                  <SearchIcon></SearchIcon>
                  <input placeholder='Buscar un chat'
                    value={filterKeyword}
                    onChange={(e) => filtrarchat(e.target.value)}
                  >
                  </input>
                </div>
                {
                  databandeja?.map(item => (
                    <>
                      <div className='conversations'
                        onClick={() => { select(item?.email_destino, item?.conversacion?.name_destino) }}
                      >
                        {
                          <BANDEJA item={item}></BANDEJA>
                        }
                      </div>
                    </>
                  ))
                }
              </> : <NEWCHAT setnewchat={setnewchat} select={select} datauser={datauser}></NEWCHAT>
        }
      </div>
      {
        close === true ?
          <div className='chats-online'>
            <div className='chat-actual'>
              <div className='circle'></div>
              <p>{localStorage.getItem("id_chat_destino_smit")}</p>
            </div>
            <HISTORY_CONVERSATION conversation_data={conversation_data} od={od}></HISTORY_CONVERSATION>
            {
              bot === true ?
                <BOTSMIT setbot={setbot} data={conversation_data}></BOTSMIT> :
                <div className='iniciar-chat'>
                  <UPLOAD_IMAGE_CHAT
                    userorigen={props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email}
                    userdestino={localStorage.getItem("id_chat_destino_smit")}
                    settiposend={settiposend}
                    setImageSrc={setImageSrc}
                    setfilesrc={setfilesrc}
                    setaudiosrc={setaudiosrc}
                    setdataimg={setdataimg}
                    setdataaudio={setdataaudio}
                    setrecord={setrecord}
                  ></UPLOAD_IMAGE_CHAT>
                  <textarea
                    className='input-chat'
                    value={newmess}
                    placeholder='Escribe aqui'
                    disabled={tiposend === "text" ? false : true}
                    onChange={(e) => setnewmess(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    style={{ resize: 'none' }}
                  >
                  </textarea>

                  {
                    tiposend === "text" ? <SendIcon className='buttton-send-icon' onClick={() => handlenewmessage()}></SendIcon> :
                      tiposend === "img" || tiposend === "archivo" ? <SendIcon className='buttton-send-icon' onClick={() => handlenewmessageimg()}></SendIcon> :
                        tiposend === "audio" ? <SendIcon className='buttton-send-icon' onClick={() => handlenewmessageaud()}></SendIcon> :
                          <></>
                  }

                </div>
            }
            {
              imageSrc === null || imageSrc === "" ?
                <></> :
                <div className='upload-img'>
                  <img src={imageSrc}></img>
                  <HighlightOffIcon onClick={
                    () => { setImageSrc(""); settiposend("text") }}></HighlightOffIcon>
                </div>
            }
            {
              filesrc === null || filesrc === "" ?
                <></> :
                <div className='upload-file'>
                  {
                    dataimg?.ext === "pdf" || dataimg?.ext === "PDF" ?
                      <>
                        <img src={iconopdf} alt=''></img>
                        <p>{dataimg?.name_archivo}.{dataimg?.ext}</p>
                      </> :
                      dataimg?.ext === "xlsx" || dataimg?.ext === "csv" ?
                        <>
                          <img src={iconogs} alt=''></img>
                          <p>{dataimg?.name_archivo}.{dataimg?.ext}</p>
                        </> :
                        <>
                          <img src={iconfile} alt=''></img>
                          <p>{dataimg?.name_archivo}.{dataimg?.ext}</p>
                        </>
                  }

                  <HighlightOffIcon onClick={
                    () => {
                      setImageSrc("");
                      settiposend("text");
                      setfilesrc("");
                      setaudiosrc("");
                    }}>
                  </HighlightOffIcon>
                </div>
            }
            {
              record === true ?
                <div className='upload-file recordgif'>
                  Grabando...
                  <img src={recordgif} alt=''></img>

                </div> : <></>
            }
            {
              dataaudio === null ? <></> :
                <div className='upload-file upload-audio-mp3'>
                  <audio ref={audioRef} controls className='reproductor-audio-smit' />
                  <HighlightOffIcon onClick={
                    () => {
                      setImageSrc("");
                      settiposend("text");
                      setfilesrc("");
                      setaudiosrc("");
                      setdataaudio(null);
                    }}>
                  </HighlightOffIcon>
                </div>
            }
          </div>
          :
          <LOADING_CHAT />
      }
    </div>
  )
}
