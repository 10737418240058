import {
  Box,
  Button,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { CustomDialog } from "react-st-modal";
import SELECT_DATE_REPORT from '../report/select_date_report';
import AssessmentIcon from '@mui/icons-material/Assessment';

const SEARCH_FILTERS = (props) => {
  
  console.log(props.fi)
  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          sx={{ width: 150 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          sx={{ width: 150 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>

      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', borderRadius: "15px", width: "100%" }}
            onClick={(e) => { props.handle_get_sales() }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 200 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='GENERAR REPORTE'
            sx={{ height: '35px', marginLeft: "5px", width: "100%", background: 'green', '&:hover': { background: 'green' } }}
            onClick={async () => {

              await CustomDialog(
                <SELECT_DATE_REPORT
                  range_dates={props?.range_dates}
                  data={props?.data}
                ></SELECT_DATE_REPORT>,
                {
                  className: "custom-modal-report",
                  title: `Seleccione una de las fechas de registro`,
                  showCloseIcon: true,
                }
              );


            }} >
            GENERAR REPORTE <AssessmentIcon></AssessmentIcon>
          </Button>
        </Box>
      </FormControl>

    </Box>
  );
};

export default SEARCH_FILTERS;
