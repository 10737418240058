import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid } from '@mui/material';
import { ValidationTextField } from '../../../main_components/lable/labe'; // Asegúrate de que el componente esté correctamente importado
import { FormData, Props } from '../interface/interface';

const FORM_PAY_CONDITIONS: React.FC<Props> = ({ open, onClose, data, onSave, isNew }) => {
    const [formData, setFormData] = useState<FormData>(data);
    const [errors, setErrors] = useState<Record<string, string>>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numValue = Number(value?.toUpperCase());

    setFormData(prev => ({
        ...prev,
        [name]: numValue,
        description: name === 'term' ? (numValue === 0 ? 'Contado' : `CRÉDITO ${numValue} DÍA${numValue > 1 ? 'S' : ''}`) : prev.description
    }));
        setErrors(prev => ({ ...prev, [name]: '' }));
    };

    const validateField = (name: string, value: number) => {
        let error = '';

        switch (name) {
            case 'term':
                if (value === undefined || value === null) {
                    error = 'El campo es obligatorio';
                } else if (value < 0) {
                    error = 'El valor debe ser un número positivo';
                }
                break;
            // Puedes agregar más casos de validación aquí
        }

        return error;
    };

    const handleSubmit = () => {
        let validationErrors: Record<string, string> = {};

        if (formData && typeof formData === 'object') {
            Object.keys(formData).forEach(key => {
                const error = validateField(key, Number(formData[key]));
                if (error) {
                    validationErrors[key] = error;
                }
            });
        }
        console.log((validationErrors).length)

        if (Object.keys(validationErrors).length > 0) {
            console.log(validationErrors)
            setErrors(validationErrors);
        } else {
            // Aquí puedes manejar la lógica de envío del formulario si todo es válido
            console.log(formData)

            onSave(formData,true);
        }
    };

    useEffect(() => {
        setFormData(data);
    }, [data, open]);

    useEffect(() => {
        if (!open) {
            setErrors({});
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} sx={{ maxWidth: "380px", margin: "0 auto" }} fullWidth>
            <DialogTitle sx={{ fontSize: "16px", padding: "0px", margin: "0 auto" }}>{isNew ? 'Crear Condición de Pago' : 'Editar Condición de Pago'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {[
                        { label: "Días de crédito", accessor: "term", sm: 12, dfl: 0, type: 'number' },
                    ].map(field => (
                        <Grid item xs={12} sm={field.sm} style={{ paddingTop: "22px" }} key={field.accessor} >
                            <ValidationTextField
                                label={field.label}
                                required
                                placeholder={field.label}
                                variant="outlined"
                                name={field.accessor}
                                size='medium'
                                value={formData?.[field.accessor]}
                                type={field.type}
                                onChange={handleChange}
                                sx={{ width: "100%" }}
                                error={!!errors?.[field.accessor]}
                                helperText={errors?.[field.accessor]}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: "0px", margin: "0 auto", width: "90%" }}>
                <Button onClick={onClose} variant="contained" color="error" sx={{ m: .5, width: "100%" }}>Cancelar</Button>
                <Button onClick={handleSubmit} variant="contained" color="success" sx={{ m: .5, width: "100%" }}>
                    {isNew ? 'Crear' : 'Actualizar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FORM_PAY_CONDITIONS;
