import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import TableContainer from "../../../componentSociosNegocios/componentpromotions/SubTableContainer";
import SubTable from "./consult_cabinets";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_data_businesspartners, get_businesspartners_rfc } from "../../../services/businesspartners/businesspartners";

function DataExt(dponit) {
  console.log(dponit);

  const dialog = useDialog();
  const [data, setdata] = useState([]);
  const [modalT, setmodalT] = useState(false);
  const [comentary, setcomentary] = useState(dponit.system_commentary);
  useEffect(() => {
    if(dponit?.method==="UPDATE"){   
    handleSubmitG();
    handleSubmitGs();
  }
  }, []);

  //consulta de la direccion de un punto de un punto de venta
  const handleSubmitG = async() => {
    try{
      console.log("entra")
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_businesspartners_rfc(dponit?.bp, dponit?.rfc);
             //console.log(response);
            setdata(d===null?[]:d?.data?.pointOfSaleList);
          }catch(err){
            console.log("error", err);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }

  };

  const handleSubmitGs = async() => {
    
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{  
            const d = await get_data_businesspartners(dponit?.id);
            console.log(d);
            comentarios(d===null?[]:d.data.system_commentary);
          }catch(err){
            console.log(err);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  function comentarios(com) {
    const newText = com.replace(/\|\|/g, "\n");
    const newText2 = newText.replace(/\|/g, "\n");
    const newText3 = newText2.replace(
      "Importación manual.",
      "Importación manual.\n"
    );
    const parrafos = newText3.split("\n\n");
    parrafos.reverse();
    const invertedtext = parrafos.join("\n\n");
    setcomentary(invertedtext?.trim());
  }

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: "Acciones",
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("cabinetsBs", row.original.id);
          }

          return (
            <a {...row.getToggleRowExpandedProps()}>
              {row?.isExpanded ? (
                <span>🔼</span>
              ) : (
                <span onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
      },
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Punto de venta",
        accessor: "name",
      },
      {
        Header: "RFC",
        accessor: "rfc",
      },
      {
        Header: "Ruta",
        accessor: "route",
      },
      {
        Header: "Fecha de registro",
        accessor: "date_register",
      },
    ],
    []
  );

  return (
    <div>
      <div className="formulariopointofsale">
        <div>
          <h1 className="h1conge">
            Puntos de venta(s) que pertenecen al socio de negocios:{" "}
          </h1>
          <br></br>
          <div className="tableInfExt">
            <TableContainer
              exportar={false}
              columns={columns}
              data={data}
              infEx={true}
              Gmodal={modalT}
              minfilas={true}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div>
          <br></br>

          <div className="contenirdivH">
            <h1 className="h1conge">Historial de actualizaciones: </h1>

            <textarea
              className="textareapuntosventa"
              value={comentary}
            ></textarea>
          </div>
        </div>

        <br></br>
        <br></br>
        <Box>
          {/* <Button
            style={{ "background-color": "red" }}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            onClick={() => {
              dialog.close();
            }}
          >
            cerrar
          </Button> */}
        </Box>
      </div>
    </div>
  );
}
export default DataExt;
