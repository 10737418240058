export const modulos = [
    {value: '',label: 'Seleccione un módulo',},
    {value: 'GENERAL', label: 'GENERAL'},
    {value: 'ACTIVOS',label: 'ACTIVOS'},
    {value: 'BANCOS',label: 'BANCOS'},
    {value: 'COLABORADORES', label: 'COLABORADORES'},
    {value: 'FINANZAS',label: 'FINANZAS',},
    {value: 'INVENTARIOS',label: 'INVENTARIOS',},
    {value: 'REPORTES',label: 'REPORTES',},
    {value: 'COMPRAS',label: 'COMPRAS',},
    {value: 'FACTURAS',label: 'FACTURAS',},
    {value: 'SOCIO DE NEGOCIOS',label: 'SOCIO DE NEGOCIOS',},
    {value: 'OPERACIONES CON UNILEVER',label: 'OPERACIONES CON UNILEVER',},
    {value: 'SOPORTE', label: 'SOPORTE'},
    {value: 'CONFIGURACION', label: "CONFIGURACION"},
    // {value: 'ANDROID MÓVIL',label: 'ANDROID MÓVIL',}
];

export const general_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'GEN', label: 'General',}
];

export const activos_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'ACT_CONG', label: 'Congeladores'},
    {value: 'ACT_MOVC', label: 'Movimiento Congeladores'},
    {value: 'ACT_MARM',label: 'Marcas y modelos'},
    {value: 'ACT_VEHC',label: 'Vehículos'}
];

export const bancos_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'BAN_CORC',label: 'Corte de caja',},
    {value: 'BAN_DEUD',label: 'Deudas',},
    {value: 'BAN_PAGO', label: 'Pagos'},
    {value: 'BAN_ANTS', label: 'Antiguedad de Saldos'},
    {value: 'BAN_REPE', Label: 'Reporte de Ernesto'}
];

export const colaboradores_categories = [
    {value: '000', label: 'Seleccione un valor'},
    {value: 'COL_EMPL', label: 'Empleados'},
    {value: 'COL_COND', label: 'Conductores'}, 
    {value: 'COL_ASIG', label: 'Asignación de Personal'},
    {value: 'COL_REGC', label: 'Registro de Comisiones'}
]

export const finanzas_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'FIN_CCON',label: 'Cuentas Contables'},
    {value: 'FIN_CPAG', label: "CUentas de Pago"}
];

export const inventarios_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'INV_LINE', label: 'Línea de productos',},
    {value: 'INV_PROD', label: 'Productos',},
    {value: 'INV_LIST', label: 'Lista de precios'},
    {value: 'INV_ALMP', label: 'Almacén de productos',},
    {value: 'INV_MOVP', label: 'Movimientos de productos',},
    {value: 'INV_CARP', label: 'Carga de Productos'}
];

//falta agregar categorias
export const reportes_categories = [
    {value: '000',label: 'Seleccione un valor',},
    // {value: 'REP_DETV',label: 'Detalles de ventas',},
    {value: 'REP_MAPR',label: 'Mapa de rutas',},
    {value: 'REP_REPV',label: 'Reportes de Ventas'},
    {value: 'REP_VEXR',label: 'Reporte de ventas por ruta',},
    {value: 'REP_VETR', label: 'Reporte de ventas en tiempo real',},
    {value: 'REP_SUMA',label: 'Reporte sumaria anual',},
    {value: 'REP_SUMS',label: 'Reporte sumaria semanal',},
];


export const compras_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'COM_CDEN',label: 'Consultar documentos envíados',},
    {value: 'COM_ENVD',label: 'Envío de documentos',},
    {value: 'COM_PROV', label: 'Consultar Proveedores'},
    {value: 'COM_CATG',label: 'Catálogo de gastos',},
    {value: 'COM_REGG',label: 'Reglas de gastos',},
    {value: 'COM_GAND',label: 'Gastos no deducibles',},
    {value: 'COM_GAEX',label: 'Gastos del extranjero',},   
    {value: 'COM_REPG',label: 'Reporte de gastos',},
    {value: 'COM_REGA',label: 'Reporte de gastos agrupados',},
    {value: 'COM_POLI',label: 'Pólizas',},   
];

export const facturas_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'FAC_CONC',label: 'Consultar conceptos',},
    {value: 'FAC_FACT',label: 'Consultar facturas',},
    {value: 'FAC_FPPD',label: 'Consultar facturas PPD',},
    {value: 'FAC_IMPS',label: 'Consultar impuestos',},
    {value: 'FAC_CART',label: 'Realizar Carta Porte',},
    {value: 'FAC_RCPA', label: 'Realizar complemento de pago',},
    {value: 'FAC_RFAC',label: 'Realizar factura',},
    {value: 'FAC_REPC', label: 'Reporte de Complementos de Pago'}
];

export const snegocios_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'SDN_DESC',label: 'Consultar descuentos',},
    {value: 'SDN_PROM',label: 'Consultar promociones',},
    {value: 'SDN_PVEN',label: 'Consultar puntos de venta',},
    {value: 'SDN_PVSS',label: "Puntos de Venta sin Sector"},
    {value: 'SDN_SECT',label: 'Consultar sectores',},
    {value: 'SDN_SDNS',label: 'Consultar socio de negocios',},
    {value: 'SDN_RSNS',label: 'Recuperar socio de negocios',},
    {value: 'SDN_REGG',label: 'Registro general',}
];

export const unilever_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'OPU_BONS', label: 'Reporte de bonificaciones',},
    {value: 'OPU_DMS', label: 'DMS'}
];

export const soporte = [
    {value: '000', label: 'Seleccione un valor'},
    {value: 'SOP_CDA', label: "Centro de ayuda"},
]

export const configuration_categories = [
    { value: '000', label: 'Seleccione un valor'},
    { value: 'CON_RUTS', label: 'Rutas'},
    { value: 'CON_ALMS', label: 'Almacenes'},
    { value: 'CON_GRUT', label: 'Gestión Rutas'}
]
