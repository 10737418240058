//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { useDialog, Confirm } from "react-st-modal";

//COMPONENTES

//ESTILOS

import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../../../main_components/methods_v2/export";
import { get_warehouses } from "../../../../../services/cashclosing/cashclosing";
import { calculate_total } from "../../../../../main_components/methods_v2/auxiliary_functions";
import { update_null_identifiers } from "../../../../../main_components/methods_v2/auxiliar_funtion";
import { BREADCRUMBS } from "../../../../../main_components/pagination/breadcrumbs";
import ADD from "../../../../../imgComponents/agregar.png"
import { toinvoice_fromdocument_autoventa } from "../../../../../services/cashclosing/cashclosing";
import MODAL_TABLE from "../../../../../main_components/modal/modal_Table";
function CONSULT_CASHCLOSING_SECTORS(props) {
  const [total_rows, settotal_rows] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [total_products, settotal_products] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState(props.sectors);
  const [modalT, setmodalT] = useState("");

  const dialog = useDialog();

  console.log(props.sectors)

  console.log(props.id_route)

  const handel_toinvoce_fromdocument = async (id_sector) => {
    try {
      try {
        console.log(props.id_route)
        console.log(id_sector)
        setmodalT(true);

        var result = await toinvoice_fromdocument_autoventa([], props.id_route, id_sector);
        alert("Éxito, las cartas porte se están generando...");

      } catch (err) {
        console.log(err)
        console.log(err?.response)
        console.log(err?.response?.status)
        if (err?.response?.status === 500) {
          alert(err?.response?.data?.errorMessage);
        }
        else if (err?.response?.status === 502) {
          alert("Las cartas se están generando, recibirás un correo electrónico al finalizar.")
        }
        else if (err?.response?.status === 504) {
          alert("Las cartas se están generando, recibirás un correo electrónico al finalizar.")
        }
        else if (err?.code === "ERR_NETWORK") {
          alert("Las cartas se están generando, recibirás un correo electrónico al finalizar.")
        }
        else if (err?.response?.status === 401) {
          alert("Token expirado.")
        }
        else {
          alert("Error no contemplado, favor de comunicarse con soporte.")
        }

      }
    } catch (err) {
      console.log(err);
    } finally {
      setmodalT(false);
    }
  };

  const update_identifiers =
    [
      { quantity: "number" },
      { code: "string" },
      { description: "string" },

    ];
  const handle_warehose = async () => {

    setData([]);
    setIsLoading(true);
    var dta;
    try {
      const dt = await get_warehouses(sessionStorage.getItem("selectwarehouse"), sessionStorage.getItem("datawarehose"));
      const data = dt.data.sort((a, b) => a.quantity < b.quantity ? 1 : a.quantity > b.quantity ? -1 : 0);
      dta = update_null_identifiers(data?.filter((d) => d.quantity !== 0), update_identifiers);
      settotal_products(dta?.map(obj => obj.quantity).filter(d => d !== null));
      setData(dta);

    } catch (err) {
      console.log("error", err);
      setData([]);

    } finally {
      setIsLoading(false)
    }
  };



  const columns = useMemo(
    () => [

      {
        id: "action",
        header: "Acciones",
        accessorFn: (d) => {


          return (<img src={ADD}
            onClick={async () => {
              const result = await Confirm(
                "SECTOR SELECCIONADO: " + d.name_sector,
                "¿ESTA USTED SEGURO DE GENERAR LAS CARTAS PORTE PARA ESTE SECTOR?, DE NO SER ASI DE AL BOTON DE CANCELAR PARA ESCOGER OTRO"
              );
              if (result) {
                handel_toinvoce_fromdocument(d.id)
                dialog.close();
              } else {
              }
            }}

          ></img>)
        }
      },
      {
        id: "name_sector",
        header: "Nombre del Sector",
        accessorKey: "name_sector",
        size:300
      },
      {
        id: "number_sector",
        header: "Número del Sector",
        accessorKey: "number_sector",
        size:300

      },
    ],

    [data, props.sectors]
  );

  useEffect(() => {
    handle_warehose();
  }, [])

  return (
    <div>
      <MODAL_TABLE modalGeneral={modalT} modalT={modalT} message="Generando reporte..."></MODAL_TABLE>

      <MaterialReactTable
        columns={columns}
        data={props.sectors}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}



        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);

            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }




        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx}
            share_filter={false}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"Productos"}
            update_identifiers={update_identifiers}
          />
        )
        }
      />

    </div>

  );
}

export default CONSULT_CASHCLOSING_SECTORS;
