//PAQUETERIAS
import { useEffect, useState, useMemo, useRef} from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./RecoveryPartner.css"
//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";

import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_pos_recover } from "../../services/businesspartners/businesspartners";
//IMAGENES/ICONOS
import RECOVERY from "../../imgComponents/reconsultaTable.png"
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RECOVERY_POS from "./tools/recovery_pos";


const RECOVERY_PARTNER = () => {

const [data, setData] = useState([]);
const [open, setopen]=useState(false);  
var entrada=0;
const notificationSystemRef = useRef();
const [modalT, setmodalT] = useState(false);
const [menssage, setmenssage] = useState("");
const [modalGeneral, setmodalGeneral] = useState(false);

useEffect(()=>{
  handleSubmit();
},[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}

const handleSubmit =async () => {  
  if(entrada===0){
    setmenssage("Buscando Socios de Negocios...");
    entrada=entrada+1;
    setmodalGeneral(true);
    setmodalT(true);
  }else{
    setmodalT(true);
  }
  setData([]);
  setopen(true);
  
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_pos_recover();
          setData(d === null ? [] : d.data.data);  
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        }catch(err){
          setData([]);
          setopen(false)
          setmodalGeneral(false);
          setmodalT(false);
        }      
      }, 1000);
    }else{}
  } catch (error) {}
};

const columns = useMemo(
  () => [
    { 
    Header:"Acciones",

    Cell: ({ row }) => {
        return (
          <div>
            <img
              src={RECOVERY}
                     onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                   <RECOVERY_POS alertas={alertas} handleSubmit={handleSubmit} row={row} setmodalT={setmodalT}/>
                 </div>,
               {
                 className: "modalpuntosventa",
                 title:"Recuperar Socio de Negocios",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           );
         }
        }
              
              title="Recuperar Socio de Negocios"
              alt=""
            ></img>   
          </div>
        );
      },
    },
     {
       Header: "Código Punto de Venta",
       accessor: (d)=>{
        return d?.point_of_sale?.code;
       },
     },
     {
      Header: "Punto de Venta",
      accessor: (d)=>{
        return d?.point_of_sale?.name;
      }
     },
     {
      Header:"Código Socio",
      accessor: (d)=>{
        return d?.business_partner?.code;
      }
     },
    {
       Header: "Socio de Negocios",
       accessor: (d)=>{
        return d?.business_partner?.business_partner;
       },
    },
    {
      Header: "RFC",
      accessor: (d)=>{
        return d.business_partner.rfc;
      },
    },
    ],
    []
);
  return (
    <>

<div className="containeritems py-2">
<NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

<MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
  ></MODAL_TABLE>
    <BREADCRUMBS niveles={
          [
            {label: "RECUPERAR SOCIO DE NEGOCIOS", path: null}, 
          ]
        }
        new_version={true}
        modul="RECUPERAR SOCIO DE NEGOCIOS V2"
        path={"/consult_recovery_partner_v2"}
    ></BREADCRUMBS>
    <br/><br/><br/>

</div>

      <div className="Tablerecoveryb">
        <div style={{ width: "98%", height: "100%", paddingLeft: "20px" }}>
    
        <div className="tablerecposbus">
        <StickyTable>
              <TableContainer
                paginado={"Socios de Negocios"}
                consultas={"Socios de Negocios consultados"}
                nametable={"Recuperar Socio de Negocios"}
                exportar={true}
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                Gmodal={open}
              />
            </StickyTable>
        </div>
               
          </div>
        </div>
    </>
  );
}

export default RECOVERY_PARTNER;
