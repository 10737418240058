//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, useDialog } from "react-st-modal";
import TextField from "@mui/material/TextField";

//COMPONENTES
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_routes } from "../../services/cashclosing/cashclosing";
import { get_sales } from "../../services/unilever/unilever";
//ESTILOS
import "../../main_components/Table/tablecontainer.css";
import "./dms.css";

//IMAGENES


import MODAL_TABLE from "../../main_components/modal/modal_Table";

//COMPONENTES


const sales_type = [
  { value: "PROPIA", label: "PROPIA" },
  { value: "SECUNDARIA", label: "SECUNDARIA" },
];
const INTERFAZ_SALES = () => {

  const [data, setData] = useState([]);
  const [menssage, setmenssage] = useState([]);
  const [modalGeneral, setmodalGeneral] = useState([]);
  const [fi, setfi] = useState(false);
  const [ff, setff] = useState(false);
  const [routes, setroutes] = useState([]);
  const [selectRoute, setselectRoute] = useState("TODAS");
  const [selectsaletype, setselectsaletype] = useState("PROPIA");

  
  const [modalT, setmodalT] = useState(false);
  
  
  const dialog = useDialog();
  console.log(selectRoute)


  const handleSubmit = async (event) => {
    event?.preventDefault();

    setmodalT(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            await get_sales(fi,ff,selectRoute,selectsaletype);
            setmodalGeneral(false);
            setmodalT(false);
            window.location="/DMS_V2"

          } catch (err) {
            setmodalGeneral(false);
            setmodalT(false);
            alert(JSON.stringify(err.response.data))
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_router = async () => {
    try {
      const dt = await get_routes();
      const data = dt.data;
      var dtAdd = {
        route: "TODAS",
      };
      data.push(dtAdd);
      console.log(data);
      setroutes(data);
    } catch (error) {
      setroutes([]);
    }
  };

  useEffect(() => {
    handle_router();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>

      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      <div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%", // Esto es opcional según tus necesidades
          }}
        >
          <div
            className="contenerControllerReport"
          >
            <div className="bp-1f">
              <input
                type="text"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha inicial"
                onChange={(event) => setfi(event.target.value)}
              ></input>
            </div>
            <div className="bp-2f">
              <input
                type="text"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha Final"
                onChange={(event) => setff(event.target.value)}
              ></input>
            </div>
          </div>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%", // Esto es opcional según tus necesidades
          }}
        >
          <div
            className="contenerControllerReport"
          >
            <div className="bp-1f">
            <TextField
              select
              label="Ruta"
              child
              value={selectRoute}
              sx={{ width: "100%" }}
              onChange={(e)=>setselectRoute(e.target.value)}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {routes?.map((option) => (
                <option
                  key={option?.route}
                  value={option?.route}
                  selected={option?.route}
                >
                  {option?.route}
                </option>
              ))}
            </TextField>
            </div>
            <div className="bp-2f">
            <TextField
              select
              label="TIpo de venta"
              child
              value={selectsaletype}
              sx={{ width: "100%" }}
              onChange={(e)=>setselectsaletype(e.target.value)}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {sales_type?.map((option) => (
                <option
                  key={option?.value}
                  value={option?.value}
                  selected={option?.value}
                >
                  {option?.label}
                </option>
              ))}
            </TextField>
            </div>
          </div>
        </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
        <Button
          variant="contained"
          sx={{ width: "44%", marginLeft: "5%" }}
          className="ButtonModal"
          onClick={() => handleSubmit()}
        >
          Generar Interfaz
        </Button>

        <Button
          variant="contained"
          sx={{ width: "44%", marginRight: "5%" }}
          className="ButtonModal2"
          onClick={() => {
            dialog.close();
          }}
        >
          Cancelar
        </Button>
      </Box>

      <br></br>

    </div>

    </div >

  );
};

export default INTERFAZ_SALES;
