import { useEffect, useMemo, useRef, useState } from 'react';
import {
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import { CustomDialog, Confirm } from "react-st-modal";
import DELETE from "../../../../../imgComponents/borrar.png";
import VISUALIZAR from "../../../../../imgComponents/lupa.png";
import { refresh_token } from '../../../../../main_components/tokens/tokenrefresh';
import { delete_expense_group } from '../../../../../services/expenses/expenses';

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const ConsultDetailGrupExpense = async () => {

        if (sessionStorage.getItem("PERMISOPOLICY") === "ROLE_POLICY") {
            sessionStorage.setItem("IdGrupExpense", props?.row.original?.id);
            sessionStorage.setItem(
              "nameConceptExpense",
              props?.row.original?.concept
            );
            window.location = "/expensesGrupDetail_v2";
          } else {
            alert(
              "No cuentas con el rol necesario para visualizar los detalles de grupo., Solicita el rol con tu supervisor."
            );
          }
    };

    const handle_delete_expense_group = async (id)=>{
        props?.setmodalT(true);
        try {
          var rf_token = await refresh_token();
          if (rf_token === true) {
            try {
              const d = await delete_expense_group(id);
              console.log(d);
              props?.setmodalT(false);
              props?.handleSubmit();
            } catch (err) {
                props?.setmodalT(false);
                alert("Error.," + err.response?.data?.errorMessage);
            }
          } else {
          }
        } catch (err) {
          console.log(err);
        }
      }
    


    return (
        <div>
            <MenuItem
                key={"keymenu1"}
                sx={{ m: 0 }}
                onClick={async () => {
                   props.closeMenu();
                   ConsultDetailGrupExpense();
                    
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={VISUALIZAR}
                        className="mapa_icono"
                        title="Ver Detalles"
                    ></img>
                </ListItemIcon>
                Ver Detalles
            </MenuItem>
            <MenuItem
                key={"keymenu1"}
                sx={{ m: 0 }}
                onClick={async () => {
                   props.closeMenu();
                   const result = await Confirm(
                      "¿Esta usted seguro de eliminar este grupo de gastos?",
                      "Eliminar Grupo de gastos",
                      "Si",
                      "No"
                    );
                    if (result) {
                        handle_delete_expense_group(props?.row.original.id);
                    } else {}
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={DELETE}
                        className="mapa_icono"
                        title="Eliminar Grupo de Gastos"
                    ></img>
                </ListItemIcon>
                Eliminar Grupo de Gastos
            </MenuItem>

        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
