import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import TocIcon from '@mui/icons-material/Toc';
import "./search_filter_web.css";

export const SEARCH_FILTER_WEB_MOVIL = (props) => {

  return (
    <>
      {
        props?.minimenu===true?
          props?.openmini===true?
            <div className='content-minwid-menu'>
              <div className='back-mini-menu'>
                <FilterListOffIcon onClick={()=>props?.setopenmini(false)}></FilterListOffIcon>
                <h2>Filtros de Tabla</h2>
              </div>
              <>{props?.filterMovComponent}</>
            </div>:
            <div className='openmenu'>
              <p onClick={()=>props?.setopenmini(true)}>Filtros de búsqueda <TocIcon ></TocIcon></p>  
            </div>:
            <>
             {props?.renderTopToolbarCustomActionsFilters===true?<></>:<>{props.filterMovComponent}</>}
            </>
            
      }
    </>
  )
}
