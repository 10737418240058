//PAQUETERIAS
import { useEffect, useState, useMemo, useRef} from "react";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import "./almacenes.css";
import { delete_warehouse, get_warehouse } from "../../services/configuration/configuration";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import OPERATIONS_WAREHOUSE from "./tools/op_warehouse";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const WAREHOUSES_V2 = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  


  useEffect(()=>{
    handleSubmit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const update_identifiers =
[
  { code: 'string' },
  { warehouse_name: 'string' },
  { description: 'String' },
  { type: 'string' },
  { date_register: 'string' },
];
const handleSubmit = async () => {
    
    setmodalT(true);
    setopen(true);
    setData([]);
    setIsLoading(true);
    

    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_warehouse();
            var ndt = update_null_identifiers(d?.data?.warehouses, update_identifiers);
            setData(ndt === null ? [] : ndt); 
            setopen(false);
            setIsLoading(false);
            setmodalT(false);
          }catch(err){
            setData([]);
            setopen(false);
            setIsLoading(false);
            setmodalT(false);
          }              
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
};

const columns = useMemo(
  () => [
    // {
    //   Header:()=>(
    //     <div>
    //       <img
    //         id="crearRol"
    //         src={CREATE}
    //         onClick={() => {
    //           CustomDialog(               
    //          <div>
    //           <EditWarehouse operation={"CREATE"} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}/>
    //          </div>,
    //        {
    //          className: "modalTItle",
    //          title:"Crear Almacen",
    //          showCloseIcon: true,
    //          isCanClose:false,
    //        }
    //    );
    //  }}
    //         title="Crear Almacén"
    //         className=""
    //         alt=""
    //       ></img>
    //     </div>
    //   ),
      
    //   id: "Acciones",
    //   Cell: ({ valor, row }) => {
    //   const DeleteWH = async () => {  
      
    //   setmodalT(true); 
    //   setData([]);
    //   setopen(true);

    //     try {
    //       var rf_token = await refresh_token();
    //       if(rf_token===true){
    //         setTimeout(async() => {
    //           try{
    //             const dt = await delete_warehouse(row.original.id);
    //             console.log(dt);
    //             alertas("Almacén Eliminado", true);
    //             handleSubmit();
    //           }catch(err){
    //             console.log("error", err);
    //             setopen(false);
    //             alertas("Error, no se pudo eliminar el almacén", false);
    //             setmodalT(false);
    //             handleSubmit();
    //           }         
    //         }, 1000);
    //       }else{}
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    //     return (
    //       <div>
    //         <img
    //           src={Delete}
    //           onClick={async() =>{
    //               const result = await Confirm('¿Esta usted seguro de Eliminar el Almacén','Eliminar Almacén','Si','No');
    //               if(result){
    //                 DeleteWH()
    //                 }
    //               }            
    //             }
              
    //           title="Eliminar Almacén"
    //           alt=""
    //         ></img>
    //           &nbsp;
    //         <img
    //           src={Edit}
    //           onClick={
    //             () => {
    //               CustomDialog(               
    //              <div>
    //                 <EditWarehouse operation={"EDIT"} row={row} handleSubmit={handleSubmit} reconsultar={reconsultar} alertas={alertas}></EditWarehouse>
    //              </div>,
    //            {
    //              className: "modalTItle",
    //              title:"Editar Almacén",
    //              showCloseIcon: true,
    //              isCanClose:false,
    //            }
    //        ); 
    //      }
    //     }
              
    //           title="Editar Almacén"
    //           alt=""
    //         ></img>
    //       </div>
    //     );
    //   },
    // },
    {
      id: "code",
      header: "Código",
      accessorKey:"code",
      muiTableBodyCellProps: {
        align: "center"
      }
    },
    {
      id: "warehouse_name",
      header:"Nombre",
      accessorKey:"warehouse_name"
    },
    {
      id: "description",
      header:"Descripción",
      accessorKey:"description",
      size: 300
    },
    {
      id: "type",
      header:"Tipo",
      accessorKey:"type",
      filterVariant: 'select',
      muiTableBodyCellProps: {
        align: "center"
      }
    },
    {
      id: "date_register",
      header:"Fecha registro",
      accessorKey:"date_register",
      size: 200
    }
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>
    <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR ALMACENES", path: null},
                ]
              }
        
      ></BREADCRUMBS>
<div className='new_table_v2'>
  <div className="">      

    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>  
           <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img
                title="Registrar Almácen"
                src={CREATE}
                className=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <OPERATIONS_WAREHOUSE
                        operation={"CREATE"}
                        handleSubmit={handleSubmit} 
                        setmodalT={setmodalT} 
                        alertas={alertas} 
                    />,
                    {
                      className: "custom-modal-3",
                      title: "Registrar Almácen",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                }}
              ></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
               closeMenu={closeMenu}
                setmodalT={setmodalT}
                setmenssage={setmenssage}
                setmodalGeneral={setmodalGeneral}
                row={row}
                handleSubmit={handleSubmit}
                setreconsult={setreconsult}
                refresh_token={refresh_token}
                alertas={alertas}
              />
            ]}
           renderToolbarInternalActions={({ table }) => (
             <RENDER_TOOLBAR_INTERNAL_ACTIONS
               table={table} 
               file_name={"Consultar Almacenes"}
               update_identifiers={update_identifiers}
               alertas={alertas}
             />
           )
        }
      />

          </div>
        </div>
    </>
  );
}

export default WAREHOUSES_V2;
