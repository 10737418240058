// Importaciones de paquetes
import React, { useEffect, useMemo, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";

// Importaciones de componentes
import { refresh_token } from "../../main_components/tokens/tokenrefresh";

import { get_packages } from "../../services/unilever/unilever";
import {
  formattedCurrentDate,
  formattedPreviousDate,
} from "../../main_components/date/day";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import SEARCH_FILTERS from "./tools/shearch_filter";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { previous_date, date_current } from "../../main_components/date/date";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

interface Package {
  id: string;
  package_name: string;
  state: string;
  deliver_name: string;
  date_register: string;
  send_date: string;
  interface_type: string;
  fullusername: string;
  bonification_package_name:string;
}

interface RowSelection {
  [key: string]: boolean;
}



interface PackageResponse {
  data: {
    records: Package[];
  };
}

const DMS_V2: React.FC = () => {
  const [data, setData] = useState<Package[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const [reconsult, setreconsult] = useState<boolean>(false);
  const [fi, setfi] = useState<Dayjs>(dayjs(previous_date));
  const [ff, setff] = useState<Dayjs>(dayjs(date_current));
  const [modalT, setmodalT] = useState<boolean>(false);
  const [menssage, setmenssage] = useState<string>("");
  const [modalGeneral, setmodalGeneral] = useState<boolean>(false);

  console.log(data);

  const columns: MRT_ColumnDef<Package>[] = useMemo(
    () => [
      {
        id: "package_name",
        header: "Paquete",
        accessorKey: "package_name",
        size: 300,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "state",
        header: "Estado",
        accessorKey: "state",
        size: 180,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "deliver_name",
        header: "Reportes",
        accessorKey: "deliver_name",
        accessorFn: (d) => d?.deliver_name,
        size: 350,
        muiTableBodyCellProps: {
          align: "center",
          sx: {
            fontSize: "12.5px",
          },
        },
      },
      {
        id: "date_register",
        header: "Generado",
        accessorKey: "date_register",
        size: 180,
        muiTableBodyCellProps: {
          align: "justify",
        },
      },
      {
        id: "send_date",
        header: "Envío",
        accessorKey: "send_date",
        size: 180,
        muiTableBodyCellProps: {
          align: "justify",
        },
      },
      {
        id: "interface_type",
        header: "Interfaz",
        accessorKey: "interface_type",
        size: 200,
        muiTableBodyCellProps: {
          align: "center",
        },
      },

      {
        id: "fullusername",
        header: "Usuario",
        accessorKey: "fullusername",
        size: 280,
        muiTableBodyCellProps: {
          align: "justify",
        },
      },
      {
        id: "bonification_package_name",
        header: "Paquete",
        accessorKey: "bonification_package_name",
        size: 150,
        muiTableBodyCellProps: {
          align: "justify",
        },
      },
    ],
    []
  );

  const update_identifiers =
  [
    { package_name: 'string' },
    { state: 'string' },
    { deliver_name: 'String' },
    { date_register: 'string' },
    { send_date: 'string' },
    { interface_type: 'string' },
    { fullusername: 'string' },
    {bonification_package_name:'string'}
  ];

  const handle_get_invoice = async (
    event?: React.SyntheticEvent
  ): Promise<void> => {
    event?.preventDefault();
    setIsLoading(true);
    setData([]);
    try {
      const rf_token: boolean = await refresh_token();
      if (rf_token) {
        setTimeout(async () => {
          try {
            const dt: PackageResponse = await get_packages(
              fi.format("YYYY-MM-DD"),
              ff.format("YYYY-MM-DD")
            );
            var ndt = update_null_identifiers(dt?.data?.records, update_identifiers);
            setData(ndt);
          } catch (err) {
            console.error("Error fetching packages:", err);
            setData([]);
          } finally {
            setIsLoading(false);
          }
        }, 1000);
      }
    } catch (err) {
      console.error("Error in refresh token or other process:", err);
    }
  };

  useEffect(() => {
    handle_get_invoice();
  }, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }} className="new_table_v2">
      <BREADCRUMBS niveles={[{ label: "DMS", path: null }]}></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        message2={""}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        // enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{
          color: "secondary",
          thickness: 5,
          size: 55,
        }}
        muiSkeletonProps={{ animation: "pulse", height: 28 }}
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "69vh",
            "@media (max-height: 810px)": { maxHeight: "65vh" },
          },
        }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode="pages"
        rowPinningDisplayMode="sticky"
        layoutMode="grid"
        muiTableHeadCellProps={{
          sx: { flex: "0 0 auto", whiteSpace: "normal !important" },
        }}
        muiTableBodyCellProps={{
          sx: { flex: "0 0 auto", whiteSpace: "normal !important" },
        }}
        muiPaginationProps={{
          color: "primary",
          shape: "rounded",
        }}
        displayColumnDefOptions={{
          "mrt-row-pin": {
            enableHiding: true,
          },
          "mrt-row-actions": {
            enableHiding: true,
          },
          "mrt-row-expand": {
            enableHiding: true,
          },
          "mrt-row-select": {
            enableHiding: true,
          },
        }}
        muiTableBodyRowProps={({ row, table }) => {
          const { density } = table.getState();
          return {
            sx: {
              height: row.getIsPinned()
                ? `${
                    density === "compact"
                      ? 30
                      : density === "comfortable"
                      ? 35
                      : 69
                  }px`
                : undefined,
            },
          };
        }}
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS
            key={"actionmenu" + row?.original?.id}
            closeMenu={closeMenu}
            setmodalT={setmodalT}
            setmenssage={setmenssage}
            setmodalGeneral={setmodalGeneral}
            row={row}
            handle_get_invoice={handle_get_invoice}
            setreconsult={setreconsult}
            refresh_token={refresh_token}
          />,
        ]}
        renderTopToolbarCustomActions={({}) => (
          <SEARCH_FILTERS
            fi={fi}
            setfi={setfi}
            ff={ff}
            setff={setff}
            handle_search={handle_get_invoice}
          ></SEARCH_FILTERS>
        )}
        renderToolbarInternalActions={({ table }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table}
            file_name={
              "FACTURAS DEL " +
              fi?.format("YYYY-MM-DD") +
              " AL " +
              ff?.format("YYYY-MM-DD")
            }
            update_identifiers={[]}
          />
        )}
      />
    </div>
  );
};

export default DMS_V2;
